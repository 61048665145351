import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';


import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import {firestore} from 'firebase';
import {AngularFireStorage} from '@angular/fire/storage';
import {Router} from '@angular/router';
import {finalize, map, shareReplay, tap} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';
import {dbItem, ProductItem, UploadFile, UploadFileList} from './../models/tools.model';
import {BehaviorSubject, Observable} from 'rxjs';

declare const require: any;



@Injectable({
  providedIn: 'root'
})
export class DbService {
  loaded = false;
  supply = 'main';
  private loadedFileListOb: BehaviorSubject<boolean>;
  public loadedDataOb: BehaviorSubject<boolean>;
  private loadedOrderOb: BehaviorSubject<boolean>;
  private loadedFontOb: BehaviorSubject<number>;
  private uploadFileEventOb: BehaviorSubject<UploadFile>;
  data;
  currentOrderID;
  order;
  orderID = {};
  dataProductID = {};
  dataOBsupply;
  orderOBsupply;
  filesListOB;
  fileList: UploadFileList = {img:[]};
  dbOptList: dbItem[];
  dbOB;
  fonts;
  fontCount = 0;

  // Files
  addFile(file) {
    const newID = this.fs.createId();
    file.id = newID;
    this.fs.collection('files').doc(newID).set(file).then(r => {
      console.log('file created:' + file);
    });
  }
  deleteFile2(id) {
    this.fs.collection('files').doc(id).delete().then(r => {
      console.log('file deleted:' + id);
    });
  }

  productTypes = [
    {type: 'businessCard', name: 'Business Cards', page: 0},
    {type: 'invitationCard', name: 'Invitation Cards', page: 0},
    {type: 'walletInsert', name: 'Wallet Insert', page: 0},
    {type: 'dogTag', name: 'Dog Tags', page: 0},
    {type: 'keyChain', name: 'Key Chains', page: 0},
    {type: 'jewlery', name: 'Jewlery', page: 0}
    ];
  materialTypes = [
    {type: 'cardBlack', name: 'Card (black)'},
    {type: 'cardBlue', name: 'Card (blue)'},
    {type: 'cardRed', name: 'Card (red)'},
    {type: 'cardGold', name: 'Card (gold)'},
    {type: 'cardSilver', name: 'Card (silver)'},
    {type: 'dogTag', name: 'dogTag'},
    {type: 'disk1', name: 'disk1'},
    {type: 'disk2', name: 'disk2'},
    {type: 'square1', name: 'square2'}
  ];
  pricePack = {
    card: [
    {count: 1, price: 5.99, sku: 'sku_FmNnH6SuMa7g0Y', priceB: 0.50, skuB: 'sku_Fss23fsNPPCW7V'},
    {count: 10, price: 19.99, sku: 'sku_FqYxyVXOiOnN7v', priceB: 0.50, skuB: 'sku_Fss23fsNPPCW7V'},
    {count: 25, price: 34.99, sku: 'sku_FqYyFltZT0CKYc', priceB: 0.50, skuB: 'sku_Fss23fsNPPCW7V'},
    {count: 50, price: 59.99, sku: 'sku_FqYyWs6gBcccwS', priceB: 0.50, skuB: 'sku_Fss23fsNPPCW7V'},
    {count: 100, price: 99.99, sku: 'sku_FqYzzFb4qovWss', priceB: 0.50, skuB: 'sku_Fss23fsNPPCW7V'}
    ],
    dogtag: [
      {count: 1, price: 15.99, sku: 'sku_FqZ7eMHxwMOrqT', priceB: 5.0, skuB: 'sku_Fss4cxfAblkWyI'}
      ],
    earing: [
      {count: 1, price: 19.99, sku: 'sku_FqZ6Ba3JVv6kXM', priceB: 5.0, skuB: 'sku_Fss4cxfAblkWyI'}
    ],
    insert: [
      {count: 1, price: 19.99, sku: 'sku_FrDQ1ea0sS0emT', priceB: 5.0, skuB: 'sku_Fss4cxfAblkWyI'}
    ],
  };
  selectedProductType = 'businessCard';
  previewScale = 0;
  getPricePack(card) {
    if (this.pricePack[card]) {
      return this.pricePack[card];
    } else {
      return this.pricePack['tag'];
    }
  }
  getPrices(product, orderConfig) {
    let result;
    console.log(product, orderConfig);
    if ( this.pricePack[product.pricePack] && this.pricePack[product.pricePack][orderConfig.selectedPack] ) {
      const priceP = this.pricePack[product.pricePack][orderConfig.selectedPack];
      if (product.genOptB) {
        result = {
          totalPrice: orderConfig.quantity * priceP.price + (orderConfig.quantity * priceP.count * priceP.priceB),
          totalPriceB: (orderConfig.quantity * priceP.count * priceP.priceB),
          unitPrice: (orderConfig.quantity * priceP.price + (orderConfig.quantity * priceP.count * priceP.priceB)) / (orderConfig.quantity * priceP.count),
          totalUnits: orderConfig.quantity * priceP.count,
          sku: priceP.sku,
          skuB: priceP.skuB,
          pricePack: this.pricePack[product.pricePack]
        };
      } else {
        result = {
          totalPrice: orderConfig.quantity * priceP.price,
          totalPriceB: 0,
          unitPrice: (orderConfig.quantity * priceP.price) / (orderConfig.quantity * priceP.count),
          totalUnits: orderConfig.quantity * priceP.count,
          sku: priceP.sku,
          skuB: false,
          pricePack: this.pricePack[product.pricePack]
        };
      }
    } else {
      result = {
        totalPrice: 0,
        unitPrice: 0,
        totalUnits: 0,
        sku: 0,
        pricePack: this.pricePack['tag']
      };
    }
    return result;
  }
  createFontPreview(font) {
    const self = this;
    const path = font.getPath('A', 0, 25, 30);
    const fullpath = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" version="1.1">` + path.toSVG() + `</svg>`;
    return self.sanitizer.bypassSecurityTrustHtml(fullpath);
  }
  loadFonts() {
    const self = this;
    const opentype = require('./../../assets/opentype.js');
    self.fonts = Array.apply(null, Array(38));
    opentype.load('./../../assets/fonts/Ewert/Ewert-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {

        self.fonts[0] = ({name: 'Ewert', font: font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/Fruktur/Fruktur-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[1] = ({name:'Fruktur',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/Pacifico/Pacifico-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[2] = ({name:'Pacifico',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/Creepster/Creepster-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[3] = ({name:'Creepster',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/Kunstler/KUNSTLER.TTF', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[4] = ({name:'Kunstler',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/BellMT/BELL.TTF', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[5] = ({name:'Bell MT',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/BellMT/BellMTBold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[6] = ({name:'Bell MT Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/Faster_One/FasterOne-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[7] = ({name:'Faster-One',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/AmaticSC-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[8] = ({name:'AmaticSC',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/Cinzel-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[9] = ({name:'Cinzel-Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/Cinzel-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[10] = ({name:'Cinzel',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/Codystar-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[11] = ({name:'Codystar',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/DancingScript-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[12] = ({name:'DancingScript-B',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/DancingScript-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[13] = ({name:'DancingScript',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
    opentype.load('./../../assets/fonts/new/JuliusSansOne-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[14] = ({name:'JuliusSansOne',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Michroma-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[15] = ({name:'Michroma',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Monofett-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[16] = ({name:'Monofett',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Monoton-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[17] = ({name:'Monoton',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/MountainsofChristmas-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[18] = ({name:'MountainsofChristmas-Regular',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/OpenSansCondensed-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[19] = ({name:'OpenSansCondensed-Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });



    opentype.load('./../../assets/fonts/new/OpenSansCondensed-Light.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[20] = ({name:'OpenSansCondensed-Light',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/OpenSansCondensed-LightItalic.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[21] = ({name:'OpenSansCondensed-LightItalic',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/PoiretOne-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[22] = ({name:'PoiretOne',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Quicksand-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[23] = ({name:'Quicksand-Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Quicksand-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[24] = ({name:'Quicksand',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/ReenieBeanie-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[25] = ({name:'ReenieBeanie',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Righteous-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[26] = ({name:'Righteous',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Rubik-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[27] = ({name:'Rubik-Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Rubik-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[28] = ({name:'Rubik-Regular',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Rubik-RegularItalic.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[29] = ({name:'Rubik-Italic',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/RugeBoogie-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[30] = ({name:'RugeBoogie',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Sacramento-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[31] = ({name:'Sacramento',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/SairaStencilOne-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[32] = ({name:'SairaStencilOne',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/Satisfy-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[33] = ({name:'Satisfy',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });




    opentype.load('./../../assets/fonts/new/SecularOne-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[34] = ({name:'SecularOne',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/TitilliumWeb-Bold.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[35] = ({name:'TitilliumWeb-Bold',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/TitilliumWeb-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[36] = ({name:'TitilliumWeb',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });

    opentype.load('./../../assets/fonts/new/UnifrakturMaguntia-Regular.ttf', function (err, font) {
      if (err) {
        console.log('the font could not be loaded :(');
      } else {
        self.fonts[37] = ({name:'UnifrakturMaguntia',font:font, preview: self.createFontPreview(font)});
        self.fontCount += 1;
        self.loadedFontOb.next(self.fontCount);
      }
    });
  }
  constructor(public fs: AngularFirestore,
              public fsStorage: AngularFireStorage,
              public  router: Router,
              public storage: AngularFireStorage,
              private db: AngularFireDatabase,
              private sanitizer: DomSanitizer) {
    this.loadedFileListOb = new BehaviorSubject<boolean>(false);
    this.loadedDataOb = new BehaviorSubject<boolean>(false);
    this.loadedOrderOb = new BehaviorSubject<boolean>(false);
    this.loadedFontOb = new BehaviorSubject<number>(0);
    this.uploadFileEventOb = new BehaviorSubject<UploadFile>({}as UploadFile);
    this.setSupply(this.supply);
    this.loadFonts();
    this.setFileList();
    // this.setDBopt();
    this.filesListOB.subscribe(files => {
      this.fileList = files as UploadFileList;
      this.loadedFileListOb.next(true);
      this.loaded = true;
    });
    // this.dbOB.subscribe(dbRef => {
    //  console.log(dbRef);
    //  this.dbOptList = dbRef;
    // });

  }
  public getLoadedFileListOb(): Observable<boolean> {
    return this.loadedFileListOb.asObservable();
  }
  public getLoadedDataOb(): Observable<boolean> {
    return this.loadedDataOb.asObservable();
  }
  public getLoadedOrderOb(): Observable<boolean> {
    return this.loadedOrderOb.asObservable();
  }
  public getLoadedFontOb(): Observable<number> {
    return this.loadedFontOb.asObservable();
  }
  public getUploadFileEventOb(): Observable<UploadFile> {
    return this.uploadFileEventOb.asObservable();
  }
  setSupply(supply) {
    const self = this;
    this.supply = supply;
    this.dataOBsupply = this.fs.doc('data/' + this.supply).valueChanges().pipe(shareReplay(1));
    this.dataOBsupply.subscribe(data => {
      this.data = data;
      if(data){
        this.data.product.forEach( p => {
            self.dataProductID[p.id] = p;
          }
        );
        this.loadedDataOb.next(true);
      }
    });
  }
  createSupply(supply) {
    const userRef: AngularFirestoreDocument<any> = this.fs.doc(`data/${supply}`);
    const data = {
      config: {
        commision: 0.5,
        tax: 0.07
      },
      material: [],
      product: []
    };
    return userRef.set(data , { merge: true });
  }
  saveSupply(data) {
    const self = this;
    console.log(data);
    return this.fs.doc('data/' + this.supply).update(data);
  }
  addProduct(prod) {
    this.data.product.push(prod);
    this.saveSupply(this.data);
  }

  getAllOrders() {
   return this.fs.collection('order').valueChanges().pipe(shareReplay(1));
  }

  updateOrderByUserId(userID, data) {
    const self = this;
    return this.fs.doc('order/' + userID).update(data);
  }
  setOrder(userID) {
    const self = this;
    if (this.currentOrderID === userID) {
    } else {
      this.orderOBsupply = this.fs.doc('order/' + userID).valueChanges().pipe(shareReplay(1));
      this.currentOrderID = userID;
      this.orderOBsupply.subscribe(data => {
        console.log(data);
        if (!data) {
          this.createOrder(userID);
        } else {
          this.order = data;
          this.order.product.forEach( p => {
              self.orderID[p.config.id] = p;
            }
          );
          this.loadedOrderOb.next(true);
        }
      });
    }
  }
  createOrder(userID) {
    const userRef: AngularFirestoreDocument<any> = this.fs.doc(`order/${userID}`);
    const data = {
      config: {
        user: userID
      },
      product: []
    };
    return userRef.set(data , { merge: true });
  }
  saveOrder(data) {
    const self = this;
    console.log(data);
    return this.fs.doc('order/' + data.config.user).update(data);
  }
  addOrder(prod, conf = null) {
    if (!conf) {
      conf = {
        id: Math.floor(Math.random() * 100000000),
        status: 'selected',
        pay: false,
        quantity: 1,
        note: ''
      };
    }
    this.order.product.push({product: prod, config: conf});
    this.saveOrder(this.order);
  }
  deleteOrder(order) {
      const id = this.order.product.indexOf(order);
      this.order.product.splice(id, 1 );
      this.saveOrder(this.order);
  }
  setDBopt() {
    this.dbOB = this.fs.doc('databases/opt').valueChanges().pipe(shareReplay(1));
  }
  saveDBopt() {
    const self = this;
    console.log(self.dbOptList);
    return this.fs.doc('databases/opt').update(self.dbOptList);
  }
  setFileList() {
    this.filesListOB = this.fs.doc('uploads/files').valueChanges().pipe(shareReplay(1));
  }
  saveFileList() {
    const self = this;
    // console.log(self.fileList);
    return this.fs.doc('uploads/files').set(self.fileList);
  }
  // general rupose upload.....
  startUpload( name, fileData) {
    // console.log(file, fileData );
    const self = this;
    // The storage rootPath
    const path = `produce/${name}`;
    // Reference to storage bucket
    const ref = this.storage.ref(path);
    // The main task
    const uploadTask = this.storage.upload(path, fileData);
    // Progress monitoring
    const percentage = uploadTask.percentageChanges();
    const snapshot = uploadTask.snapshotChanges().pipe(
       // tap(console.log),
      // The file's download URL
      finalize( async () =>  {
        // file.downloadURL = await ref.getDownloadURL().toPromise();
        // self.fileList.img.push(file);
        // self.saveFileList();
      }),
    );
    snapshot.subscribe(data => {
      // console.log(data);
    });
  }

  deleteFile(file: UploadFile) {
    const self = this;
    const desertRef = this.fsStorage.storage.refFromURL(file.downloadURL);
    // Delete the file
    desertRef.delete().then(e => {
      self.fileList.img.splice(self.fileList.img.indexOf(file), 1);
      self.saveFileList();
    }).catch(e => {
      console.log(e);
    });
  }

  updateFileList(list) {
    this.db.object(`device/files`).set(list);
    this.sendCom('updateFiles:');
  }
  // robot control
  getRobotConfig() {
    return this.db.object(`device/config`).valueChanges();
  }
  getRobotStatus() {
    return this.db.object(`device/status`).valueChanges();
  }
  updateRobotConfig(data) {
    return this.db.object(`device/config`).update(data);
  }
  sendCom(code) {
    return this.db.object(`device/test`).update({ com:code ,comExecuted:false});
  }
  sendComArr(code) {
    return this.db.object(`device/test`).update({ comArr:code ,comArrExecuted:false});
  }

}
