import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {round} from '../../iiLib';
import * as paper from 'paper';
@Component({
  selector: 'app-vector-set2d',
  templateUrl: './vector-set2d.component.svg',
  styleUrls: ['./vector-set2d.component.css']
})
export class VectorSet2dComponent implements OnInit, OnChanges {
@Input() value = [];
@Output() valueChange: EventEmitter<any> = new EventEmitter<any[]>();

  valueScaled = [];
  valueOpt = [];
  loaded = false;
  roundTo = 2;
  rawValue = [];

  fillColor = 'rgb(147,147,147)';
  fillColorBG = 'rgb(147,147,147)';
  d = '';
  uiSizeFactor = 3;
  snap = 5;
  uiSpacing = 10;
  grid = 20;
  gridSize = 20;
  scale = 1;
  opt = {};
  showIndex = false;
  lastSelected;
  @Input() offset = { x: 0, y: 0 };
  @Input() size = { x: 50, y: 25 };

  makeArr(n){
    return Array.from({length: n}, (v, i) => i);
  }
  addMidpoint(n){
    this.value.splice(n,0,[
      this.value[n][0]+(this.value[n-1][0]-this.value[n][0])/2,
      this.value[n][1]+(this.value[n-1][1]-this.value[n][1])/2
    ]);
    this.changeColor();
}
  setScale(n){
    this.scale += round(0.1*n,1);
    this.changeColor();
}
  clickMarker(marker){
    this.valueOpt[marker].selected = !this.valueOpt[marker].selected;
    this.lastSelected = marker;
    this.refreshMarkers();
    console.log(marker);
  }
  drop(i,e){
let x = Math.round((this.value[i][0]+(e.distance.x/this.scale))/this.snap)*this.snap;
let y = Math.round((this.value[i][1]+(e.distance.y/this.scale))/this.snap)*this.snap;

    this.value[i] = [x, y];
    this.changeColor();
    this.valueChange.emit(this.value);
    console.log(e);
  }

  dropLine(i,e){
    let x = Math.round((this.value[i][0]+(e.distance.x/this.scale))/this.snap)*this.snap;
    let y = Math.round((this.value[i][1]+(e.distance.y/this.scale))/this.snap)*this.snap;

    this.value[i] = [x, y];
    let x2 = Math.round((this.value[i+1][0]+(e.distance.x/this.scale))/this.snap)*this.snap;
    let y2 = Math.round((this.value[i+1][1]+(e.distance.y/this.scale))/this.snap)*this.snap;

    this.value[i+1] = [x2, y2];
    this.changeColor();
    this.valueChange.emit(this.value);
    console.log(e);
  }
  dropLineCenter(i,e){

    let x = Math.round((this.value[i][0]+(e.distance.x/this.scale))/this.snap)*this.snap;
    let y = Math.round((this.value[i][1]+(e.distance.y/this.scale))/this.snap)*this.snap;

    let x2 = Math.round((this.value[i+1][0]+(e.distance.x/this.scale))/this.snap)*this.snap;
    let y2 = Math.round((this.value[i+1][1]+(e.distance.y/this.scale))/this.snap)*this.snap;

    this.value.splice(i+1,0,[
      x,
      y
    ],[
      x2,
      y2
    ]);
    this.valueOpt[i].selected = true;
    this.valueOpt[i+2].selected = true;
    this.valueOpt[i].selected = true;

    this.changeColor();
    this.valueChange.emit(this.value);
  }
  dropPage(e){
    this.offset = {x:this.offset.x + Math.round(e.distance.x), y:this.offset.y + Math.round(e.distance.y)};
    this.changeColor();
  }
  movePage(e){
    //this.offset = {x:this.offset.x + Math.round(e.delta.x), y:this.offset.y + Math.round(e.delta.y)};
    //this.changeColor();
    //console.log(e);
  }
  deleteSelected(){
    let list = [];

    this.valueOpt.forEach((m,i,arr)=>{
      if(m.selected){
        list.push(i);
      }
    });

    list.forEach((m,i,arr)=>{
        this.valueOpt[m].selected = false;
        this.value.splice(m,1);
        this.valueScaled.splice(m,1);
        this.valueOpt.splice(m,1);
    });

    this.changeColor();
  }
  refreshMarkers(){
    this.valueOpt.forEach(m=>{
      if(m.selected){
        m.fill = 'rgb(255, 0, 0)';
      }else{
        m.fill = 'rgb(255, 255, 0)';
      }
    })
  }
  changeColor(scale = true) {
    let s;



    if (scale) {//Pass through
    } else {
      let maxX = -99999, minX = 99999;
      let maxY = -99999, minY = 99999;
      this.value.forEach(v => {
        if (v[0] < minX) {
          minX = v[0];
        } if (v[1] < minY) {
          minY = v[1];
        } if (v[0] > maxX) {
          maxX = v[0];
        } if (v[1] > maxY) {
          maxY = v[1];
        }
      });
      let dx = maxX-minX;
      let dy = maxY-minY;
      if ( dx-this.size.x > dy-this.size.y ) {
        s = this.size.x / dx;
      } else {
        s = this.size.y / dy;
      }
      this.scale = s;
    }

    let d = '';
    this.valueScaled = [];
    this.value.forEach( (v,i,r) => {
        let x = (v[0])*this.scale+this.offset.x, y = (v[1])*this.scale+this.offset.y;
        this.valueScaled.push([x, y]);
        this.valueOpt.push({selected:false, fill:'rgb(216,177,177)'});
        if (i===0) {
          d += ` M ${x} ${y}`;
        } else {
          d += ` L ${x} ${y}`;
        }
    });
    this.d = d;
    this.loaded = true;

  }

  constructor() {
    this.changeColor();
  }
  ngOnChanges(changes){
    this.changeColor();
  }

  ngOnInit(): void {

  }
  round(n, p) {
    let m = Math.pow(10,p);
    return Math.round(n*m)/m;
  }

}
