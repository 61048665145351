import {v3, deepEqual} from '../../iiLib';

declare const require: any;
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DbService} from '../../services/db.service';

import {CuraWASM} from 'cura-wasm';
import * as THREE from 'three';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
import {STLExporter} from 'three/examples/jsm/exporters/STLExporter';
const threeCSG = require('./../../3d/CSGMesh.js').default;
const {CSG} = require('@jscad/csg/src/api/index.js').csg;
const stlSerializer = require('@jscad/stl-serializer');
const exporterSTL = new STLExporter();
import Typeson from 'typeson';
import pako from 'pako';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);

declare var Slicer: any;
var Slicer = require('./../../../assets/lib/slicer.js').default;
const exporterGLTF = new GLTFExporter();
const dxfSerializer = require('@jscad/dxf-serializer');

class ActionData {
  run: any;
  delay: number;

  constructor(run, delay) {
    this.run = run;
    this.delay = delay;
  }

}

//class which executes a queue of actions as promises in a sequence
class ActionQueue {
  queue: any[];
  currentPromise: any;
  constructor() {
    this.queue = [];
    this.currentPromise = null;
  }

  add(run, delay=1000) {
    this.queue.push(new ActionData(run, delay));
  }

  next() {
    if (this.currentPromise) {
      this.currentPromise.run();
        //delay next action by 1 second
        setTimeout(() => {
          this.next();
          this.currentPromise = this.queue.shift();
        } , this.currentPromise.delay);
    } else if (this.queue.length > 0) {
      this.currentPromise = this.queue.shift();
      this.next();
    } else {
      this.currentPromise = null;
    }
  }

  start() {
    this.next();
  }
}




class MeshSlicer{
  mesh;
  //box;
  param;
  sliceLines = new THREE.Group();
  sliceCAG = [];
  slicePT = [];
  slicePath = [];
  ready = false;
  materialLine = new THREE.LineBasicMaterial({
    color: '#e88600'
  });
  constructor(mesh,public rootObj){
    this.mesh = mesh;
    this.param =  {
      // base params
      axis: "z",
      layerHeight: 1,
      lineWidth: 0.1,
      precision: 5,
      mode: "full",

      numWalls: 2,
      numTopLayers: 3,
      optimizeTopLayers: true,
      infillType: 0,
      infillDensity: 0.1,
      infillOverlap: 0.5,

      makeRaft: false,
      raftNumTopLayers: 3,
      raftTopLayerHeight: 0.05,
      raftTopLineWidth: 0.05,
      raftTopDensity: 1.0,
      raftNumBaseLayers: 1,
      raftBaseLayerHeight: 0.1,
      raftBaseLineWidth: 0.1,
      raftBaseDensity: 0.5,
      raftOffset: 1.0,
      raftGap: 0.05,
      raftWriteWalls: false,

      // display params; these determine how much to compute
      previewSliceMesh: false,
      fullUpToLayer: true,
      fullShowInfill: false
    };
  }

  genLayers(layerHeight){
    this.ready = false;
    if(this.sliceLines.parent){
      this.sliceLines.parent.remove(this.sliceLines);
    }

    this.param.layerHeight = layerHeight;
    let self = this, path, OnePoint, PtSet, Fulllayer = [], line, layerIslandSet;
    let material = this.materialLine;



    let slices = new Slicer(this.mesh,this.param,THREE);
    let layerCAG = [], layerPT = [], layerLine = new THREE.Group(), layerPath = [], layerIslandPT;

    this.sliceCAG = [];
    this.slicePT = [];
    this.sliceLines = new THREE.Group();
    this.slicePath = [];

    //let test = slices.sliceLayers[1].source.toPolygonSet().elements;
    //console.log(test);

    slices.sliceLayers.forEach(layerIslandSetObj => {
      layerIslandSet = layerIslandSetObj.source.toPolygonSet().elements;
      layerPT = [];
      layerLine = new THREE.Group();//
      layerPath = [];
      layerCAG = [];//

      //Itterate through each island in the layer
      layerIslandSet.forEach(LayerIsland => {

        layerIslandPT = [];
        let geometry = new THREE.Geometry();

        //add points for visual representation
        LayerIsland.points.forEach(pt => {
          OnePoint = pt.toVector3(THREE.Vector3);
          geometry.vertices.push(OnePoint);
          layerIslandPT.push(OnePoint);
        });

        //add points for CAG
        PtSet = [];
        geometry.vertices.forEach(pt => {
          PtSet.push([pt.x, pt.y]);
        });

        path = new CSG.Path2D(PtSet, true);
        layerPath.push(path);

        //Add First point to close the path
        let FirstPt = LayerIsland.points[0].toVector3(THREE.Vector3);
        geometry.vertices.push(FirstPt);
        layerIslandPT.push(FirstPt);

        line = new THREE.Line(geometry, material);
        layerLine.add(line);

        layerCAG.push(path.innerToCAG());
      });

      this.sliceCAG.push(layerCAG);
      this.slicePT.push(layerPT);
      this.sliceLines.add(layerLine);
      this.slicePath.push(layerPath);
    });

    console.log(this.sliceCAG);
    if(this.rootObj){
      this.rootObj.csgOutput.add(this.sliceLines);
    }
    this.ready = true;
  }

  genDXF(layer){
    const rawData = dxfSerializer.serialize(this.sliceCAG[layer],
      {cagTo: 'polyline', // or polyline
        csgTo: '3dface', // or polyline
        pathTo: 'lwpolyline',
        statusCallback: null});
    //console.log(rawData);
    save( new Blob( [ rawData ], { type: 'application/dxf' } ), this.rootObj.name + '.dxf');
  }
  genAllDXF(){
    let layerSet = this.layoutSlices();
    const rawData = dxfSerializer.serialize(layerSet,
      {cagTo: 'polyline', // or polyline
        csgTo: '3dface', // or polyline
        pathTo: 'lwpolyline',
        statusCallback: null});
    //console.log(rawData);
    save( new Blob( [ rawData ], { type: 'application/dxf' } ), this.rootObj?.name + '_set.dxf');
  }

  showLayer(layer){
    this.sliceLines.traverse(obj => {
      obj.visible = false;
    });
    if(layer >= 0) {
      this.sliceLines.visible = true;
      this.sliceLines.children[layer].traverse(obj => {
        obj.visible = true;
      });
    }
  }
  dispose(){
    this.ready = false;
    if(this.sliceLines.parent){
      this.sliceLines.parent.remove(this.sliceLines);
    }
    this.sliceCAG = null;
    this.slicePT = null;
    this.sliceLines = null;
    this.slicePath = null;
  }

  genLayerModel(){
    let cagList = [];
    let self = this;
    //itterate though all slices
    this.sliceCAG.forEach(slice => {
      let sizeXL = 0, sizeYL = 0;
      let OuterCAG;
      let CutCAG;
      slice.forEach(island => {
        let bounds = island.getBounds();
        let size = v3(bounds[1]._x-bounds[0]._x,bounds[1]._y-bounds[0]._y,0);
        //find the largest island
        if(size.x > sizeXL && size.x > sizeYL){
          sizeXL = size.x;
          sizeYL = size.y;
          OuterCAG = island;
        }
      });
      CutCAG = OuterCAG.translate([0,0,0]);

      slice.forEach(island => {
        if(deepEqual(island, OuterCAG)){

        }else{
          CutCAG = CutCAG.subrtact(island);
        }

      });
      cagList.push(CutCAG);

    });
    return cagList;
  }

//NEEDS WORK!
  layoutSlices(){
    let cagList = [];
    let self = this;
    //itterate though all slices
    let posX=0, posY=0, spacing = 10, lastX = 0;
    this.sliceCAG.forEach(slice => {
      let sizeX, sizeY, sizeXL = 0, sizeYL = 0;
      let OuterCAG;
      slice.forEach(island => {
        let bounds = island.getBounds();
        let size = v3(bounds[1]._x-bounds[0]._x,bounds[1]._y-bounds[0]._y,0);
        if(size.x > sizeXL){
          sizeXL = size.x;
          OuterCAG = island;
        }
      });
      slice.forEach(island => {
        let movedIsland = island.translate([posX+lastX+spacing,0,0]);
        cagList.push(movedIsland);
      });
      posX = posX+sizeXL+spacing;
      lastX = sizeXL;
    });
    return cagList;
  }

}
class CuraSlicerOpt{
  mesh;
  profile = 'a1';
  opt = [
    {key:'layer_height', value: 0.5},
    {key:'retraction_combing', value:'off'},
    {key:'initial_layer_height', value:0.5},
    {key:'nozzle_diameter', value:1},
    {key:'line_width', value:1},
    {key:'layer_height', value:0.5},
    //{key:'connect_infill_polygons', value:0},
    {key:'magic_spiralize', value:1},
   // {key:'speed_infill', value:50},
    //{key:'speed_print', value:20},
   // {key:'speed_wall', value:10},
   // {key:'speed_topbottom', value:50},
    //{key:'speed_travel', value:210},
    //{key:'command_line_settings', value:false},
    {key:'wall_line_count', value:1},
    {key:'top_layers', value:0},
    {key:'bottom_layers', value:0},
    {key:'initial_bottom_layers', value:0},
    {key:'adhesion_type', value:'none'},
    //{key:'brim_width', value:0},
    {key:'z_seam_type', value:'shortest'},
    {key:'machine_start_gcode', value:';start code here <<<<<<\n'},
    {key:'machine_end_gcode', value:'G0 Z30;end code here <<<<<<\n'},
    //{key:'mesh_position_x', value:0},
    //{key:'mesh_position_y', value:0},
    //{key:'mesh_position_z', value:0},
    {key:'machine_center_is_zero', value: true},
    {key:'material_bed_temp_wait', value:false},
    {key:'material_print_temp_wait', value:false},
    {key:'material_print_temp_prepend', value:false},
    {key:'material_bed_temp_prepend', value:false},
    {key:'remove_empty_first_layers', value:false},
    {key:'retraction_hop_enabled', value:true},
    {key:'retraction_enable', value:false},
    {key:'retraction_hop', value:3},
    {key:'retraction_speed', value:50},
    {key:'speed_z_hop', value:200},
    {key:'retraction_amount', value:1},
    {key:'travel_retract_before_outer_wall', value:true},
    {key:'travel_avoid_supports', value:true},
    {key:'travel_avoid_distance', value:2}
  ];
  optSet = [];
  optUI = [
  ];
  status = '';
  percent = '';

  sendFile = false;
  saveFile = true;

  url = "192.168.1.110";
  fileName = 'live';
  duet = {};
  gCode = '';

  setPreset(name){
    if(name == '1wall'){

    }
  }
//removes option from option array
  deleteOpt(opt){
    this.opt.splice(this.opt.indexOf(opt),1);

  }
  constructor(){
    this.optSet = [
      {name:'vaseFast', opt:[
          {key:'magic_spiralize', value:1},
          {key:'layer_height', value:0.2},
          {key:'speed_topbottom', value:50},
          {key:'speed_travel', value:210},
          {key:'speed_wall_x', value:50},
          {key:'speed_infill', value:50},
          //{key:'command_line_settings', value:false},
          {key:'wall_line_count', value:1},
          {key:'top_layers', value:0},
          {key:'bottom_layers', value:0},
          {key:'initial_bottom_layers', value:0},
          {key:'adhesion_type', value:'none'},
          {key:'brim_width', value:0},
          {key:'z_seam_type', value:'shortest'},
          {key:'machine_start_gcode', value:';start code here <<<<<<\n'},
          {key:'machine_end_gcode', value:'G0 Z30;end code here <<<<<<\n'},
          {key:'mesh_position_x', value:0},
          {key:'mesh_position_y', value:0},
          {key:'mesh_position_z', value:0},
          {key:'machine_center_is_zero', value: true},
          {key:'material_bed_temp_wait', value:false},
          {key:'material_print_temp_wait', value:false},
          {key:'material_print_temp_prepend', value:false},
          {key:'material_bed_temp_prepend', value:false},
          {key:'remove_empty_first_layers', value:false},
          {key:'retraction_hop_enabled', value:true},
          {key:'retraction_enable', value:5},
          {key:'retraction_hop', value:3},
          {key:'speed_z_hop', value:100},
          {key:'retraction_amount', value:1},
          {key:'travel_retract_before_outer_wall', value:true},
          {key:'travel_avoid_supports', value:true},
          {key:'travel_avoid_distance', value:2}
        ]},
      {name:'vaseSlow', opt:[
          {key:'magic_spiralize', value:1},
          {key:'layer_height', value:0.2},
          {key:'speed_topbottom', value:50},
          {key:'speed_travel', value:210},
          {key:'speed_wall_x', value:50},
          {key:'speed_infill', value:50},
          //{key:'command_line_settings', value:false},
          {key:'wall_line_count', value:1},
          {key:'top_layers', value:0},
          {key:'bottom_layers', value:0},
          {key:'initial_bottom_layers', value:0},
          {key:'adhesion_type', value:'none'},
          {key:'brim_width', value:0},
          {key:'z_seam_type', value:'shortest'},
          {key:'machine_start_gcode', value:';start code here <<<<<<\n'},
          {key:'machine_end_gcode', value:'G0 Z30;end code here <<<<<<\n'},
          {key:'mesh_position_x', value:0},
          {key:'mesh_position_y', value:0},
          {key:'mesh_position_z', value:0},
          {key:'machine_center_is_zero', value: true},
          {key:'material_bed_temp_wait', value:false},
          {key:'material_print_temp_wait', value:false},
          {key:'material_print_temp_prepend', value:false},
          {key:'material_bed_temp_prepend', value:false},
          {key:'remove_empty_first_layers', value:false},
          {key:'retraction_hop_enabled', value:true},
          {key:'retraction_enable', value:5},
          {key:'retraction_hop', value:3},
          {key:'retraction_speed', value:30},
          {key:'speed_z_hop', value:100},
          {key:'retraction_amount', value:1},
          {key:'travel_retract_before_outer_wall', value:true},
          {key:'travel_avoid_supports', value:true},
          {key:'travel_avoid_distance', value:2}
        ]},
      {name:'fillFast', opt:[
          {key:'magic_spiralize', value:1},
          {key:'layer_height', value:0.2},
          {key:'speed_topbottom', value:50},
          {key:'speed_travel', value:210},
          {key:'speed_wall_x', value:50},
          {key:'speed_infill', value:50},
          //{key:'command_line_settings', value:false},
          {key:'wall_line_count', value:1},
          {key:'top_layers', value:0},
          {key:'bottom_layers', value:0},
          {key:'initial_bottom_layers', value:0},
          {key:'adhesion_type', value:'none'},
          {key:'brim_width', value:0},
          {key:'z_seam_type', value:'shortest'},
          {key:'machine_start_gcode', value:';start code here <<<<<<\n'},
          {key:'machine_end_gcode', value:'G0 Z30;end code here <<<<<<\n'},
          {key:'mesh_position_x', value:0},
          {key:'mesh_position_y', value:0},
          {key:'mesh_position_z', value:0},
          {key:'machine_center_is_zero', value: true},
          {key:'material_bed_temp_wait', value:false},
          {key:'material_print_temp_wait', value:false},
          {key:'material_print_temp_prepend', value:false},
          {key:'material_bed_temp_prepend', value:false},
          {key:'remove_empty_first_layers', value:false},
          {key:'retraction_hop_enabled', value:true},
          {key:'retraction_enable', value:5},
          {key:'retraction_hop', value:3},
          {key:'speed_z_hop', value:100},
          {key:'retraction_amount', value:1},
          {key:'travel_retract_before_outer_wall', value:true},
          {key:'travel_avoid_supports', value:true},
          {key:'travel_avoid_distance', value:2}
        ]},
      {name:'fillSlow', opt:[
          {key:'magic_spiralize', value:1},
          {key:'layer_height', value:0.2},
          {key:'speed_topbottom', value:50},
          {key:'speed_travel', value:210},
          {key:'speed_wall_x', value:50},
          {key:'speed_infill', value:50},
          //{key:'command_line_settings', value:false},
          {key:'wall_line_count', value:1},
          {key:'top_layers', value:0},
          {key:'bottom_layers', value:0},
          {key:'initial_bottom_layers', value:0},
          {key:'adhesion_type', value:'none'},
          {key:'brim_width', value:0},
          {key:'z_seam_type', value:'shortest'},
          {key:'machine_start_gcode', value:';start code here <<<<<<\n'},
          {key:'machine_end_gcode', value:'G0 Z30;end code here <<<<<<\n'},
          {key:'mesh_position_x', value:0},
          {key:'mesh_position_y', value:0},
          {key:'mesh_position_z', value:0},
          {key:'machine_center_is_zero', value: true},
          {key:'material_bed_temp_wait', value:false},
          {key:'material_print_temp_wait', value:false},
          {key:'material_print_temp_prepend', value:false},
          {key:'material_bed_temp_prepend', value:false},
          {key:'remove_empty_first_layers', value:false},
          {key:'retraction_hop_enabled', value:true},
          {key:'retraction_enable', value:5},
          {key:'retraction_hop', value:3},
          {key:'speed_z_hop', value:100},
          {key:'retraction_amount', value:1},
          {key:'travel_retract_before_outer_wall', value:true},
          {key:'travel_avoid_supports', value:true},
          {key:'travel_avoid_distance', value:2}
        ]},
    ];
  }

  handleDuet(type,res){
    //console.log(type,res);
    if(type == 'rr_upload'){
      if(res.err == 0){
        this.status = 'uploaded.';
      }
    }
    if(type == 'rr_status'){
        this.duet = res;
      if(res.err == 0){
        this.status = 'reloaded.';
      }
    }
    if(type == 'rr_reply'){
      //console.log(res);
      if(res.err == 0){
        this.status = 'reply.';
      }
    }
  }
  addOpt(key,value){
    this.opt.push({key:key, value:value});
  }
  removeOpt(id){
    this.opt.splice(id,1);
  }
  //get specific option by key
  getOptByKey(key){
    for(let i=0;i<this.opt.length;i++){
      if(this.opt[i].key == key){
        return this.opt[i];
      }
    }
    return null;
  }

  //

}

@Component({
  selector: 'app-csg-thumbnail-view',
  templateUrl: './csg-thumbnail-view.component.html',
  styleUrls: ['./csg-thumbnail-view.component.css']
})
export class CsgThumbnailViewComponent implements OnInit, OnChanges{
  @Input() id;
  @Input() ws;
  @Input() height = 200;
  @Input() csgObj = null;
  @Input() simple = true;
  @Input() showBasic = false;
  @Input() csgUrl1 = '';
  @Input() stl = false;
  @Input() rootObj;
  actionQueueDelay = 1000;
  actionQueueAdd = false;
  actionQueue = new ActionQueue();
  slicer = false;
  finalURL;
  csgURL={};
  mesh;
  csgObj1;
  meshSlicer;
  curaSlicer;
  slicerPanel = false;
  showCura = true;
  show = {slice3d:false, slice2d:false, pad:false, cam:false};
  customActionCode = "G1 Z10 G1 Z0"
  editPad = false;
  padRow = [30,20,10];
  padCol = [-30,-20,-10,0,10,20,30];
  eDistance = 0;
  eFactor = 0.2;

  setPad(){
    if (typeof this.padRow == 'string') {
      let str = this.padRow+"";
      this.padRow = str.split(',').map(function(item) {
        return parseInt(item, 10);
      });
    }

    if (typeof this.padCol == 'string') {
      let str = this.padCol+"";
      this.padCol = str.split(',').map(function(item) {
        return parseInt(item, 10);
      });
    }

  }
  actions = [
    {
      name:'DockA',
      code:`M98 P"0:/macros/dock"`,
      pre:false,
      post:false
    },
    {
      name:'DockB',
      code:`M98 P"0:/macros/dock2"`,
      pre:false,
      post:false
    },
    {
      name:'custom',
      code: this.customActionCode,
      pre:false,
      post:false
    },
    {
      name:'runHere',
      code:"G92 X0 Y0 \n M32 live.gcode \n",
      pre:false,
      post:false
    },
    {
      name:'homeXY',
      code:"G28 X Y \n",
      pre:false,
      post:false
    },
    {
      name:'load',
      code:"G0 X30 Y-30 \n G28 Z \n G0 Z60 \n",
      pre:false,
      post:false
    },
    {
      name:'dab',
      code:"G1 Z10 \n G0 Z0 \n G1 E3 \n G1 Z10 E-2 \n",
      pre:false,
      post:false
    },
    {
      name:'impact dab',
      code:"G1 Z10 \n G0 E3 Z0 F9000 \n G1 Z10 E-2 F9000 \n",
      pre:false,
      post:false
    },
    {
      name:'UnDockA',
      code:`M98 P"0:/macros/undock"`,
      pre:false,
      post:false
    },
    {
      name:'UnDockB',
      code:`M98 P"0:/macros/undock2"`,
      pre:false,
      post:false
    }
  ];

  doAction(code,a = null){
    if(a){
      this.sendGcode(a.code);
      if(this.actionQueueAdd){
        this.actionQueue.add(() => this.sendGcode(a.code));
      }
    }
    if(code){
      this.actions.forEach(a=>{
        if(a.pre){
          this.sendGcode(a.code);
        }
          });
      this.sendGcode(code);
      if(this.actionQueueAdd){
        this.actionQueue.add(() => this.sendGcode(code));
      }
      this.actions.forEach(a=>{
      if(a.post){
        this.sendGcode(a.code);
      }
        });
    };
  }

  genSTL(name) {
    exportSTL(this.mesh, name);
  }

  constructor(public db: DbService) { }

  run2Dslice1(layer){
    let ptSet = this.meshSlicer.slicePath[layer][0].points;
    let str = "";
    str += `G0 Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z0 \n`;
    ptSet.forEach(pt => {
      str += `G1 X${pt._x} Y${pt._y} \n`;
    })
    str += `G1 X${ptSet[0]._x} Y${ptSet[0]._y} Z0 \n`;
    str += `G0 Z10 \n`;
    let blobStr = new Blob([str], {type: "text/plain"});
    this.db.sendRequest('rr_upload',blobStr,this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);
  }

  run2D(){
    console.log(this.meshSlicer.slicePath);
    let layer_height = this.meshSlicer.param.layerHeight;
    let ptSet = this.meshSlicer.slicePath[0][0].points;
    let str = "";
    this.eDistance = 0;
    str += `G0 Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z0 \n`;
    this.meshSlicer.slicePath.forEach((layer,i,arr) => {
      str += `//Layer ${i}  \n`;
      layer.forEach((path,pi,pathArr) => {
        str += `G0 X${path.points[0]._x} Y${path.points[0]._y} \n`;

        path.points.forEach( (pt,pti,ptArr) => {
          let dist = 0;
          if(pti == 0){
            dist = 0;
          } else {
            dist = Math.sqrt(
              Math.pow(pt._x-ptArr[pti-1]._x,2) +
              Math.pow(pt._y-ptArr[pti-1]._y,2)
            );
            this.eDistance += dist;
            str += `G1 X${pt._x} Y${pt._y} Z${i*layer_height} E${this.eDistance*this.eFactor} \n`;
          }
        })
        if(path.points.length > 2) {
          let distLast = Math.sqrt(
            Math.pow(
              path.points[0]._x - path.points[path.points.length - 1]._x, 2) +
            Math.pow(
              path.points[0]._y - path.points[path.points.length - 1]._y, 2)
          );
          this.eDistance += distLast;
          str += `G1 X${path.points[0]._x} Y${path.points[0]._y} E${this.eDistance * this.eFactor} \n`;
        }

      })
    });
    str += `G0 Z40 \n`;
    let blobStr = new Blob([str], {type: "text/plain"});
    this.db.sendRequest('rr_upload',blobStr,this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);

    if(this.rootObj){
      this.rootObj.gcodeOutput = blobStr;
      this.rootObj.gcodeMetaData = {type:'2D contour'};
    }
  }
  run2Dslice(layer){
    let ptSet = this.meshSlicer.slicePath[layer][0].points;
    let str = "";
    str += `G0 Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z10 \n`;
    str += `G0 X${ptSet[0]._x} Y${ptSet[0]._y} Z0 \n`;
    ptSet.forEach(pt => {
      str += `G1 X${pt._x} Y${pt._y} \n`;
    })
    str += `G1 X${ptSet[0]._x} Y${ptSet[0]._y} Z0 \n`;
    str += `G0 Z10 \n`;
    let blobStr = new Blob([str], {type: "text/plain"});
    this.db.sendRequest('rr_upload',blobStr,this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);

    if(this.rootObj){
      this.rootObj.gcodeOutput = blobStr;
      this.rootObj.gcodeMetaData = {type:'2D contour',layer:layer};
    }
  }
  runGrid(){
    let self = this;
    let ptSet = [];
    this.padRow.forEach( row => {
      self.padCol.forEach( col => {
        ptSet.push({_x:row,_y:col});
      });
    });
    let str = "";
    str += `G0 Z10 \n`;
    str += `G0 Y${ptSet[0]._x} X${ptSet[0]._y} Z10 \n`;
    str += `G0 Y${ptSet[0]._x} X${ptSet[0]._y} Z0 \n`;
    ptSet.forEach(pt => {
        this.actions.forEach(a=>{
          if(a.pre){
            str += a.code;
          }
        });
      str += `G1 Y${pt._x} X${pt._y} \n`;
        this.actions.forEach(a=>{
          if(a.post){
            str += a.code;
          }
        });
    })
    str += `G0 Z10 \n`;
    let blobStr = new Blob([str], {type: "text/plain"});
    this.db.sendRequest('rr_upload',blobStr,this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);
    if(this.rootObj){
      this.rootObj.gcodeOutput = blobStr;
      this.rootObj.gcodeMetaData = {type:'grid'};
    }
  }

  addContour(layer){
    let sack = this.ws.newCSG('extrude2d',this.ws.basePlane);
    let ptSet = this.meshSlicer.slicePath[layer][0].points;
    let pt = [];
    ptSet.forEach(p => {
      pt.push([p._x,p._y]);
    });
      let n = this.ws.newCSG('shape2d', sack);
      n.updateRawPt(pt);
  }

  sendGcodeAsFile(code:string, filename:string){
    let blobStr = new Blob([code], {type: "text/plain"});
    this.db.sendRequest('rr_upload',blobStr,this.curaSlicer.url,filename,this.curaSlicer);
  }

  updateFile(file,name,dir){
    return this.db.sendPostRequestFileDyDir(file, this.curaSlicer.url, dir, file);
  }

  sendGcode(code){
    this.db.sendRequest('rr_gcode',{},encodeURIComponent(this.curaSlicer.url),code,this.curaSlicer).then(res => {
      console.log(res);
      this.updateDuet();
    });
  }
  sendGcodePromise(code){
    return this.db.sendRequest('rr_gcode',{},encodeURIComponent(this.curaSlicer.url),code,this.curaSlicer);
  }

  updateDuet(){
    this.db.sendRequest('rr_status',{},this.curaSlicer.url,'1',this.curaSlicer);
  }
  updateDetailed(){
    this.db.sendRequest('rr_status',{},this.curaSlicer.url,'2',this.curaSlicer);
  }
  playFile(name){
    this.db.sendRequest('rr_status',{},this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);
  }
  loginDuet() {
    this.db.sendRequest('login',{},this.curaSlicer.url,this.curaSlicer.fileName,this.curaSlicer);
  }

  ngOnInit() {
    this.curaSlicer = new CuraSlicerOpt();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.csgObj){
      this.getModelByCSG();
    } else if (this.csgUrl1){
      this.getModelByURL(this.csgUrl1);
    } else {
      this.getCSG(this.id);
    }
  }

  sliceMesh() {
    if (this.meshSlicer && this.meshSlicer.sliceLines && this.meshSlicer.sliceLines.parent) {
      this.meshSlicer.sliceLines.parent.remove(this.meshSlicer.sliceLines);
    }
    if(!this.meshSlicer && this.mesh){
      this.meshSlicer = new MeshSlicer(this.mesh,this.rootObj);
    } else {
      this.meshSlicer.dispose();
      this.meshSlicer = undefined;
      this.meshSlicer = new MeshSlicer(this.mesh,this.rootObj);
    }
  }

  sliceCura(){
    this.showCura = true;
    this.loginDuet();
    this.updateDuet();
    console.log(this);
    exportSlices(this.csgObj, this.curaSlicer, this);
    this.updateDuet();
  }

  async getModelByCSG(){
    const self = this;
        let material = new THREE.MeshStandardMaterial({color:'#0086ce', metalness:0.4, roughness:0.4});
        self.mesh = new THREE.Mesh(new THREE.Geometry, material);
        jsCSGtoMesh(self.mesh, this.csgObj);
        //Mesh ->  GLTF
        exporterGLTF.parse( self.mesh, function ( gltf: any ) {
          self.finalURL = URL.createObjectURL(new Blob( [ gltf ], { type: 'application/octet-stream' } ));
        },{
          trs: false,
          onlyVisible: true,
          truncateDrawRange: true,
          binary: true,
          forcePowerOfTwoTextures: false
        });
        console.log('csg Process Ended');
  }

  async getModelByURL(url) {
    const self = this;
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
      var blob = xhr.response;
      var reader = new FileReader();
      reader.onload = function() {
        //Decompression, casting to CSG, CSG -> Mesh
        let material = new THREE.MeshStandardMaterial({color:'#0086ce', metalness:0.4, roughness:0.4});
        self.csgObj1 = CSG.fromCompactBinary(castCompactCSG(pako.inflate(reader.result,{ to: 'string' })));
        self.mesh = new THREE.Mesh(new THREE.Geometry, material);
        jsCSGtoMesh(self.mesh, self.csgObj1);

        //Mesh ->  GLTF
        exporterGLTF.parse( self.mesh, function ( gltf: any ) {
          self.finalURL = URL.createObjectURL(new Blob( [ gltf ], { type: 'application/octet-stream' } ));

        }, {
          trs: false,
          onlyVisible: true,
          truncateDrawRange: true,
          binary: true,
          forcePowerOfTwoTextures: false
        });

        //console.log(csgObj, self.mesh);
        console.log('csg Process Ended');
        //return resolve(self.mesh);

      };
      reader.readAsText(blob);
    };
    xhr.open('GET', url);
    xhr.send();

  }
  async getCSG(id){
    if(this.csgURL[id]){
      this.getModelByURL(this.csgURL[id]);
      return this.csgURL[id];
    } else {
      let url = await this.db.fsStorage.ref('csgOBJ/' + id + '.csg').getDownloadURL().toPromise();
      if(url){
        this.csgURL[id] = url;
        this.getModelByURL(this.csgURL[id]);
      }
      //console.log(url);
      return this.csgURL[id];
    }
  }
}

async function exportSlices(csgObj,curaSlicer,rootObj){
  const self = this;
  curaSlicer.status = "Converting";

  const rawData= stlSerializer.serialize(csgObj);
  const result = new Blob(rawData);
  await new Response(result).arrayBuffer().then(buffer => {
    console.log(result,buffer);
    const main = async (rootObj,curaSlicer) =>
    {
      curaSlicer.status = "Started Slicer";
      const slicer = new CuraWASM({
        overrides: curaSlicer.opt,
        transfer: false,
        verbose: true
      });
      //Progress logger (Ranges from 0 to 100)
      slicer.on('progress', percent =>
      {
        //curaSlicer.status = "Slicing:"+percent+"%";
        //curaSlicer.percent = percent;
      });

      //Slice (This can take multiple minutes to resolve!)
      const {gcode, metadata} = await slicer.slice(buffer, 'stl');

      if(curaSlicer.sendFile){
        curaSlicer.status = "Sending file";
        rootObj.db.sendRequest('rr_upload',gcode,curaSlicer.url,curaSlicer.fileName,curaSlicer);
      }
      if(curaSlicer.saveFile){
        curaSlicer.status = "Saving file";
        saveString(gcode, curaSlicer.fileName+'.gcode' );
      }
      if(rootObj.rootObj){
        rootObj.gcodeOutput = gcode;
        rootObj.gcodeMetaData = metadata;
      }
    }
    main(rootObj,curaSlicer);
  });
}

function castCompactCSG(OldCsg) {
  const parsed = JSON.parse(OldCsg);
// Revive back again:
  const revived = TSON.revive(parsed);
  return revived;
}
function jsCSGtoMesh(mesh, csg) {
  mesh.geometry.dispose();
  mesh.geometry = threeCSG.toMesh(threeCSG.fromJSCADcsg(csg), new THREE.Matrix4(), true);
  mesh.geometry.computeFaceNormals();
  mesh.geometry.computeVertexNormals();
}

function save(blob, filename ) {
  const link = document.createElement( 'a' );
  link.style.display = 'none';
  document.body.appendChild( link );

  link.href = URL.createObjectURL( blob );
  link.download = filename;
  link.click();

}
function saveString( text, filename ) {

  save( new Blob( [ text ], { type: 'text/plain' } ), filename );

}
// utility functions
function exportSTL(mesh, name) {
  const result = exporterSTL.parse(mesh);
  saveString( result, name + '.stl' );
}
