import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';

@Component({
  selector: 'rc-slides',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <ng-container *ngIf="{selected: 0} as ui">
      <div  class="slides-container" [ngStyle]="{'height': arr[ui.selected].height}">
        <div class="slides-toolbar">
          <span *ngFor="let tab of arr; index as i" >
          <button [ngClass]="{'selected':ui.selected == i}" (click)="ui.selected = i;">
              {{tab.name}}
          </button>
        </span>
        </div>
        <ng-container *ngFor="let tab of arr" >
          <div *ngIf="tab.name === arr[ui.selected].name"  class="slides-main-text" [ngStyle]="{'background-image': 'url('+ arr[ui.selected].img +')'}">
            {{tab.text}}
          </div>
        </ng-container>
      </div>
      <div class="note">
        {{arr[ui.selected].note}}
      </div>
      <ng-container *ngIf="content.edit && localEdit">
        name:<input type="text" [(ngModel)]="arr[ui.selected].name">
        text:<input type="text" [(ngModel)]="arr[ui.selected].text">
        <mat-form-field class="paragraph-text">
          <textarea matInput placeholder="Text" [(ngModel)]="arr[ui.selected].note" [rows]="3"></textarea>
        </mat-form-field>
        image:<input type="text" [(ngModel)]="arr[ui.selected].img">
        height:<input type="text" [(ngModel)]="arr[ui.selected].height">
        <app-image-uploader (selectedFile)="arr[ui.selected].img = $event.downloadURL" [size]="[400,400]"></app-image-uploader>
        <button (click)="removeSlide(ui.selected)">Remove Slide</button>
        <button (click)="addSlide()">Add new slide</button>
      </ng-container>
    </ng-container>
  `
})
export class SlidesComponent implements OnInit, ContentOnCreate {

  @Input() public arr: any[];
  content;
  root;
  localEdit = false;
  constructor() { }

  ngOnInit() {
  }
  addSlide() {
    this.arr.push({name: 'new slide', text: 'new slide text', img: 'https://www.inventinside.com/assets/images/InventInsideLogo.png'});
  }
  removeSlide(i) {
    this.arr.splice(i, 1);
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.arr = values.arr;
  }

}
