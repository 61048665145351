import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DbService } from '../../services/db.service';
import {LivePartService} from '../../services/live-part.service';
import {map, take} from 'rxjs/operators';
import {RootService, UserService} from '../../services/root.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-part',
  templateUrl: './part.component.html',
  styleUrls: ['./part.component.css']
})
export class PartComponent implements OnInit, OnDestroy {
  part$;
  edit = false;
  data;
  opt;

  routerSub;
  id;
  constructor(public db: DbService,
              public livePart: LivePartService,
              public root: RootService,
              public auth: AuthService,
              public router: Router,
              private route: ActivatedRoute) {
    const self = this;
    this.routerSub = this.route.params.subscribe(params => {
        this.id = params['id']; // (+) converts string 'id' to a number
        const ref = this.livePart.fs.collection('p').doc(this.id);
        this.part$ = ref.valueChanges();
        this.part$.pipe(take(1)).subscribe(d => {
          if (d && d.hasOwnProperty('data') && d.hasOwnProperty('opt')) {
              this.data = d['data'];
              this.opt = d['opt'];
              //console.log('loaded', this);
          }
        });
      });
  }
  ngOnInit() {
  }
  ngOnDestroy(): void {
      this.routerSub?.unsubscribe();
  }
  getObjThumbnailId() {
    //console.log(part);
    let found = null;
    if (this.data) {
      this.data.forEach(p => {
        if (!p.p && p.type == 'plane') {
          found = p;
        }
      });
    }
    if (found && found.c && found.c[0]) {
      return found.c[0];
    }
  }
  getObjData(part, id) {
    let found = null;
    if (part && part.data && id) {
      part.data.forEach(p => {
        if (p.uid == id) {
          found = p;
        }
      });
    }
    return found;
  }
  updatePart() {
    this.livePart.updatePartData(this.id, this.data, this.opt, true);
  }
  deletePart(id) {
    this.livePart.deletePart(id);
  }
  openPartWindow(mode, id) {
    return this.root.openPartWindow(mode, id);
  }// Stub
}
