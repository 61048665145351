import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule, MatCheckboxModule, MatInputModule} from '@angular/material';
import {MatSliderModule} from '@angular/material/slider';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatGridListModule} from '@angular/material/grid-list';
@NgModule({
  imports: [
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    MatTabsModule,
    MatExpansionModule,
    MatTreeModule,
    MatGridListModule,
  ],
  exports: [
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatInputModule,
    MatSliderModule,
    MatTabsModule,
    MatExpansionModule,
    MatTreeModule,
    MatGridListModule,
  ]
})

export class AngularMaterialModule { }
