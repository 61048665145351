import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AngularDraggableModule } from 'angular2-draggable';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import {NgxPaginationModule} from 'ngx-pagination';

import { ContentModule } from './content/content.module';
import { ReactiveContentModule } from './reactive-content';
// Firebase services + enviorment module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule} from '@angular/fire/firestore';
import { AngularFireStorageModule} from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';

import { TreeModule } from 'angular-tree-component';
import { ContextMenuModule} from 'ngx-contextmenu';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EngineComponent } from './3d/engine/engine.component';

import { DbService} from './services/db.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EditorComponent } from './pages/editor/editor.component';
import { HomeComponent } from './pages/home/home.component';
import { PartsComponent } from './pages/parts/parts.component';

import {SafeStylePipe} from './pipe/safe-style.pipe';
import {RootService} from './services/root.service';

//widgets
import { CsgExplorerComponent } from './widget/csg-explorer/csg-explorer.component';
import {ImageListComponent} from './widget/image-list/image-list.component';
import {ImageUploaderComponent} from './widget/image-uploader/image-uploader.component';
import { StlModelViewerComponent } from './widget/model-view/stl-model-viewer.component';
import { RegisterComponent } from './widget/auth/register/register.component';
import { UserComponent } from './widget/auth/user/user.component';
import { UserStatusComponent } from './widget/auth/user-status/user-status.component';
import { AuthGuard} from './widget/auth/auth.guard';
import {TreeEditorComponent} from './widget/tree-editor/tree-editor.component';
import {VectorSet2dComponent} from './widget/vector-set2d/vector-set2d.component';
import {CsgThumbnailViewComponent} from './widget/csg-thumbnail-view/csg-thumbnail-view.component';
import {CamComponent} from './widget/cam/cam.component';

export function onMonacoLoad() {
// validation settings
  monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false
  });

// compiler options
  monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
    allowNonTsExtensions: true
  });


// extra libraries
  monaco.languages.typescript.javascriptDefaults.addExtraLib(`
declare class CubeOptions {
  size?: number | number[];
  center?: boolean | boolean[];
  round?: boolean;
  radius?: number[];
  fn?: number;
}
declare interface SphereOptions {
  r?: number;
  center?: boolean | boolean[];
  fn?: number;
  type?: string;
}
declare interface CylinderOptions {
  r?: number;
  r1?: number;
  r2?: number;
  h?: number;
  center?: boolean | boolean[];
  fn?: number;
  round?: boolean;
}
declare interface TorusOptions {
  ri?: number;
  ro?: number;
  fni?: number;
  fno?: number;
  roti?: number;
}

declare class CSG {
    union(object: CSG): CSG;
    subtract(object: CSG): CSG;
    intersect(object: CSG): CSG;
}

    declare function sin(x:number,n:number): number;
    declare function cos(x:number,n:number): number;
    declare function tan(x:number,n:number): number;
    declare function filter(x:number,min:number, max:number): number;
    declare function filterUV(u,v,x,startU,startV,endU,endV): number;
    declare function filterUVgrid(u,v,x,nU,nV,gU,gV,startU,startV,endU,endV): number;

    declare function cube(opt: CubeOptions): CSG;
    declare function sphere(opt: SphereOptions): CSG;
    declare function cylinder(opt: CylinderOptions): CSG;
    declare function torus(opt: TorusOptions): CSG;
    declare function union(...objects: CSG[]): CSG;
    declare function difference(...objects: CSG[]): CSG;
    declare function intersect(...objects: CSG[]): CSG;
    declare function translate(position: number[], object: CSG[] | CSG): CSG;
    declare function rotate(position: number[], object: CSG[] | CSG): CSG;
    declare function scale(position: number[], object: CSG[] | CSG): CSG;
    `, 'ts:filename/facts.d.ts');
  function createDependencyProposals(range) {
    // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
    // here you could do a server side lookup
    return [
      {
        label: '"cube"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Cube solid.",
        insertText: 'cube({size: 10, center: [true, true, true], radius: [0,0,0], fn:32})',
        range: range
      },
      {
        label: '"cylinder"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Cylinder solid",
        insertText: 'cylinder({r1: 10, r2:10, h:20, center: [true, true, true] round: false, fn:32})',
        range: range
      },
      {
        label: '"sphere"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Sphere solid",
        insertText: 'sphere({r:10, center: [true, true, true], type: \'geodesic\', fn: 32})',
        range: range
      },
      {
        label: '"torus"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Describe your library here",
        insertText: 'torus({ri: 10 ro: 3, fni:16, fno:32 });',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      },
      {
        label: '"translate"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Describe your library here",
        insertText: 'translate([0,0,0], );',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      },
      {
        label: '"rotate"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Describe your library here",
        insertText: 'rotate([0,0,0], );',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      },
      {
        label: '"scale"',
        kind: monaco.languages.CompletionItemKind.Function,
        documentation: "Describe your library here",
        insertText: 'scale([0,0,0], );',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
      }
    ];
  }
  monaco.languages.registerCompletionItemProvider('javascript', {
    provideCompletionItems: function(model, position) {
      // find out if we are completing a property in the 'dependencies' object.
      var word = model.getWordUntilPosition(position);
      var range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn
      };
      return {
        suggestions: createDependencyProposals(range)
      };
    }
  });
}

const monacoConfig: NgxMonacoEditorConfig = {
  baseUrl: 'assets',
  defaultOptions: { scrollBeyondLastLine: false },
  onMonacoLoad
};

import {UserProfileComponent} from './pages/user-profile/user-profile.component';
import {NgxPicaModule} from '@digitalascetic/ngx-pica';


import { ServiceWorkerModule } from '@angular/service-worker';

import {DragDropModule} from '@angular/cdk/drag-drop';

import {ItemComponent} from './pages/item/item.component';
import {PartComponent} from './pages/part/part.component';
import {MarkedPipe} from './pipe/marked.pipe';


@NgModule({
  declarations: [
    CamComponent,
    ImageListComponent,
    ImageUploaderComponent,
    MarkedPipe
  ],
  exports: [MarkedPipe, ImageUploaderComponent, ImageListComponent, CamComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    TreeModule.forRoot(),
    ContextMenuModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    MonacoEditorModule.forRoot(monacoConfig),
    AngularDraggableModule,
    NgxPaginationModule,
    ContentModule.forRoot(),
    ReactiveContentModule.forRoot(),
    NgxPicaModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SubModule { }

@NgModule({
    declarations: [
        AppComponent,
        EngineComponent,
        RegisterComponent,
        UserComponent,
        UserStatusComponent,
        EditorComponent,
        HomeComponent,
        ItemComponent,
        PartComponent,
        PartsComponent,
        StlModelViewerComponent,
        SafeStylePipe,
        CsgExplorerComponent,
        UserProfileComponent,
        CsgThumbnailViewComponent,
        TreeEditorComponent,
        VectorSet2dComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularMaterialModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        TreeModule.forRoot(),
        ContextMenuModule.forRoot(),
        BrowserAnimationsModule,
        FormsModule,
        MonacoEditorModule.forRoot(monacoConfig),
        AngularDraggableModule,
        NgxPaginationModule,
        ContentModule.forRoot(),
        ReactiveContentModule.forRoot(),
        NgxPicaModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        SubModule,
      DragDropModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [DbService, AuthGuard, RootService],

  exports: [
    StlModelViewerComponent,
    CsgThumbnailViewComponent,
    PartsComponent
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
