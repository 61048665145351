import { Component, OnInit, Input } from '@angular/core';
import {PresenceService} from '../../../services/presence.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.sass']
})
export class UserStatusComponent implements OnInit {

  @Input() uid;
  @Input() rootPartUid;

  presence$;
  user$;

  constructor(private auth: AuthService,
              private presence: PresenceService) { }

  ngOnInit() {
    this.presence$ = this.presence.getPresence(this.uid);
    this.user$ = this.auth.getUser(this.uid);
  }

}
