import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ContentOnCreate, ContentEmbeddable } from '../../reactive-content';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DbService} from '../../services/db.service';

@Component({
  selector: 'rc-grid',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <div [ngClass]="{'edit-border': content.edit}">

      <ng-container *ngIf="{edit: false} as opt">

        <ng-container *ngIf="edit()">
          <button class="panel-edit-btn" (click)="opt.edit = !opt.edit" >
            .
          </button>


          <div *ngIf="opt.edit" class="optionsWindow">
          <br>showType:
            Name:<input [(ngModel)]="root.name">
            <button (click)="root.show = !root.show">show [toggle]</button>
            <button (click)="root.showType = ''">none</button>
            <button (click)="root.showType = 'hide'">hide</button>

          <button  (click)="addRow('row')">+ ROW</button>
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div class="box" *ngFor="let child of childList" >
                <div class="grid-row-opt" cdkDrag>
                  {{child.itemKey}}
                  <button (click)="deleteChild(child.itemKey)">X</button>
                  <button (click)="addColumn(child.obj._embedded.columns,'column')">+</button>
                  <button (click)="addColumn(child.obj._embedded.columns,'columnImg')">+Img</button>
                  <button (click)="addColumn(child.obj._embedded.columns, 'column3d')">+3d</button>
                  <button (click)="addColumn(child.obj._embedded.columns, 'columnNote')">+Note</button>
                  <button (click)="addColumn(child.obj._embedded.columns, 'columnModel')">+Model</button>
                </div>


                <div cdkDropList cdkDropListOrientation="horizontal" class="drop-list-h" (cdkDropListDropped)="drop2($event, child.obj._embedded.columns)">
                  <div class="box-h" *ngFor="let column of child.obj._embedded.columns">
                    <span cdkDrag>[{{column._type}}]</span> <input type="text" class="small-grid-input" [(ngModel)]="column.size">x<input type="text" class="small-grid-input" [(ngModel)]="column.offset">
                    <button (click)="deleteColumn(child.obj._embedded.columns,column)">X</button>
                  </div>
                </div>
              </div>

            </div>
            <div>
              <button (click)="updateDocument()">Update Layout</button>
            </div>

          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="root.showType == 'hide'" >
        <button class="grid-expand" (click)="root.show = !root.show">show {{root.name}}</button>
      </div>

      <div [hidden]="root.show">
        <ng-container  class="container" #embed></ng-container>
      </div>

    </div>
  `
})
export class GridComponent implements OnInit, ContentOnCreate, ContentEmbeddable {

  @ViewChild('embed', { read: ViewContainerRef, static: true })
  public embed: ViewContainerRef;

  childList = [];
  content;
  root;
  localEdit = false;
  edit(){
    if(this.localEdit && this.content.edit){
      return true;
    } else {
      return false;
    }
  }

  addRow(type) {
    if (type === 'row') {
      this.root._embedded[this.childList.length+1] = this.db.createPageItem(type);
    }
    //this.content.updateHostedContent(this.content.document);
    this.genChildList();
    console.log(this.root);
  }

  addColumn(row,type) {
    row.push(this.db.createPageItem(type));
    //this.content.updateHostedContent(this.content.document);
    this.genChildList();
    console.log(this.root);
  }

  deleteColumn(row, col) {
    row.splice(row.indexOf(col), 1 );
    this.genChildList();
    //this.content.updateHostedContent(this.content.document);
  }

  deleteChild(child) {
    delete this.root._embedded[child];
    this.genChildList();
    //this.content.updateHostedContent(this.content.document);
  }
  constructor(public db: DbService) { }

  ngOnInit() {
  }

  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.genChildList();
  }

  contentEmbeddable(): ViewContainerRef {
    return this.embed;
  }

  genChildList() {
    const self = this;
    self.childList = [];
    if (this.root._embedded && Object.keys(this.root._embedded).length > 0) {
      Object.keys(self.root._embedded).sort().forEach((key: string) => {
        self.childList.push({ itemKey: key, type: self.root._embedded[key]._type, obj: self.root._embedded[key] });
      });
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    const self = this;
    moveItemInArray(this.childList, event.previousIndex, event.currentIndex);
    let newSet = {};
    self.childList.forEach((c, i) => {
      newSet[i] = c.obj;
    });
    this.root._embedded = newSet;
  }
  drop2(event: CdkDragDrop<any[]>, arr) {
    const self = this;
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  updateDocument() {
    this.content.updateHostedContent(this.content.document);
  }

}
