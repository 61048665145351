import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

declare const require: any;
import { ContentOnCreate } from '../../reactive-content';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as THREE from 'three';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
const threeCSG = require('./../../3d/CSGMesh.js').default;
const {CSG} = require('@jscad/csg/src/api/index.js').csg;
import Typeson from 'typeson';
import pako from 'pako';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
import firebase from "firebase/app";
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);
const exporterGLTF = new GLTFExporter();

@Component({
  selector: 'rc-view3d',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <div class="mat-card-header">{{root.name}}</div>
    <button *ngIf="root.showButton_model"  class="full"  (click)="root.show = !root.show">
      <ng-container *ngIf="!root.show">
      Load 3D model
      </ng-container>
      <ng-container *ngIf="root.show">
        Hide model
      </ng-container>
    </button>

    <ng-container *ngIf="root.show" >
        <div *ngIf="root.showE" class="full" [style.height]="90 + '%'">
          <iframe  width="100%"  [style.height]="root.height + 'px'"  [src]="safeURLstrE"></iframe>
        </div>
        <div *ngIf="root.showFull" class="full" [style.height]="90 + '%'">
          <iframe  width="100%"  [style.height]="root.height + 'px'"  [src]="safeURLstrFull"></iframe>
        </div>
        <div *ngIf="root.showSmall" class="full" [style.height]="90 + '%'">
          <iframe  width="100%"  [style.height]="root.height + 'px'"  [src]="safeURLstrSmall"></iframe>
        </div>
        <div *ngIf="root.showUpdate" class="full" [style.height]="90 + '%'">
          <iframe  width="100%"  [style.height]="root.height + 'px'"  [src]="safeURLstrUpdate"></iframe>
        </div>
    </ng-container>
    <p class="note">
      {{root.note}}
    </p>
    <button  *ngIf="root.pageLink"  (click)="goto(root.pageLink)">Go to Page:{{root.pageLink}}</button>


  <button *ngIf="root.alt && root.showButton_stl" (click)="openPartWindow('small', root.alt)"><mat-icon>open_in_new</mat-icon>STL</button>
  <button *ngIf="root.alt && root.showButton_edit" (click)="openPartWindow('popup', root.alt)"><mat-icon>open_in_new</mat-icon>Edit</button>
  <button *ngIf="edit()" (click)="expandAttachment = !expandAttachment">+@</button>
  <div *ngIf="edit() && expandAttachment" >
    name:<input [(ngModel)]="root.name"><br>
    csgID:<input [(ngModel)]="root.src"><br>
    showImg:<input type="checkbox" [(ngModel)]="root.showImg"><br>

    showEdit:<input type="checkbox" [(ngModel)]="root.showE"><br>
    showFull:<input type="checkbox" [(ngModel)]="root.showFull"><br>
    showSmall:<input type="checkbox" [(ngModel)]="root.showSmall"><br>
    showUpdate:<input type="checkbox" [(ngModel)]="root.showUpdate"><br>

    pageLink:<input [(ngModel)]="root.pageLink"><br>
    partLink:<input type="text" [(ngModel)]="root.alt"><br>
    height:<input [(ngModel)]="root.height"><br>
    showButtons MODEL:<input type="checkbox" [(ngModel)]="root.showButton_model"><br>
    showButtons STL:<input type="checkbox" [(ngModel)]="root.showButton_stl"><br>
    showButtons EDIT:<input type="checkbox" [(ngModel)]="root.showButton_edit"><br>
    note:<br>
    <textarea  [(ngModel)]="root.note"></textarea>
</div>
  `,
  styles: [`
    .full { width: 97%; text-align: center; margin: 0 auto; }`]
})
export class View3dComponent implements OnInit, ContentOnCreate {
  expandAttachment = false;
  @Input()
  public src: string;

  safeURLstrE: any;
  safeURLstrFull: any;
  safeURLstrSmall: any;

  safeURLstrUpdate: any;

  @Input()
  public alt: string;

  id;
  height = 150;
  csgObj = null;
  simple = true;
  slicer = false;
  finalURL;
  csgURL={};
  mesh;
  csgObj1;
  meshSlicer;
  curaSlicer;
  slicerPanel = false;
  showCura = false;
  localEdit = false;

  content;
  root;
  constructor(public router: Router, public dom:DomSanitizer) { }
  edit() {
    if (this.localEdit && this.content.edit) {
      return true;
    } else {
      return false;
    }
  }
  safeURL(str){
    return this.dom.bypassSecurityTrustResourceUrl(str);
  }
  ngOnInit() {
    this.getModelByURL(this.root.src);
    this.safeURLstrE = this.safeURL('https://inventinside.com/editor/'+ this.root.alt +'/');
    this.safeURLstrFull = this.safeURL('https://inventinside.com/editor/'+ this.root.alt +'/full');
    this.safeURLstrSmall = this.safeURL('https://inventinside.com/editor/'+ this.root.alt +'/small');
    this.safeURLstrUpdate = this.safeURL('https://inventinside.com/editor/'+ this.root.alt +'/update')

  }
  goto(page){
    let rootURL = window.location.hostname;
    window.open('http://'+rootURL+':'+window.location.port+'/item/'+page,'popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
  }
  openPartWindow(mode, id){
    let rootURL = window.location.hostname;
    if(mode == 'same'){
      this.router.navigate(['/editor', id]);
    } else if (mode == 'popup') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'split') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id+'/split','popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'blank') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'_blank','scrollbars=no,resizable=yes');
    } else if (mode == 'small') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id + '/small','_blank','scrollbars=no,resizable=yes');
    }
  }
  sUrl(s){
    return this.safeURL('https://inventinside.com/parts/rr/'+ s);
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.src = values.src;
    this.alt = values.alt;
  }

  async getModelByURL(id){

    var storage = firebase.storage();
    let url = await storage.ref('csgOBJ/' + id + '.csg').getDownloadURL();

    if(url){
      this.csgURL[id] = url;
      this.getModelByURL(this.csgURL[id]);
    }


    const self = this;
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
      var blob = xhr.response;
      var reader = new FileReader();
      reader.onload = function() {
        //Decompression, casting to CSG, CSG -> Mesh
        let material = new THREE.MeshStandardMaterial({color:'#0086ce', metalness:0.4, roughness:0.4});
        self.csgObj1 = CSG.fromCompactBinary(castCompactCSG(pako.inflate(reader.result,{ to: 'string' })));
        self.mesh = new THREE.Mesh(new THREE.Geometry, material);
        jsCSGtoMesh(self.mesh, self.csgObj1);

        //Mesh ->  GLTF
        exporterGLTF.parse( self.mesh, function ( gltf: any ) {
          self.finalURL = URL.createObjectURL(new Blob( [ gltf ], { type: 'application/octet-stream' } ));
        },{
          trs: false,
          onlyVisible: true,
          truncateDrawRange: true,
          binary: true,
          forcePowerOfTwoTextures: false
        });

        //console.log(csgObj, self.mesh);
        console.log('csg Process Ended');
        //return resolve(self.mesh);

      };
      reader.readAsText(blob);
    };
    xhr.open('GET', url);
    xhr.send();

  }

}
function castCompactCSG(OldCsg) {
  const parsed = JSON.parse(OldCsg);
// Revive back again:
  const revived = TSON.revive(parsed);
  return revived;
}
function jsCSGtoMesh(mesh, csg) {
  mesh.geometry.dispose();
  mesh.geometry = threeCSG.toMesh(threeCSG.fromJSCADcsg(csg), new THREE.Matrix4(), true);
  mesh.geometry.computeFaceNormals();
  mesh.geometry.computeVertexNormals();
}
