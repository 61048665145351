declare const require: any;
declare global {
  interface Window {
    THREE: any;
    JXG:any;
  }
}
import * as THREE from 'three';
import {CSGPayload, CSGrequest} from '../models/engine.interface';
import {fromWorkerPool} from 'observable-webworker';
import {LivePartService} from '../services/live-part.service';
import {VariableDB, VariableSet} from './variableSolver';

import {OrbitControls} from './controls/OrbitControls';
import {TransformControls} from 'three/examples/jsm/controls/TransformControls';
import {STLExporter} from 'three/examples/jsm/exporters/STLExporter';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
import Typeson from 'typeson';
import pako from 'pako';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
import {TextMesh} from 'troika-3d-text/dist/textmesh-standalone.esm.js';
import {d2r, deepEqual, ID, pushUnique, r2d, removeFromArr, v3, v3toArr} from '../iiLib';
import {CsgPhyisicsManager} from './physics';
import {interpret, Machine} from 'xstate';
import {GCodeSender} from './GCodeSender';
import {DOF, Goal, IKRootsHelper, Joint, Link, Solver} from 'closed-chain-ik';

window['THREE'] = THREE;
var fit = require('canvas-fit');
const exporterSTL = new STLExporter();
const threeCSG = require('./CSGMesh.js').default;
const dxfSerializer = require('@jscad/dxf-serializer');
const {CSG, CAG} = require('@jscad/csg/csg.js');
const shortHash = require('shorthash');
declare var Slicer: any;
var Slicer = require('./../../../src/assets/lib/slicer.js').default;
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);

const importObject = { imports: { imported_func: (arg) => console.log(arg) } };

WebAssembly.instantiateStreaming(fetch("/assets/lib/scad/openscad.wasm"), importObject).then(
  (obj) => console.log(obj)
);



//base materials
const material = new THREE.MeshPhysicalMaterial( { color: '#ef931a', opacity: 1, wireframe: false, side: THREE.FrontSide } );
const materialLine = new THREE.LineBasicMaterial({
  color: '#1b22ef',
  linewidth: 3,
});
//2D slicer interface class
class MeshSlicer {
  mesh;
  box;
  param;
  sliceLines = new THREE.Group();
  sliceCAG = [];
  slicePT = [];
  slicePath = [];
  ready = false;
  materialLine = new THREE.LineBasicMaterial({
    color: '#e88600'
  });
  constructor(box,mesh){
    this.mesh = mesh;
    this.box = box;
    //this.engine = engine;
    //param = {layerHeight: 1}
    this.param =  {
      // base params
      axis: "z",
      layerHeight: 1,
      lineWidth: 0.1,
      precision: 5,
      mode: "full",

      numWalls: 2,
      numTopLayers: 3,
      optimizeTopLayers: true,
      infillType: 0,
      infillDensity: 0.1,
      infillOverlap: 0.5,

      makeRaft: true,
      raftNumTopLayers: 3,
      raftTopLayerHeight: 0.05,
      raftTopLineWidth: 0.05,
      raftTopDensity: 1.0,
      raftNumBaseLayers: 1,
      raftBaseLayerHeight: 0.1,
      raftBaseLineWidth: 0.1,
      raftBaseDensity: 0.5,
      raftOffset: 1.0,
      raftGap: 0.05,
      raftWriteWalls: false,

      // display params; these determine how much to compute
      previewSliceMesh: false,
      fullUpToLayer: true,
      fullShowInfill: false
    };
  }

  genLayers(layerHeight){
    this.ready = false;
    if(this.sliceLines.parent){
      this.sliceLines.parent.remove(this.sliceLines);
    }

    this.param.layerHeight = layerHeight;
    let self = this, path, OnePoint, PtSet, Fulllayer = [], line, layerIslandSet;
    let material = this.materialLine;


    let slices = new Slicer(this.mesh,this.param,THREE);
    let layerCAG = [], layerPT = [], layerLine = new THREE.Group(), layerPath = [], layerIslandPT;

    this.sliceCAG = [];
    this.slicePT = [];
    this.sliceLines = new THREE.Group();
    this.slicePath = [];

    //let test = slices.sliceLayers[1].source.toPolygonSet().elements;
    //console.log(test);

    slices.sliceLayers.forEach(layerIslandSetObj => {
      layerIslandSet = layerIslandSetObj.source.toPolygonSet().elements;
      layerPT = [];
      layerLine = new THREE.Group();//
      layerPath = [];
      layerCAG = [];//

      //Itterate through each island in the layer
      layerIslandSet.forEach(LayerIsland => {

        layerIslandPT = [];
        let geometry = new THREE.Geometry();

        //add points for visual representation
        LayerIsland.points.forEach(pt => {
          OnePoint = pt.toVector3(THREE.Vector3);
          geometry.vertices.push(OnePoint);
          layerIslandPT.push(OnePoint);
        });

        //add points for CAG
        PtSet = [];
        geometry.vertices.forEach(pt => {
          PtSet.push([pt.x, pt.y]);
        });
        layerPT.push(PtSet);

        path = new CSG.Path2D(PtSet, true);
        layerPath.push(path);

        //Add First point to close the path
        let FirstPt = LayerIsland.points[0].toVector3(THREE.Vector3);
        geometry.vertices.push(FirstPt);
        layerIslandPT.push(FirstPt);

        line = new THREE.Line(geometry, material);
        layerLine.add(line);

        layerCAG.push(path.innerToCAG());
      });

      this.sliceCAG.push(layerCAG);
      this.slicePT.push(layerPT);
      this.sliceLines.add(layerLine);
      this.slicePath.push(layerPath);
    });

    //console.log(this.box);
    this.mesh.add(this.sliceLines);
    this.ready = true;
    return layerPT;
  }

  genDXF(layer){
    const rawData = dxfSerializer.serialize(this.sliceCAG[layer],
      {cagTo: 'polyline', // or polyline
        csgTo: '3dface', // or polyline
        pathTo: 'lwpolyline',
        statusCallback: null});
    //console.log(rawData);
    save( new Blob( [ rawData ], { type: 'application/dxf' } ), this.box.name + '.dxf');
  }
  genAllDXF(){
    let layerSet = this.layoutSlices();
    const rawData = dxfSerializer.serialize(layerSet,
      {cagTo: 'polyline', // or polyline
        csgTo: '3dface', // or polyline
        pathTo: 'lwpolyline',
        statusCallback: null});
    //console.log(rawData);
    save( new Blob( [ rawData ], { type: 'application/dxf' } ), this.box.name + '_set.dxf');
  }

  showLayer(layer){
    this.sliceLines.traverse(obj => {
      obj.visible = false;
    });
    if(layer >= 0) {
      this.sliceLines.visible = true;
      this.sliceLines.children[layer].traverse(obj => {
        obj.visible = true;
      });
    }
  }
  dispose(){
    this.ready = false;
    if(this.sliceLines.parent){
      this.sliceLines.parent.remove(this.sliceLines);
    }
    this.sliceCAG = null;
    this.slicePT = null;
    this.sliceLines = null;
    this.slicePath = null;
  }

  genLayerModel(){
    let cagList = [];
    let self = this;
    //itterate though all slices
    this.sliceCAG.forEach(slice => {
      let sizeXL = 0, sizeYL = 0;
      let OuterCAG;
      let CutCAG;
      slice.forEach(island => {
        let bounds = island.getBounds();
        let size = v3(bounds[1]._x-bounds[0]._x,bounds[1]._y-bounds[0]._y,0);
        //find the largest island
        if(size.x > sizeXL && size.x > sizeYL){
          sizeXL = size.x;
          sizeYL = size.y;
          OuterCAG = island;
        }
      });
      CutCAG = OuterCAG.translate([0,0,0]);

      slice.forEach(island => {
        if(deepEqual(island, OuterCAG)){

        }else{
          CutCAG = CutCAG.subrtact(island);
        }

      });
      cagList.push(CutCAG);

    });
    return cagList;
  }

//NEEDS WORK!
  layoutSlices(){
    let cagList = [];
    let self = this;
    //itterate though all slices
    let posX=0, posY=0, spacing = 10, lastX = 0;
    this.sliceCAG.forEach(slice => {
      let sizeX, sizeY, sizeXL = 0, sizeYL = 0;
      let OuterCAG;
      slice.forEach(island => {
        let bounds = island.getBounds();
        let size = v3(bounds[1]._x-bounds[0]._x,bounds[1]._y-bounds[0]._y,0);
        if(size.x > sizeXL){
          sizeXL = size.x;
          OuterCAG = island;
        }
      });
      slice.forEach(island => {
        let movedIsland = island.translate([posX+lastX+spacing,0,0]);
        cagList.push(movedIsland);
      });
      posX = posX+sizeXL+spacing;
      lastX = sizeXL;
    });
    return cagList;
  }

}

interface JointControlObj {
  joint: Joint;
  link: Link;
  LinkObjectID: string;
  JointObjectID: string;
  position: THREE.Vector3;
  DOF: DOF[];
  DOFValues: number;
  minLimit: number;
  maxLimit: number;
}
class IKsolver {
  solver: Solver;
  links: Link[];
  joints: Joint[];
  goals: Goal[] = [];
  jSet: JointControlObj[] = [];
  ikHelper: IKRootsHelper;
  solverOptions = {
    maxIterations: 3,
    divergeThreshold: 0.005,
    stallThreshold: 1e-3,
    translationErrorClamp: 0.25,
    rotationErrorClamp: 0.25,
    translationConvergeThreshold: 1e-3,
    rotationConvergeThreshold: 1e-3,
    restPoseFactor: 0.001,
  };
  params = {
    controls: 'translate',
    solvePosition: true,
    solveRotation: false,
  };
  targetObject;
  transformControls;
  constructor(public WS) {
    this.jSet = [
      {link: null, joint: null, LinkObjectID: '', JointObjectID: '', position: new THREE.Vector3(0, 0, 70), DOF: [DOF.EZ], DOFValues: 0, minLimit: 0, maxLimit: 0},
      {link: null, joint: null, LinkObjectID: '', JointObjectID: '', position: new THREE.Vector3(0, 0, 70), DOF: [DOF.EX], DOFValues: 0, minLimit: 0, maxLimit: 0},
      {link: null, joint: null, LinkObjectID: '', JointObjectID: '', position: new THREE.Vector3(0, 0, 70), DOF: [DOF.EX], DOFValues: 0, minLimit: 0, maxLimit: 0},
      {link: null, joint: null, LinkObjectID: '', JointObjectID: '', position: new THREE.Vector3(0, 0, 70), DOF: [DOF.EX], DOFValues: 0, minLimit: 0, maxLimit: 0},
    ];
  }
  save() {
    let saveObj = {
      jSet: []
    };

    this.jSet.forEach(joint => {
      saveObj.jSet.push({
        joint: null,
        link: null,
        LinkObjectID: joint.LinkObjectID ? joint.LinkObjectID : '',
        JointObjectID: joint.JointObjectID ? joint.JointObjectID : '',
        position: {x: joint.position.x, y: joint.position.y, z: joint.position.z},
        DOF: joint.DOF,
        DOFValues: joint.DOFValues,
        minLimit: joint.minLimit,
        maxLimit: joint.maxLimit,
      });
    });
    return saveObj;
  }
  load(ikSet) {
   this.jSet = ikSet.jSet;
  }
  removejSet(jSet) {
    this.jSet.splice(this.jSet.indexOf(jSet), 1);
  }
  addjset() {
    this.jSet.push({
        joint: null,
        link: null,
        LinkObjectID: '',
        JointObjectID: '',
        position: new THREE.Vector3(0, 0, 70),
        DOF: [DOF.EX], DOFValues: 0, minLimit: 0, maxLimit: 0
      });
  }
  attachControls() {
    this.transformControls.attach(this.targetObject);
  }
  detachControls() {
    this.transformControls.detach();
  }
  remove() {
    const self = this;
    this.WS.scene.remove(this.targetObject);
    this.WS.scene.remove(this.transformControls);
    this.WS.scene.remove(this.ikHelper);
    self.goals.forEach(goal => {
      this.WS.scene.remove(goal);
    });
    this.transformControls.dispose();
  }
  init() {
    let WS = this.WS;
    const self = this;

    self.transformControls = new TransformControls( WS.camera, WS.renderer.domElement );
    self.transformControls.setSpace( 'global' );
    WS.scene.add( self.transformControls );

    self.transformControls.addEventListener( 'mouseDown', () => WS.controls.enabled = false );
    self.transformControls.addEventListener( 'mouseUp', () => WS.controls.enabled = true );

    self.targetObject = new THREE.Group();
    self.targetObject.position.set( 0, 1, 1 );
    WS.scene.add( self.targetObject );
    self.transformControls.attach( self.targetObject );

    let ikRoot = null;
    let currRoot = null;

    for ( let i = 0; i < this.jSet.length; i++ ) {
      let jset = this.jSet[i];

      const link = new Link();
      const joint = new Joint();
      joint.setPosition( jset.position.x, jset.position.y, jset.position.z );
      joint.setDoF( ...jset.DOF );
      joint.setDoFValues( jset.DOFValues );
      joint.setRestPoseValues( 0 );
      joint.restPoseSet = true;
      if (jset.minLimit  && jset.maxLimit ) {
        joint.setMinLimits( jset.minLimit );
        joint.setMaxLimits( jset.maxLimit );
      }
      this.jSet[i].joint = joint;
      this.jSet[i].link = link;

      link.addChild( joint );

      if ( currRoot ) {
        currRoot.addChild( link );
      }
      if ( ikRoot === null ) {
        ikRoot = link;
      }
      currRoot = joint;
    }

    const finalLink = new Link();
    finalLink.setPosition( 0, 0.5, 0 );
    currRoot.addChild( finalLink );

    ikRoot.updateMatrixWorld( true );
    self.targetObject.matrix.set( ...finalLink.matrixWorld ).transpose();
    self.targetObject.matrix
      .decompose( self.targetObject.position, self.targetObject.quaternion, self.targetObject.scale );

    // TODO: rotation seems not to work here
    const goal = new Goal();
    this.goals.push(goal);
    goal.makeClosure( finalLink );

    self.ikHelper = new IKRootsHelper( ikRoot );
    self.ikHelper.setResolution( window.innerWidth, window.innerHeight );
    self.ikHelper.traverse( c => {

    } );
    WS.scene.add( self.ikHelper );

    self.solver = new Solver( ikRoot );
    Object.assign( self.solver, self.solverOptions );

    function updateGoalDoF() {

      const dof = [];
      if ( self.params.solvePosition ) {

        dof.push( DOF.X, DOF.Y, DOF.Z );

      }

      if ( self.params.solveRotation ) {

        dof.push( DOF.EX, DOF.EY, DOF.EZ );

      }

      goal.setGoalDoF( ...dof );

    }

    updateGoalDoF();
    self.transformControls.addEventListener( 'change', () => {
      ikRoot.updateMatrixWorld( true );
      goal.setPosition(
        self.targetObject.position.x,
        self.targetObject.position.y,
        self.targetObject.position.z,
      );
      goal.setQuaternion(
        self.targetObject.quaternion.x,
        self.targetObject.quaternion.y,
        self.targetObject.quaternion.z,
        self.targetObject.quaternion.w,
      );
      self.solver.solve();
    } );
    self.transformControls.addEventListener( 'mouseUp', () => {
      ikRoot.updateMatrixWorld( true );
      self.targetObject.matrix.set( ...finalLink.matrixWorld ).transpose();
      self.targetObject.matrix
        .decompose( self.targetObject.position, self.targetObject.quaternion, self.targetObject.scale );
      self.jSet.forEach(j => {
        let posLink = new THREE.Vector3();
        let quaternionLink = new THREE.Quaternion();
        let scaleLink = new THREE.Vector3();

        let posJoint = new THREE.Vector3();
        let quaternionJoint = new THREE.Quaternion();
        let scaleJoint = new THREE.Vector3();

        let mLink = new THREE.Matrix4().fromArray(j.link.matrixWorld).transpose();
          mLink.decompose( posLink, quaternionLink, scaleLink );
        let mJoint = new THREE.Matrix4().fromArray(j.joint.matrixWorld).transpose();
          mJoint.decompose( posJoint, quaternionJoint, scaleJoint );
        if (j.LinkObjectID) {
          let obj = WS.getObjectByUid(j.LinkObjectID);
          obj.setPos(posLink);
          obj.setRot(new THREE.Euler().setFromQuaternion(quaternionLink));
        }
        if (j.JointObjectID) {
          let obj = WS.getObjectByUid(j.JointObjectID);
          obj.setPos(posJoint);
        }
      });
    });
  }
}

export class WorkSpace {
  ikSolver;
  dbService;
  pointerMode = {selectCP:true, clickObj:true, clickMarkers:false};
  evalMode = {plane:false};
  uiMode = {widgets:true};
  addMarkerOptions = {type:'marker', sketch:false, code:false, rotate:false};
  GS; //G-Code sender
  version = 0.39;
  scene; //Main scene
  camera; // Main Camera
  renderer; //Main Renderer
  clock;
  controls: any; // Orbit controls
  transformControls;
  transformObjID;

  lightSet; // Light set
  grid; // build volume Grid

  objectPicking; //ObjectPicking manager
  mode = '';
  AppState;
  tree;
  // Part variables
  basePlane: Plane; // Base object
  variableDB;
  opt = {};
  PT = [];
  lastExecutedAction = 0;
  objects = {};
  selectedVar = []; // selected, pined up top
  selectedObj = undefined;

  key = {
    copy: false,
    gestures: false,
    joystick: false,
  };
  P; // Physics engine manager

  evalPlate() {
    this.variableDB.evaluate();
    if (this.evalMode.plane) {
      this.basePlane.children.forEach(obj => {
        obj.updateCSG(true, false);
      });
    } else {
      this.selectedObj.updateCSG(true, false);
    }
  }
  getObjectByUid(id) {
    return this.objects[id];
  }

  setMode(mode, context) {
    this.mode = mode;
  }

  constructor(public canvasRef, public livePart: LivePartService) {
    this.livePart.WS = this;
    this.variableDB = new VariableDB(this);
    this.P = new CsgPhyisicsManager(this);
  }
  // setups
  initScene() {
    //Initialize the world objects
    this.scene = new THREE.Scene();
    this.clock = new THREE.Clock();
    this.camera = new THREE.PerspectiveCamera(54, window.innerWidth / window.innerHeight, 10, 2500);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: this.canvasRef,
      preserveDrawingBuffer: true
    });
    // Setup Render
    this.renderer.setSize(window.innerWidth - 100, window.innerHeight - 100);
    this.renderer.setPixelRatio(2);

    // Setup Camera
    this.camera.position.x = 0;
    this.camera.position.y = -200;
    this.camera.position.z = 50;
    this.camera.lookAt(0, 0, 0);
    this.camera.up.set(0, 0, 1);
  }

  init() {
    const self = this;
    this.GS = new GCodeSender(self.dbService);

    // Setup Scene
    this.initScene();
    if(this.uiMode.widgets) {
      this.grid = new Grid(this.scene, 100, 10);
      this.grid.setVisible(this.AppState.ui);
    }

    // Lights
    this.lightSet = new LightSet(this.scene);
    this.lightSet.createPointLightSet(this.AppState.ui.LightSp, this.AppState.ui.LightH);


    this.objectPicking = new ObjectPicking(this.renderer, this.scene, this.camera, this.canvasRef, this);
    // setup Transforms
    this.controls = new OrbitControls( this.camera, this.renderer.domElement );
    this.controls.enableKeys = false;
    this.controls.addEventListener('change', event => {
      // self.labelManager.update();
      //if (self.livePart.liveRTC) {
      //  self.livePart.liveRTC.sendCamUpdate();
      //}
    });
    this.controls.addEventListener('start', event => {
      this.P.physics_enable = false;
      for (const property in self.objects) {
        //console.log(`${property}: ${self.objects[property]}`);
        if (self.objects[property].type === 'cp') {
          self.objects[property].mesh?.parent?.updateMatrixWorld();
            self.camera.parent?.updateMatrixWorld();
            let factor = self.objects[property].mesh?.getWorldPosition(v3()).distanceTo( this.camera.position ) * Math.min( 1.9 * Math.tan( Math.PI * this.camera.fov / 360 ) / this.camera.zoom, 7 );
          if (factor) {
            self.objects[property].scale.set( 1, 1, 1 ).multiplyScalar( factor * 1 / 40 );
          }

        }
      }
    });
    this.controls.addEventListener('end', event => {
      self.P.physics_enable = true;
      for (const property in self.objects) {
        //console.log(`${property}: ${self.objects[property]}`);
        if(self.objects[property].type === 'cp' || self.objects[property].type == 'point2d'){
          self.objects[property].mesh?.parent?.updateMatrixWorld();
            self.camera.parent?.updateMatrixWorld();
            let factor = self.objects[property].mesh?.getWorldPosition(v3()).distanceTo( this.camera.position ) * Math.min( 1.9 * Math.tan( Math.PI * this.camera.fov / 360 ) / this.camera.zoom, 7 );
          if(factor){
            self.objects[property].mesh.scale.set( 1, 1, 1 ).multiplyScalar( factor * 1 / 40 );
          }
          if(self.objects[property].type == 'point2d'){
            self.objects[property].mesh.scale.set( 1, 1, 1 ).multiplyScalar( factor * 1 / 360 );
          }

        }
      }
    });

    this.transformControls = new TransformControls(this.camera, this.canvasRef);
    this.transformControls.addEventListener( 'dragging-changed', function ( event ) {
      self.controls.enabled = ! event.value;
    } );
    this.transformControls.addEventListener( 'mouseDown', function ( event ) {
      let objData;
      if (this.object && this.object.userData && this.object.userData.uid) {
        objData = self.objects[this.object.userData.uid];
        if (objData && objData.onTransformMouseDown) {
          objData.onTransformMouseDown(event);
        }
      }
    });
    this.transformControls.addEventListener( 'objectChange', function ( event ) {
      let objData;
      if (this.object && this.object.userData && this.object.userData.uid) {
        objData = self.objects[this.object.userData.uid];
        if (objData && objData.onTransformMouseChange) {
          objData.onTransformMouseChange(event);
        }
      }
    });
    this.transformControls.addEventListener( 'mouseUp', function ( event ) {
      let objData;
      if (this.object && this.object.userData && this.object.userData.uid) {
        objData = self.objects[this.object.userData.uid];
        if (objData && objData.onTransformMouseUp) {
          objData.onTransformMouseUp(event);
        }
      }
    });
    this.scene.add(this.transformControls);

    this.resetWS();
    this.ikSolver = new IKsolver(this);
  }

  //Helper object creator
  newCSGSet(setType,root,v1,v2){
    if(setType == 'treeRoot'){
      let u = this.newCSG('union', root, null, true);
      let from = this.newCSG('union', u, null, true);
      let to = this.newCSG('union', u, null, true);
    } else if(setType == 'treeRootD'){
      let u = this.newCSG('difference', root, null, true);
      let from = this.newCSG('union', u, null, true);
      let to = this.newCSG('union', u, null, true);
    } else if(setType == 'treeRootI'){
      let u = this.newCSG('intersection', root, null, true);
      let from = this.newCSG('union', u, null, true);
      let to = this.newCSG('union', u, null, true);
    } else if(setType == 'addToUnionRoot'){
      let newRoot = this.newCSG('union', root.parent, null, true);
      root.changeParent(newRoot);
      newRoot.click();
    } else if(setType == 'subtractFromUnionRoot'){
      let newRoot = this.newCSG('difference', root.parent, null, true);
      root.changeParent(newRoot);
      let nu = this.newCSG('union', newRoot, null, true);
      newRoot.click();
    }
  }
  addNewCodePart(code){
    let newPart = this.newCSG('jsCadV2',this.basePlane);
    newPart.code = code;
    newPart.updateCSG();
  }
  addNewCodePartV1(code){
    let newPart = this.newCSG('code',this.basePlane);
    newPart.code = code;
    newPart.updateCSG();
  }
  addBB(obj){
    obj.csgOutput.geometry.computeBoundingBox();
    let bb = obj.csgOutput.geometry.boundingBox;
    let size = bb.getSize(new THREE.Vector3());
    let center = bb.max.clone().add(bb.min).multiplyScalar(-0.5).add(obj.G.position);
    let n = this.newCSG('box',obj.parent);
    n.setPos(center.multiplyScalar(-1));
    n.nickName = "Bounding box of "+obj.name;
    n.updateConfig(size.x,size.y,size.z);
  }
  addBS(obj){
    obj.csgOutput.geometry.computeBoundingBox();
    let bb = obj.csgOutput.geometry.boundingBox;
    let size = bb.getSize(new THREE.Vector3());
    let center = bb.max.clone().add(bb.min).multiplyScalar(-0.5).add(obj.G.position);
    let n = this.newCSG('sphere',obj.parent);
    n.setPos(center.multiplyScalar(-1));
    n.nickName = "Bounding sphere of "+obj.name;
    n.updateConfig(size.x);
  }
  addBC(obj){
    obj.csgOutput.geometry.computeBoundingBox();
    let bb = obj.csgOutput.geometry.boundingBox;
    let size = bb.getSize(new THREE.Vector3());
    let center = bb.max.clone().add(bb.min).multiplyScalar(-0.5).add(obj.G.position);
    let n = this.newCSG('cylinder',obj.parent);
    n.setPos(center.multiplyScalar(-1));
    n.nickName = "Bounding cylinder of "+obj.name;
    n.updateConfig(size.x,0,size.z);
  }

  copyToClip(obj){
    const P = this.AppState.r.PART;
    const dataSet = P.getObjects(this.objects); //Array of all obj save data
    const varSet = P.getVariableDB(this.variableDB); //userVar copy
    let dataString = JSON.stringify({data:dataSet,varSet:varSet,base:obj.uid});
    copyTextToClipboard(dataString);
  }
  insertFromString(str,target) {
    let payload = JSON.parse(str);
    this.loadRefData2(payload.data, payload.varSet,target, payload.base);
  }

  // rendering loop
  calculateAspectRatio(): number {
    const height = this.canvasRef.clientHeight;
    if (height === 0) {
      return 0;
    }
    return this.canvasRef.clientWidth / this.canvasRef.clientHeight;
  }
  updateCamerasAspectRatio() {
    const aspect = this.calculateAspectRatio();
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
  }
  onResize(event: Event) {
    //this.canvasRef.style.width = '100%';
    //this.canvasRef.style.height = '100%';
    fit(this.canvasRef);

    this.renderer.setSize(this.canvasRef.offsetWidth, this.canvasRef.offsetHeight);
    this.updateCamerasAspectRatio();
  }

  async startRenderingLoop() {
    const self = this;
    this.renderer.autoClear = false;

    this.renderer.setAnimationLoop(
      () =>  {
      let deltaTime = self.clock.getDelta();

      if (self.objectPicking.ready && self.controls.enabled) {
        self.objectPicking.pick();
      }

      self.renderer.clear();
      self.renderer.render(self.scene, self.camera);

      if(self.P.phyics_ready && self.P.physics_enable && self.P.physics_enable_ui) {
          try {
            self.P.renderStep(deltaTime);
          } catch (e){
              console.log('physics error:',e);
              self.P.physics_enable = false;
              self.P.phyics_ready = false;
              self.P.physics_enable_ui = false;
            }
        }
    });
  }

  // addFunctions
  newCSG(type, parent, uid = null, external = false, opt = null) {
    if (opt == null) {opt = {type:null, code:false, rotate:false} };

    if (opt.type === 'marker') {
      this.addAtMarker(type, parent, opt);
    }
    if (opt.type === null) {
      let newobj;
      if (type === 'box') {
        newobj = new Box(parent, this, uid);
      } else if (type === 'sphere') {
        newobj = new Sphere(parent, this, uid);
      } else if (type === 'cylinder') {
        newobj = new Cylinder(parent, this, uid);
      } else if (type === 'toroid') {
        newobj = new Toroid(parent, this, uid);
      }
      else if (type === 'expand') {
        newobj = new Expand(parent, this, uid);
      } else if (type === 'union') {
        newobj = new Union(parent, this, uid);
      } else if (type === 'difference') {
        newobj = new Difference(parent, this, uid);
      }
      else if (type === 'intersection') {
        newobj = new Intersection(parent, this, uid);
      } else if (type === 'repeatxyz') {
        newobj = new RepeatXYZ(parent, this, uid);
      } else if (type === 'repeatr') {
        newobj = new RepeatR(parent, this, uid);
      } else if (type === 'construct') {
        newobj = new Construct(parent, this, uid);
      } else if (type === 'code') {
        newobj = new Code(parent, this, uid);
      } else if (type === 'implicit') {
        newobj = new Implicit(parent, this, uid);
      } else if (type === 'parametric') {
        newobj = new Parametric(parent, this, uid);
      } else if (type === 'gear') {
        newobj = new Gear(parent, this, uid);
      } else if (type === 'bevelgear') {
        newobj = new BevelGear(parent, this, uid);
      } else if (type === 'rackgear') {
        newobj = new RackGear(parent, this, uid);
      } else if (type === 'pulleygear') {
        newobj = new PulleyGear(parent, this, uid);
      } else if (type === 'pulleybelt') {
        newobj = new PulleyBelt(parent, this, uid);
      } else if (type === 'extrude2d') {
        newobj = new Extrude2D(parent, this, uid);
      } else if (type === 'point2d') {
        //newobj = new Point2D(parent, this, uid);
      } else if (type === 'shape2d') {
        newobj = new Shape2D(parent, this, uid);
      } else if (type === 'codemaker2d') {
        newobj = new CodeMaker2D(parent, this, uid);
      } else if (type === 'jsCadV2') {
        newobj = new jsCadV2(parent, this, uid);
      } else if (type === 'fastener') {
        newobj = new Fastener(parent, this, uid);
      } else if (type === 'motor') {
        newobj = new Motor(parent, this, uid);
      } else if (type === 'slot') {
        newobj = new Slot(parent, this, uid);
      }
      if (external) {
      }
      this.AppState.setStatus(`Object Created: ${type}`);
      return newobj;
    }
  }
  addAtMarker(type, parent, opt) {
    let self = this;
    let root, obj;

    if (opt.code) {
      root = self.newCSG('code', this.basePlane, null, false, null);
    } else if (opt.sketch) {
      root = self.newCSG('extrude2d', this.basePlane, null, false, null);
    } else {
        root = self.newCSG('union', this.basePlane, null, false, null);
    }

    if (opt.sketch) {
      obj = self.newCSG('shape2d', root, null, false, null);
      let rawPT = [];
      parent.clickMarkers.forEach((marker) => {
        rawPT.push([marker.position.x, marker.position.y]);
      });
      obj.updateRawPt(rawPT);
    } else {
      parent.clickMarkers.forEach((marker) => {
        obj = self.newCSG(type, root, null, false, null);
        obj.setPos(marker.position);
        if (opt.rotate) {
          obj.setRot(marker.rotation);
        }
      });
    }
  }
  // actions live
  updateData(id = null, update = true, partInfo = null) {
    const self = this;
    const P = self.AppState.r.PART;

    this.AppState.setStatus(`Preparing files:`, true);

    if (partInfo) {
      partInfo = P.updatePartInfo(partInfo);
    }
    if (partInfo.owner == '') {
      partInfo.owner = self.AppState.r.USER.getUserID();
    }

    //const stlFiles = P.getRootsSTL(self.objects); //STL direct download [id -> obj]
    const csgFiles = P.getRoots(self.objects); //csgOBJ compressed strings for storage [id -> obj]
    const dataSet = P.getObjects(self.objects); //Array of all obj save data
    const varSet = P.getVariableDB(self.variableDB); //userVar copy
    const userVarPreset = self.variableDB.userVarPreset; //userVar copy
    const ikSet = self.ikSolver.save();
    const varSetPin = [];

    this.selectedVar.forEach( v => {
      varSetPin.push(v.getRefName());
    });

    if (partInfo) {
      this.opt = {...this.opt, varSet, varSetPin, partInfo, physics:this.P.save(), ikSet, userVarPreset};
    } else {
      this.opt = {...this.opt, varSet, varSetPin,  physics:this.P.save(), ikSet, userVarPreset};
    }
    this.opt['pointerMode'] = this.pointerMode;
    this.opt['evalMode'] = this.evalMode;
    const partOpt = {
      ...self.opt,
      partInfo
    };
    if (update) {
        self.livePart.updatePart(id, dataSet, self.opt,  csgFiles);
        self.AppState.setStatus(`Update Completed:`);
        return {id: id, data: dataSet, opt: partOpt};
      } else {
        id = this.livePart.fs.createId();
        partOpt['hidden'] = false;
        self.livePart.updatePart(id, dataSet, self.opt,  csgFiles);
        self.AppState.setStatus(`Saving Completed: ${partInfo["name"]}, id:${self.livePart.newPartId}`);
        return {id: id, data: dataSet, opt: partOpt};
      }
  }
  updateSnippetData(obj, update = true, partInfo = null) {
    const self = this;
    let c = '';
    if(obj.csgType === 'union'){//save UI code.
      c = obj.uiCode;
    }else{
      c = obj.code;
    }
      const data = {
        img: '',
        csgUID: obj.uid,
        code: c,
        variables: obj.save().opt.sV,
      };
      if (update) {
        if (obj.snippet.id) {
          self.livePart.updateCodeSnippet(obj.snippet.id, data);
        }
      } else {
        self.livePart.createCodeSnippet(partInfo);
      }
  }

  addObjFromData(data, parent, uid = null) {
    let newObj;
    if(data?.opt?.csgType){ //csg
      newObj = this.newCSG(data.opt.csgType, parent, uid);
    } else if (data.type) { //all others
      newObj = this.newCSG(data.type, parent, uid);
    }

    newObj.load(data);
    return newObj;
  }
  loadData(data, opt, refBase = null) {
    const self = this;
    let base = [];
    let objectsID = {};
    let parts = {};
    let baseCSG = [];
    data.forEach( obj => {
      objectsID[obj.uid] = obj;
      if (!obj.p) {
        base.push(obj);
      } else {
        if (!parts.hasOwnProperty(obj.type)) {
          parts[obj.type] = [];
        }
        parts[obj.type].push(obj);
      }
    });

    function buildCSG(objID, baseObj, objSET) {
      // make obj
      const objData = objSET[objID];
      const newObj = self.addObjFromData(objData, baseObj, objID);
      // make kids
      objData.c.forEach( c => {
        if (objSET[c] && (objSET[c].type === 'csg'  || objSET[c].type == 'shape2d')) {
          buildCSG(c, newObj, objSET);
        }
      });
    }
    base.forEach(b => {
      if (b.type === 'plane') {
        this.basePlane = new Plane( v3( b.pos[0], b.pos[1], b.pos[2]), new THREE.Euler(b.rot[0], b.rot[1], b.rot[2]), this, b.uid);

        // findBase for CSG
        if (parts.hasOwnProperty('csg')) {
          parts['csg'].forEach(csg => {
            if (csg.p === b.uid) {
              baseCSG.push(csg);
            }
          });
          baseCSG.forEach(bCSG => {
            buildCSG(bCSG.uid, this.basePlane, objectsID);
          });
        }
      }
    });
    //
    opt.varSet.forEach(v => {
      self.variableDB.userVar.push(v);
    });

    baseCSG.forEach(b => {
      if (self.objects[b.uid]) {
        self.objects[b.uid].updateCSG(true, true, true);
      }
    });
    self.opt = opt;
    if (opt['pointerMode']) {
      self.pointerMode = opt.pointerMode;
    }
    if (opt['evalMode']) {
      self.evalMode = opt.evalMode;
    }

    //selected CP list.
    opt.varSetPin?.forEach(name => {
      self.variableDB.varDB.forEach(varSet => {
        varSet.variables.forEach(v => {
          if (v.getRefName() == name) {
            self.selectedVar.push(v);
          }
        });
      });
    });
    //ikSet
    if (opt.ikSet) {
      self.ikSolver.load(opt.ikSet);
    }
    // varPresetSet
    if (opt.userVarPreset) {
        self.variableDB.userVarPreset = opt.userVarPreset;
    }

  }

  onFinalCSGRender(finalObj) {
    const self = this;
    //load Physics
    if(this.opt.hasOwnProperty('physics')){
      this.P.load(this.opt['physics']);
      if(this.P.physics_enable_ui){
        this.basePlane.children.forEach(c => {
          if(c.type = 'csg'){
            c.click();
          }
        });
        this.transformControls.detach();
      }else{

      }
    }
    Object.keys(self.objects).forEach( k => {
        self.objects[k].onFirstLoad(finalObj);
    });
    this.transformControls.detach();
  }
  loadRefData(data, opt, refBase) {
    console.log('loadRefData',data,opt);
    // sort all obj
    const self = this;
    let base = [];
    let objectsID = {};
    let parts = {};
    let baseCSG = [];
    data.forEach( obj => {
      objectsID[obj.uid] = obj;
      if (!obj.p) {
        base.push(obj);
      } else {
        if (!parts.hasOwnProperty(obj.type)) {
          parts[obj.type] = [];
        }
        parts[obj.type].push(obj);
      }
    });

    function buildCSG(objID, baseObj, objSET) {
      // make obj
      const objData = objSET[objID];
      const newObj = self.addObjFromData(objData, baseObj, objID);
      // make kids
      objData.c.forEach( c => {
        if (objSET[c] && (objSET[c].type === 'csg' || objSET[c].type == 'shape2d')) {
          buildCSG(c, newObj, objSET);
        }
      });
    }
    base.forEach(b => {
      if (b.type === 'plane') {
        // this.basePlane = new Plane( v3( b.pos[0], b.pos[1], b.pos[2]), new THREE.Euler(b.rot[0], b.rot[1], b.rot[2]), this, b.uid);
        // findBase for CSG
        if (parts.hasOwnProperty('csg')) {
          parts['csg'].forEach(csg => {
            if (csg.p === b.uid) {
              baseCSG.push(csg);
            }
          });
          // build all csgObj
          baseCSG.forEach(bCSG => {
            buildCSG(bCSG.uid, refBase, objectsID);
          });
        }
      }
    });
    //
    opt.varSet.forEach(v => {
      self.variableDB.userVar.push(v);
    });

    baseCSG.forEach(b => {
      if (self.objects[b.uid]) {
        self.objects[b.uid].updateCSG(true);
      }
    });
    this.opt = opt;
    //console.log(base, objectsID, parts);
  }
  loadRefData2(data, varset, refBase, baseID) {
    // sort all obj
    const self = this;
    let base = [];
    let objectsID = {};
    let parts = {};
    let baseCSG = [];
    data.forEach( obj => {
      objectsID[obj.uid] = obj;
      if (obj.uid == baseID) {
        base.push(obj);
      } else {
        if (!parts.hasOwnProperty(obj.type)) {
          parts[obj.type] = [];
        }
        parts[obj.type].push(obj);
      }
    });

    function buildCSG(objID, baseObj, objSET) {
      // make obj
      const objData = objSET[objID];
      //strips ID
      const newObj = self.addObjFromData(objData, baseObj, null);
      // make kids
      objData.c.forEach( c => {
        if (objSET[c] && (objSET[c].type === 'csg' || objSET[c].type == 'shape2d')) {
          buildCSG(c, newObj, objSET);
        }
      });
    }
    base.forEach(b => {
        // this.basePlane = new Plane( v3( b.pos[0], b.pos[1], b.pos[2]), new THREE.Euler(b.rot[0], b.rot[1], b.rot[2]), this, b.uid);
        // findBase for CSG
        if (parts.hasOwnProperty('csg')) {
          parts['csg'].forEach(csg => {
            if (csg.p === b.uid) {
              baseCSG.push(csg);
            }
          });
          // build all csgObj
          baseCSG.forEach(bCSG => {
            buildCSG(bCSG.uid, refBase, objectsID);
          });
        }
    });
    //
    varset.forEach(v => {
      let add = true;
      self.variableDB.userVar.forEach(userV => {
        if (userV.name == v.name) {
          add = false;
        }
      });
      if(add){
        self.variableDB.userVar.push(v);
      }
    });

    baseCSG.forEach(b => {
      if (self.objects[b.uid]) {
        self.objects[b.uid].updateCSG(true);
      }
    });
    //console.log(base, objectsID, parts);
  }
  resetWS() {
    this.lastExecutedAction = 0;
    this.variableDB = new VariableDB(this);
    if(this.basePlane != null){
      this.basePlane.remove();
    }
    this.basePlane = new Plane( v3( 0, 0, 0), new THREE.Euler(0, 0, 0), this);
    this.rebuildTree();
    this.basePlane.setSelectedObj();
  }

  // tree
  rebuildTree() {
    if (this.basePlane) {
      this.tree = [this.rebuildBranch(this.basePlane)];
    } else {
      this.tree = [];
    }

  }
  rebuildBranch(obj) {
    const self = this;
    const children = [];
    let hide = false;
    if (obj && obj.children) {
      obj.children.forEach(c => {
        if (c.type !== 'cp' && c.type !== 'point2d') {
          // hide if type is point2d or cp
          children.push(self.rebuildBranch(c));
        }
      });
      let name;
      if (obj.type === 'cp') {
        name = obj.propertyName;
      } else if (obj.type === 'csg') {
        name = obj.type + ':' + obj.name;
      } else {
        name = obj.type + ':' + obj.name;
      }
      return {id: obj.uid, name, type: obj.type, hide:hide, getObj: function() { return obj; }, children};
    } else {
      return [];
    }

  }

  // csg solver
  private *workPool(tasks: CSGrequest[]): IterableIterator<CSGrequest> {
    for (const file of tasks) {
      yield file;
    }
  }
  runWorker(data) {
    const self = this;
    this.AppState.setStatus(`Solving..`, true, 'start');
    let q = 0;

    function findRootParent(obj) {
      if (obj.parent && obj.parent.csgType) {
        return findRootParent(obj.parent);
      } else {
        return obj;
      }
    }
    function findAllRootChildren(obj, arr) {
      let count = 0;
      let solved = false;
      if (obj.children) {
        obj.children.forEach(child => {
          if (child.type === 'csg') {
            const dataC = child.save();
            if (child.currentGenUIDfinal !== dataC.gUIDf) {
              count += 1;
            }
            const childData = findAllRootChildren(child, arr);
            pushUnique({obj: child, solved: childData.solved, count: childData.count, data: childData.dataO}, arr);
          }
        });
        const dataO = obj.save();
        if (obj.currentGenUIDfinal === dataO.gUIDf) {
          solved = true;
        }
        return {count, solved, dataO};
      }
    }
    const rootParent = findRootParent(data.obj);

    function recursiveChain() {
      const rootChildren = [];
      const root = findAllRootChildren(rootParent, rootChildren);
      pushUnique({obj: rootParent, solved: root.solved, count: root.count, data: root.dataO}, rootChildren);
      const concurentProcess = [];
      rootChildren.forEach(c => {
        if (c.count === 0 && c.solved === false) {
          c.obj.startSolving(); // UI Hook
          c.obj.priority = q;
          pushUnique({opt: JSON.stringify(c.data), inputUid: c.obj.uid}, concurentProcess);
        }
      });
      if (concurentProcess.length) {
        let n = '';
        concurentProcess.forEach(c => {n = n + ' ' + self.objects[c.inputUid].name + ',';    });
        self.AppState.setStatus(`Solving: ${concurentProcess.length} Parts: ${n} `, false, 'show');
      } else {
        self.AppState.setStatus(`Completed`, false, 'show');
      }
      if (concurentProcess) {
        q += 1;
        return self.solve(concurentProcess).then(_ => recursiveChain());
      } else {
        return Promise.resolve();
      }
    }
    recursiveChain().then(_ => console.log('end'));
  }
  solve(concurentProcess) {
    return new Promise((resolve, reject) => {
      const self = this;
      const task: IterableIterator<CSGrequest> = this.workPool(concurentProcess);
      let count = concurentProcess.length;

      let pool = fromWorkerPool<CSGrequest, CSGPayload>(
        () => {
          return new Worker(`../csg-compute.worker.ts`, { type: 'module' });
        },
        task
      ).subscribe((payload: CSGPayload) => {
        count -= 1;
        const obj = self.objects[payload.inputUid];
        obj.csgCB = payload.CSG;
        if (payload.CSG['class'] === 'CAG') {
          obj.csgOBJ = CAG.fromCompactBinary(payload.CSG).extrude({offset: [0, 0, 0.1]});
          jsCSGtoMesh(obj.csgOutput, obj.csgOBJ);
        } else {
          obj.csgOBJ = CSG.fromCompactBinary(payload.CSG);
          jsCSGtoMesh(obj.csgOutput, obj.csgOBJ);
        }
        obj.updateCSGcompleted(payload.gUIDf);
        if (count === 0) {
          resolve();
        }
      }, (err) => {
        if (err.obj && err.obj.uid && self.objects.hasOwnProperty(err.obj.uid)) {
          self.objects[err.obj.uid].endSolving(err);
        }
        console.log('error', err);
      });
      concurentProcess.forEach( p => {
        let obj;
        if (self.objects.hasOwnProperty(p.inputUid)) {
          obj = self.objects[p.inputUid];
          obj.csgSolveSub = pool;
        }
      });
    });
  }
}

// scene Obj's
class ObjectPicking {
  renderer;
  context;
  scene;
  camera: THREE.PerspectiveCamera;
  canvas;
  raycaster;
  mouse = {x: 50, y: 50};
  pickedObject;
  pickedObjectIntersection;
  pickedObjectSavedColor;
  ready = false;
  type = 'hover';
  timeLast = 0;
  timeLastClick = 99999;
  timeChange = 100;
  timeDelta = 9999999;
  click = 0;
  e;
  WS;

  constructor(renderer, scene, camera, canvas, WS) {
    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    this.canvas = canvas;
    this.WS = WS;
    this.init();
  }

  actionClick(e, self) {
    if (self.click > 1) {
      self.setPickPosition(e, 'double_click');
    } else if(self.click === 1) {
      self.setPickPosition(e, 'click');
    }
    self.click = 0;
  }
  singleClick(e) {
    this.click = this.click + 1;
    setTimeout(this.actionClick, 300, e , this);

  }
  init() {
    const self = this;
    this.raycaster = new THREE.Raycaster();
    this.pickedObject = null;
    this.pickedObjectSavedColor = 0;

    this.renderer.getContext().canvas.addEventListener('click', (event) => {
      self.singleClick(event);
      self.e = event;
    });

    this.renderer.getContext().canvas.addEventListener('contextmenu', (event) => {
      self.setPickPosition(event, 'rclick');
      self.e = event;
    });

    //this.renderer.getContext().canvas.addEventListener('mousemove', (event) => {
    //  self.setPickPosition(event, 'hover');
    //  self.e = event;
    //});

    this.renderer.getContext().canvas.addEventListener('mouseout', (event) => {
      self.clearPickPosition(self);
      self.e = event;
    });

    this.renderer.getContext().canvas.addEventListener('mouseleave', (event) => {
      self.clearPickPosition(self);
      self.e = event;
    });

    window.addEventListener('touchstart', (event) => {
      // prevent the window from scrolling
      event.preventDefault();
      self.singleClick(event.touches[0]);
      self.e = event;
    }, {passive: true});

    //window.addEventListener('touchmove', (event) => {
    //  self.setPickPosition(event.touches[0], 'hover');
    //  self.e = event;
    //}, {passive: true});

    window.addEventListener('touchend', (event) => {
      self.clearPickPosition(self);
    }, {passive: true});

    this.ready = true;
    self.e = event;
  }
  pick() {
    if ((performance.now() - this.timeLast) > this.timeChange && this.type && this.WS.basePlane) {
      const self = this;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      const intersectedObjects = this.raycaster.intersectObjects(this.WS.scene.children, true);
      if (intersectedObjects.length) {
        self.pickedObject = false;
        self.pickedObjectIntersection = null;
        if (this.WS.pointerMode.selectCP && !self.pickedObject) {
          let i = 0;
          let currentObj = null;
          while (i < intersectedObjects.length) {
            if (intersectedObjects[i].object.userData && intersectedObjects[i].object.userData.uid) {
              currentObj = self.WS.objects[intersectedObjects[i].object.userData.uid];
            }
            if ( currentObj &&
              ( currentObj.type === 'point2d' ||
                currentObj.type === 'cp' ||
                intersectedObjects[i].object.name === 'cp' ||
                intersectedObjects[i].object.name === 'point2d' )) {
              self.pickedObject = currentObj;
              break;
            }
            i += 1;
          }
        }
        if (this.WS.pointerMode.clickObj && !self.pickedObject) {
          let i = 0;
          let currentObj = null;
          while (i < intersectedObjects.length) {
            if (intersectedObjects[i].object.userData && intersectedObjects[i].object.userData.uid) {
              currentObj = self.WS.objects[intersectedObjects[i].object.userData.uid];
            }
            if ( currentObj && ((currentObj.type === 'csg' && currentObj.uiCode) || currentObj.uid === self.WS.selectedObj.uid ) )  {
              self.pickedObject = currentObj;
              self.pickedObjectIntersection = intersectedObjects[i];
              break;
            }
            i += 1;
          }
        }
        if (self.pickedObject) {
            this.timeChange = 100;
            if (this.type === 'click') {
              if (self.pickedObject.type === 'csg' && self.pickedObject.uiCode) {
                self.pickedObject.actionClick(self.pickedObjectIntersection);
              }
              console.log('click');
              self.pickedObject.click(self.pickedObjectIntersection);
              this.type = null;
            } else if (this.type === 'hover') {
              self.pickedObject.hover();
              this.type = null;
            } else if (this.type === 'double_click') {
              console.log('DBclick');
              self.pickedObject.click(self.pickedObjectIntersection);
              self.pickedObject.DBclick(self.pickedObjectIntersection);
              this.type = null;
            } else if (this.type === 'rclick') {
              this.type = null;
            }
        }
      }
      this.timeLast = performance.now();
    }
    this.type = null;
  }
  setPickPosition(event, type) {
    this.type = type;
    const rect = this.canvas.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / this.renderer.getContext().canvas.clientWidth ) *  2 - 1;
    this.mouse.y = ((event.clientY - rect.top) / this.renderer.getContext().canvas.clientHeight) * -2 + 1;  // note we flip Y
  }
  clearPickPosition(self) {
    // unlike the mouse which always has a position
    // if the user stops touching the screen we want
    // to stop picking. For now we just pick a value
    // unlikely to pick something
    this.type = null;
    this.mouse.x = 0;
    this.mouse.y = 0;
  }
}

// Scene Obj's
class LightSet {
  scene: THREE.Scene;
  lightSet: Light[] = [];
  params;

  constructor(scene) {
    this.scene = scene;
    this.params = {
      distance: 700,
      height: 700
    };
  }
  createPointLightSet(distance, height) {
    const initialPosition = v3(-distance / 2, -distance / 2, 0);
    this.params.distance = distance;
    this.params.height = height;

    for (let j = 0; j < 2; j++) {
      for (let i = 0; i < 2; i++) {
        const light = new Light(this.scene);
        light.setPosition(initialPosition.clone().add(v3(i * distance, j * distance, height)));
        light.insert();
        this.lightSet.push(light);
      }
    }
  }
}
class Light {
  type = 'point';
  scene: THREE.Scene;
  helper;
  light;
  params;

  constructor(scene, type = 'point') {
    this.scene = scene;
    this.type = type;
    this.params = {
      color: 'rgb(255,255,255)',
      intensity: 1,
      distance: 1000,
      helper: false,
      helperSize: 5,
      inserted: false,
      tween: false,
      rotationOrder: 'XYZ'
    };
    this.create();
    this.createHelper();
  }

  create() {
    this.light = new THREE.PointLight( this.params.color, this.params.intensity, this.params.distance );
  }

  setPosition(position) {
    if (this.light) {
      this.light.position.x = position.x;
      this.light.position.y = position.y;
      this.light.position.z = position.z;
    }
  }
  setRotation(rotation) {
    if (this.light) {
      this.light.setRotationFromEuler(new THREE.Euler(rotation.x, rotation.y, rotation.z, this.params.rotationOrder));
    }
  }

  createHelper() {
    if (!this.params.helper && !this.helper) {
      this.helper = new THREE.PointLightHelper(this.light, this.params.helperSize);
      this.params.helper = true;
      this.light.add(this.helper);
    }
  }

  removeHelper() {
    if (this.params.helper && this.helper) {
      this.helper.parent.remove(this.helper);
      this.helper = null;
      this.params.helper = false;
    }
  }

  insert() {
    if (!this.params.inserted) {
      this.scene.add(this.light);
      this.params.inserted = true;
    }

  }
  remove() {
    if (this.params.inserted) {
      this.scene.remove(this.light);
      this.params.inserted = false;
    }

  }

}
class Grid {
  grid;
  grid1;
  grid2;
  grid3;
  axes;
  scene;
  size;
  divisions;
  divisions2;
  divisions3;
  add;
  textArr = [];
  aArr = [];
  bArr = [];
  buildVolume;
  constructor(scene, size, divisions, add = true) {
    this.size = size;
    this.add = add;
    this.divisions = divisions;
    this.scene = scene;
    this.init();
  }

  init() {
    let self = this;
    let size = this.size;
    let div = this.divisions;

    let fontSize = 3;
    this.grid = new THREE.Group();
    this.buildVolume = new THREE.Mesh(new THREE.BoxGeometry(size+1.66,1.66,size+1.66),material.clone());
    this.buildVolume.position.set(0,-1.66/2,0);
    this.buildVolume.visible = false;
    this.grid.add(this.buildVolume);

    this.grid1 = new THREE.GridHelper(size, div,new THREE.Color('#000000'), new THREE.Color('#0086ce'));
    if(this.divisions2){
      let gridA = new THREE.GridHelper(size, this.divisions2,new THREE.Color('#000000'),new THREE.Color('#e2e8ef'));
      gridA.position.set(-0.03,-0.03,-0.03);
      this.grid1.add(gridA);
      this.aArr.push(gridA);
    }
    if(this.divisions3){
      let gridB = new THREE.GridHelper(size, this.divisions3,new THREE.Color('#000000'),new THREE.Color('#ffefe1'));
      gridB.position.set(-0.06,-0.06,-0.06);
      this.grid1.add(gridB);
      this.bArr.push(gridB);
    }

    this.grid2 = new THREE.GridHelper(size, 1);
    if(this.divisions2){
      let gridA = new THREE.GridHelper(size, this.divisions2,new THREE.Color('#000000'),new THREE.Color('#e2e8ef'));
      gridA.position.set(-0.03,-0.03,-0.03);
      this.grid2.add(gridA);
      this.aArr.push(gridA);
    }
    if (this.divisions3) {
      let gridB = new THREE.GridHelper(size, this.divisions3,new THREE.Color('#000000'),new THREE.Color('#ffefe1'));
      gridB.position.set(-0.06,-0.06,-0.06);
      this.grid2.add(gridB);
      this.bArr.push(gridB);
    }
    this.grid2.rotateZ(Math.PI / 2);
    this.grid3 = new THREE.GridHelper(size, 1);
    if (this.divisions2) {
      let gridA = new THREE.GridHelper(size, this.divisions2,new THREE.Color('#000000'),new THREE.Color('#e2e8ef'));
      gridA.position.set(-0.03,-0.03,-0.03);
      this.grid3.add(gridA);
      this.aArr.push(gridA);
    }
    if (this.divisions3) {
      let gridB = new THREE.GridHelper(size, this.divisions3,new THREE.Color('#000000'),new THREE.Color('#ffefe1'));
      gridB.position.set(-0.06,-0.06,-0.06);
      this.grid3.add(gridB);
      this.bArr.push(gridB);
    }
    this.grid3.rotateX(Math.PI / 2);


    this.grid.add(this.grid1);
    this.grid.add(this.grid2);
    this.grid.add(this.grid3);

    function T(text,p){
      let topText = new TextMesh();
      self.textArr.push(topText);
      self.grid.add(topText);
// set properties to configure:
      topText.text = text;
      topText.rotateX(Math.PI/-2);
      topText.position.copy(p);
      topText.fontSize = fontSize;
      topText.color = 0x9966FF;
// be sure to call sync() after all properties are set to update the rendering:
      topText.sync();
      return topText;
    }
    let top = T('' + size + 'x' + size + 'mm',v3(-size/2 ,0,size/2 + fontSize*1.1));
    let topY = T('+y',v3(0 ,0,-size/2 - fontSize*1.3));
    let topX = T('+z',v3(0 ,size/2 + fontSize*1.3,0));
    let topZ = T('+x',v3(size/2 + fontSize*0.3,0,0 ));
    topX.rotateX(Math.PI/2);
    this.grid.rotateX(Math.PI / 2);
    if (this.add) {
      this.scene.add(this.grid);
    }
    this.axes = new THREE.AxesHelper( 1000 );
    if (this.add) {
      this.scene.add(this.axes);
    }
  }
  setVisible(opt) {
    this.divisions = opt.divisions;
    this.divisions2 = opt.divisions2;
    this.divisions3 = opt.divisions3;
    this.size = opt.size;
    this.update();

    this.grid.visible = opt.divisionsOn.main;

    this.grid1.visible = opt.grid.z;
    this.grid1.position.setY(opt.offset.z);

    this.grid2.visible = opt.grid.x;
    this.grid2.position.setX(opt.offset.x);

    this.grid3.visible = opt.grid.y;
    this.grid3.position.setZ(-opt.offset.y);

    this.axes.visible = opt.axes;
    this.buildVolume.visible = opt.buildVolume;

    this.aArr.forEach(a => {
      a.visible = opt.divisionsOn.a;
    });
    this.bArr.forEach(b => {
      b.visible = opt.divisionsOn.b;
    });

    //console.log(this);
    //console.log(opt);
  }
  getGrid() {
    return this.grid;
  }
  destroy() {
    this.textArr.forEach(t => {
      t.parent.remove(t);
      t.dispose();
    });
    this.textArr = [];
    this.aArr = [];
    this.bArr = [];
    this.grid.parent.remove(this.grid);
    this.grid2.parent.remove(this.grid2);
    this.grid3.parent.remove(this.grid3);
    this.axes.parent.remove(this.axes);
  }
  update(){
    this.destroy();
    this.init();
  }
}
interface GearOpt {
  pitch?: number;
  pressureAngle?: number;
  clearance?: number;
  backlash?: number;
  profileShift?: number;
  t1?: number;
  t2?: number;
  holeD1?: number;
  holeD2?: number;
  res?: number;
  stepsPerToothAngle?: number;
  show?: string;
  height?: number;
  rackLength?: number;
  twist?: number;
  toothCount?: number;
  pType?: number;
  add_t_w?: number;
  add_t_d?: number;
  t_h?: number;
  b_h?: number;
  b_d?: number;
  t_g?: number;
  b_g?: number;
  shaft_d?: number;
  bolt_d?: number;
  nut_d?: number;
  nut_h?: number;
  nut_offset?: number;
  nut_slot?: boolean;
  bolt_count?: number;
  belt_t?: number;
  gentype?: string;
}
interface FOpt {
  fType: number;
  nType: number;
  nutOffset: number;
  cs: boolean;
  tapper: number;
  nutSlot: number;
  h: number;
  opt: FOptCustom;
}
interface FOptCustom {
  d: number;
  capD: number;
  capH: number;
  nutD: number;
  nutH: number;
}
interface ShellOpt {
  en: boolean;
  in?: boolean;
  wall: number;
  xt?: boolean;
  yt?: boolean;
  zt?: boolean;
  xb?: boolean;
  yb?: boolean;
  zb?: boolean;
}
interface CodeOpt {
  usedWedges: number;
  wedgeOpen: boolean;	// wedge on edge open or closed
  bottom: number;		// south pole is 0
  top: number;		// max. equator * 2 (is north pole)
  withBottom: boolean;	// with a bottom (bottom > 0)
  withTop: boolean;

  quadLine: any;
  circOpen: boolean;		// circular connected or disconnected

  widthSegments: number;
  heightSegments: number;
  depthSegments: number;
  smoothness: number;
  radius: any;

  waffleDeep: any;
  rounding: any;
  pointX: any;
  pointY: any;
  contourmode: any; //string
  // waffled,		// four faces / segment, with center vertex
}
interface CurvOpt {
  pre: string;
  post: string;
}
interface PhysicsOpt {
  type: string,
  mesh: string,//tri_mesh,solid
  density: Number;
  friction: Number;
  restitution: Number;
  lockT:  any[];
  lockR: any[];
  jointSet: JointOpt[];
  initSet: InitOpt[];
}
interface JointOpt {
    type: string;
    rootUID: string;//b
    jointPositionA: any;
    jointPositionB: any;
    jointRotationA: any;
    jointRotationB: any;
    axis: any;
    axisB: any;
    limits: any;
    limitsEnabled: any;
}
interface InitOpt {
  type: string;
  value: any;
}
// base objects
class Obj {
  type: string;
  name = '';
  nickName = '';
  color = '#348ECA';
  mesh;
  curvOpt: CurvOpt = {pre:'',post:''};
  G: THREE.Group = new THREE.Group();

  geometry;
  position: THREE.Vector3 = new THREE.Vector3();
  rotation: THREE.Euler = new THREE.Euler();
  scale: THREE.Vector3 = new THREE.Vector3(1, 1, 1);
  enable = true;
  // tree
  children = [];
  parent;
  dependencies = [];
  // states
  I;
  M;
  uid: string; // Unique Id of the object

  //WS
  WS: WorkSpace;
  error = false;
  pointSet = false;
  transformOpt = {mode: 'p', axis: [true, true, true], size: 1, snap: 1, space: 'local'};
  variableSet: VariableSet;

  // customConfigs
  gearOpt: GearOpt = {} as GearOpt;
  fOpt: FOpt = {} as FOpt;
  shellOpt: ShellOpt = {} as ShellOpt;
  codeOpt: CodeOpt = {} as CodeOpt;
  phyOpt: PhysicsOpt = {} as PhysicsOpt;
  meshSlicer;//2D Slicer object for this geometry
  code = '';
  codeEn = false;
  clone = '';
  animation = {time:[], contacts:[], points:[], velA:[], velL:[]};
  c = [];

  DBclick(pickedObjectIntersection = null) {
    this.setSelectedObj();
    this.attachTransform();
  }
  save(clo=false,dbsave=false) {
    if (this.clone && !clo && !dbsave) {
      const cloneObj = this.WS.objects[this.clone].save(true);
      cloneObj.uid = this.uid;
      cloneObj.name = this.name;
      cloneObj.nickName = this.nickName;
      cloneObj.color = this.color;
      return cloneObj;
    }
    const childrean = [];
    this.c = [];
    const self = this;
    this.children.forEach(c => {
      childrean.push(c.uid);
      if (c.csgType || c.type === 'shape2d' || c.type === 'point2d') {
        this.c.push(c.uid);
      }
    });
    let p;
    if (this.G) {
      this.G.updateMatrix();
    }
    const varLib = [];
    if (this.variableSet.enabled) {
      this.variableSet.variables.forEach(v => {
        if (v.exp !== '') {
          const index = self.variableSet.varSubSet[v.subSetName].indexOf(v);
          varLib.push({n: v.name, i: index, ssN: v.subSetName,  exp: v.exp });
        }
      });
    }
    if (this.parent) {
      p = this.parent.uid;
    } else {
      p = 0;
    }


    let obj = {
      uid: this.uid,
      type: this.type,
      name: this.name,
      enable: this.enable,
      nickName: this.nickName,
      color: this.color,
      curvOpt: this.curvOpt,
      phyOpt: this.phyOpt,
      pos: v3toArr(this.G.position),
      rot: v3toArr({x:this.G.rotation.x*180/Math.PI, y:this.G.rotation.y*180/Math.PI, z:this.G.rotation.z*180/Math.PI}),
      scale: v3toArr(this.G.scale),
      vLib: varLib,
      animation: this.animation,
      userVar: this.WS.variableDB.userVar,
      clone: this.clone,
      q:0, //priority when solving, undefined error.
      p,
      cAllObj: childrean,
      c: this.c
    };
    if (!this.codeEn){
      obj.userVar = [];
    }
    //console.log(obj);
    return this._save(obj);
  }
  _save(obj) {
    return obj;
  }

  actions = [];
  load(data) {
    const self = this;
    if (this.variableSet.enabled) {
      data.vLib.forEach(v => {
        try {
          self.variableSet.varSubSet[v.ssN][v.i].exp = v.exp;
        } catch (e) {
          console.log('err:', e);
        }
      });
    }
    const m = new THREE.Matrix4().fromArray(data.t.e);
    this.setMatrix(m);
    if(data.name) {
      this.name = data.name;
    }
    if(data.clone) {
      this.clone = data.clone;
    }
    if(data.enable === false) {
      this.enable = data.enable;
    }
    if(data.nickName) {
      this.nickName = data.nickName;
    }
    if(data.color){
      this.color = data.color;
    }
    if(data.actions){
      this.actions = data.actions;
    }
    if(data.opt?.shell){
      this.shellOpt = data.opt.shell;
    }
    if(data.curvOpt){
      this.curvOpt = data.curvOpt;
    }
    //console.log(this.phyOpt);
    if(data.phyOpt){
      this.phyOpt = data.phyOpt;
    }
    return this._load(data);
  }
  _load(data) {
    return data;
  }
  setMatrix(m) {
    this.G.applyMatrix4(m);
    this.updatePRS(m);
  }
  updatePRS(m) {
    this.position = this.G.position;
    this.rotation = this.G.rotation;
    this.scale = this.G.scale;
  }
  constructor(type, parent, position, WS, uid = null) {
    this.type = type;
    this.WS = WS;
    this.phyOpt = {
      type: 'dynamic',
      mesh: 'solid',
      density: 1,
      friction: 0.5,
      restitution:0,
      lockT:  [1,1,1],
      lockR: [1,1,1],
      jointSet: [],
      initSet: []
    };

    this.variableSet = new VariableSet(this);
    this.position = position;
    if (uid) {
      this.uid = uid;
    } else {
      this.uid = ID();
    }
    this.setParent(parent);
    this.WS.objects[this.uid] = this;
  }
  // config
  setTransform(mode = null) {
    const self = this;
    if (mode) {
      this.transformOpt.mode = mode;
    }
    this.transformOpt.snap = this.WS.AppState.ui.snap;
    if (self.WS.transformControls.object && self.WS.transformControls.object.uuid === this.G.uuid && this.variableSet.enabled && this.variableSet.varSubSet[this.transformOpt.mode]) {
        this.transformOpt.axis.forEach( (a, i, arr) => {
          if (this.variableSet.varSubSet[this.transformOpt.mode][i].exp === '') {
            self.transformOpt.axis[i] = true;
          } else {
            self.transformOpt.axis[i] = false;
          }
        });
    }
    configTransform(self.WS.transformControls, this.transformOpt);
  }
  attachTransform() {
    const self = this;
    self.setTransform();
    if(self.WS.transformObjID && self.WS.objects.hasOwnProperty(self.WS.transformObjID)){
      self.WS.objects[self.WS.transformObjID].detachedTransformEvent(this);
    }
    self.WS.transformObjID = self.uid;
    self.WS.transformControls.attach(self.G);
    self.attachedTransformEvent(self);
  }
  detachTransform() {
    const self = this;
    self.WS.transformControls.detach();
  }
  detachedTransformEvent(e) {
    const self = this;
    //self.I.send('view');
  }
  attachedTransformEvent(e) {
    const self = this;
    //self.I.send('view');
  }
  setGeometry(geometry) {
    this.geometry = geometry;
    this.init();
    this.initState();
    this.updateRelations();
  }
  init() {
    const self = this;
    this.mesh = new THREE.Mesh(this.geometry, material.clone());
    this.G.add(this.mesh);
    this.G.name = this.type;
    this.G.position.copy(this.position);
    this.G.rotation.copy(this.rotation);
    this.G.scale.copy(this.scale);
    setObjRef(this.mesh, self);
    setObjRef(this.G, self);
  }
  setVariable(){
  }
  onFirstLoad(finalObj) {
  }

  // hierarchy functions
  updateRelations() {
    if (this.parent) {
      this.parent.G.add(this.G);
    } else if (this.G && this.G.parent) {
      // this.G.parent.remove(this.G);
    }
    this._updateRelations();
    this.WS.rebuildTree();
  }
  _updateRelations() {}
  setParent(parent) {
    if ( parent ) {
      this.parent = parent;
      parent.addChild(this);
    }
    this.updateRelations();
  }
  addChild(child) {
    this.children.push(child);
    child.parent = this;
    child.updateRelations();
  }
  removeChild(child) {
    const id = this.children.indexOf(child);
    if (id > -1) {
      this.children.splice(id, 1);
      child.parent = undefined;
    }
    child.updateRelations();
  }
  changeParent(newParent, update = false) {
    if(newParent.uid === this.uid){ //prevent parent change to self
      return;
    }
    this.parent.removeChild(this);
    newParent.addChild(this);
    if (update) {
      if ( newParent.type === 'csg') {
        newParent.updateCSG();
      } else if (this.type === 'csg') {
        this.updateCSG();
      }
      this.dbUpdate('changeParent', {to: newParent.uid});
    }
  }
  copyTo(targetParent, update = false) {
    if(targetParent.uid === this.uid){ //prevent copy to self
      return;
    }
    const copyData = JSON.parse(JSON.stringify(this.save(false,true)));
    copyData.name = '';
    const newObj = this.WS.addObjFromData(copyData, targetParent, null);
    if (newObj) {
      this.children.forEach(c => {
        if (c.type !== 'cp') {
          c.copyTo(newObj, false);
        }
      });
    } else {
      console.log('Failed to copy OBJ');
    }
    if (update) {
      if ( targetParent.type === 'csg') {
        targetParent.updateCSG();
      } else if (this.type === 'csg') {
        this.updateCSG();
      }
      this.dbUpdate('Copy to', {to: targetParent.uid});
    }
    return newObj;
  }
  // higher funcions
  copyAll(fromObj) {
    let toObj = this;
    for (let a of fromObj.variableSet.varSubSetTree) {
      for (let b of fromObj.variableSet.varSubSetTree[a]) {
        if (fromObj.variableSet.varSubSetTree[a][b] && toObj.variableSet.varSubSetTree[a][b]) {
          toObj.variableSet.varSubSetTree[a][b].exp = fromObj.variableSet.varSubSetTree[a][b].exp;
          toObj.variableSet.varSubSetTree[a][b].set(fromObj.variableSet.varSubSetTree[a][b].get());
        }
      }
    }
  }

  copyMatrix(fromObj, set=['p','r','s'], set2=['x','y','z']){
    let toObj = this;
    for (let a of set) {
      for (let b of set2) {
        if (fromObj.variableSet.varSubSetTree[a][b] && toObj.variableSet.varSubSetTree[a][b]) {
          toObj.variableSet.varSubSetTree[a][b].exp = fromObj.variableSet.varSubSetTree[a][b].exp;
          toObj.variableSet.varSubSetTree[a][b].set(fromObj.variableSet.varSubSetTree[a][b].get());
        }
      }
    }
  }
  resetMatrix(set=['p','r','s']) {
    //let fromObj = this.clipboard;
    for (let a of set) {
      for (let b of ['x','y','z']) {
          this.variableSet.varSubSetTree[a][b].exp = '';
          if (a === 's') {
            this.variableSet.varSubSetTree[a][b].set(1);
          } else {
            this.variableSet.varSubSetTree[a][b].set(0);
          }
        }
      }
  }
  clearOperation() {
    let list = [];
    this.children.forEach(c => {
      list.push(c);
    });
    list.forEach(c => {
      if (c.type === 'csg') {
        c.changeParent(this.parent);
        c.copyMatrix(this);
      }
    });
  }
  // csg overloads
  updateCSG() {}

  //align-childSet
  alignAll(axis) {
    let self = this;
    if(this.getAllChildren([],'csg').length){
      this.children.forEach( (c,i,arr) => {
        let pos = c.G.position.clone();
        pos[axis] = arr[0].G.position[axis];
        c.setPos(pos);
      });
      this.updateCSG();
    } else {
      this.parent.children.forEach( (c,i,arr) => {
        let pos = c.G.position.clone();
        pos[axis] = self.G.position[axis];
        c.setPos(pos);
      });
      this.parent.updateCSG();
    }
  }

  // chain object resolvers
  getSiblings(type = null) {
    const self = this;
    const s = [];
    if (this.parent) {
      this.parent.children.forEach(c => {
        if (this.uid !== c.uid) {
          if (type) {
            if (c.type === type) {
              s.push(c);
            }
          } else {
            s.push(c);
          }
        }
      });
    }
    return s;
  }
  getAllChildren(arr = [], type = null) {
    this.children.forEach(child => {
      child.getAllChildren(arr);
      if (type) {
        if (child.type === type) {
          arr.push(child);
        }
      } else {
        arr.push(child);
      }
    });
    return arr;
  }
  getAllDistantChildren(arr = [], type = null, depth = 0) {
    this.children.forEach(child => {
      if (depth > 0) {
        if (type) {
          if (child.type === type) {
            arr.push(child);
          }
        } else {
          arr.push(child);
        }
      }
      depth += 1;
      child.getAllDistantChildren(arr, type, depth);
    });
    return arr;
  }
  getAllParents(arr = []) {
    if (this.parent) {
      this.parent.getAllParents(arr);
      arr.push(this.parent);
    }
    return arr;
  }
  getAllParentsChildren(arr = [], type = null) {
    if (this.parent) {
      this.parent.getAllParentsChildren(arr, type);
      const siblings = this.getSiblings(type);
      siblings.forEach(s => {
        arr.push(s);
      });
    }
    return arr;
  }
  findRootParent(obj) {
    if (obj.parent && obj.parent.csgType) {
      return this.findRootParent(obj.parent);
    } else {
      return obj;
    }
  }
  findAllRootChildren(obj, arr) {
    if (obj.children) {
      obj.children.forEach(child => {
        if (child.type === 'csg') {
          this.findAllRootChildren(child, arr);
          pushUnique(child, arr);
        }
      });
    }
  }
  // variables setup
  initVariables() {}
  _initVariables() {}

  // positioning
  setPos(pos) {
    this.G.position.copy(pos);
    this.position = this.G.position;
    this.update();
    this._update();
  }
  setRot(rot) {
    this.G.setRotationFromEuler(rot);
    this.rotation = this.G.rotation;
    this.update();
    this._update();
  }
  setScale(scale) {
    this.G.scale.copy(scale);
    this.scale = this.G.scale;
    this.update();
    this._update();
  }

  //core
  initState() {
    const self = this;
    this.M = Machine({
        // Machine identifier
        id: this.uid,
        // Initial state
        initial: 'view',

        // Local context for entire machine
        context: {
          visible: true,
          color: '#0db9fe',
          translate: false
        },

        // State definitions
        states: {
          view: {
            entry: 'setView',
            on: {
              select: {target: 'select'},
              edit: {target: 'edit'},
              hide: {target: 'hide'},
              shadow: {target: 'shadow'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          select: {
            entry: 'setSelect',
            on: {
              view: {target: 'view'},
              edit: {target: 'edit'},
              hide: {target: 'hide'},
              shadow: {target: 'shadow'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          edit: {
            entry: 'setEdit',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              hide: {target: 'hide'},
              shadow: {target: 'shadow'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          shadow: {
            entry: 'setShadow',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              hide: {target: 'hide'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          parentShadow: {
            entry: 'setParentShadow',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              hide: {target: 'hide'},
              childShadow: {target: 'childShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          childShadow: {
            entry: 'setChildShadow',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              hide: {target: 'hide'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          hide: {
            entry: 'setHide',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              shadow: {target: 'shadow'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'},
              parentHide: {target: 'parentHide'}
            }
            /* ... */
          },
          childHide: {
            entry: 'setChildHide',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              shadow: {target: 'shadow'},
              hide: {target: 'hide'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              parentHide: {target: 'parentHide'}
            }
          },
          parentHide: {
            entry: 'setParentHide',
            on: {
              view: {target: 'view'},
              select: {target: 'select'},
              edit: {target: 'edit'},
              shadow: {target: 'shadow'},
              hide: {target: 'hide'},
              childShadow: {target: 'childShadow'},
              parentShadow: {target: 'parentShadow'},
              childHide: {target: 'childHide'}
            }
          }
        }
      }, {
      actions: {
        // action implementation
        setView: (context, event) => {
            self.setView(context, event);
        },
        setSelect: (context, event) => {
            self.setSelect(context, event);
        },
        setEdit: (context, event) => {
            self.setEdit(context, event);
            if (self.type === 'cp' && !self['controlVariable']?.exp) {
              self.attachTransform();
            } else if (self.type === 'csg'){
              self.attachTransform();
            } else if (self.type === 'point2d'){
              self.attachTransform();
            } else if (self.type === 'shape2d'){
              self.attachTransform();
            }
            const node = self.WS.AppState?.root?.pTree?.treeModel.getNodeById(this.uid);
            if (node) {
              node.ensureVisible();
              if(this.children.length < 5){
                node.expand();
              }
              node.focus();
            }
        },
        setShadow: (context, event) => {
            self.setShadow(context, event);
        },
        setHide: (context, event) => {
            self.setHide(context, event);
        },
        setParentShadow: (context, event) => {
            self.setParentShadow(context, event);
        },
        setChildShadow: (context, event) => {
            self.setChildShadow(context, event);
        },
        setParentHide: (context, event) => {
            self.setParentHide(context, event);
        },
        setChildHide: (context, event) => {
            self.setChildHide(context, event);
        },
      }
    });
    this.I = interpret(this.M);
    this.I.start();
  }

  _setSelectedObj() {

  }
  resetMarkers() {
    const self = this;
    let list = [];
    this.clickMarkers.forEach((marker) => {
      self.removeMarker(marker);
      list.push(marker);
    });
    list.forEach((marker) => {
      self.removeMarker(marker);
    });

    this.clickMarkers = [];
  }
  removeMarker(marker) {
    marker.parent?.remove(marker);
    this.clickMarkers.splice(this.clickMarkers.indexOf(marker), 1);
  }
  setSelectedObj(){//ROOT STUB
    this._setSelectedObj();
    const node = this.WS.AppState?.root?.pTree?.treeModel.getNodeById(this.uid);
    if (node) {
      node.ensureVisible();
      node.focus();
    }
    this.WS.selectedObj?.resetMarkers();
    this.WS.selectedObj = this;
    this.resetMarkers();
    this.updateTLinks();
  }
  setView(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 1, '#7b87fe', 1);
  }
  setSelect(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 1, '#fefc1e', 1);
  }
  setEdit(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 1, '#fe0712', 1);
  }
  setShadow(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 0.5, '#45494a', 0.5);
  }
  setParentShadow(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 0.5, '#0f72a5', 0.5);
  }
  setChildShadow(context, event) {
    this.G.visible = true;
    setMaterial(this.mesh, 0.5, '#0f72a5', 0.5);
  }
  setHide(context, event) {
    this.G.visible = false;
  }
  setParentHide(context, event) {
    this.G.visible = false;
  }
  setChildHide(context, event) {
    this.G.visible = false;
  }
  _destroy(userInit = false) {
    const self = this;
    if (this.parent) {
      this.parent.removeChild(this);
    }
    this.I = undefined;
    this.M = undefined;
    this.variableSet.removeAll();
    this.removeTLinks();
    //delete this.variableSet;
    delete this.WS.objects[this.uid];
  }
  remove(userInit = false) {
  }

  childUpdated() {
    if (this.parent) {
      this.parent.childUpdated();
    }
  }
  dependencyUpdate() {
  }
  addDependency(obj, oneWay = true) {
    this.dependencies.push(obj);
    if (oneWay) {
      obj.dependencies.push(this);
    }
  }
  _update() {
    this.dependencies.forEach( dep => {
      dep.dependencyUpdate();
    });
    if (this.parent) {
      this.parent.childUpdated();
      this.updateTLinks();
    }
  }
  update() {
  }

  // events
  highlight() {
    if(this.type == 'cp'){
      this.parent.toggleState();
      this.click();
    }
    if(this.type == 'csg'){
      if(this.parent.type != 'csg'){
        this.toggleState();
      }else {
        this.click();
      }
    }

  }

  toggleState() {
    if ( this.I.state.value === 'view') {
      this.I.send('edit');
    } else if ( this.I.state.value === 'edit') {
      this.I.send('view');
    }
    this.setSelectedObj();
  }
  clickMarkers = [];

  markerCalc(type,v1=null,v2=null,v3=null) {
    console.log('markerCalc',type,v1,v2,v3);
    if (type === 'distance') {
      let distance = 0;
      this.clickMarkers.forEach((marker, i, arr) => {
        if (i > 0) {
          distance += this.clickMarkers[i-1].position.distanceTo(this.clickMarkers[i].position);
        }
      });
      return distance;
    } else if (type === 'measureVector') {
      return v1.clone().sub(v2);
    } else if (type === 'measureAngle') {
      return v1.clone().sub(v2).angleTo(v2.clone().sub(v3))*180/Math.PI;
    }
  }
  click(pickedObjectIntersection = null) {
     //add axis helper point on the intersection mesh
    if (this.WS.pointerMode.clickMarkers && pickedObjectIntersection) {
      if (this.type === 'csg') {
        let axisHelper = new THREE.AxesHelper(3);
        axisHelper.position.copy(pickedObjectIntersection.point);

        //TODO: normalize the rotation is required
        const q = new THREE.Quaternion().setFromUnitVectors(v3(0, 0, 1), pickedObjectIntersection.face.normal);
        axisHelper.rotation.setFromQuaternion(q);
        this.WS.scene.add(axisHelper);
        this.clickMarkers.push(axisHelper);
      }
    }
    //
    //if(this.I.state.value == 'edit'){
    //  this.I.send('view');
    //}else {
    //  this.I.send('view');
    //}
    //this.setSelectedObj();
  }

  hover() {
    //console.log('Hover');
  }
  hide() {
    this.I.send('hide');
  }

  onTransformMouseUp(e) {
  }
  dbUpdate(action, data = null) {
    //none
  }
  onTransformMouseDown(e) {
    //console.log('mouse down');
  }
  onTransformMouseChange(e) {
    let obj = e.target.object;
    this.update();
    this._update();
  }

  treeLinks = {points: [], pos0: new THREE.Vector3(0,0,0), mesh:null, hLine:{x:null, y:null, z:null}, tLine:{x:null, y:null, z:null}, pLine:{x:null, y:null, z:null}};
  treeLineMaterial = new THREE.LineBasicMaterial( { color : 0xff0000 } );
  treeLineMaterialDz = new THREE.LineDashedMaterial( { color: 0x0000ff, dashSize: 4.9, gapSize: 0.1 } );
  addLine = [];

  actionLinks = [{mesh: null}];
  cutMaterial = new THREE.MeshBasicMaterial( {color: 0xAA3939,  transparent:true, opacity:0.3, side: THREE.DoubleSide} );
  mirrorMaterial = new THREE.MeshBasicMaterial( {color: 0x3939AA,  transparent:true, opacity:0.3, side: THREE.DoubleSide} );
  updateTLinks() {
    const self = this;
    this.removeTLinks();
    if ( this.parent?.G?.position && this.parent.children.length < 5 && this.WS.uiMode.widgets) {
      this.treeLinks.points.push(v3());
      this.treeLinks.points.push(this.G.position.clone());
      this.treeLinks.mesh = new THREE.Line( new THREE.BufferGeometry().setFromPoints(this.treeLinks.points), this.treeLineMaterialDz.clone());
      this.G.parent?.add(this.treeLinks.mesh);
    }
    this.actions.forEach((a,i, arr) => {
      let bb = new THREE.Box3().setFromObject(this.G);
      let size = bb.getSize(new THREE.Vector3());
      let maxSize = Math.max(size.x, size.y, size.z)+5;
      let mat = self.mirrorMaterial;
      if (self.actions[i].action === 'cut') {
        mat = self.cutMaterial;
      }
      this.actionLinks[i] = {mesh: null};
      this.actionLinks[i].mesh = new THREE.Mesh( new THREE.PlaneGeometry( maxSize, maxSize ), mat.clone() );
      this.actionLinks[i].mesh.lookAt(
        v3(self.actions[i].opt.x, self.actions[i].opt.y, self.actions[i].opt.z)
      );
      this.actionLinks[i].mesh.position.copy(
        v3(self.actions[i].origin.x, self.actions[i].origin.y, self.actions[i].origin.z)
      );
      this.G.add(this.actionLinks[i].mesh);
      if (self.actions[i].opt) {
      }
      if (self.actions[i].origin) {
      }
    });
  }
  removeTLinks() {
    this.treeLinks.points = [];
    if (this.treeLinks.mesh) {
      disposeMesh(this.treeLinks?.mesh);
    }
    this.actionLinks.forEach((a,i, arr) => {
      this.actionLinks[i]?.mesh?.parent?.remove(this.actionLinks[i].mesh);
    });
  }

}
// working
class Plane extends Obj {
  grid;
  _save(obj) {

    return {...obj,
      pos: v3toArr(this.G.position),
      rot: v3toArr(this.G.rotation),
      scale: v3toArr(this.G.scale)
    };
  }
  constructor(position, rotation = null, WS, uid = null) {
    super('plane', null, new THREE.Vector3(), WS, uid);
    const geo = new THREE.Geometry();
    this.position = position;
    this.rotation = rotation;
    this.setGeometry(geo);
  }
  init() {
    const self = this;
    this.G = new THREE.Group();
    this.mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry( 30, 30, 1 ), material.clone());
    this.G.position.copy(this.position);
    setObjRef(this.mesh, self);
    setObjRef(this.G, self);
    this.WS.scene.add( this.G );
  }
  remove(userInit = false) {
    const self = this;
    const cList = [];
    this.children.forEach(c => {
      cList.push(c);
    });
    cList.forEach(c => {
      if (c.type === 'csg' ) {
        c.remove();
      }
    });
    this.WS.transformControls.detach();
    this.dependencies.forEach(dep => {
      removeFromArr(this, dep.dependencies);
      if (dep.pointSet) {
        removeFromArr(self, dep.ptSet);
        dep.dependencyUpdate();
      } else {
        dep.remove();
      }
    });
    this.mesh.parent?.remove(this.mesh);
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.mesh = undefined;
    this.grid = null;

    this.G.parent.remove(this.G);
    this.G = undefined;
    this._destroy(userInit);
  }
}
class ControlPoint extends Obj {
  propertyName;
  propertyValue;
  direction = v3(0, 0, 0);
  cpPosEq;
  valueEq;
  controlVariable;
  light;
  cpSizeFactor = 1;
  angle = false;
  text = {
    g: {} as any,
    name: {} as any,
    name2: {} as any
  };
  updateFunction;
  _save(obj) {
    return {...obj,
      propertyName: this.propertyName,
      propertyValue: this.propertyValue,
    };
  }
  constructor( parent, propertyName, WS, opt , uid = null ) {
    super('cp', parent, v3(), WS, uid);
    this.propertyName = propertyName;
    const geo = new THREE.SphereGeometry(this.WS?.AppState?.ui?.cpScale*this.cpSizeFactor,6,6);//new THREE.SphereGeometry( 1, 9, 3 );

    if (opt.axis) {
      this.transformOpt.axis = opt.axis;
    }
    this.setGeometry(geo);
    if (opt.color) {
      this.mesh.material.color.set(opt.color);
    }
    if (opt.angle === true) {
      this.angle = true;
    }
    //config text
    this.text.name = new TextMesh();
    this.text.name2 = new TextMesh();
    this.text.g = new THREE.Group();
    this.text.g.add(this.text.name);
    this.text.g.add(this.text.name2);
    this.WS.scene.add(this.text.g);
    this.text.g.visible = false;

    this.text.name.anchorY = 'bottom';
    this.text.name.anchorX = 'right';
    this.text.name.maxWidth = 1.00;
    this.text.name.textAlign = 'center';

    this.text.name2.anchorY = 'top';
    this.text.name2.anchorX = 'left';
    this.text.name2.maxWidth = 1.00;
    this.text.name2.textAlign = 'center';
  }
  updateText() {
    if (this.text.name && this.text.name.quaternion) {
      if (this.controlVariable) {
        this.text.name.text = this.propertyName + ': ';
        if(this.controlVariable.exp) {
          this.text.name.text += '[' + this.controlVariable.exp + ']';
        }
      } else if(this.propertyValue) {
        //this.text.name.text = this.parent.name + '.' + this.propertyName + ': ' + this.propertyValue;
        this.text.name.text = this.propertyName + ': ' + this.propertyValue;
      } else {
        this.text.name.text = this.propertyName;
      }
      this.text.name.color = this.mesh.material.color.getHex();

      this.text.g.position.setFromMatrixPosition(this.mesh.matrixWorld.clone());
      this.text.g.quaternion.copy(this.WS.camera.quaternion);
      this.text.name.sync();
      this.text.name.scale.copy(this.mesh.scale);
    }
    if (this.text.name2 && this.text.name2.quaternion && this.propertyValue) {
      this.text.name2.text =  this.propertyValue;
      this.text.name2.color = this.mesh.material.color.getHex();

      this.text.g.position.setFromMatrixPosition(this.mesh.matrixWorld.clone());
      this.text.g.quaternion.copy(this.WS.camera.quaternion);
      this.text.name2.sync();
      this.text.name2.scale.copy(this.mesh.scale);
    }
  }

  click(pickedObjectIntersection = null){
    if(this.I.state.value == 'edit' && (this.WS.selectedObj.uid === this.uid)){
      this.I.send('view');
      this.I.send('edit');
    } else if (this.I.state.value == 'view') {
      this.I.send('edit');
    } else {
      this.I.send('view');
    }
    this.setSelectedObj();
  }


  uiSelect(){
    this.click();
  }
  exportSTL(){
    this.parent.exportSTL();
  }
  loopCPSiblings(exp){
    if(this.parent) {
      for (let i=0; i < this.parent.children.length; i++) {
        if(this.parent.children[i].type === 'cp' && this.parent.children[i].uid != this.uid){
          exp(this.parent.children[i]);
        }
      }
    }
  }

  initControlVar(variable) {
    this.controlVariable = variable;
  }
  setView(context, event) {
    this.G.visible = true;
    this.text.g.visible = false;
    this.setStyle();
  }
  setSelect(context, event) {
    this.G.visible = true;
    this.text.g.visible = true;
    this.setStyle();
  }

  setStyle(){
    let s = this.WS.AppState.ui.s[this.I.state.value];
    let scaleFactor;
    //console.log(s);
    if(s) {
      if(this.controlVariable?.exp){
        this.text.name.fontSize = s.text1;
        this.text.name2.fontSize = s.text2;
        scaleFactor = s.scaleEXP;
      } else {
        this.text.name.fontSize = s.text1EXP;
        this.text.name2.fontSize = s.text2EXP;
        scaleFactor = s.scale;
      }
      this.updateText();
    }
  }

  setEdit(context, event) {
    this.G.visible = true;
    this.text.g.visible = true;
    this.setStyle();
  }
  setShadow(context, event) {
    this.G.visible = true;
    this.text.g.visible = true;
    this.text.name.fontSize = 1;
    this.text.name2.fontSize = 1;
  }
  setHide(context, event) {
    this.G.visible = false;
    this.text.g.visible = false;
  }

  setValue(vec, external = false, externalValue = 0) {
    let x;
    if (external) {
      x = externalValue;
    } else {
      x = this.valueEq(vec);
    }
    const change = this.cpPosEq(x);



    this.updateText();
    this.propertyValue = x;
    this.setPos(change);
    return change;
  }
  reposition() {
    if (this.controlVariable) {
      const change = this.cpPosEq(this.propertyValue);
      this.updateText();
      this.G.position.copy(change);
    }
  }

  remove(userInit = false) {
    const self = this;
    this.text.name.parent.remove(this.text.name);
    this.text.name.dispose();

    this.WS.transformControls.detach();
    this.dependencies.forEach(dep => {
      removeFromArr(this, dep.dependencies);
      if (dep.pointSet) {
        removeFromArr(self, dep.ptSet);
        dep.dependencyUpdate();
      } else {
        dep.remove();
      }
    });
    this.mesh.parent.remove(this.mesh);
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.mesh = undefined;
    this.G.parent.remove(this.G);
    this.G = undefined;
    if (this.propertyName) {
      delete this.parent.cp[this.propertyName];
    }
    this._destroy(userInit);
  }
  dependencyUpdate() {
    if (this.controlVariable) {
      if (!this.G.parent && this.controlVariable && this.controlVariable.exp === '') {
        //this.parent.G.add(this.G);
      }
      if (this.G.parent && this.controlVariable && this.controlVariable.exp !== '') {
        //this.G.parent.remove(this.G);
      }
      this.G.position.copy(this.position);
      this.updateText();
      this._update();
    }
  }
  onTransformMouseUp(e) {
    this.parent.updateCSG();
    this.updateTLinks();
  }
  onTransformMouseChange(e) {
    if (this.controlVariable) {
      this.setValue(this.G.position);
    } else {
      this.updateFunction();
    }
  }
}

class CSGobj extends Obj {
  priority: 0;
  currentGenUIDfinal;
  initialLoad = true;
  csgType; //base type ex: box
  csgOp = false; // fertility, can it have children?

  //Aliasis

  //2d Slicer tools
  meshSlicer;
  gcodeOutput;
  gcodeMetaData;

  //UI
  selected = false;

  cp = {};
  edgesGeometry;
  centerHandle;
  mixer; //animation mixer

  csgOutput;
  csgOutputP;//Physics mesh
  Gpa = new THREE.Group(); //animation group
  csgOutputA; //animation mesh
  GpaLines; //animation path trace

  csgOBJ; // CSG obj openJScad.
  csgCB; //payload.CSG; from worker. compact bin

  //Satate variables:
  csgSOLVING = false;
  csgTime = 0;
  csgStartTime = 0;
  csgLastBuildTime = 0;
  csgBuildProgress = 0;
  csgLastBuildUpdateTime = 0;

  csgSolveSub = null; //subscription to worker thread.

  csgFocusView = false;
  csgBuildError = false;

  oldgenUID;
  genUID = '';
  genUIDt = '';
  genUIDf = '';
  //actions = [{action:'cut', origin:{x:0,y:0,z:0}, opt: {x:1, y:0, z:0, copy:false}}];

  zeroAxis;
  zeroAxisLinks;

  ui = {
    ui: {
      select: '#fff203',
      edit: '#ff0200',
      view: '#00b1ff'
    },
    csg: {
      select: '#fff203',
      edit: '#ff0200',
      view: '#00b1ff'
    },
    union:{
      first: '#00ff00',
      other: '#00c300'
    },
    difference:{
      first: '#00ff00',
      other: '#d20014'
    },
    intersection:{
      first: '#00d0d2',
      other: '#00d0d2'
    },
    op: {
      select: 0.9,
      view: 0.5,
      edit: 0.7,
      dim: 0.3,
    }
  };

  csgErrorObj;
  Gp = new THREE.Group();
  Gp2 = new THREE.Group();
  rOrder = 'xyz';
  physics;
  P;
  jointCP = [];
  addJoint(opt) {
    this.phyOpt.jointSet.push(opt);
  }
  removeJoint(opt) {
    removeFromArr(opt, this.phyOpt.jointSet);
  }
  onFirstLoad(finalObj) {
  }
  addInit(opt) {
    if (this.phyOpt.initSet == undefined) {
      this.phyOpt.initSet = [];
    }
    this.phyOpt.initSet.push(opt);
  }
  removeInit(opt) {
    removeFromArr(opt, this.phyOpt.initSet);
  }

  startSolving() {
      if (this.parent && this.parent.type == 'csg') {
        this.parent.updateSolving();
      }
      if(!this.csgSOLVING) {
        this.csgStartTime = performance.now();
        this.csgBuildError = false;
        this.csgOutput.material.emissive.set(0xce1818);
      }
      this.csgSOLVING = true;
  }
  cancelSolveSub() {
    if(this.csgSolveSub){
      this.csgSolveSub.unsubscribe();
    }
    this.csgSolveSub = null;
  }

  cloneSelfNewID(){
    this.copyTo(this.parent,false);
    this.remove(true);
  }
  // generate name based on name or nickname
  generateName(){
    let name = '';
    if(this.name){
      name += this.name;
    } else if(this.nickName){
      name += this.nickName;
    } else {
      name += 'unnamed_'+this.uid;
    }
    this.variableSet.variableDB?.userVar.forEach( v=> {
      if (v.data.addName == true) {
        name += '_'+v.name + '-'+v.exp;
      }
    });
    return name;
  }

  endSolving(error = undefined){
    const self = this;

    if(!error){
      this.error = false;
      this.csgOutput.material.emissive.set(0x000000);
      this.csgOutput.material.color.set(this.color);
      this.csgLastBuildTime = Math.round( performance.now() - this.csgStartTime);
      this.oldgenUID = this.genUID+'';
    } else {
      this.csgErrorObj = error?.err?.message;
      this.csgBuildError = error?.text;
      console.log('e',this.csgErrorObj);

      this.csgLastBuildTime = 0;
    }

    this.csgSOLVING = false;
    this.csgBuildProgress = 0;

    if (this.parent && this.parent.type == 'csg') {
      this.parent.updateSolving();
    }

  }
  updateSolving() {
    if (this.csgSOLVING) {
      this.csgLastBuildUpdateTime = Math.round( performance.now() - this.csgStartTime);
      if (this.csgLastBuildTime) {
        this.csgBuildProgress = Math.round(this.csgLastBuildUpdateTime/this.csgLastBuildTime*100);
      }
    } else {
      this.startSolving();
    }
  }
  calcStartBuildTime() {
    this.csgStartTime = performance.now();
  }
  calcBuildTime() {
    this.csgLastBuildTime = Math.round( performance.now() - this.csgStartTime);
  }

  _saveCSG(opt) {
    let actionsGenID = shortHash.unique(JSON.stringify(this.actions));
    let size;
    let center;
    opt.enable = this.enable;
    if(this.animation){
      opt.animation = this.animation;
    }
    if(this.csgOutput){
      this.csgOutput.geometry.computeBoundingBox();
      let bb = this.csgOutput.geometry.boundingBox;
      size = bb.getSize(new THREE.Vector3());
      center = bb.max.clone().add(bb.min).multiplyScalar(-0.5).add(this.G.position).multiplyScalar(-1);
    }
    const t = {e: this.G.matrix.clone().elements, aID:actionsGenID};

    this.genUID = shortHash.unique(JSON.stringify(opt));
    this.genUIDt = shortHash.unique(JSON.stringify(t));
    this.genUIDf = shortHash.unique(this.genUID + this.genUIDt);
    return {
      bb: {center:v3toArr(center), size:v3toArr(size)},
      q: this.priority,
      t,
      actions: this.actions,
      genUID: this.genUID,
      gUIDt: this.genUIDt,
      gUIDf: this.genUIDf,
    };
  }
  constructor( parent, WS, uid = null, csgOp ) {
    super('csg', parent, v3(), WS, uid);
    this.csgOp = csgOp;
    const geo = new THREE.BoxGeometry( this.WS?.AppState?.ui?.cpScale * 10, this.WS?.AppState?.ui?.cpScale *10, this.WS?.AppState?.ui?.cpScale *10 );
    this.setGeometry(geo);
  }

  //tree OPs
  loopCP(exp){
    if(this.parent && this.parent.type === 'csg') {
      for (let i=0; i < this.children.length; i++) {
        if(this.children[i].type === 'cp'){
          exp(this.children[i]);
        }
      }
    }
  }
  loopSiblings(exp){
    if(this.parent && this.parent.type === 'csg') {
      for (let i=0; i < this.children.length; i++) {
        if(this.children[i].uid != this.uid && this.children[i].type === 'csg'){
          exp(this.children[i]);
        }
      }
    }
  }
  loopChildren(exp){
      for (let i=0; i < this.children.length; i++) {
        if(this.children[i].uid != this.uid && this.children[i].type === 'csg'){
          exp(this.children[i]);
        }
      }
  }
  loopRootPath(exp,arr){
    if (this.isRootNode()){
      arr.unshift(this);
      let i=0;
      arr.forEach(n=>{
        exp(n,i,arr);
        i++;
      });
    } else if (this.parent && this.parent.type == 'csg'){
      arr.unshift(this);
      this.parent.loopRootPath(exp,arr);
    }
  }
  isRootNode(){
    if(this.parent && this.parent.type != 'csg' || !this.parent){
        return true;
    } else {
        return false;
    }
  }
  isParentRootNode(){
    if(this.parent && this.parent.type != 'csg'){
      return this.parent.isRootNode();
    }else{
      return false;
    }
  }
  isParentOpNode(){
    if(this.parent && this.parent.type == 'csg' && this.parent.csgOp){
      return true;
    }else{
      return false;
    }
  }


  allChildren(obj, arr = []) {
    const self = this;
    obj.children.forEach(c => {
      self.allChildren(c, arr);
      arr.push(c);
    });
    return arr;
  }
  // UI functions
  setSelect(context, event) { //highlighted view
    this.G.visible = true;
    if(this.WS.AppState.view === 'editor'){
      this.mesh.visible = true;
    }

    if(this.csgOutput){
      this.csgOutput.visible = false;
      this.csgOutput.material.color.set(this.color);
      this.setPhysicsMeshShow(false);
    }
  }
  setEdit(context, event) {//CSG shadow, meshes... CP's
    this.G.visible = true;
    this.csgOutput.visible = true;
    setMaterial(this.csgOutput, this.ui.op.dim, this.ui.ui.edit);
    this.selected = false;


    if(this.WS.AppState.view === 'editor'){
      this.mesh.visible = true;
    }

    if(this.csgOp){
      let childList = [];
      this.loopChildren(c=>{
        if(c.csgOBJ){
          childList.push(c);
        }
        c.G.visible = true;
        c.mesh.visible = false;
        c.csgOutput.visible = false;
      });
      if(childList.length > 1 && this.csgType == 'difference'){
        childList.forEach(c=>{
          c.csgOutput.visible = true;
          setMaterial(c.csgOutput, this.ui.op.dim, this.ui.ui.edit);
        });
        childList[0].csgOutput.visible = false;
        this.csgOutput.visible = true;
        setMaterial(this.csgOutput, this.ui.op.edit, this.ui.ui.view);
      }
      if(childList.length > 1 && this.csgType == 'intersection'){
        childList.forEach(c=>{
          c.csgOutput.visible = true;
          setMaterial(c.csgOutput, this.ui.op.dim, this.ui.intersection.other);
        });
        childList[0].csgOutput.visible = true;
        setMaterial(childList[0].csgOutput, this.ui.op.dim, this.ui.intersection.first);
        this.csgOutput.visible = true;
        setMaterial(this.csgOutput, this.ui.op.edit, this.ui.ui.view);
      }
      if(childList.length > 1 && this.csgType == 'union'){
        childList.forEach(c=>{
          c.csgOutput.visible = true;
          setMaterial(c.csgOutput, this.ui.op.dim, this.ui.ui.edit);
        });
      }
    }
  }
  setPhysicsMeshShow(init=true){
    if(init){
      this.loopChildren(c => {
        c.setPhysicsMeshShow(false);
      });
    }
    if(this.WS.P.physics_enable_ui && this.physics){
      this.Gp.visible = true;
      this.Gp2.visible = true;
      if(this.GpaLines){
        this.GpaLines.visible = true;
      }
      this.G.visible = false;
      //@ts-ignore
      this.parent?.csgOutput.visible = false;
    }else{
      this.Gp.visible = false;
      this.Gp2.visible = false;
      if(this.GpaLines){
        this.GpaLines.visible = false;
      }
    }
  }
  setView(context, event) {//CSG ONLY
    this.G.visible = true;
    this.csgOutput.visible = true;
    setMaterial(this.csgOutput, 1, this.color);
    this.mesh.visible = false;
    this.selected = false;
    if(this.csgOp){
      this.loopChildren(c=>{
        c.G.visible = false;
        c.mesh.visible = false;
        c.csgOutput.visible = false;
      });
    }
    this.setPhysicsMeshShow(false);
    this.detachTransform();
  }
  setShadow(context, event) {
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
  }
  setHide(context, event) {
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
    this.selected = false;
    this.detachTransform();
  }
  setParentShadow(context, event) {
    if(this.parent && this.parent.type == 'csg'){
      this.parent.I.send('parentShadow')
    }
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
  }
  setParentHide(context, event) {
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
  }
  setChildShadow(context, event) {
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
  }
  setChildHide(context, event) {
    this.G.visible = false;
    this.csgOutput.visible = false;
    this.mesh.visible = false;
  }
  // Common CSG functions
  sliceMesh(){
    if (this.meshSlicer && this.meshSlicer.sliceLines && this.meshSlicer.sliceLines.parent) {
      this.meshSlicer.sliceLines.parent.remove(this.meshSlicer.sliceLines);
    }
    if(!this.meshSlicer && this.csgOutput){
      this.meshSlicer = new MeshSlicer(this,this.csgOutput);
    } else {
      this.meshSlicer.dispose();
      this.meshSlicer = undefined;
    }
  }

  // Geometry export functions
  async exportSTL() {
    let name = this.generateName();
    let blob = exportSTL(this.csgOutput, name);
  }
  async exportGcode() {
    let name = this.generateName();
    let blob = exportBlob(this.gcodeOutput, name);
  }
  async uploadSTL() {
    let name = this.generateName();
    let blob = exportSTL(this.csgOutput, name);
    await this.WS.livePart.uploadToSW(blob,name);
  }
  exportGLTF() {
    let name = this.generateName();
    console.log(this.Gpa,this.csgOutputA);
    exportGLTF(this.Gpa,this.csgOutputA, name);
  }
  exportCGLTF() {
    this.children.forEach(c=>{
      let name = this.generateName() + '_' + c.generateName();
      exportGLTF(c.Gpa,c.csgOutputA, name);
      exportGLTF(c.GpaLines,c.GpaLines, name + 'lines');
    });
  }
  exportGLTFpath() {
    let name = this.generateName() + 'lines';
    exportGLTF(this.GpaLines,this.GpaLines, name);
  }
  exportCSG() {
    exportCSGcb(this.csgCB, this.uid);
  }

  addCP(name, opt = null) {
    this.cp[name] = new ControlPoint(this, name, this.WS, opt);
    this.cp[name].addDependency(this);
    return this.cp[name];
  }
  addAction(data) {
    this.actions.push(data);
    this.resetActionsVar();
    this.updateTLinks();
  }
  removeAction(act) {
    this.actions.splice(this.actions.indexOf(act),1);
    this.updateTLinks();
  }

  // updates
  updateCSG(run = true, updateDB = true, focusView=false) {
    const self = this;
    self.WS.variableDB.evaluate();
    const data = this.save();
    if (run) {
      new Promise<any>( (resolve, reject) => {
        if (data.gUIDf !== this.currentGenUIDfinal) {
          if(this.csgSolveSub){
            this.cancelSolveSub();
          }
          self.WS.runWorker({opt: data, obj: this});
          resolve({update: true});
        } else {
          resolve({update: false});
        }
      }).then(r => {
        if (r.update === true && updateDB) {
          self.csgFocusView = focusView;
        }
      });
    } else {
      return {opt: data, obj: this};
    }
  }

  jointsMesh = [];
  updatePhysicsShape() {
   //Erace the object holder group
    this.Gp2.traverse( object => {
      emptyGroup(object);
    });
    if (this.WS.P.physics_enable_ui && this.physics) {
      emptyGroup(this.Gp);
      emptyGroup(this.Gp2);
      this.Gp?.parent?.remove(this.Gp);
      this.Gp2?.parent?.remove(this.Gp2);
      this.csgOutputP?.parent?.remove(this.csgOutputP);
      this.csgOutputP?.geometry.dispose();
      this.csgOutputP?.material.dispose();

      if (this.csgOutput) {
        this.csgOutputP = this.csgOutput.clone();
      } else {
        this.csgOutputP = this.mesh.clone();
      }
      this.csgOutputP.material.color.set('green');
      this.csgOutputP.visible = true;
      this.csgOutputP?.position.sub(this.G.position);
      let q2 = new THREE.Euler(-this.G.rotation.x, -this.G.rotation.y, -this.G.rotation.z, 'ZYX');
      this.Gp2.rotation.copy(q2);
      this.Gp2.add(this.csgOutputP);
      this.Gp.add(this.Gp2);
      this.G.parent.add(this.Gp);
      this.phyOpt.jointSet.forEach( (j, i) => {
        if (j.type === 'spherical') {
        this.jointsMesh[i] = new THREE.Mesh( new THREE.SphereGeometry( 0.5, 32, 32 ), new THREE.MeshBasicMaterial( {color: 0xffff00} ) );
        this.jointsMesh[i].position.set(j.jointPositionB.x, j.jointPositionB.y, j.jointPositionB.z);
        this.WS.objects[j.rootUID].Gp.add(this.jointsMesh[i]);
        let jointsMeshB = new THREE.Mesh( new THREE.TorusGeometry( 1, 0.1, 16, 100 ), new THREE.MeshBasicMaterial( {color: 0xffff00} ) );
        jointsMeshB.position.set(j.jointPositionA.x, j.jointPositionA.y, j.jointPositionA.z);
        this.Gp.add(jointsMeshB);
        }
      });

    } else {
      this.Gp?.parent?.remove(this.Gp);
      this.Gp2?.parent?.remove(this.Gp2);
      disposeMesh(this.csgOutputP);
    }
  }
  updateAnimationShape() {
    if (this.Gpa?.['animations']?.length) {
      const material = this.treeLineMaterial.clone();
      const points = [];
      const velA = [];
      const velL = [];
      this.WS.P.objAnimation[this.uid]?.pos.forEach( (val, i, arr) => {
        if ( (( i + 1 ) % 3 ) === 0 ) {
          let av = this.WS.P.objAnimation[this.uid].angvel;
          let lv = this.WS.P.objAnimation[this.uid].linvel;
          velA.push({x: av[i - 2], y: av[i - 1], z: av[i]});
          velL.push({x: lv[i - 2], y: lv[i - 1], z: lv[i]});
          points.push({x: arr[i - 2], y: arr[i - 1], z: arr[i]});
        }
      });
      this.animation = {time: this.WS.P.objAnimation[this.uid]?.time, contacts: this.WS.P.contacts, points, velA, velL, };
      disposeMesh(this.GpaLines);
      this.GpaLines = new THREE.Line( new THREE.BufferGeometry().setFromPoints( points ), material );
      if ( this.csgOutput ) {
        disposeMesh(this.csgOutput);
        this.csgOutputA = this.csgOutput.clone();
        //console.log(this.csgOutputA);
        let p = this.G.position.clone().multiplyScalar(-1);
        this.csgOutputA.position.copy(p);
      } else {
        //this.csgOutputA = this.mesh.clone();
      }
      emptyGroup(this.Gpa);
      this.Gpa.add(this.csgOutputA);
      this.G.parent.add(this.GpaLines);
    } else {
      disposeMesh(this.GpaLines);
    }
  }
  playAnimation() {
    console.log(this.Gpa?.['animations']);
    if (this.Gpa?.['animations']) {
      const self = this;
      this.mixer = new THREE.AnimationMixer(this.Gpa);
      const clip = self.Gpa?.['animations'][0];
      clip.loop = THREE.LoopOnce;
      self.mixer.clipAction(clip).play();
    }
  }
  pauseAnimation() {
    const self = this;
    self.mixer.stop();
  }

  updateCSGcompleted(gUIDf) {
    const self = this;

    this.currentGenUIDfinal = gUIDf;
    this.updatePhysicsShape();
    this.updateAnimationShape();
    this.resetActionsVar();
    this.updateTLinks();
    self.endSolving();

    if (this.csgFocusView && this.initialLoad) { //If the parent is plane, first display.
        this.initialLoad = false;
        this.setSelectedObj();
        this.I.send('edit');
        this.I.send('view');
        this.WS.onFinalCSGRender(this);
    }
  }
  _setSelectedObj() {
    this.selected = true;
  }
  _updateRelations() {
    if (this.parent && this.G && this.csgOutput) {
      this.G.parent.add(this.csgOutput);
      if (this.parent.type === 'csg') {
        let i = this.parent.children.indexOf(this);
        let t = this.parent.children.length;
        let s = 1 + this.WS.AppState.ui.cpScale * i;
        this.centerHandle.scale.set(s, s, s);
      } else {
        this.centerHandle.scale.set(1, 1, 1);
      }
    }
  }

  // INIT
  init() {
    const self = this;
    this.zeroAxis = new THREE.AxesHelper(this.WS.AppState.ui.cpScale * 50);
    this.zeroAxis.visible = this.WS.uiMode.widgets;
    this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    this.centerHandle = new THREE.Mesh(new THREE.TorusGeometry( this.WS.AppState.ui.cpScale * 6, this.WS.AppState.ui.cpScale * 1, 4 , 4 ), material.clone());
    this.centerHandle.name = 'cp';

    this.csgOutput = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), material.clone());
    this.csgOutput.geometry.computeBoundingBox();
    this.mesh = new THREE.Mesh();
    this.G.add(this.centerHandle);
    this.G.parent.add(this.csgOutput);
    this.G.name = this.type;
    this.G.position.copy(this.position);
    setObjRef(this.mesh, self);
    setObjRef(this.csgOutput, self);
    setObjRef(this.G, self);
    setObjRef(this.centerHandle, self);
    this.initVariables();
  }
  initVariables() {
    const self = this;
    this.variableSet.enabled = true;

    const ssetEnf = function(value) { self.enable = Boolean(value); };
    const sgetEnf = function() { return self.enable; };
    this.variableSet.addVariable('enable', ssetEnf, sgetEnf, 'obj', 'boolean');

    // p.x
    const psetXf = function(value) { self.G.position.setX(Number(value)); };
    const pgetXf = function() { return self.G.position.x; };
    this.variableSet.addVariable('x', psetXf, pgetXf, 'p');
    // p.y
    const psetYf = function(value) { self.G.position.setY(Number(value)); };
    const pgetYf = function() { return self.G.position.y; };
    this.variableSet.addVariable('y', psetYf, pgetYf, 'p');
    // p.z
    const psetZf = function(value) { self.G.position.setZ(Number(value)); };
    const pgetZf = function() { return self.G.position.z; };
    this.variableSet.addVariable('z', psetZf, pgetZf, 'p');

    // r.x
    const rsetXf = function(value) {
      const x = self.G.rotation.x;
      const y = self.G.rotation.y;
      const z = self.G.rotation.z;
      self.G.rotation.set(d2r(Number(value)), y, z); };
    const rgetXf = function() { return r2d(self.G.rotation.x); };
    this.variableSet.addVariable('x', rsetXf, rgetXf, 'r', 'angle');
    // r.y
    const rsetYf = function(value) {
      const x = self.G.rotation.x;
      const y = self.G.rotation.y;
      const z = self.G.rotation.z;
      self.G.rotation.set(x, d2r(Number(value)), z); };
    const rgetYf = function() { return r2d(self.G.rotation.y); };
    this.variableSet.addVariable('y', rsetYf, rgetYf, 'r', 'angle');
    // r.z
    const rsetZf = function(value) {
      const x = self.G.rotation.x;
      const y = self.G.rotation.y;
      const z = self.G.rotation.z;
      self.G.rotation.set(x, y, d2r(Number(value))); };
    const rgetZf = function() { return r2d(self.G.rotation.z); };
    this.variableSet.addVariable('z', rsetZf, rgetZf, 'r', 'angle');
    // s.x
    const ssetXf = function(value) { self.G.scale.setX(Number(value)); };
    const sgetXf = function() { return self.G.scale.x; };
    this.variableSet.addVariable('x', ssetXf, sgetXf, 's', 'scale');
    // s.y
    const ssetYf = function(value) { self.G.scale.setY(Number(value)); };
    const sgetYf = function() { return self.G.scale.y; };
    this.variableSet.addVariable('y', ssetYf, sgetYf, 's', 'scale');
    // s.z
    const ssetZf = function(value) { self.G.scale.setZ(Number(value)); };
    const sgetZf = function() { return self.G.scale.z; };
    this.variableSet.addVariable('z', ssetZf, sgetZf, 's', 'scale');

    //physics
    const pDensitySet = function(value) { self.phyOpt.density = Number(value); };
    const pDensityGet = function() { return self.phyOpt.density; };
    this.variableSet.addVariable('density', pDensitySet, pDensityGet, 'physics', 'number');

    const pFrictionSet = function(value) { self.phyOpt.friction = Number(value); };
    const pFrictionGet = function() { return self.phyOpt.friction; };
    this.variableSet.addVariable('friction', pFrictionSet, pFrictionGet, 'physics', 'number');

    const pRestitutionSet = function(value) { self.phyOpt.restitution = Number(value); };
    const pRestitutionGet = function() { return self.phyOpt.restitution; };
    this.variableSet.addVariable('restitution', pRestitutionSet, pRestitutionGet, 'physics', 'number');

    const lockRxset = function(value) { self.phyOpt.lockR[0] = Boolean(value); };
    const lockRxget = function() { return self.phyOpt.lockR[0]; };
    this.variableSet.addVariable('lockRx', lockRxset, lockRxget , 'physics', 'boolean');

    const lockRyset = function(value) { self.phyOpt.lockR[1] = Boolean(value); };
    const lockRyget = function() { return self.phyOpt.lockR[1]; };
    this.variableSet.addVariable('lockRy', lockRyset, lockRyget , 'physics', 'boolean');

    const lockRzset = function(value) { self.phyOpt.lockR[2] = Boolean(value); };
    const lockRzget = function() { return self.phyOpt.lockR[2]; };
    this.variableSet.addVariable('lockRz', lockRzset, lockRzget , 'physics', 'boolean');

    const lockTxset = function(value) { self.phyOpt.lockT[0] = Boolean(value); };
    const lockTxget = function() { return self.phyOpt.lockT[0]; };
    this.variableSet.addVariable('lockTx', lockTxset, lockTxget , 'physics', 'boolean');

    const lockTyset = function(value) { self.phyOpt.lockT[1] = Boolean(value); };
    const lockTyget = function() { return self.phyOpt.lockT[1]; };
    this.variableSet.addVariable('lockTy', lockTyset, lockTyget , 'physics', 'boolean');

    const lockTzset = function(value) { self.phyOpt.lockT[2] = Boolean(value); };
    const lockTzget = function() { return self.phyOpt.lockT[2]; };
    this.variableSet.addVariable('lockTz', lockTzset, lockTzget , 'physics', 'boolean');


    this.resetActionsVar();
    this._initVariables();
  }
  resetActionsVar() {
    // actions
    const self = this;
    this.actions.forEach((a,i,arr)=> {
      if(self.actions[i].opt){

        const ssetXf01 = function(value) {if(!self.actions[i] || !self.actions[i].opt){return}; self.actions[i].opt.x = Number(value); };
        const sgetXf01 = function() { return self.actions[i]?.opt?.x; };
        let xVar = self.variableSet.addVariable('optX', ssetXf01, sgetXf01, 'action'+i);

        const ssetYf02 = function(value) {if(!self.actions[i] || !self.actions[i].origin){return}; self.actions[i].opt.y = Number(value); };
        const sgetYf02 = function() { return self.actions[i]?.opt?.y; };
        let yVar = self.variableSet.addVariable('optY', ssetYf02, sgetYf02, 'action'+i);

        const ssetZf03 = function(value) {if(!self.actions[i] || !self.actions[i].origin){return}; self.actions[i].opt.z = Number(value); };
        const sgetZf03 = function() { return self.actions[i]?.opt?.z; };
        let zVar = self.variableSet.addVariable('optZ', ssetZf03, sgetZf03, 'action'+i);
      }
      if(self.actions[i].origin){
        const ssetXf21 = function(value) {if(!self.actions[i] || !self.actions[i].origin){return};self.actions[i].origin.x = Number(value); };
        const sgetXf21 = function() { return self.actions[i]?.origin?.x; };
        let xoVar = self.variableSet.addVariable('originX', ssetXf21, sgetXf21, 'action'+i);

        const ssetYf22 = function(value) {if(!self.actions[i] || !self.actions[i].origin){return}; self.actions[i].origin.y = Number(value); };
        const sgetYf22 = function() { return self.actions[i]?.origin?.y; };
        let yoVar = self.variableSet.addVariable('originY', ssetYf22, sgetYf22, 'action'+i);

        const ssetZf23 = function(value) {if(!self.actions[i] || !self.actions[i].origin){return}; self.actions[i].origin.z = Number(value); };
        const sgetZf23 = function() { return self.actions[i]?.origin?.z; };
        let zoVar = self.variableSet.addVariable('originZ', ssetZf23, sgetZf23, 'action'+i);
      }
    });
  }
  setVariable() {
    this.setTransform();
    this._update();
    this.updateCSG(true);
  }

  remove(userInit = false) {
    const self = this;
    const cList = [];
    const parent = self.parent;
    this.WS.P.physicsRemoveObj(this);
    if(this.WS.selectedObj?.uid === this.uid){
      this.WS.selectedObj = this.parent;
    }
    this.children.forEach(c => {
      cList.push(c);
    });
    cList.forEach(c => {
      if (c.type === 'csg' ) {
        c.remove();
      }
    });
    this.WS.transformControls.detach();
    this.dependencies.forEach(dep => {
      removeFromArr(this, dep.dependencies);
      if (dep.pointSet) {
        removeFromArr(self, dep.ptSet);
        dep.dependencyUpdate();
      } else {
        dep.remove();
      }
    });
    this.edgesGeometry.dispose();
    this.mesh.parent?.remove(this.mesh);
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();

    this.csgOutput?.parent?.remove(this.csgOutput);
    this.csgOutput?.geometry.dispose();
    this.csgOutput?.material.dispose();

    this.centerHandle.parent.remove(this.centerHandle);
    this.centerHandle.geometry.dispose();
    this.centerHandle.material.dispose();

    this.centerHandle = undefined;
    this.csgOutput = undefined;
    this.mesh = undefined;

    this.G.parent.remove(this.G);
    this.GpaLines?.parent.remove(this.GpaLines);
    this.GpaLines?.geometry.dispose();
    emptyGroup(this.G);
    this.G = undefined;
    this._destroy(userInit);
    if (parent && userInit) {
      if(parent.type === 'csg') {
        parent.updateCSG();
      }
      parent.setSelectedObj();
    }
  }

  // Mouse events and transforms
  actionClick() {
    //console.log("action Click!");
  }

  onTransformMouseUp(e) {
      this.updateCSG();
  }
  detachedTransformEvent(e) {
    this.hideTransform();
  }
  attachedTransformEvent(e) {
    this.showTransform();
  }

  // Attaches zeroAxes to parent.
  showTransform(root = null) {
    if (this.WS.uiMode.widgets) {
      this.parent?.G.add(this.zeroAxis); //Attach this obj zero axis to parent
    }
    if (this.parent && this.parent.showTransform) {
      this.parent?.showTransform(root);
    }
  }
  // Detaches zeroAxes from parent.
  hideTransform() {
    this.zeroAxis.parent?.remove(this.zeroAxis); //remove this obj from parents zero axis.
    if (this.parent && this.parent.hideTransform) {
      this.parent?.hideTransform();
    }
  }

}

class Box extends CSGobj {
  x = 10;
  y = 10;
  z = 10;
  centerX = 1;
  centerY = 1;
  centerZ = 1;
  roundX = 0;
  roundY = 0;
  roundZ = 0;
  shellOpt = {
    en: false,
    in: true,
    wall: 1,
    xt: true,
    yt: true,
    zt: true,
    xb: true,
    yb: true,
    zb: true,
  };
  xVar;
  yVar;
  zVar;
  fn = 8;

  codeEn = false;
  code = 'res.radius = function ( u, v, t )\n{ return 1 }\n';
  codeOpt = {
    radius: 2,
    widthSegments: 2,
    heightSegments: 2,
    depthSegments: 2,
    smoothness: 4,
    pointX:0.001,
    pointY:0.999,
    waffleDeep:3,
    contourmode: 'linear',// 'profile', 'linear', 'bezier',  'rounding'
  } as CodeOpt;
  _save(obj) {
    let opt = {csgOp: this.csgOp, csgType: this.csgType,
      x: this.x, y: this.y, z: this.z,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      xR: this.roundX, yR: this.roundY, zR: this.roundZ,
      fn: this.fn, shell: this.shellOpt,
      codeOpt:this.codeOpt, code:this.code, codeEn:this.codeEn};

    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    this.code = data.opt.code;
    this.codeEn = data.opt.codeEn;
    if(data.opt.codeOpt){
      this.codeOpt = data.opt.codeOpt;
    }
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC, data.opt.xR, data.opt.yR, data.opt.zR, data.opt.fn);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'box';
    this.name = 'B' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x Size
    const self = this;
    this.variableSet.addVariable('radius',
      function(value) { self.codeOpt.radius = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.radius; },
      'codeOpt');
    this.variableSet.addVariable('widthSegments',
      function(value) { self.codeOpt.widthSegments = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.widthSegments; },
      'codeOpt');
    this.variableSet.addVariable('heightSegments',
      function(value) { self.codeOpt.heightSegments = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.heightSegments; },
      'codeOpt');
    this.variableSet.addVariable('depthSegments',
      function(value) { self.codeOpt.depthSegments = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.depthSegments; },
      'codeOpt');

    this.variableSet.addVariable('smoothness',
      function(value) { self.codeOpt.smoothness = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.smoothness; },
      'codeOpt');

    this.variableSet.addVariable('pointX',
      function(value) { self.codeOpt.pointX = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.pointX; },
      'codeOpt');
    this.variableSet.addVariable('pointY',
      function(value) { self.codeOpt.pointY = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.pointY; },
      'codeOpt');
    // shell
    const setShellEnf = function(value) { self.shellOpt.en = Boolean(value); self.dependencyUpdate(); };
    const getShellEnf = function() { return self.shellOpt.en; };
    this.variableSet.addVariable('shell', setShellEnf, getShellEnf, 'shell', 'boolean');

    const setShellInEnf = function(value) { self.shellOpt.in = Boolean(value); self.dependencyUpdate(); };
    const getShellInEnf = function() { return self.shellOpt.in; };
    this.variableSet.addVariable('inner', setShellInEnf, getShellInEnf, 'shell', 'boolean');

    const setWallEnf = function(value) { self.shellOpt.wall = Number(value); self.dependencyUpdate(); };
    const getWallEnf = function() { return self.shellOpt.wall; };
    this.variableSet.addVariable('wall', setWallEnf, getWallEnf, 'shell');

    // shell Sides
    const setShellxf = function(value) { self.shellOpt.xt = Boolean(value); self.dependencyUpdate(); };
    const getShellxf = function() { return self.shellOpt.xt; };
    this.variableSet.addVariable('x+', setShellxf, getShellxf, 'shellSide', 'boolean');

    const setShellyf = function(value) { self.shellOpt.yt = Boolean(value); self.dependencyUpdate(); };
    const getShellyf = function() { return self.shellOpt.yt; };
    this.variableSet.addVariable('y+', setShellyf, getShellyf, 'shellSide', 'boolean');

    const setShellzf = function(value) { self.shellOpt.zt = Boolean(value); self.dependencyUpdate(); };
    const getShellzf = function() { return self.shellOpt.zt; };
    this.variableSet.addVariable('z+', setShellzf, getShellzf, 'shellSide', 'boolean');


    const setShellxmf = function(value) { self.shellOpt.xb = Boolean(value); self.dependencyUpdate(); };
    const getShellxmf = function() { return self.shellOpt.xb; };
    this.variableSet.addVariable('x-', setShellxmf, getShellxmf, 'shellSide', 'boolean');

    const setShellymf = function(value) { self.shellOpt.yb = Boolean(value); self.dependencyUpdate(); };
    const getShellymf = function() { return self.shellOpt.yb; };
    this.variableSet.addVariable('y-', setShellymf, getShellymf, 'shellSide', 'boolean');

    const setShellzmf = function(value) { self.shellOpt.zb = Boolean(value); self.dependencyUpdate(); };
    const getShellzmf = function() { return self.shellOpt.zb; };
    this.variableSet.addVariable('z-', setShellzmf, getShellzmf, 'shellSide', 'boolean');

    // size
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');

    // center
    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = Number(value); self.cp['y'].setValue(v3(), true, self.y); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');

    // round
    const rsetXf = function(value) { self.roundX = value; self.dependencyUpdate(); };
    const rgetXf = function() { return self.roundX; };
    this.variableSet.addVariable('x', rsetXf, rgetXf, 'round');

    const rsetYf = function(value) { self.roundY = value; self.dependencyUpdate(); };
    const rgetYf = function() { return self.roundY; };
    this.variableSet.addVariable('y', rsetYf, rgetYf, 'round');

    const rsetZf = function(value) { self.roundZ = value; self.dependencyUpdate(); };
    const rgetZf = function() { return self.roundZ; };
    this.variableSet.addVariable('z', rsetZf, rgetZf, 'round');

    const rsetFNf = function(value) { self.fn = value; self.dependencyUpdate(); };
    const rgetFNf = function() { return self.fn; };
    this.variableSet.addVariable('fn', rsetFNf, rgetFNf, 'fn');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateConfig(x = null, y = null, z = null, centerX = null, centerY = null, centerZ = null, roundX = null, roundY = null, roundZ = null, fn = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;

    this.roundX = roundX ? roundX : this.roundX;
    this.roundY = roundY ? roundY : this.roundY;
    this.roundZ = roundZ ? roundZ : this.roundZ;

    this.fn = fn ? fn : this.fn;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar(x / (1 + self.centerY));
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * (1 + self.centerY);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (1 + self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (1 + self.centerZ);
    };
    this.updateConfig(this.x, this.y, this.z);
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.BoxGeometry(this.x, this.y, this.z );
    //this.geometry.translate((1 - this.centerX) * this.x / 2, (1 - this.centerY) * this.y / 2, (1 - this.centerZ) * this.z / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}
class Sphere extends CSGobj {
  x = 10;
  centerX = 1;
  centerY = 1;
  centerZ = 1;
  xVar;
  fn = 8;
  fn2 = 8;
  shellOpt = {
    en: false,
    in: true,
    wall: 1,
    pZ:1,
    nZ:1,
  };
  geoType = 'normal'; // geodesic , normal
  codeEn = false;
  code = 'res.rAzimuthPole = function ( u, v, t )\n{ return 1 }\n';
  codeOpt = {
    usedWedges:1,
    wedgeOpen:true,	// wedge on edge open or closed
    bottom:0,		// south pole is 0
    top:1,		// max. equator * 2 (is north pole)
    withBottom:true,	// with a bottom (bottom > 0)
    withTop:true,
    quadLine:false,
    circOpen:false
  }  as CodeOpt;

  scopeV = [];
  scopeObj = {};
  startAngle = 0;
  sAngleVar;
  snippet;
  _save(obj) {
    let opt = {csgOp: this.csgOp, csgType: this.csgType,
      x: this.x,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      fn: this.fn, fn2: this.fn2, geoType: this.geoType, shell: this.shellOpt,
      startAngle: this.startAngle,
      codeOpt:this.codeOpt, code:this.code, codeEn:this.codeEn, userVar: []};
    if(this.codeEn){
      opt = {...opt, userVar: obj.userVar}
    }
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    this.code = data.opt.code;
    this.codeEn = data.opt.codeEn;
    if(data.opt.startAngle){
      this.startAngle = data.opt.startAngle;
    }
    if(data.opt.codeOpt){
      this.codeOpt = data.opt.codeOpt;
    }
    this.updateConfig(data.opt.x, data.opt.xC, data.opt.yC, data.opt.zC, data.opt.fn, data.opt.fn2, data.opt.geoType);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'sphere';
    this.name = 'S' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x
    const self = this;

    // shell
    const setShellEnf = function(value) { self.shellOpt.en = Boolean(value); self.dependencyUpdate(); };
    const getShellEnf = function() { return self.shellOpt.en; };
    this.variableSet.addVariable('shell', setShellEnf, getShellEnf, 'shell', 'boolean');

    const setShellInEnf = function(value) { self.shellOpt.in = Boolean(value); self.dependencyUpdate(); };
    const getShellInEnf = function() { return self.shellOpt.in; };
    this.variableSet.addVariable('inner', setShellInEnf, getShellInEnf, 'shell', 'boolean');

    const setWallEnf = function(value) { self.shellOpt.wall = Number(value); self.dependencyUpdate(); };
    const getWallEnf = function() { return self.shellOpt.wall; };
    this.variableSet.addVariable('wall', setWallEnf, getWallEnf, 'shell');


    const setXf = function(value) { self.cp['x'].setValue(v3(), true, value); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    const osetXf = function(value) { self.centerX = value; self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = value; self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = value; self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');

    const rsetFNf = function(value) { self.fn = value; self.dependencyUpdate(); };
    const rgetFNf = function() { return self.fn; };
    this.variableSet.addVariable('fn', rsetFNf, rgetFNf, 'fn');

    const rsetFN2f = function(value) { self.fn2 = value; self.dependencyUpdate(); };
    const rgetFN2f = function() { return self.fn2; };
    this.variableSet.addVariable('fn2', rsetFN2f, rgetFN2f, 'fn');

    this.variableSet.addVariable('top',
      function(value) { self.codeOpt.top = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.top; },
      'codeOpt');

    this.variableSet.addVariable('bottom',
      function(value) { self.codeOpt.bottom = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.bottom; },
      'codeOpt');

    this.variableSet.addVariable('wedgeOpen',
      function(value) { self.codeOpt.wedgeOpen = Boolean(value); self.dependencyUpdate(); },
      function() { return self.codeOpt.wedgeOpen; },
      'codeOpt', 'boolean');

    this.variableSet.addVariable('usedWedges',
      function(value) { self.codeOpt.usedWedges = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.usedWedges; },
      'codeOpt');

    this.variableSet.addVariable('withBottom',
      function(value) { self.codeOpt.withBottom = Boolean(value); self.dependencyUpdate(); },
      function() { return self.codeOpt.withBottom; },
      'codeOpt', 'boolean');

    this.variableSet.addVariable('withTop',
      function(value) { self.codeOpt.withTop = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.withTop; },
      'codeOpt', 'boolean');

    this.sAngleVar = this.variableSet.addVariable('startAngle',
      function(value) { self.startAngle = Number(value); self.cp['startAngle'].setValue(v3(), true, self.startAngle); self.dependencyUpdate(); },
      function() { return self.startAngle; },
      "a", 'angle');
  }
  setUpCP() {
    const self = this;

    //add control points
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});

    //Add angle control points
    this.addCP('startAngle', { axis: [true, true, false], color: '#81c268'});


    //Initiate the
    this.cp['x'].initControlVar(this.xVar);
    this.cp['startAngle'].initControlVar(this.sAngleVar);
  }
  updateConfig(x = null, centerX = null, centerY = null, centerZ = null, fn = null, fn2 = null, geoType = null) {
    x = x ? x : this.x;
    this.fn = fn ? fn : this.fn;
    this.fn2 = fn2 ? fn2 : this.fn2;
    this.geoType = geoType ? geoType : this.geoType;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;
    this.cp['startAngle'].setValue(v3(), true, this.startAngle);
    this.cp['x'].setValue(v3(), true, x);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    //update startAngle angular control point
    this.cp['startAngle'].direction.set(1, 0, 0);
    this.cp['startAngle'].cpPosEq = function(x) {
      return self.cp['startAngle'].direction.clone().normalize().multiplyScalar(1+self.x/2).applyAxisAngle(v3(0, 0, 1), x * Math.PI / 180);
    };
    this.cp['startAngle'].valueEq = function(x) {
      if(self.cp['startAngle'].position.y >= 0) {
        return Math.round(self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }else{ //if in the negative plane, we are past 180 degrees
        return Math.round(360 - self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }
    };
    this.updateConfig(this.x);
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.startAngle = this.cp['startAngle'].propertyValue;
    this.cp['x'].reposition();
    this.cp['startAngle'].reposition();

    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.SphereGeometry(this.x/2, 16, 16 );
    //this.geometry.rotateX(Math.PI/2);
    //this.geometry.translate((1 - this.centerX) * this.x / 2, (1 - this.centerY) * this.x / 2, (1 - this.centerZ) * this.x / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }

  loadScopeVar(sV) {
    const self = this;
    //this.variableSet.removeAll();
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.nickName = snippet.name;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  removeScopeVar(name) {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      let id = this.scopeV.indexOf(self.scopeObj[name]);
      this.scopeV.splice(id,1);
      delete self.scopeObj[name];
    }
  }
}
class Cylinder extends CSGobj {
  x = 10;
  y = 0;
  z = 10;
  centerX = 1;
  centerY = 1;
  centerZ = 1;
  round = 0;
  xVar;
  yVar;
  zVar;
  fn = 36;
  fn2 = 8;
  shellOpt = {
    en: false,
    in: true,
    wall: 1,
    zt: true,
    zb: true,
  };
  scopeV = [];
  scopeObj = {};
  startAngle = 0;
  sAngleVar;

  snippet;

  codeEn = false;
  code = 'res.rCircHeight = function ( u, v, t )\n{ return 1 }\n';
  codeOpt = {
    usedWedges:1,
    wedgeOpen:true,	// wedge on edge open or closed
    bottom:0,		// south pole is 0
    top:1,		// max. equator * 2 (is north pole)
    withBottom:true,	// with a bottom (bottom > 0)
    withTop:true,
    quadLine:false,
    circOpen:false
  } as CodeOpt;

  _save(obj) {
    let opt = {csgOp: this.csgOp, csgType: this.csgType,
      x: this.x, y: this.y, z: this.z, startAngle: this.startAngle,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      r: this.round, fn: this.fn, fn2: this.fn2,
      shell: this.shellOpt, codeOpt:this.codeOpt, code:this.code, codeEn:this.codeEn, userVar: []};
    if(this.codeEn){
      opt = {...opt, userVar: obj.userVar}
    }
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    this.code = data.opt.code;
    this.codeEn = data.opt.codeEn;
    if(data.opt.startAngle){
      this.startAngle = data.opt.startAngle;
    }
    if(data.codeOpt){
      this.codeOpt = data.codeOpt;
    }
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC, data.opt.r, data.opt.fn, data.opt.fn2);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'cylinder';
    this.name = 'C' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    const self = this;
    //shell
    // shell
    const setShellEnf = function(value) { self.shellOpt.en = Boolean(value); self.dependencyUpdate(); };
    const getShellEnf = function() { return self.shellOpt.en; };
    this.variableSet.addVariable('shell', setShellEnf, getShellEnf, 'shell', 'boolean');

    const setShellInEnf = function(value) { self.shellOpt.in = Boolean(value); self.dependencyUpdate(); };
    const getShellInEnf = function() { return self.shellOpt.in; };
    this.variableSet.addVariable('inner', setShellInEnf, getShellInEnf, 'shell', 'boolean');

    const setWallEnf = function(value) { self.shellOpt.wall = Number(value); self.dependencyUpdate(); };
    const getWallEnf = function() { return self.shellOpt.wall; };
    this.variableSet.addVariable('wall', setWallEnf, getWallEnf, 'shell');

    const setShellztf = function(value) { self.shellOpt.zt = Boolean(value); self.dependencyUpdate(); };
    const getShellztf = function() { return self.shellOpt.zt; };
    this.variableSet.addVariable('z+', setShellztf, getShellztf, 'shellSide', 'boolean');

    const setShellzmf = function(value) { self.shellOpt.zb = Boolean(value); self.dependencyUpdate(); };
    const getShellzmf = function() { return self.shellOpt.zb; };
    this.variableSet.addVariable('z-', setShellzmf, getShellzmf, 'shellSide', 'boolean');

    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');

    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = Number(value); self.cp['y'].setValue(v3(), true, self.y); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');

    // s.z
    const rsetf = function(value) { self.round = value; self.dependencyUpdate(); };
    const rgetf = function() { return self.round; };
    this.variableSet.addVariable('caps', rsetf, rgetf, 'round', 'boolean');

    const rsetFNf = function(value) { self.fn = value; self.dependencyUpdate(); };
    const rgetFNf = function() { return self.fn; };
    this.variableSet.addVariable('fn', rsetFNf, rgetFNf, 'fn');

    const rsetFN2f = function(value) { self.fn2 = value; self.dependencyUpdate(); };
    const rgetFN2f = function() { return self.fn2; };
    this.variableSet.addVariable('fn2', rsetFN2f, rgetFN2f, 'fn');

    this.variableSet.addVariable('quadLine',
      function(value) { self.codeOpt.quadLine = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.quadLine; },
      'codeOpt', 'boolean');

    this.variableSet.addVariable('withBottom',
      function(value) { self.codeOpt.withBottom = Boolean(value); self.dependencyUpdate(); },
      function() { return self.codeOpt.withBottom; },
      'codeOpt', 'boolean');

    this.variableSet.addVariable('withTop',
      function(value) { self.codeOpt.withTop = value; self.dependencyUpdate(); },
      function() { return self.codeOpt.withTop; },
      'codeOpt', 'boolean');

    this.sAngleVar = this.variableSet.addVariable('startAngle',
      function(value) { self.startAngle = Number(value); self.cp['startAngle'].setValue(v3(), true, self.startAngle); self.dependencyUpdate(); },
      function() { return self.startAngle; },
      "a", 'angle');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [true, false, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});

    //Add angle control points
    this.addCP('startAngle', { axis: [true, true, false], color: '#81c268'});

    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
    this.cp['startAngle'].initControlVar(this.sAngleVar);
  }
  updateConfig(x = null, y = null, z = null, centerX = null, centerY = null, centerZ = null, round = null, fn = null, fn2 = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;
    this.round = round ? round : this.round;
    this.fn = fn ? fn : this.fn;
    this.fn2 = fn2 ? fn2 : this.fn2;
    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.cp['startAngle'].setValue(v3(), true, this.startAngle);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX)).add( v3(0, 0, -(self.z / 2)*self.centerZ));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    this.cp['y'].direction.set(1, 0, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar((x + self.x) / (1 + self.centerX) ).add( v3(0, 0, self.z / ( 1 + self.centerZ)) );
    };
    this.cp['y'].valueEq = function(x) {
      return ( self.cp['y'].position.clone().dot(self.cp['y'].direction) - self.x / (1 + self.centerX) ) * (1 + self.centerX);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (1 + self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (1 + self.centerZ);
    };
    //update startAngle angular control point
    this.cp['startAngle'].direction.set(1, 0, 0);
    this.cp['startAngle'].cpPosEq = function(x) {
      return self.cp['startAngle'].direction.clone().normalize().multiplyScalar(1+self.x/2).applyAxisAngle(v3(0, 0, 1), x * Math.PI / 180);
    };
    this.cp['startAngle'].valueEq = function(x) {
      if(self.cp['startAngle'].position.y >= 0) {
        return Math.round(self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }else{ //if in the negative plane, we are past 180 degrees
        return Math.round(360 - self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }
    };
    this.updateConfig(this.x, this.y, this.z);
    this.cp['x'].reposition();
    this.cp['y'].reposition();
    this.cp['z'].reposition();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.startAngle = this.cp['startAngle'].propertyValue;
    this.cp['x'].reposition();
    this.cp['y'].reposition();
    this.cp['startAngle'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.CylinderGeometry((this.y + this.x) / 2, this.x / 2, this.z, 16, 1 );
    //this.geometry.rotateX(Math.PI / 2);
    //this.geometry.translate((1 - this.centerX) * this.x / 2, (1 - this.centerY) * this.y / 2, (1 - this.centerZ) * this.z / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }

  loadScopeVar(sV) {
    const self = this;
    //this.variableSet.removeAll();
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }

}
class Toroid extends CSGobj {
  x = 20;
  y = 5;
  xVar;
  yVar;
  shellOpt = {
    en: false,
    in: true,
    wall: 1
  };
  fn = 8;
  fn2 = 8;
  startAngle;
  sAngleVar;
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType,
      x: this.x, y: this.y,startAngle: this.startAngle,
      fn: this.fn, fn2: this.fn2, shell: this.shellOpt};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    if(data.opt.startAngle){
      this.startAngle = data.opt.startAngle;
    }
    this.updateConfig(data.opt.x, data.opt.y, data.opt.fn, data.opt.fn2);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'toroid';
    this.name = 'T' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x
    const self = this;

    // shell
    const setShellEnf = function(value) { self.shellOpt.en = Boolean(value); self.dependencyUpdate(); };
    const getShellEnf = function() { return self.shellOpt.en; };
    this.variableSet.addVariable('shell', setShellEnf, getShellEnf, 'shell', 'boolean');

    const setShellInEnf = function(value) { self.shellOpt.in = Boolean(value); self.dependencyUpdate(); };
    const getShellInEnf = function() { return self.shellOpt.in; };
    this.variableSet.addVariable('inner', setShellInEnf, getShellInEnf, 'shell', 'boolean');

    const setWallEnf = function(value) { self.shellOpt.wall = Number(value); self.dependencyUpdate(); };
    const getWallEnf = function() { return self.shellOpt.wall; };
    this.variableSet.addVariable('wall', setWallEnf, getWallEnf, 'shell');

    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    const rsetFNf = function(value) { self.fn = value; self.dependencyUpdate(); };
    const rgetFNf = function() { return self.fn; };
    this.variableSet.addVariable('fn', rsetFNf, rgetFNf, 'fn');

    const rsetFN2f = function(value) { self.fn2 = value; self.dependencyUpdate(); };
    const rgetFN2f = function() { return self.fn2; };
    this.variableSet.addVariable('fn2', rsetFN2f, rgetFN2f, 'fn');

    this.sAngleVar = this.variableSet.addVariable('startAngle',
      function(value) { self.startAngle = Number(value); self.cp['startAngle'].setValue(v3(), true, self.startAngle); self.dependencyUpdate(); },
      function() { return self.startAngle; },
      "a", 'angle');
  }
  setUpCP() {
    //add shape control points
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [true, false, false], color: '#1b9f02'});

    //Add angle control points
    this.addCP('startAngle', { axis: [true, true, false], color: '#81c268'});


    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);

    this.cp['startAngle'].initControlVar(this.sAngleVar);
  }
  updateConfig(x = null, y = null, fn = null, fn2 = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    this.fn = fn ? fn : this.fn;
    this.fn2 = fn2 ? fn2 : this.fn2;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['startAngle'].setValue(v3(), true, this.startAngle);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + 1)).add(v3(0, 0, self.y/2));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + 1);
    };

    this.cp['y'].direction.set(1, 0, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar((x + self.x) / (1 + 1) );
    };
    this.cp['y'].valueEq = function(x) {
      return ( self.cp['y'].position.clone().dot(self.cp['y'].direction) - self.x / (1 + 1) ) * (1 + 1);
    };
    //update startAngle angular control point
    this.cp['startAngle'].direction.set(1, 0, 0);
    this.cp['startAngle'].cpPosEq = function(x) {
      return self.cp['startAngle'].direction.clone().normalize().multiplyScalar(1+self.x/2+self.y/4).add(v3(0, 0, self.y/2)).applyAxisAngle(v3(0, 0, 1), x * Math.PI / 180);
    };
    this.cp['startAngle'].valueEq = function(x) {
      if(self.cp['startAngle'].position.y >= 0) {
        return Math.round(self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }else{ //if in the negative plane, we are past 180 degrees
        return Math.round(360 - self.cp['startAngle'].position.clone().angleTo(self.cp['startAngle'].direction) * 180 / Math.PI);
      }
    };

    this.updateConfig(this.x, this.y);
    this.cp['x'].reposition();
    this.cp['y'].reposition();


  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.startAngle = this.cp['startAngle'].propertyValue;

    this.cp['y'].reposition();
    this.cp['x'].reposition();
    this.cp['startAngle'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.TorusGeometry((this.x) / 2, (this.y) / 2,  16, 32);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}
class Slot extends CSGobj {
  x = 10;
  y = 5;
  y2 = 10;
  z = 10;
  centerX = 1;
  centerY = 0;
  centerZ = 0;
  roundX = 0;
  roundY = 0;
  roundZ = 0;
  h1 = 0;
  h2 = 0;

  shellOpt = {
    en: true,
    in: true,
    wall: 2,
    zt: true,
    zb: true,
  };
  xVar;
  yVar;
  y2Var;
  zVar;
  fn = 16;
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType,
      x: this.x, y: this.y, y2: this.y2, z: this.z,
      h1: this.h1, h2: this.h2,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      xR: this.roundX, yR: this.roundY, zR: this.roundZ,
      fn: this.fn, shell: this.shellOpt};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    this.h1 = data.opt.h1;
    this.h2 = data.opt.h2;
    this.updateConfig(data.opt.x, data.opt.y, data.opt.y2, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC, data.opt.xR, data.opt.yR, data.opt.zR, data.opt.fn);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'slot';
    this.name = 'S' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x Size
    const self = this;
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('Spacing', setXf, getXf, 'slot');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('d1', setYf, getYf, 'slot');

    // s.y
    const setY2f = function(value) { self.cp['y2'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getY2f = function() { return self.y2; };
    this.y2Var = this.variableSet.addVariable('d2', setY2f, getY2f, 'slot');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('height', setZf, getZf, 'slot');

    const seth1f = function(value) { self.h1 = Number(value); self.dependencyUpdate(); };
    const geth1f = function() { return self.h1; };
    this.variableSet.addVariable('h1', seth1f, geth1f, 'slot');

    const seth2f = function(value) { self.h2 = Number(value); self.dependencyUpdate(); };
    const geth2f = function() { return self.h2; };
    this.variableSet.addVariable('h2', seth2f, geth2f, 'slot');


    // shell
    const setShellEnf = function(value) { self.shellOpt.en = Boolean(value); self.dependencyUpdate(); };
    const getShellEnf = function() { return self.shellOpt.en; };
    this.variableSet.addVariable('shell', setShellEnf, getShellEnf, 'shell', 'boolean');

    const setShellInEnf = function(value) { self.shellOpt.in = Boolean(value); self.dependencyUpdate(); };
    const getShellInEnf = function() { return self.shellOpt.in; };
    this.variableSet.addVariable('inner', setShellInEnf, getShellInEnf, 'shell', 'boolean');

    const setWallEnf = function(value) { self.shellOpt.wall = Number(value); self.dependencyUpdate(); };
    const getWallEnf = function() { return self.shellOpt.wall; };
    this.variableSet.addVariable('wall', setWallEnf, getWallEnf, 'shell');


    const setzmEnf = function(value) { self.shellOpt.zt = Boolean(value); self.dependencyUpdate(); };
    const getzmEnf = function() { return self.shellOpt.zt; };
    this.variableSet.addVariable('z+', setzmEnf, getzmEnf, 'shellSide', 'boolean');

    const setzpEnf = function(value) { self.shellOpt.zb = Boolean(value); self.dependencyUpdate(); };
    const getzpEnf = function() { return self.shellOpt.zb; };
    this.variableSet.addVariable('z-', setzpEnf, getzpEnf, 'shellSide', 'boolean');

    // center
    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');

    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');

    // round
    const rsetFNf = function(value) { self.fn = value; self.dependencyUpdate(); };
    const rgetFNf = function() { return self.fn; };
    this.variableSet.addVariable('fn', rsetFNf, rgetFNf, 'fn');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('y2', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['y2'].initControlVar(this.y2Var);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateConfig(x = null, y = null, y2 = null, z = null, centerX = null, centerY = null, centerZ = null, roundX = null, roundY = null, roundZ = null, fn = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    y2 = y2 ? y2 : this.y2;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;

    this.roundX = roundX ? roundX : this.roundX;
    this.roundY = roundY ? roundY : this.roundY;
    this.roundZ = roundZ ? roundZ : this.roundZ;

    this.fn = fn ? fn : this.fn;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['y2'].setValue(v3(), true, y2);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar(x / 2).add(v3(self.x * (self.centerX / -2), 0, 0));
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * 2;
    };

    this.cp['y2'].direction.set(0, 1, 0);
    this.cp['y2'].cpPosEq = function(x) {
      return self.cp['y2'].direction.clone().multiplyScalar(x / 2).add(v3(self.x * (1 - self.centerX / 2), 0, 0));
    };
    this.cp['y2'].valueEq = function(x) {
      return self.cp['y2'].position.clone().dot(self.cp['y2'].direction) * 2;
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (1 + self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (1 + self.centerZ);
    };
    this.updateConfig(this.x, this.y, this.z);
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.y2 = this.cp['y2'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.cp['y'].reposition();
    this.cp['y2'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.BoxGeometry(this.x, this.y, this.z );

    //this.geometry.translate((1 - this.centerX) * this.x / 2, 0, (1 - this.centerZ) * this.z / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}

//gears
class BevelGear extends CSGobj {
  x = 10;
  y = 0;
  z = 50;
  xVar;
  yVar;
  zVar;
  pitchVar;
  gearOpt = {
    OffsetA: 40,
    OffsetB: 40,
    pitch: 3.14,
    pressureAngle: 20,
    clearance: 0.3,
    backlash: 0.3,
    profileShift: 0,
    t1: 30,
    t2: 30,
    holeD1: 3,
    holeD2: 3,
    res: 3,
    stepsPerToothAngle: 3,
    show: 'SET',
    gentype: 'SET',
    height: 5,
    rackLength: 22,
    twist: 0
  };
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType, gearOpt: this.gearOpt, x: this.x, y: this.y, z: this.z};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.gearOpt);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'bevelgear';
    this.name = 'Gb' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  selectType(event) {
    this.gearOpt.gentype = event.target.value;
    this.updateCSG(true, false);
  }
  _initVariables() {
    const self = this;
    const setPf = function(value) { self.gearOpt.pitch = Number(value); self.dependencyUpdate(); };
    const getPf = function() { return self.gearOpt.pitch; };
    this.pitchVar = this.variableSet.addVariable('pitch', setPf, getPf, 'gear');

    const setT1f = function(value) { self.gearOpt.t1 = Number(value); self.dependencyUpdate(); };
    const getT1f = function() { return self.gearOpt.t1; };
    this.pitchVar = this.variableSet.addVariable('t1', setT1f, getT1f, 'gear');

    const setT2f = function(value) { self.gearOpt.t2 = Number(value); self.dependencyUpdate(); };
    const getT2f = function() { return self.gearOpt.t2; };
    this.pitchVar = this.variableSet.addVariable('t2', setT2f, getT2f, 'gear');

    const setholeD1f = function(value) { self.gearOpt.holeD1 = Number(value); self.dependencyUpdate(); };
    const getholeD1f = function() { return self.gearOpt.holeD1; };
    this.pitchVar = this.variableSet.addVariable('holeD1', setholeD1f, getholeD1f, 'gear');

    const setholeD2f = function(value) { self.gearOpt.holeD2 = Number(value); self.dependencyUpdate(); };
    const getholeD2f = function() { return self.gearOpt.holeD2; };
    this.pitchVar = this.variableSet.addVariable('holeD2', setholeD2f, getholeD2f, 'gear');

    //params
    const setpressureAnglef = function(value) { self.gearOpt.pressureAngle = Number(value); self.dependencyUpdate(); };
    const getpressureAnglef = function() { return self.gearOpt.pressureAngle; };
    this.pitchVar = this.variableSet.addVariable('pressureAngle', setpressureAnglef, getpressureAnglef, 'gear');

    const setprofileShiftf = function(value) { self.gearOpt.profileShift = Number(value); self.dependencyUpdate(); };
    const getprofileShiftf = function() { return self.gearOpt.profileShift; };
    this.pitchVar = this.variableSet.addVariable('profileShift', setprofileShiftf, getprofileShiftf, 'gear');

    const setclearancef = function(value) { self.gearOpt.clearance = Number(value); self.dependencyUpdate(); };
    const getclearancef = function() { return self.gearOpt.clearance; };
    this.pitchVar = this.variableSet.addVariable('clearance', setclearancef, getclearancef, 'gear');

    const setbacklashf = function(value) { self.gearOpt.backlash = Number(value); self.dependencyUpdate(); };
    const getbacklashf = function() { return self.gearOpt.backlash; };
    this.pitchVar = this.variableSet.addVariable('backlash', setbacklashf, getbacklashf, 'gear');

    const setheightf = function(value) { self.gearOpt.height = Number(value); self.dependencyUpdate(); };
    const getheightf = function() { return self.gearOpt.height; };
    this.pitchVar = this.variableSet.addVariable('height', setheightf, getheightf, 'gear');

    const settwistf = function(value) { self.gearOpt.twist = Number(value); self.dependencyUpdate(); };
    const gettwistf = function() { return self.gearOpt.twist; };
    this.pitchVar = this.variableSet.addVariable('twist', settwistf, gettwistf, 'gear');

// s.x
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [true, false, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateConfig(x = null, y = null, z = null, gearOpt = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    gearOpt = gearOpt ? gearOpt : this.gearOpt;
    this.gearOpt = gearOpt;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x);
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction);
    };

    this.cp['y'].direction.set(1, 0, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar((x + self.x)).add( v3(0, 0, self.z) );
    };
    this.cp['y'].valueEq = function(x) {
      return ( self.cp['y'].position.clone().dot(self.cp['y'].direction) - self.x );
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x);
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction);
    };
    this.updateConfig(this.x, this.y, this.z);
    this.cp['x'].reposition();
    this.cp['y'].reposition();
    this.cp['z'].reposition();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.cp['y'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.CylinderGeometry((this.y + this.x) / 2, this.x / 2, this.z, 16, 1 );
    //this.geometry.rotateX(Math.PI / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}
class RackGear extends CSGobj {
  x = 10;
  y = 30;
  z = 5;
  xVar;
  yVar;
  zVar;
  pitchVar;
  gearOpt = {
    rackWidth:  10,
    pitch: 3.14,
    pressureAngle: 20,
    clearance: 0.3,
    backlash: 0.3,
    profileShift: 0,
    t1: 30,
    t2: 30,
    holeD1: 3,
    holeD2: 3,
    res: 3,
    stepsPerToothAngle: 3,
    show: 'A',
    height: 5,
    rackLength: 22,
    twist: 0
  };
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType, gearOpt: this.gearOpt, x: this.x, y: this.y, z: this.z};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.gearOpt);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'rackgear';
    this.name = 'Gr' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    const self = this;
    const setPf = function(value) { self.gearOpt.pitch = Number(value); self.dependencyUpdate(); };
    const getPf = function() { return self.gearOpt.pitch; };
    this.pitchVar = this.variableSet.addVariable('pitch', setPf, getPf, 'gear');



    //params
    const setpressureAnglef = function(value) { self.gearOpt.pressureAngle = Number(value); self.dependencyUpdate(); };
    const getpressureAnglef = function() { return self.gearOpt.pressureAngle; };
    this.pitchVar = this.variableSet.addVariable('pressureAngle', setpressureAnglef, getpressureAnglef, 'gear');

    const setprofileShiftf = function(value) { self.gearOpt.profileShift = Number(value); self.dependencyUpdate(); };
    const getprofileShiftf = function() { return self.gearOpt.profileShift; };
    this.pitchVar = this.variableSet.addVariable('profileShift', setprofileShiftf, getprofileShiftf, 'gear');

    const setclearancef = function(value) { self.gearOpt.clearance = Number(value); self.dependencyUpdate(); };
    const getclearancef = function() { return self.gearOpt.clearance; };
    this.pitchVar = this.variableSet.addVariable('clearance', setclearancef, getclearancef, 'gear');

    const setbacklashf = function(value) { self.gearOpt.backlash = Number(value); self.dependencyUpdate(); };
    const getbacklashf = function() { return self.gearOpt.backlash; };
    this.pitchVar = this.variableSet.addVariable('backlash', setbacklashf, getbacklashf, 'gear');

    const setheightf = function(value) { self.gearOpt.height = Number(value); self.dependencyUpdate(); };
    const getheightf = function() { return self.gearOpt.height; };
    this.pitchVar = this.variableSet.addVariable('height', setheightf, getheightf, 'gear');
// s.x
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateConfig(x = null, y = null, z = null, gearOpt = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    gearOpt = gearOpt ? gearOpt : this.gearOpt;
    this.gearOpt = gearOpt;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(-x);
    };
    this.cp['x'].valueEq = function(x) {
      return -self.cp['x'].position.clone().dot(self.cp['x'].direction);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar((x * self.gearOpt.pitch / 2)).add( v3(0, 0, self.z) );
    };
    this.cp['y'].valueEq = function(x) {
      return Math.round( self.cp['y'].position.clone().dot(self.cp['y'].direction) / self.gearOpt.pitch * 2);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x);
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction);
    };
    this.updateConfig(this.x, this.y, this.z);
    this.cp['x'].reposition();
    this.cp['y'].reposition();
    this.cp['z'].reposition();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.cp['y'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.BoxGeometry( this.x, this.y*this.gearOpt.pitch, this.z);
    //this.geometry.rotateX(Math.PI / 2);
    //this.geometry.translate(-this.x/2, 0, this.z/2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}
class Gear extends CSGobj {
  x = 10;
  y = 3;
  z = 50;
  xVar;
  yVar;
  zVar;
  pitchVar;
  gearOpt = {
    rackWidth:  10,
    pitch: 3.14,
    pressureAngle: 20,
    clearance: 0.3,
    backlash: 0.3,
    profileShift: 0,
    t1: 30,
    t2: 30,
    holeD1: 3,
    holeD2: 3,
    res: 3,
    stepsPerToothAngle: 3,
    show: 'A',
    height: 5,
    rackLength: 22,
    twist: 0
  };
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType, gearOpt: this.gearOpt, x: this.x, y: this.y, z: this.z};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.gearOpt);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'gear';
    this.name = 'G' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    const self = this;
    const setPf = function(value) { self.gearOpt.pitch = Number(value); self.dependencyUpdate(); };
    const getPf = function() { return self.gearOpt.pitch; };
    this.pitchVar = this.variableSet.addVariable('pitch', setPf, getPf, 'gear');

    const setT1f = function(value) { self.gearOpt.t1 = Number(value); self.dependencyUpdate(); };
    const getT1f = function() { return self.gearOpt.t1; };
    this.pitchVar = this.variableSet.addVariable('t1', setT1f, getT1f, 'gear');

    const setholeD1f = function(value) { self.gearOpt.holeD1 = Number(value); self.dependencyUpdate(); };
    const getholeD1f = function() { return self.gearOpt.holeD1; };
    this.pitchVar = this.variableSet.addVariable('holeD1', setholeD1f, getholeD1f, 'gear');

    //params
    const setpressureAnglef = function(value) { self.gearOpt.pressureAngle = Number(value); self.dependencyUpdate(); };
    const getpressureAnglef = function() { return self.gearOpt.pressureAngle; };
    this.pitchVar = this.variableSet.addVariable('pressureAngle', setpressureAnglef, getpressureAnglef, 'gear');

    const setprofileShiftf = function(value) { self.gearOpt.profileShift = Number(value); self.dependencyUpdate(); };
    const getprofileShiftf = function() { return self.gearOpt.profileShift; };
    this.pitchVar = this.variableSet.addVariable('profileShift', setprofileShiftf, getprofileShiftf, 'gear');

    const setclearancef = function(value) { self.gearOpt.clearance = Number(value); self.dependencyUpdate(); };
    const getclearancef = function() { return self.gearOpt.clearance; };
    this.pitchVar = this.variableSet.addVariable('clearance', setclearancef, getclearancef, 'gear');

    const setbacklashf = function(value) { self.gearOpt.backlash = Number(value); self.dependencyUpdate(); };
    const getbacklashf = function() { return self.gearOpt.backlash; };
    this.pitchVar = this.variableSet.addVariable('backlash', setbacklashf, getbacklashf, 'gear');

    const setheightf = function(value) { self.gearOpt.height = Number(value); self.dependencyUpdate(); };
    const getheightf = function() { return self.gearOpt.height; };
    this.pitchVar = this.variableSet.addVariable('height', setheightf, getheightf, 'gear');

    const settwistf = function(value) { self.gearOpt.twist = Number(value); self.dependencyUpdate(); };
    const gettwistf = function() { return self.gearOpt.twist; };
    this.pitchVar = this.variableSet.addVariable('twist', settwistf, gettwistf, 'gear');

// s.x
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [true, false, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateConfig(x = null, y = null, z = null, gearOpt = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    gearOpt = gearOpt ? gearOpt : this.gearOpt;
    this.gearOpt = gearOpt;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar((x  * self.gearOpt.pitch) / Math.PI / 2);
    };
    this.cp['x'].valueEq = function(x) {
      return Math.round(self.cp['x'].position.clone().dot(self.cp['x'].direction) * Math.PI * 2 / self.gearOpt.pitch);
    };

    this.cp['y'].direction.set(1, 0, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar((x/2)).add( v3(0, 0, self.z) );
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * 2;
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x);
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction);
    };
    this.updateConfig(this.x, this.y, this.z);
    this.cp['x'].reposition();
    this.cp['y'].reposition();
    this.cp['z'].reposition();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.cp['y'].reposition();
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.CylinderGeometry((this.x * this.gearOpt.pitch) / Math.PI / 2, (this.x * this.gearOpt.pitch) / Math.PI / 2, this.z, 32, 1 );
    //this.geometry.rotateX(Math.PI / 2);
    //this.geometry.translate(0, 0, this.z / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }
}
class PulleyGear extends CSGobj {
  pitchVar;
  gearOpt = {
    toothCount: 25,
    pType: 12,
    add_t_w: 0.2,
    add_t_d: 0,
    t_h: 6,
    b_h: 1,
    b_d: 20,
    t_g: 1,
    b_g: 1,
    shaft_d: 5,
    bolt_d: 3,
    nut_d: 6,
    nut_h: 2,
    nut_offset: 3,
    nut_slot: true,
    bolt_count: 0,
    belt_t: 2
  };
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType, gearOpt: this.gearOpt};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  selectType(event) {
    this.gearOpt.pType = Number(event.target.value);
    this.updateCSG(true, false);
  }
  _load(data) {
    this.updateConfig(data.opt.gearOpt);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'pulleygear';
    this.name = 'G' + Object.keys(WS.objects).length;
  }
  _initVariables() {
    const self = this;
    const settoothCountf = function(value) { self.gearOpt.toothCount = Number(value); self.dependencyUpdate(); };
    const gettoothCountf = function() { return self.gearOpt.toothCount; };
    this.pitchVar = this.variableSet.addVariable('toothCount', settoothCountf, gettoothCountf, 'gear');

    const setadd_t_wf = function(value) { self.gearOpt.add_t_w = Number(value); self.dependencyUpdate(); };
    const getadd_t_wf = function() { return self.gearOpt.add_t_w; };
    this.pitchVar = this.variableSet.addVariable('add_t_w', setadd_t_wf, getadd_t_wf, 'gear');

    const setadd_t_df = function(value) { self.gearOpt.add_t_d = Number(value); self.dependencyUpdate(); };
    const getadd_t_df = function() { return self.gearOpt.add_t_d; };
    this.pitchVar = this.variableSet.addVariable('add_t_d', setadd_t_df, getadd_t_df, 'gear');

    //params
    const sett_hf = function(value) { self.gearOpt.t_h = Number(value); self.dependencyUpdate(); };
    const gett_hf = function() { return self.gearOpt.t_h; };
    this.pitchVar = this.variableSet.addVariable('t_h', sett_hf, gett_hf, 'gear');

    const setb_hf = function(value) { self.gearOpt.b_h = Number(value); self.dependencyUpdate(); };
    const getb_hf = function() { return self.gearOpt.b_h; };
    this.pitchVar = this.variableSet.addVariable('b_h', setb_hf, getb_hf, 'gear');

    const setb_df = function(value) { self.gearOpt.b_d = Number(value); self.dependencyUpdate(); };
    const getb_df = function() { return self.gearOpt.b_d; };
    this.pitchVar = this.variableSet.addVariable('b_d', setb_df, getb_df, 'gear');

    const sett_gf = function(value) { self.gearOpt.t_g = Number(value); self.dependencyUpdate(); };
    const gett_gf = function() { return self.gearOpt.t_g; };
    this.pitchVar = this.variableSet.addVariable('t_g', sett_gf, gett_gf, 'gear');

    const setb_gf = function(value) { self.gearOpt.b_g = Number(value); self.dependencyUpdate(); };
    const getb_gf = function() { return self.gearOpt.b_g; };
    this.pitchVar = this.variableSet.addVariable('b_g', setb_gf, getb_gf, 'gear');

    const setshaft_df = function(value) { self.gearOpt.shaft_d = Number(value); self.dependencyUpdate(); };
    const getshaft_df = function() { return self.gearOpt.shaft_d; };
    this.pitchVar = this.variableSet.addVariable('shaft_d', setshaft_df, getshaft_df, 'gear');


    const setbolt_df = function(value) { self.gearOpt.bolt_d = Number(value); self.dependencyUpdate(); };
    const getbolt_df = function() { return self.gearOpt.bolt_d; };
    this.pitchVar = this.variableSet.addVariable('bolt_d', setbolt_df, getbolt_df, 'gear');

    const setnut_df = function(value) { self.gearOpt.nut_d = Number(value); self.dependencyUpdate(); };
    const getnut_df = function() { return self.gearOpt.nut_d; };
    this.pitchVar = this.variableSet.addVariable('nut_d', setnut_df, getnut_df, 'gear');

    const setnut_hf = function(value) { self.gearOpt.nut_h = Number(value); self.dependencyUpdate(); };
    const getnut_hf = function() { return self.gearOpt.nut_h; };
    this.pitchVar = this.variableSet.addVariable('nut_h', setnut_hf, getnut_hf, 'gear');

    const setnut_offsetf = function(value) { self.gearOpt.nut_offset = Number(value); self.dependencyUpdate(); };
    const getnut_offsetf = function() { return self.gearOpt.nut_offset; };
    this.pitchVar = this.variableSet.addVariable('nut_offset', setnut_offsetf, getnut_offsetf, 'gear');

    const setnut_slotf = function(value) { self.gearOpt.nut_slot = Boolean(value); self.dependencyUpdate(); };
    const getnut_slotf = function() { return self.gearOpt.nut_slot; };
    this.pitchVar = this.variableSet.addVariable('nut_slot', setnut_slotf, getnut_slotf, 'gear', 'boolean');

    const setbolt_countf = function(value) { self.gearOpt.bolt_count = Number(value); self.dependencyUpdate(); };
    const getbolt_countf = function() { return self.gearOpt.bolt_count; };
    this.pitchVar = this.variableSet.addVariable('bolt_count', setbolt_countf, getbolt_countf, 'gear');
  }
  updateConfig(gearOpt = null) {
    this.gearOpt = gearOpt ? gearOpt : this.gearOpt;
    this.dependencyUpdate();
  }
}
class PulleyBelt extends CSGobj {
  pitchVar;
  gearOpt = {
    toothCount: 45,
    pType: 11,
    add_t_w: 0.2,
    add_t_d: 0,
    t_h: 6,
    b_h: 0,
    b_d: 0,
    t_g: 0,
    b_g: 0,
    shaft_d: 0,
    bolt_d: 3,
    nut_d: 6,
    nut_h: 2,
    nut_offset: 3,
    nut_slot: false,
    bolt_count: 0,
    belt_t: 2
  };
  selectType(event) {
    this.gearOpt.pType = Number(event.target.value);
    this.updateCSG(true, false);
  }
  _save(obj) {
    const opt = {csgOp: this.csgOp, csgType: this.csgType, gearOpt: this.gearOpt};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.updateConfig(data.opt.gearOpt);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgType = 'pulleybelt';
    this.name = 'G' + Object.keys(WS.objects).length;
  }
  _initVariables() {
    const self = this;
    const settoothCountf = function(value) { self.gearOpt.toothCount = Number(value); self.dependencyUpdate(); };
    const gettoothCountf = function() { return self.gearOpt.toothCount; };
    this.pitchVar = this.variableSet.addVariable('toothCount', settoothCountf, gettoothCountf, 'gear');


    const setbelt_tf = function(value) { self.gearOpt.belt_t = Number(value); self.dependencyUpdate(); };
    const getbelt_tf = function() { return self.gearOpt.belt_t; };
    this.pitchVar = this.variableSet.addVariable('belt_t', setbelt_tf, getbelt_tf, 'gear');


    const setadd_t_wf = function(value) { self.gearOpt.add_t_w = Number(value); self.dependencyUpdate(); };
    const getadd_t_wf = function() { return self.gearOpt.add_t_w; };
    this.pitchVar = this.variableSet.addVariable('add_t_w', setadd_t_wf, getadd_t_wf, 'gear');

    const setadd_t_df = function(value) { self.gearOpt.add_t_d = Number(value); self.dependencyUpdate(); };
    const getadd_t_df = function() { return self.gearOpt.add_t_d; };
    this.pitchVar = this.variableSet.addVariable('add_t_d', setadd_t_df, getadd_t_df, 'gear');

    //params
    const sett_hf = function(value) { self.gearOpt.t_h = Number(value); self.dependencyUpdate(); };
    const gett_hf = function() { return self.gearOpt.t_h; };
    this.pitchVar = this.variableSet.addVariable('t_h', sett_hf, gett_hf, 'gear');

    const setb_hf = function(value) { self.gearOpt.b_h = Number(value); self.dependencyUpdate(); };
    const getb_hf = function() { return self.gearOpt.b_h; };
    this.pitchVar = this.variableSet.addVariable('b_h', setb_hf, getb_hf, 'gear');

    const setb_df = function(value) { self.gearOpt.b_d = Number(value); self.dependencyUpdate(); };
    const getb_df = function() { return self.gearOpt.b_d; };
    this.pitchVar = this.variableSet.addVariable('b_d', setb_df, getb_df, 'gear');

    const sett_gf = function(value) { self.gearOpt.t_g = Number(value); self.dependencyUpdate(); };
    const gett_gf = function() { return self.gearOpt.t_g; };
    this.pitchVar = this.variableSet.addVariable('t_g', sett_gf, gett_gf, 'gear');

    const setb_gf = function(value) { self.gearOpt.b_g = Number(value); self.dependencyUpdate(); };
    const getb_gf = function() { return self.gearOpt.b_g; };
    this.pitchVar = this.variableSet.addVariable('b_g', setb_gf, getb_gf, 'gear');

    const setshaft_df = function(value) { self.gearOpt.shaft_d = Number(value); self.dependencyUpdate(); };
    const getshaft_df = function() { return self.gearOpt.shaft_d; };
    this.pitchVar = this.variableSet.addVariable('shaft_d', setshaft_df, getshaft_df, 'gear');


    const setbolt_df = function(value) { self.gearOpt.bolt_d = Number(value); self.dependencyUpdate(); };
    const getbolt_df = function() { return self.gearOpt.bolt_d; };
    this.pitchVar = this.variableSet.addVariable('bolt_d', setbolt_df, getbolt_df, 'gear');
  }
  updateConfig(gearOpt = null) {
    gearOpt = gearOpt ? gearOpt : this.gearOpt;
    this.gearOpt = gearOpt;
    this.dependencyUpdate();
  }
}

//generated
class Fastener extends CSGobj {
  fOpt = {
    fType: 1,
    nType: 1,
    nutOffset: 10,
    cs: true,
    tapper: 60,
    nutSlot: 30,
    h: 10,
    opt: {
      d: 3,
      capD: 7, capH: 3,
      nutD: 8, nutH: 12,
    }
  };
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild, fOpt: this.fOpt};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.fOpt = data.opt.fOpt;
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'fastener';
    this.name = 'F' + Object.keys(WS.objects).length;
  }
}
class Motor extends CSGobj {
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'motor';
    this.name = 'M' + Object.keys(WS.objects).length;
  }
}
class Code extends CSGobj {
  code = ' ';
  codeType = 'jsCad';
  lang = 'js';
  scopeV = [];
  scopeObj = {};

  codeEn = true;
  snippet;

  x = 10;
  y = 10;
  z = 10;
  centerX = 1;
  centerY = 1;
  centerZ = 1;
  xVar;
  yVar;
  zVar;

  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    this.csgType = 'code';
    this.scopeV = [];
    this.name = 'code' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }

  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar(x / (1 + self.centerY));
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * (1 + self.centerY);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (1 + self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (1 + self.centerZ);
    };
    this.updateConfig(this.x, this.y, this.z);
  }
  updateConfig(x = null, y = null, z = null, centerX = null, centerY = null, centerZ = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    this.geometry.dispose();
    this.edgesGeometry.dispose();
    this.geometry = new THREE.BoxGeometry(this.x, this.y, this.z );
    this.geometry.translate((1 - this.centerX) * this.x / 2, (1 - this.centerY) * this.y / 2, (1 - this.centerZ) * this.z / 2);
    this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    this.mesh.geometry = this.edgesGeometry;
  }
  _save(obj) {
    const self = this;
    const csgChild = [];
    const csgOptC = [];
    const varList = [];
    const scopeObj = {};
    Object.keys(this.variableSet.variableDB.scope).forEach(key => {
      // check if object is a fuction
      if (typeof this.variableSet.variableDB.scope[key] != 'function') {
        scopeObj[key] = this.variableSet.variableDB.scope[key];
      }
    });
    this.children.forEach(child => {
      if (child.csgType) {
        let t = child.save();
        csgOptC.push(t);
        csgChild.push(t.gUIDf);
      }
    });
    if ( self.scopeV ) {
      self.scopeV.forEach(v => {
        varList.push({ name: v.name, value: v.get(), exp: v.exp});
      });
    }

    const opt = {
      x: this.x, y: this.y, z: this.z,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      code: this.code,
      codeType: this.codeType,
      lang: this.lang,
      sV: varList,
      userVar: obj.userVar,
      optC: csgOptC,
      //scope: scopeObj, Broken due to undefined values which may be contained within.
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }

  _load(data): any {
    const self = this;
    this.code = data.opt.code;
    this.codeType = data.opt.codeType;
    this.lang = data.opt.lang;
    this.loadScopeVar(data.opt.sV);
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC);
  }
  loadScopeVar(sV) {
    const self = this;
    //this.variableSet.removeAll();
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  removeScopeVar(v) {
    const self = this;
    //console.log(v,self);
    if (self.scopeObj.hasOwnProperty(v.name)) {
      let id = this.scopeV.indexOf(v);
      this.scopeV.splice(id,1);

      if(v.variableDB.varSubSet.hasOwnProperty('scope')){
        delete v.variableDB.varSubSet.scope;
      }
      if(v.variableDB.varDB.indexOf(v) > -1){
        this.scopeV.splice(v.variableDB.varDB.indexOf(v),1);
      }

      this.loadScopeVar(this.scopeV);
    }
  }
  _initVariables() {
    // s.x Size
    const self = this;

    // size
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');

    // center
    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = Number(value); self.cp['y'].setValue(v3(), true, self.y); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');
  }
}


class Implicit extends CSGobj {
  code = ' ';
  codeType = 'jsCad';
  lang = 'js';
  scopeV = [];
  scopeObj = {};
  codeEn = true;
  snippet;

  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    this.csgType = 'implicit';
    this.scopeV = [];
    this.name = 'Imp' + Object.keys(WS.objects).length;
  }
  _save(obj) {
    const self = this;
    const csgChild = [];
    const varList = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    if ( self.scopeV ) {
      self.scopeV.forEach(v => {
        varList.push({ name: v.name, value: v.get(), exp: v.exp});
      });
    }
    const opt = {
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      code: this.code,
      codeType: this.codeType,
      lang: this.lang,
      sV: varList
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data): any {
    const self = this;
    this.code = data.opt.code;
    this.codeType = data.opt.codeType;
    this.lang = data.opt.lang;
    this.loadScopeVar(data.opt.sV);
  }
  loadScopeVar(sV) {
    const self = this;
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }
  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  _initVariables() {
    // s.x
  }
}
class Parametric extends CSGobj {
  code = ' ';
  codeType = 'p';//p,f
  lang = 'js';
  scopeV = [];
  scopeObj = {};
  codeEn = true;
  snippet;

  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    this.csgType = 'parametric';
    this.scopeV = [];
    this.name = 'par' + Object.keys(WS.objects).length;
  }
  _save(obj) {
    const self = this;
    const csgChild = [];
    const varList = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    if ( self.scopeV ) {
      self.scopeV.forEach(v => {
        varList.push({ name: v.name, value: v.get(), exp: v.exp});
      });
    }
    const opt = {
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      code: this.code,
      codeType: this.codeType,
      lang: this.lang,
      sV: varList
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data): any {
    const self = this;
    this.code = data.opt.code;
    this.codeType = data.opt.codeType;
    this.lang = data.opt.lang;
    this.loadScopeVar(data.opt.sV);
  }
  loadScopeVar(sV) {
    const self = this;
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }
  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  _initVariables() {
    // s.x
  }
}


class Extrude2D extends CSGobj {
  extrusionType = 'linear';
  height = 10;
  twist = 0;
  slices = 1;
  fn = 32;
  angle = 360;
  startAngle = 0;
  zVar;
  //curv
  curvPancake = false;
  perimeter_extrude_crossection = '';
  sliceHeight = 1;
  sliceLayer = 0;
  sliceIsland = 0;
  childHistoryGenUID = 0;
  lastSliceHeight = 1;
  _save(obj) {
    const self = this;
    const csgChild = [];
    let shapes = [];
    this.children.forEach( (child,i,arr) => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
      if (child.type === 'shape2d') {
        const ptSet = [];
        child.PT.forEach( pt => {
          ptSet.push({x:pt.x + child.G.position.x, y:pt.y + child.G.position.y});
        });
        shapes = ptSet; // Limits to only top object for now.
      }
      if (child.type === 'csg') {
        const ptSet = [];
        if(child.meshSlicer){
          if(child.genUID != self.childHistoryGenUID || self.lastSliceHeight != self.sliceHeight){
            child.meshSlicer.genLayers(self.sliceHeight);
            self.childHistoryGenUID = child.genUIDf;
            self.lastSliceHeight = self.sliceHeight;
          }
          child.meshSlicer.slicePT[self.sliceLayer][self.sliceIsland].forEach( pt => {
            ptSet.push({x:pt[0] + child.G.position.x, y:pt[1] + child.G.position.y});
          });
        } else {
          child.sliceMesh();
          child.meshSlicer.genLayers(self.sliceHeight);
          child.meshSlicer.slicePT[self.sliceLayer][self.sliceIsland].forEach( pt => {
            ptSet.push({x:pt[0] + child.G.position.x, y:pt[1] + child.G.position.y});
          });
        }
        shapes = ptSet; // Limits to only top object for now.
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild,
      shapes:shapes, extrusionType: this.extrusionType, height:this.height,
      curvPancake: this.curvPancake, perimeter_extrude_crossection: this.perimeter_extrude_crossection,
      slices: this.slices, twist: this.twist, fn: this.fn, angle: this.angle, startAngle: this.startAngle,
      sliceHeight:this.sliceHeight, sliceLayer:self.sliceLayer, sliceIsland:self.sliceIsland
    };
    const csgOpt = this._saveCSG(opt);
    //console.log()
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'extrude2d';
    this.name = 'Ex2' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _load(data): any {
    if(data.opt.sliceIsland){
      this.sliceIsland = data.opt.sliceIsland;
    }
    if(data.opt.sliceHeight){
      this.sliceHeight = data.opt.sliceHeight;
    }
    if(data.opt.sliceLayer){
      this.sliceLayer = data.opt.sliceLayer;
    }
    if(data.opt.extrusionType){
      this.extrusionType = data.opt.extrusionType;
    }
    if(data.opt.angle){
      this.angle = data.opt.angle;
    }
    if(data.opt.startAngle){
      this.angle = data.opt.startAngle;
    }
    if(data.opt.fn){
      this.fn = data.opt.fn;
    }
    if(data.opt.twist){
      this.twist = data.opt.twist;
    }
    if(data.opt.perimeter_extrude_crossection){
      this.perimeter_extrude_crossection = data.opt.perimeter_extrude_crossection;
    }
    if(data.opt.curvPancake){
      this.curvPancake = data.opt.curvPancake;
    }
    this.updateConfig(data.opt.height);
  }
  setUpCP() {
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['z'].initControlVar(this.zVar);
  }
  updateCP() {
    const self = this;

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x);
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction);
    };
    this.updateConfig(this.height);
  }
  _initVariables() {
    const self = this;
    // p.y
    const psetHf = function(value) { self.height = (Number(value)); };
    const pgetHf = function() { return self.height; };
    this.zVar = this.variableSet.addVariable('height', psetHf, pgetHf, 'linear');

    const psetTf = function(value) { self.twist = (Number(value)); };
    const pgetTf = function() { return self.twist; };
    this.variableSet.addVariable('twist', psetTf, pgetTf, 'linear');

    const psetSf = function(value) { self.slices = (Number(value)); };
    const pgetSf = function() { return self.slices; };
    this.variableSet.addVariable('slices', psetSf, pgetSf, 'linear');

    const psetFf = function(value) { self.fn = (Number(value)); };
    const pgetFf = function() { return self.fn; };
    this.variableSet.addVariable('fn', psetFf, pgetFf, 'linear');

    const psetSAf = function(value) { self.startAngle = (Number(value)); };
    const pgetSAf = function() { return self.startAngle; };
    this.variableSet.addVariable('startAngle', psetSAf, pgetSAf, 'linear');

    const psetAf = function(value) { self.angle = (Number(value)); };
    const pgetAf = function() { return self.angle; };
    this.variableSet.addVariable('angle', psetAf, pgetAf, 'linear');
  }
  updateConfig(z = null) {
    z = z ? z : this.height;
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  dependencyUpdate() {
    this.height= this.cp['z'].propertyValue;
  }
  setExtrusionType(type) {
    this.extrusionType = type;
  }
  childUpdated() {
    //this.updateCSG(true, false);
  }
}
class Shape2D extends Obj {
  PT = [];
  cpPT = [];
  rawPT = [[0,0],[100,0],[100,100],[0,100]];
  ptEXP = {};
  LINE = [];
  shapeType = 'spline'; // spline
  curveType = '';
  curveClosed = true;
  tension = 0.5;
  res = 50;
  shape;
  shapeMesh;
  shapeGeometry;
  loaded = false;
  _save(obj) {
    const self = this;
    let ptSet = {};
    let ptEXP = {};
    this.cpPT.forEach( (pt,i,arr) => {
      ptSet['_'+i+''] = [self.cpPT[i].G.position.x, self.cpPT[i].G.position.y];
      ptEXP['_'+i+''] = [self.cpPT[i].x.exp, self.cpPT[i].y.exp];
    });
    const t = {e: this.G.matrix.clone().elements};
    const opt = {x: this.G.position.x, y: this.G.position.y,
      ptSet: ptSet,
      ptEXP: ptEXP,
      shapeType: this.shapeType,
      res: this.res,
      tension: this.tension,
      curveType: this.curveType,
      curveClosed: this.curveClosed};
    return {...obj,
      opt,
      t
    };
  }
  _load(data) {
    this.shapeType = data.opt.shapeType;
    this.res = data.opt.res;
    this.tension = data.opt.tension;
    this.curveType = data.opt.curveType;
    this.curveClosed = data.opt.curveClosed;
    this.ptEXP = data.opt.ptEXP;
    this.cpPT = [];

    console.log(data.opt);

    Object.keys(data.opt.ptSet).forEach( (key,i,arr) => {
      let pt = data.opt.ptSet[key];
      let number = key.match(/\d+/)[0];
      const PT = new Point2D(this, this.WS);
      PT.n = number;
      PT.setPosition(pt[0], pt[1]);

      if( this.ptEXP.hasOwnProperty('_'+i) ){

        if(this.ptEXP['_'+i][0]){
          //PT.x.exp = this.ptEXP['_'+i][0];
        }
        if(this.ptEXP['_'+i][1]){
          //PT.y.exp = this.ptEXP['_'+i][1];
        }
      }
      this.cpPT[number] = PT;
    });

    this.childUpdated();
  }

  onFirstLoad(finalObj) {
    this.loaded = true;
    this.setPtExp();
  }
  setPtExp() {
    this.rawPT.forEach((pt, i, arr) => {
      if (this.ptEXP.hasOwnProperty('_' + i)) {
        this.cpPT[i].x.exp = this.ptEXP['_' + i][0];
        this.cpPT[i].y.exp = this.ptEXP['_' + i][1];
      }
    });
  }

  constructor( parent, WS, uid = null ) {
    super('shape2d', parent, v3(), WS, uid);
    this.name = 's2d' + Object.keys(WS.objects).length;
    const geo = new THREE.SphereGeometry( 10, 1, 1 );
    this.setGeometry(geo);
  }
  setVariable() {
    this.childUpdated();
  }
  setCurveType(type) {
    this.curveType = type;
    this.childUpdated();
  }
  setShapeType(type) {
    this.shapeType = type;
    this.childUpdated();
  }
  init() {
    const self = this;
    this.mesh = new THREE.Mesh(new THREE.SphereGeometry( 3, 3, 3 ), material.clone());
    this.shapeGeometry = new THREE.Geometry();
    this.shapeMesh = new THREE.Mesh(this.shapeGeometry, material.clone());
    this.G.add(this.mesh);
    this.G.add(this.shapeMesh);
    this.G.name = this.type;
    setObjRef(this.mesh, self);
    setObjRef(this.G, self);
    this.initVariables();
  }
  initVariables() {
    const self = this;
    this.variableSet.enabled = true;
    // p.x
    const psetXf = function(value) { self.G.position.setX(Number(value)); };
    const pgetXf = function() { return self.G.position.x; };
    this.variableSet.addVariable('x', psetXf, pgetXf, 'p');
    // p.y
    const psetYf = function(value) { self.G.position.setY(Number(value)); };
    const pgetYf = function() { return self.G.position.y; };
    this.variableSet.addVariable('y', psetYf, pgetYf, 'p');

    const psetTf = function(value) { self.tension = Number(value); };
    const pgetTf = function() { return self.tension; };
    this.variableSet.addVariable('tension', psetTf, pgetTf, 'spline');

    const psetRf = function(value) { self.res = Number(value); };
    const pgetRf = function() { return self.res; };
    this.variableSet.addVariable('res', psetRf, pgetRf, 'spline');

    const psetCf = function(value) { self.curveClosed = value; };
    const pgetCf = function() { return self.curveClosed; };
    this.variableSet.addVariable('curveClosed', psetCf, pgetCf, 'shape', 'boolean');
    this._initVariables();



  }
  updateRawPt(ptSet){
    const self = this;
    let cList = [];
    this.rawPT = ptSet;

    this.children.forEach(c => {
      if(c.type == 'point2d'){
        cList.push(c);
      }
    });
    cList.forEach(c => {
      c.remove();
    });
    this.cpPT = [];

    this.rawPT.forEach((pt,i,arr) => {
      let PT;
      PT = new Point2D(this, this.WS);
      PT.n = i;
      PT.setPosition(pt[0], pt[1]);
      if( this.ptEXP.hasOwnProperty('_'+i) ){
        PT.x.exp = this.ptEXP['_'+i][0];
        PT.y.exp = this.ptEXP['_'+i][1];
      }
      this.cpPT[i] = PT;
    });
    this.childUpdated();
  }
  updateSegment() {
    const self = this;
    self.PT = [];
    self.rawPT = [];
    if (this.shapeType === 'line') {
      this.children.forEach(pt => {
        if (pt.type === 'point2d') {
          const pt2 = new THREE.Vector2(pt.G.position.x, pt.G.position.y);
          self.PT[pt.n] = pt2;
          self.rawPT[pt.n] = [pt.G.position.x, pt.G.position.y];
        }
      });
      self.PT.push(self.PT[0]);
    } else if (this.shapeType === 'spline') {
      let cp = [];
      this.children.forEach(pt => {
        if (pt.type === 'point2d') {
          const pt2 = new THREE.Vector2(pt.G.position.x, pt.G.position.y);
          self.rawPT[pt.n] = [pt.G.position.x, pt.G.position.y];
          cp[pt.n] = pt2;

        }
      });
      try {
        const curve = new THREE.CatmullRomCurve3(cp, this.curveClosed, this.curveType, this.tension);
        this.PT = curve.getPoints( this.res );
      } catch (e){
        console.log(e);
      }
    }



    this.shape = new THREE.Shape(self.PT);
    this.shapeGeometry = new THREE.ShapeGeometry(this.shape);
    this.shapeMesh.geometry.dispose();
    this.shapeMesh.geometry = this.shapeGeometry;
  }
  setTransform(mode = null) {
    const self = this;
    this.transformOpt.mode = 'p';
    self.transformOpt.axis = [true, true, false];
    configTransform(self.WS.transformControls, this.transformOpt);
  }
  childUpdated() {
    this.updateSegment();
    if (this.parent && this.parent.type === 'csg') {
      this.parent.updateCSG(true, false);
    }
  }
  remove(userInit = false) {
    const self = this;
    const cList = [];
    const parent = self.parent;
    this.children.forEach(c => {
      cList.push(c);
    });
    cList.forEach(c => {
      c.remove();
    });
    this.WS.transformControls.detach();

    this.dependencies.forEach(dep => {
      removeFromArr(this, dep.dependencies);
      if (dep.pointSet) {
        removeFromArr(self, dep.ptSet);
        dep.dependencyUpdate();
      } else {
        dep.remove();
      }
    });
    this.mesh.parent.remove(this.mesh);
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.mesh = undefined;

    this.shapeMesh.parent.remove(this.shapeMesh);
    this.shapeMesh.geometry.dispose();
    this.shapeMesh.material.dispose();
    this.shapeMesh = undefined;

    this.shape = undefined;

    this.G.parent.remove(this.G);
    this.G = undefined;

    this._destroy(userInit);
    if (parent && parent.type === 'csg' && userInit) {
      parent.updateCSG();
    }
  }

  _update() {
    this.dependencies.forEach( dep => {
      dep.dependencyUpdate();
    });
    if (this.parent && this.parent.type === 'csg') {
      this.parent.updateCSG(true, false);
    }
  }
  onTransformMouseChange(e) {
    //console.log('change');
  }
  onTransformMouseUp(e) {
    //console.log('change');
    this._update();
    this.update();
  }
}
class Point2D extends Obj {
  n;
  x;
  y;
  constructor(parent, WS, uid = null ) {
    super('point2d', parent, v3(), WS, uid);
    this.name = parent.name+'_p2d_' + Object.keys(this.parent.children).length;
    const geo = new THREE.SphereGeometry( WS?.AppState?.ui?.cpScale, 3, 3 );
    this.setGeometry(geo);
  }

  click(pickedObjectIntersection = null){ //prevent selection
    this.setSelectedObj();
    if(this.I.state.value == 'edit' && (this.WS.selectedObj.uid === this.uid)){
      this.I.send('view');
      this.I.send('edit');
    } else if (this.I.state.value == 'view') {
      this.I.send('edit');
    } else {
      this.I.send('view');
    }
  }
  DBclick(pickedObjectIntersection = null){
    this.I.send('select');
  }
  setVariable() {
    this.childUpdated();
  }
  setPosition(x,y) {
    this.G.position.set(x,y,0);
  }
  setTransform(mode = null) {
    const self = this;
    this.transformOpt.mode = 'p';
    let AxisFlags = [!Boolean(self.x.exp), !Boolean(self.y.exp), false];
    self.transformOpt.axis = AxisFlags;
    configTransform(self.WS.transformControls, this.transformOpt);
  }
  init() {
    const self = this;
    this.mesh = new THREE.Mesh(new THREE.SphereGeometry( 3, 3, 3 ), material.clone());
    this.G.add(this.mesh);
    this.G.name = this.type;
    setObjRef(this.mesh, self);
    setObjRef(this.G, self);
    this.initVariables();
  }
  initVariables() {
    const self = this;
    this.variableSet.enabled = true;
    // p.x
    const psetXf = function(value) { self.G.position.setX(Number(value)); self.dependencyUpdate(); self.update(); self._update(); };
    const pgetXf = function() { return self.G.position.x; };
    this.x = this.variableSet.addVariable('x', psetXf, pgetXf, 'p');
    // p.y
    const psetYf = function(value) { self.G.position.setY(Number(value)); self.dependencyUpdate(); self.update(); self._update(); };
    const pgetYf = function() { return self.G.position.y; };
    this.y = this.variableSet.addVariable('y', psetYf, pgetYf, 'p');
    this._initVariables();
  }
  onTransformMouseUp(e) {
    // console.log(e);
    this.G.position.setZ(0);
    this.update();
    this._update();
  }
  onTransformMouseDown(e) {
    //console.log('mouse down');
  }
  onTransformMouseChange(e) {
    //console.log('change');
  }
  remove(userInit = false) {
    const self = this;
    const cList = [];
    const parent = self.parent;
    this.children.forEach(c => {
      cList.push(c);
    });
    this.WS.transformControls.detach();
    this.dependencies.forEach(dep => {
      removeFromArr(this, dep.dependencies);
      if (dep.pointSet) {
        removeFromArr(self, dep.ptSet);
        dep.dependencyUpdate();
      } else {
        dep.remove();
      }
    });
    this.mesh.parent.remove(this.mesh);
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.mesh = undefined;

    this.G.parent.remove(this.G);
    this.G = undefined;
    this._destroy(userInit);
    if (parent && parent.type === 'csg' && userInit) {
      parent.updateCSG();
    }
  }
}

class CodeMaker2D extends CSGobj {
  code = ' ';
  codeType = 'jsCad';
  lang = 'js';
  scopeV = [];
  scopeObj = {};

  snippet;

  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    this.csgType = 'codemaker2d';
    this.scopeV = [];
    this.name = 'codeM' + Object.keys(WS.objects).length;
  }
  setTransform(mode = null) {
    const self = this;
    this.transformOpt.mode = 'p';
    self.transformOpt.axis = [true, true, false];
    configTransform(self.WS.transformControls, this.transformOpt);
  }
  _save(obj) {
    const self = this;
    const csgChild = [];
    const varList = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    if ( self.scopeV ) {
      self.scopeV.forEach(v => {
        varList.push({ name: v.name, value: v.get(), exp: v.exp});
      });
    }
    const opt = {
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      code: this.code,
      codeType: this.codeType,
      lang: this.lang,
      sV: varList
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data): any {
    const self = this;
    this.code = data.opt.code;
    this.codeType = data.opt.codeType;
    this.lang = data.opt.lang;
    this.loadScopeVar(data.opt.sV);
  }
  loadScopeVar(sV) {
    const self = this;
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }

  _initVariables() {
    // s.x
  }
}
class jsCadV2 extends CSGobj {
  code = ' ';
  codeType = 'jsCad';
  lang = 'js';
  scopeV = [];
  scopeObj = {};

  x = 10;
  y = 10;
  z = 10;
  centerX = 1;
  centerY = 1;
  centerZ = 1;

  snippet;
  xVar;
  yVar;
  zVar;

  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    this.csgType = 'jsCadV2';
    this.scopeV = [];
    this.name = 'codeM_jsCadv2' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['z'].initControlVar(this.zVar);
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (1 + self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (1 + self.centerX);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar(x / (1 + self.centerY));
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * (1 + self.centerY);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (1 + self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (1 + self.centerZ);
    };
    this.updateConfig(this.x, this.y, this.z);
  }
  updateConfig(x = null, y = null, z = null, centerX = null, centerY = null, centerZ = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.BoxGeometry(this.x, this.y, this.z );
    //this.geometry.translate((1 - this.centerX) * this.x / 2, (1 - this.centerY) * this.y / 2, (1 - this.centerZ) * this.z / 2);
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
  }


  _save(obj) {
    const self = this;
    const csgChild = [];
    const varList = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    if ( self.scopeV ) {
      self.scopeV.forEach(v => {
        varList.push({ name: v.name, value: v.get(), exp: v.exp});
      });
    }
    const opt = {
      x: this.x, y: this.y, z: this.z,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      code: this.code,
      codeType: this.codeType,
      lang: this.lang,
      sV: varList
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data): any {
    const self = this;
    this.code = data.opt.code;
    this.codeType = data.opt.codeType;
    this.lang = data.opt.lang;
    this.loadScopeVar(data.opt.sV);
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC);
  }
  loadScopeVar(sV) {
    const self = this;
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      return false;
    } else {
      self.scopeObj[name] = 0;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
  loadSnippet(snippet) {
    this.code = snippet.code;
    this.loadScopeVar(snippet.variables);
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

  insertSnippet(snippet) {
    this.code += `\r
    //////////---${snippet.name} \r
    ${snippet.code} \r
    //////////---`;
    this.loadScopeVar(snippet.variables);
  }

  _initVariables() {
    // s.x Size
    const self = this;

    // size
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');

    // center
    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = Number(value); self.cp['y'].setValue(v3(), true, self.y); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');
  }
}

// bad---------------------------------------------
class Construct extends CSGobj {
  constructID = '';
  partData;
  partOpt;
  url = 'https://firebasestorage.googleapis.com/v0/b/genweb-2a5f6.appspot.com/o/csgOBJ%2F_udsilbv86.csg?alt=media&token=85e48765-6f70-4e19-b033-eed385aa919c';
  partID = '';
  reloadID = '';
  category = '';
  subPart = '';
  varList;
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType,
      cID: this.constructID, csgChild, url: this.url, partID:this.partID,
      reloadID: this.reloadID, subPart: this.subPart, category: this.category};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.constructID = data.opt.cID;
    this.url = data.opt.url;
    this.partID = data.opt.partID;
    this.reloadID = data.opt.reloadID;
    this.subPart = data.opt.subPart;
    this.category = data.opt.category;

    this.reloadDB();
    //this.loadDB();
  }
  selectURL(id,url){
    this.partID = id;
    this.url = url;
    this.reloadDB();
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'construct';
    this.csgOp = true;
    this.name = 'Con' + Object.keys(WS.objects).length;
  }
  sub = [];
  varSet;
  loadDB() {
    const fs = this.WS.livePart.fs;
    const ref = fs.collection('p').doc(this.constructID);
    const store = ref.valueChanges();
    store.pipe().subscribe(d => {
      this.partData = d['data'];
      this.partOpt = d['opt'];

      const cList = [];
      this.children.forEach(c => {
        cList.push(c);
      });
      cList.forEach(c => {
        c.remove();
      });
      this.WS.loadRefData(this.partData, this.partOpt, this);
      this.updateCSG(true, true);
    });
  }
  reloadDB() {
    this.sub.forEach(s => {
      if(s.unsubscibe) {
        s.unsubscibe();
      }
    });
    this.sub = [];
    if(this.partID == ''){
      return;
    }
    const fs = this.WS.livePart.fs;
    const ref = fs.collection('p').doc(this.partID);
    const store = ref.valueChanges();
    this.sub.push(store.pipe().subscribe(d => {
      console.log(d);
      this.varSet = d['opt'].varSet;
      this.resetScopeVar();
      this.loadScopeVar(this.varSet);
      //console.log(d);
      this.reloadID = ID();
      this.updateCSG(true, false);
      this.nickName = d['name'];
    }));
  }
  loadScopeVar(sV) {
    const self = this;
    if (sV) {
      sV.forEach(v => {
        self.addScopeVar(v.name, v.value, v.exp);
      });
    }
  }
  scopeObj = {};
  scopeSet = {};
  scopeV = [];
  resetScopeVar() { // reset scope variable
    //TODO: remove scope variable
    const self = this;
  }
  addScopeVar(name, value, exp = '') {
    const self = this;
    if (self.scopeObj.hasOwnProperty(name)) {
      //console.log()
      self.scopeSet[name].exp = exp;
      self.scopeSet[name].set(exp);
      return false;
    } else {
      self.scopeObj[name] = undefined;
      // s.z
      const rsetf = function(value) { self.scopeObj[name] = Number(value); self.dependencyUpdate(); };
      const rgetf = function() { return self.scopeObj[name]; };
      const v = this.variableSet.addVariable(name, rsetf, rgetf, 'scope');
      self.scopeSet[name] = v;
      v.set(value);
      v.exp = exp;
      this.scopeV.push(v);
    }
  }
}
// bad---------------------------------------------

class Expand extends CSGobj {
    mX = 1;
    mY = 0;
    mZ = 0;
    copy = true;
    exType = 'mirror';
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild, exType:this.exType, mX:this.mX, mY:this.mY, mZ:this.mZ, copy:this.copy};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    if(data.opt.mX){
      this.mX = data.opt.mX;
    }
    if(data.opt.mY){
      this.mY = data.opt.mY;
    }
    if(data.opt.mZ){
      this.mZ = data.opt.mZ;
    }
    if(data.opt.copy === false){
      this.copy = data.opt.copy;
    }
    if(data.opt.exType){
      this.exType = data.opt.exType;
    }
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'expand';
    this.name = 'Ex' + Object.keys(WS.objects).length;
  }
  dependencyUpdate() {
    //this.geometry.dispose();
    //this.edgesGeometry.dispose();
    //this.geometry = new THREE.BoxGeometry(40, 40, 0.1);
    //this.geometry.lookAt(v3(this.mX,this.mY,this.mZ));
    //this.edgesGeometry = new THREE.EdgesGeometry(this.geometry);
    //this.mesh.geometry = this.edgesGeometry;
    //console.log(this.mesh);
  }
}
const saferEval = require('safer-eval');

class Union extends CSGobj {
  intersecting = true;
  uiCode = "";
  code = "";
  codeResult;
  context;
  snippet;
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, intersecting:this.intersecting,
      uiCode:this.uiCode, csgChild};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    this.shellOpt = data.opt.shell;
    if(data.opt.uiCode){
      this.uiCode = data.opt.uiCode;
    }
    if(data.opt.intersecting === false){
      this.intersecting = data.opt.intersecting;
    }
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'union';
    this.name = 'U' + Object.keys(WS.objects).length;
    this.centerHandle.material.color.set('#00a537')
  }
  dependencyUpdate() {
    //this.csgOutput.geometry.computeBoundingBox();
    //this.mesh.setFromObject(this.csgOutput);
  }
  async actionClick(pickedObjectIntersection = null) {
    this.context = {
      obj: this,
      WS: this.WS,
      vDB: this.WS.variableDB,
      S: this.WS.variableDB.scope,
      GS: this.WS.GS,
      P: this.WS.P,
      v3: v3,
      C: this.children,
      alert: alert
    };
    let code = 'async function r(){\n let res = 0; \n' + this.uiCode + '\n return res;}; \n r();';
    try {
      let r = saferEval(code, this.context);
      this.codeResult = r();
    } catch (e){
      return e;
      console.log(e);
      this.error = e;
    }
    console.log("action Click!",this.codeResult);
  }
  loadSnippet(snippet) {
    this.uiCode = snippet.code;
    this.snippet = snippet;
    this.updateCSG(true,false);
  }
  updateSnippet() {
    if (this.snippet) {
      this.WS.updateSnippetData(this, true);
    }
  }
  saveSnippet(name, category, description) {
    this.code = this.uiCode; // hack to save the UI code into the snippet.
    const data = {
      name,
      category,
      description,
      code: this.code,
      variables: this.save().opt.sV,
      owner: this.WS.livePart.userID
    };
    this.WS.updateSnippetData(this, false, data);
  }

}
class Difference extends CSGobj {
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'difference';
    this.name = 'D' + Object.keys(WS.objects).length;
  }
}
class Intersection extends CSGobj {
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {csgOp: this.csgOp, csgType: this.csgType, csgChild};
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  constructor( parent, WS, uid = null ) {
    super(parent, WS, uid, true);
    const self = this;
    this.csgType = 'intersection';
    this.name = 'D' + Object.keys(WS.objects).length;
  }
}
class RepeatXYZ extends CSGobj {
  x = 10;
  y = 10;
  z = 10;
  intersecting = true;
  nX = 1;
  nY = 1;
  nZ = 1;
  centerX = 1;
  centerY = 1;
  centerZ = 1;
  xVar;
  yVar;
  zVar;
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      x: this.x, y: this.y, z: this.z,
      xC: this.centerX, yC: this.centerY, zC: this.centerZ,
      xN: this.nX, yN: this.nY, zN: this.nZ, intersecting: this.intersecting
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    if(data.opt.intersecting === false){
      this.intersecting = data.opt.intersecting;
    }
    this.updateConfig(data.opt.x, data.opt.y, data.opt.z, data.opt.xC, data.opt.yC, data.opt.zC, data.opt.xN, data.opt.yN, data.opt.zN);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgOp = true;
    this.csgType = 'repeatxyz';
    this.name = 'Rxyz' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x Size
    const self = this;
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // s.z
    const setZf = function(value) { self.cp['z'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getZf = function() { return self.z; };
    this.zVar = this.variableSet.addVariable('z', setZf, getZf, 'size');

    // center
    const osetXf = function(value) { self.centerX = Number(value); self.cp['x'].setValue(v3(), true, self.x); self.dependencyUpdate(); };
    const ogetXf = function() { return self.centerX; };
    this.variableSet.addVariable('x', osetXf, ogetXf, 'offset', 'boolean');
    // s.y
    const osetYf = function(value) { self.centerY = Number(value); self.cp['y'].setValue(v3(), true, self.y); self.dependencyUpdate(); };
    const ogetYf = function() { return self.centerY; };
    this.variableSet.addVariable('y', osetYf, ogetYf, 'offset', 'boolean');
    // s.z
    const osetZf = function(value) { self.centerZ = Number(value); self.cp['z'].setValue(v3(), true, self.z); self.dependencyUpdate(); };
    const ogetZf = function() { return self.centerZ; };
    this.variableSet.addVariable('z', osetZf, ogetZf, 'offset', 'boolean');

    // round
    const rsetXf = function(value) { self.nX = value; self.dependencyUpdate(); };
    const rgetXf = function() { return self.nX; };
    this.variableSet.addVariable('nX', rsetXf, rgetXf, 'opt');

    const rsetYf = function(value) { self.nY = value; self.dependencyUpdate(); };
    const rgetYf = function() { return self.nY; };
    this.variableSet.addVariable('nY', rsetYf, rgetYf, 'opt');

    const rsetZf = function(value) { self.nZ = value; self.dependencyUpdate(); };
    const rgetZf = function() { return self.nZ; };
    this.variableSet.addVariable('nZ', rsetZf, rgetZf, 'opt');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#de0004'});
    this.addCP('y', { axis: [false, true, false], color: '#1b9f02'});
    this.addCP('z', { axis: [false, false, true], color: '#0028f1'});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['x'].cpSizeFactor = 1.5;
    const geo = new THREE.SphereGeometry(this.WS?.AppState?.ui?.cpScale*1.5,4,4);//new THREE.SphereGeometry( 1, 9, 3 );
    this.cp['x'].setGeometry(geo);
    this.cp['y'].initControlVar(this.yVar);
    this.cp['y'].setGeometry(geo);
    this.cp['z'].initControlVar(this.zVar);
    this.cp['z'].setGeometry(geo);
  }
  updateConfig(x = null, y = null, z = null, centerX = null, centerY = null, centerZ = null, nX = null, nY = null, nZ = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    z = z ? z : this.z;
    this.centerX = centerX === null ? this.centerX : centerX;
    this.centerY = centerY === null ? this.centerY : centerY;
    this.centerZ = centerZ === null ? this.centerZ : centerZ;

    this.nX = nX ? nX : this.nX;
    this.nY = nY ? nY : this.nY;
    this.nZ = nZ ? nZ : this.nZ;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.cp['z'].setValue(v3(), true, z);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x / (2 - self.centerX));
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction) * (2 - self.centerX);
    };

    this.cp['y'].direction.set(0, 1, 0);
    this.cp['y'].cpPosEq = function(x) {
      return self.cp['y'].direction.clone().multiplyScalar(x / (2 - self.centerY));
    };
    this.cp['y'].valueEq = function(x) {
      return self.cp['y'].position.clone().dot(self.cp['y'].direction) * (2 - self.centerY);
    };

    this.cp['z'].direction.set(0, 0, 1);
    this.cp['z'].cpPosEq = function(x) {
      return self.cp['z'].direction.clone().multiplyScalar(x / (2 - self.centerZ));
    };
    this.cp['z'].valueEq = function(x) {
      return self.cp['z'].position.clone().dot(self.cp['z'].direction) * (2 - self.centerZ);
    };
    this.updateConfig(this.x, this.y, this.z);
  }
  dependencyUpdate() {
    const self = this;
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
    this.z = this.cp['z'].propertyValue;

    if(self.nX > 1) {
      self.cp['x'].G.visible = true;
    }else{
      self.cp['x'].G.visible = false;
    }

    if(self.nY > 1) {
      self.cp['y'].G.visible = true;
    }else{
      self.cp['y'].G.visible = false;
    }

    if(self.nZ > 1) {
      self.cp['z'].G.visible = true;
    }else{
      self.cp['z'].G.visible = false;
    }

  }
}
class RepeatR extends CSGobj {
  x = 0;
  y = 180;
  nX = 2;
  intersecting = true;
  xVar;
  yVar;
  _save(obj) {
    const self = this;
    const csgChild = [];
    this.children.forEach(child => {
      if (child.csgType) {
        csgChild.push(child.save().gUIDf);
      }
    });
    const opt = {
      csgOp: this.csgOp, csgType: this.csgType, csgChild,
      x: this.x, y: this.y,
      xN: this.nX, intersecting: this.intersecting
    };
    const csgOpt = this._saveCSG(opt);
    return {...obj,
      ...csgOpt,
      opt,
    };
  }
  _load(data) {
    if(data.opt.intersecting === false){
      this.intersecting = data.opt.intersecting;
    }
    this.updateConfig(data.opt.x, data.opt.y, data.opt.xN);
  }
  constructor( parent, WS, uid = null) {
    super(parent, WS, uid, false);
    const self = this;
    this.csgOp = true;
    this.csgType = 'repeatr';
    this.name = 'Rr' + Object.keys(WS.objects).length;
    this.setUpCP();
    this.updateCP();
  }
  _initVariables() {
    // s.x Size
    const self = this;
    const setXf = function(value) { self.cp['x'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getXf = function() { return self.x; };
    this.xVar = this.variableSet.addVariable('x', setXf, getXf, 'size');

    // s.y
    const setYf = function(value) { self.cp['y'].setValue(v3(), true, Number(value)); self.dependencyUpdate(); };
    const getYf = function() { return self.y; };
    this.yVar = this.variableSet.addVariable('y', setYf, getYf, 'size');

    // round
    const rsetXf = function(value) { self.nX = Number(value); self.dependencyUpdate(); };
    const rgetXf = function() { return self.nX; };
    this.variableSet.addVariable('nX', rsetXf, rgetXf, 'opt');
  }
  setUpCP() {
    this.addCP('x', { axis: [true, false, false], color: '#db8c8c'});
    this.addCP('y', { axis: [true, true, false], color: '#81c268',angle: true});
    this.cp['x'].initControlVar(this.xVar);
    this.cp['y'].initControlVar(this.yVar);
  }
  updateConfig(x = null, y = null, nX = null) {
    x = x ? x : this.x;
    y = y ? y : this.y;
    this.nX = nX ? nX : this.nX;

    this.cp['x'].setValue(v3(), true, x);
    this.cp['y'].setValue(v3(), true, y);
    this.dependencyUpdate();
  }
  updateCP() {
    const self = this;
    //Update Linear control point
    this.cp['x'].direction.set(1, 0, 0);
    this.cp['x'].cpPosEq = function(x) {
      return self.cp['x'].direction.clone().multiplyScalar(x);
    };
    this.cp['x'].valueEq = function(x) {
      return self.cp['x'].position.clone().dot(self.cp['x'].direction);
    };
    //Update angular control point
    this.cp['y'].direction.set(1, 0, 0);
    this.cp['y'].cpPosEq = function(x) {
        return self.cp['y'].direction.clone().multiplyScalar(20 + self.x).applyAxisAngle(v3(0, 0, 1), x * Math.PI / 180);
    };
    this.cp['y'].valueEq = function(x) {
      if(self.cp['y'].position.y >= 0) {
      return Math.round(self.cp['y'].position.clone().angleTo(self.cp['y'].direction) * 180 / Math.PI);
      }else{ //if in the negative plane, we are past 180 degrees
        return Math.round(360 - self.cp['y'].position.clone().angleTo(self.cp['y'].direction) * 180 / Math.PI);
      }
    };
    this.updateConfig(this.x);
  }
  dependencyUpdate() {
    this.x = this.cp['x'].propertyValue;
    this.y = this.cp['y'].propertyValue;
  }
}

// transform config
// configTransform(t, {mode:r, axis:[true,true,true], size:1, snap:1, space: 'local'})
function configTransform(t, opt) {
  t.showX = opt.axis[0];
  t.showY = opt.axis[1];
  t.showZ = opt.axis[2];
  t.setSize(opt.size);
  t.space = opt.space;

  switch (opt.mode) {
    case 'r':
      t.setMode('rotate');
      t.setRotationSnap(opt.snap * 0.0174533);
      break;
    case 'p':
      t.setMode('translate');
      t.setTranslationSnap( opt.snap );
      break;
    case 's':
      t.setMode('scale');
      break;
  }
}

// mesh functions
function setObjRef(mesh, obj) {
  mesh.userData = {
    uid: obj.uid
  };
}
function jsCSGtoMesh(mesh, csg) {
  mesh.geometry.dispose();
  mesh.geometry = threeCSG.toMesh(threeCSG.fromJSCADcsg(csg), new THREE.Matrix4(), true);
  mesh.geometry.computeFaceNormals();
  mesh.geometry.computeVertexNormals();
}
function setMaterial(mesh, opacity = 1, color = null, renderOrder = 1, emissive = '#000000', wf = false, side = 'f') {
  if (opacity === 1) {
    mesh.material.transparent = true;
    mesh.material.opacity = opacity;
    mesh.visible = true;
  } else if (opacity > 0) {
    mesh.material.transparent = true;
    mesh.material.opacity = opacity;
    mesh.visible = true;
  }
  if(renderOrder){
    mesh.material.depthTest = true;
    mesh.material.wireframe = wf;
    mesh.material.polygonOffset = true;
    mesh.material.polygonOffsetFactor = -1 * renderOrder + 0.5;
    mesh.material.polygonOffsetUnits = 0.1;
    mesh.material.needsUpdate = true;
    mesh.renderOrder = renderOrder;
  }
  if (color) {
    mesh.material.color.set(color);
  }
  if (side === 'f') {
    mesh.material.side = THREE.FrontSide;
  } else if (side === 'b') {
    mesh.material.side = THREE.BackSide;
  } else if (side) {
    mesh.material.side = THREE.DoubleSide;
  }
  if (emissive && mesh.material.emissive) {
    mesh.material.emissive.set(emissive);
  }
}
function exportGLTF(anim, mesh, name) {
  const gltfExporter = new GLTFExporter();

  let options;
  if(anim?.animations){
    options = {
      binary: true,
      animations: anim.animations,
    };
    emptyGroup(anim);
    anim.add(mesh);
  } else {
    options = {
      binary: true
    };
    emptyGroup(anim);
    anim.add(mesh);
  }
  gltfExporter.parse(anim, function (result) {

    if (result instanceof ArrayBuffer) {
      saveArrayBuffer(result, name+'.glb');
    } else {
      const output = JSON.stringify(result, null, 2);
      saveString2(output, name+'.gltf');
    }

  }, options);
}

// Export functions
function exportSTL(mesh, name) {
  const result = exporterSTL.parse(mesh);
  return saveString( result, name + '.stl' );

}
function exportBlob(blob, name, ending = 'txt') {
  return saveString( blob, name + '.' + ending );

}
function exportCSGcb(cb, name) {
  let str = JSON.stringify(TSON.encapsulate(cb), null, 2);
  let deflate = pako.deflate(str,{ level:9, to: 'string' });
  saveString( deflate, name + '.csg' );
}

function exportCSG(mesh) {
  const result = exporterSTL.parse(mesh, { binary: true });
  let geom;
  if ( mesh.geometry.isBufferGeometry ) {
    geom = new THREE.Geometry().fromBufferGeometry(mesh.geometry);
  } else {
    geom = mesh.geometry.clone();
  }
  let fs = geom.faces;
  let vs = geom.vertices;
  let polys = [];
  let fm = ['a','b','c'];
  for (let i=0; i < fs.length; i++) {
    let f = fs[i];
    let vertices = [];
    for (let j = 0; j < 3; j++) {
      vertices.push(new CSG.Vertex(new CSG.Vector3D(vs[f[fm[j]]])));
      // new Vertex(vs[f[fm[j]]],f.vertexNormals[j],geom.faceVertexUvs[0][i][j]));
    }
    polys.push(new CSG.Polygon(vertices));
  }
  return CSG.fromPolygons(polys);
}
function saveGLB( text, filename ) {

  save( new Blob( [ text ], { type: 'application/octet-stream' } ), filename );

}

function save(blob, filename ) {
  const link = document.createElement( 'a' );
  link.style.display = 'none';
  link.href = URL.createObjectURL( blob );
  link.download = filename;
  document.body.appendChild( link );
  link.click();
  return blob;
}
function saveString( text, filename ) {
  return save( new Blob( [ text ], { type: 'text/plain' } ), filename );
}
function saveString2( text, filename ) {

  save( new Blob( [ text ], { type: 'model/gltf+json' } ), filename );

}
function saveArrayBuffer( buffer, filename ) { //anf GLB

  save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

}
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    //console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    //console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
function emptyGroup(group){
  for (let i = group.children.length - 1; i >= 0; i--) {
    if(group.children[i].type === "Mesh")
      group.remove(group.children[i]);
      disposeMesh(group.children[i]);
  }
}
function disposeMesh(mesh,material=1,geometry=1){
  mesh?.parent?.remove(mesh);

  if(material)
    mesh?.material?.dispose();

  if(geometry)
    mesh?.geometry?.dispose();

  mesh = undefined; //clear any reference for it to be able to garbage collected
}
