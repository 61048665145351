import * as THREE from 'three';
import Typeson from 'typeson';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

export function makeNftUrlCid(cid,ending){
  return 'https://'+cid+'.ipfs.dweb.link/'+ending;
}

export function test(){
  return 1;
}
export function ID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
}

//Arrays
export function pushUnique(item, array) {
  if (array.indexOf(item) === -1) {
    array.push(item);
    return true;
  } else {
    return false;
  }
}
export function removeFromArr(item, array) {
  if (array) {
    const id = array.indexOf(item);
    if (id > -1) {
      array.splice(id, 1);
      return true;
    } else {
      console.log('unable to remove, item not found',id, item);
      return false;
    }
  } else {
    console.log('unable to remove', item);
    return false;
  }
}


//Obj
export function deepEqual(x, y) {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
    ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

//performance
export function timeFunction(f,log = false) {
  const t0 = performance.now();
  f();
  const t1 = performance.now();
  let td = (t1 - t0);
  if (log) {console.log('function took: ' + td + ' ms.');}
  return td;
}

//Math
export function round(num, decimal) {
  return Math.round(num * Math.pow(10, decimal) ) / Math.pow(10, decimal);
}
export function d2r(deg) {
  return deg * Math.PI / 180;
}
export function r2d(rad) {
  return rad  * 180 / Math.PI;
}


//Files


//
export function timeToStr(time) {
  let result = "";
  result += time.getFullYear() + "-";
  result += (time.getMonth() + 1) + "-";
  result += time.getDate() + "T";
  result += time.getHours() + ":";
  result += time.getMinutes() + ":";
  result += time.getSeconds();
  return result;
}
export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
export function removeEmpty(obj) {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};
export function getremoveEmpty(obj) {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
    else {
      console.log('undefined',key,obj[key]);
    }
  });
  return newObj;
};
export class nftStore {
  api_key = '';
  constructor() {
    this.api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDY2QkNjYUQwNUYwRDQ3MmM0ODQ3YzVDZjQyRGU5MmY1NzQyNmI5ZjMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzNzIwNTYwNDE4NiwibmFtZSI6Im1haW4ifQ.BVVWsnHl7FNqWTL5-oT4d0ZdtXLnM3JgYoLS_1saTyc';
  }
  storeSTL(files: any[]): Promise<any> {
    return new Promise((resolve, reject) => {
        if(files.length > 0) {
          let FormData = this.prepareFilesFormData(files);
          //use uploadNFT to upload all files
          this.uploadNFT(FormData).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject('no files');
        }
      }
    );
  }
  convertBlobToFile(oldBlob, fileName, fileExtension = 'stl') {
    let fileType = 'text/plain';
    if (fileExtension === 'stl') {
      fileType = 'application/stl';
    } else if (fileExtension === 'obj') {
      fileType = 'application/obj';
    } else if (fileExtension === 'gltf') {
      fileType = 'application/gltf';
    } else if (fileExtension === 'glb') {
      fileType = 'application/glb';
    } else if (fileExtension === 'zip') {
      fileType = 'application/zip';
    } else {
      fileType = 'text/plain';  // default
    }
    let newblob = new Blob([oldBlob], {type: fileType});
    let fileNameWithExtension = fileName + '.' + fileExtension;
    let fileNameWithOutExtension = fileName;
    let fileSize = newblob.size;
    let newfile = new File([newblob], fileNameWithExtension, {type: fileType});
    return newfile;
  }
  convertJsonToFile(objData, fileName) {
    let json = JSON.stringify(objData, null, 2);
    let blob = new Blob([json], {type: 'application/json'});
    let datanewfile = new File([blob], fileName+'.json', {type: 'application/json'});
    return datanewfile;
  }
  prepareFilesFormData(files) {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return formData;
  }
  // check if ntf exists as a promise
  checkNFT(cid) : Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.nft.storage/check/' + cid);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.api_key);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.send();
    });
  };
  // get nft as a promise
  getNFT(cid) : Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.nft.storage/' + cid);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.api_key);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }
  // upload nft as a promise
  uploadNFT(nft) : Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', 'https://api.nft.storage/upload');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.api_key);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.send(nft);
    });
  }
  // delete nft as a promise
  deleteNFT(cid) : Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('DELETE', 'https://api.nft.storage/delete/' + cid);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.api_key);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }
  // get list of all nft as a promise
  getNFTList() : Promise<any> {
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', 'https://api.nft.storage/list');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.api_key);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      }
      xhr.send();
    });
  }
}


// utility functions
export function v3(x: any = 0 , y = 0, z = 0) {
  if ( x instanceof Array && x.length === 3) {
    return new THREE.Vector3(x[0], x[1], x[2]);
  } else if ( x instanceof Array && x.length === 2) {
    return new THREE.Vector3(x[0], x[1], 0);
  } else if ( x instanceof THREE.Vector3) {
    return x;
  } else if ( x instanceof Object) {
    // check if x has property x, y, z
    if (x.hasOwnProperty('x') && x.hasOwnProperty('y') && x.hasOwnProperty('z')) {
      return new THREE.Vector3(x.x, x.y, x.z);
    } else if (x.hasOwnProperty('x') && x.hasOwnProperty('y')) {
      return new THREE.Vector3(x.x, x.y, 0);
    }
    // check if x has property _x, _y, _z
    if (x.hasOwnProperty('_x') && x.hasOwnProperty('_y') && x.hasOwnProperty('_z')) {
      return new THREE.Vector3(x._x, x._y, x._z);
    } else if (x.hasOwnProperty('_x') && x.hasOwnProperty('_y')) {
      return new THREE.Vector3(x._x, x._y, 0);
    }
  } else {
    return new THREE.Vector3(x, y, z);
  }
}
export function v3toArr(v) {
  if (v) {
    return [round(v.x, 3), round(v.y, 3), round(v.z, 3)];
  } else {
    return [0, 0, 0];
  }

}
export function arrtoV3(arr) {
  if (arr) {
    return new THREE.Vector3(arr[0], arr[1], arr[2]);
  } else {
    return new THREE.Vector3();
  }

}
export function toggle(int) {
  if (int === 0 ){
    return 1;
  } else {
    return 0;
  }
}
export function flipB(i) {
  let bool  = Boolean(i);
  if (bool) {
    return 0;
  } else {
    return 1;
  }
}
export function timeF(f) {
  const t0 = performance.now();
  f();
  const t1 = performance.now();
  console.log('function took: ' + (t1 - t0) + ' ms.');
}
export function faceCam(camera,obj,from) {

  let rotMat = new THREE.Matrix4();
  let worldMat = obj.matrixWorld.clone();

  //rotMat.makeRotationFromQuaternion(camera.quaternion.clone());
  //worldMat.premultiply( rotMat );

  // Affecting the scale not the rotation O_O
  //obj.setRotationFromMatrix(worldMat);
  obj.position.setFromMatrixPosition(from.matrixWorld.clone());


}


function removeMesh(mesh) {
  mesh.parent.remove(mesh);
  mesh.geometry.dispose();
  mesh.material.dispose();
}
export function castCompactCSG(OldCsg) {
  const parsed = JSON.parse(OldCsg);
// Revive back again:
  const revived = TSON.revive(parsed);
  return revived;
}
export function saveCompactCSG(OldCsg){
  return JSON.stringify(TSON.encapsulate(OldCsg), null, 2);
}

