import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';

@Component({
  selector: 'rc-headline',
  template: `<div class="head-line" *ngIf="!content.edit">{{ root.text }}</div>
  <span *ngIf="content.edit"><input class="head-line" type="text" [(ngModel)]="root.text"></span>`
})
export class HeadlineComponent implements OnInit, ContentOnCreate {
  root;
  content;

  constructor() { }

  ngOnInit() {
  }

  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
  }
}
