import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentDocument} from '../../reactive-content';
import {DbService} from '../../services/db.service';
import {RootService, UserService} from '../../services/root.service';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit, OnDestroy {
  public index: ContentDocument;
  public current: ContentDocument;
  page$;
  pt = [[22,33],[32,31],[42,13],[11,31],[33,70]];
  userInfo;
  admin = false;
  item = '';
  routerSub;
  constructor(public db: DbService,
              public auth: AuthService,
              public root: RootService,
              public router: Router,
              private route: ActivatedRoute) {
    this.routerSub = this.route.params.subscribe(params => {
      this.item = params['name']; // (+) converts string 'name' to a number
    });
  }
  ngOnInit() {
    this.auth.user$.subscribe(u => {
      this.admin = this.root.checkAdmin(u.uid);
    });
    this.resetContent();
    this.getContent();
  }
  resetContent() {
    this.current = {
      edit: true,
      editShow: true,
      title: 'Loading content...',
      _type: 'page',
      _embedded: {
        headline: {
          text: 'Loading content...',
          _type: 'headline'
        }
      }
    };
  }
  getContent() {
    this.page$ = this.db.getPageData('item_' + this.item).subscribe(data => {
      if (data) {
        this.current = data;
      } else {
        console.log('no data');
      }
    });
  }
  updateContent() {
    this.db.updatePageData('item_' + this.item, this.current);
  }
  ngOnDestroy(): void {
    //this.page$.unsubscribe();
  }
}
