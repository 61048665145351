/* slicer.js */
var MCG;




var THREE = {};
// src is either one THREE.Mesh or an array of them

function Slicer(src, params, THREEjs) {
  MCG = {Math:{}, Sweep:{}, Boolean:{}, Infill:{}, Generate:{},
    Types:{
      context: 1,

      vector: 11,
      polygon: 12,
      segment: 13,

      abstractGeometrySet: 21,
      polygonSet: 22,
      segmentSet: 23,

      abstractAdjacencyMap: 31,
      directedAdjacencyMap: 32
    }
  };
  this.Modes = {
    preview: "preview",
    full: "full"
  };
  this.InfillTypes = {
    none: 0,
    solid: 1,
    lines: 2,
    grid: 4,
    triangles: 8,
    hex: 16,
    // mask for all infill types that consist of lines that don't need to be
    // connected to each other
    disconnectedLineType: 1 | 4 | 8
  };
  this.DefaultParams = {
    // base params
    axis: "z",
    layerHeight: 0.1,
    lineWidth: 0.1,
    precision: 5,
    mode: this.Modes.full,

    numWalls: 2,
    numTopLayers: 3,
    optimizeTopLayers: true,
    infillType: this.InfillTypes.none,
    infillDensity: 0.1,
    infillOverlap: 0.5,

    makeRaft: true,
    raftNumTopLayers: 3,
    raftTopLayerHeight: 0.05,
    raftTopLineWidth: 0.05,
    raftTopDensity: 1.0,
    raftNumBaseLayers: 1,
    raftBaseLayerHeight: 0.1,
    raftBaseLineWidth: 0.1,
    raftBaseDensity: 0.5,
    raftOffset: 1.0,
    raftGap: 0.05,
    raftWriteWalls: false,

    // display params; these determine how much to compute
    previewSliceMesh: false,
    fullUpToLayer: true,
    fullShowInfill: false
  };
  THREE = THREEjs;
  //params = this.DefaultParams;
  initMCG();
  initLayer();
  init();


  console.log(this);

  // set only the base parameters



  var meshes = isArray(src) ? src : [src];

  var sourceGeo = new THREE.Geometry();

  // merge the input meshes into the slicer's own copy of the source geometry
  for (var m = 0; m < meshes.length; m++) {
    var mesh = meshes[m];
    if (!mesh) continue;

    sourceGeo.merge(mesh.geometry, mesh.matrixWorld);
  }

  this.sourceGeo = sourceGeo;
  this.sourceVertexCount = sourceGeo.vertices.length;
  this.sourceFaceCount = sourceGeo.faces.length;

  // set only the base parameters - need these to calculate mesh bounds and
  // slice count
  this.setBaseParams(params);

  // 1. assume right-handed coords
  // 2. look along negative this.axis with the other axes pointing up and right
  // then this.ah points right and this.av points up
  this.ah = cycleAxis(this.axis);
  this.av = cycleAxis(this.ah);

  // calculate upper and lower bounds of all faces and the entire mesh
  this.calculateFaceBounds();

  // calculate number of slices
  this.calculateNumSlices();

  // init layers to null
  this.sliceLayers = null;
  this.raftLayers = null;

  // set the rest of the parameters
  this.updateParams(params);

  this.currentLevel = this.getMaxLevel();

  // contains the geometry objects that are shown on the screen
  this.geometries = {};
  this.makeGeometry();

  // construct the layers array, which contains the lazily constructed contours
  this.makeLayers();
  // construct the raft layers
  this.makeRaftLayers();

  this.setMode(this.mode);
}

function init() {

  Slicer.Modes = {
    preview: "preview",
    full: "full"
  };

  Slicer.InfillTypes = {
    none: 0,
    solid: 1,
    lines: 2,
    grid: 4,
    triangles: 8,
    hex: 16,
    // mask for all infill types that consist of lines that don't need to be
    // connected to each other
    disconnectedLineType: 1 | 4 | 8
  };


  Slicer.prototype.setBaseParams = function (params) {
    console.log(this);
    var defaults = params;

    this.axis = defaults.axis;
    this.layerHeight = defaults.layerHeight;
    this.lineWidth =  defaults.lineWidth;
    this.precision =  defaults.precision;

    this.mode = defaults.mode;
  }

  Slicer.prototype.setParams = function (params) {
    params = params || {};

    for (var p in Slicer.DefaultParams) {
      if (params.hasOwnProperty(p)) {
        this[p] = params[p];
      }
      else {
        this[p] = Slicer.DefaultParams[p];
      }
    }
  }


// update slicer params, handle the consequences of updating them, and return
// those that were updated
  Slicer.prototype.updateParams = function (params) {
    params = params || {};
    var defaults = Slicer.DefaultParams;
    var updated = {};

    for (var p in defaults) {
      var hasParam = params.hasOwnProperty(p);
      var val = undefined;

      // if no initial value set, get one from params if present, else, from
      // defaults
      if (this[p] === undefined) val = hasParam ? params[p] : defaults[p];
      // else, if initial value set, only update if present in params
      else if (hasParam) val = params[p];

      if (val !== undefined && this[p] !== val) {
        this[p] = val;
        updated[p] = val;
      }
    }

    this.handleUpdatedParams(updated);

    return updated;
  }

// if some params changed, they may require invalidating some existing data
// structures
  Slicer.prototype.handleUpdatedParams = function (params) {
    var raftUpdated = false;

    if (hasProp("numWalls")) {
      this.forEachSliceLayer(function (layer) {
        layer.unreadyWalls();
        layer.params.numWalls = params.numWalls;
      });
    }
    if (hasProp("numTopLayers") || hasProp("optimizeTopLayers")) {
      var numTopLayers = this.numTopLayers;
      this.forEachSliceLayer(function (layer) {
        layer.unreadyInfillContour();
        layer.params.numTopLayers = numTopLayers;
      });
    }
    if (hasProp("infillType")) {
      this.forEachSliceLayer(function (layer) {
        layer.unreadyInfill();
        layer.params.infillType = params.infillType;
      });
    }
    if (hasProp("infillDensity")) {
      if (this.infillDensity === 0) this.infillType = Slicer.InfillTypes.none;
      this.forEachSliceLayer(function (layer) {
        layer.unreadyInfill();
        layer.params.infillDensity = params.infillDensity;
      });
    }
    if (hasProp("infillOverlap")) {
      this.forEachSliceLayer(function (layer) {
        layer.unreadyInfillContour();
        layer.params.infillOverlap = params.infillOverlap;
      });
    }
    if (hasProp("makeRaft")
      || hasProp("raftNumTopLayers")
      || hasProp("raftNumBaseLayers")) {
      this.numRaftLayers = this.makeRaft ? this.raftNumBaseLayers + this.raftNumTopLayers : 0;
      raftUpdated = true;
    }
    if (hasProp("raftTopLayerHeight")
      || hasProp("raftTopDensity")
      || hasProp("raftBaseLayerHeight")
      || hasProp("raftBaseDensity")
      || hasProp("raftGap")
      || hasProp("raftOffset")) {
      raftUpdated = true;
    }

    if (raftUpdated) {
      this.calculateBaseline();
      this.floorToBaseline();
      this.makeRaftLayers();
    }

    function hasProp(name) {
      return params.hasOwnProperty(name);
    }
  }

// map a function to all slice layers
  Slicer.prototype.forEachSliceLayer = function (f) {
    if (!this.sliceLayers) return;

    for (var i = 0; i < this.sliceLayers.length; i++) {
      f(this.sliceLayers[i]);
    }
  }

// map a function to all raft layers
  Slicer.prototype.forEachRaftLayer = function (f) {
    if (!this.raftLayers) return;

    for (var i = 0; i < this.raftLayers.length; i++) {
      f(this.raftLayers[i]);
    }
  }

// map a function to all layers
  Slicer.prototype.forEachLayer = function (f) {
    this.forEachSliceLayer(f);
    this.forEachRaftLayer(f);
  }

// called from constructor, calculates min and max for every face on the axis
  Slicer.prototype.calculateFaceBounds = function () {
    var faceBounds = [];
    var axis = this.axis;
    var min = new THREE.Vector3().setScalar(Infinity);
    var max = new THREE.Vector3().setScalar(-Infinity);

    for (var i = 0; i < this.sourceFaceCount; i++) {
      var face = this.sourceGeo.faces[i];
      var bounds = faceGetBounds(face, this.sourceGeo.vertices);

      max.max(bounds.max);
      min.min(bounds.min);

      // store min and max for each face
      faceBounds.push({
        face: face.clone(),
        max: bounds.max[axis],
        min: bounds.min[axis]
      });
    }

    this.min = min;
    this.max = max;

    this.faceBoundsArray = faceBounds;
  }

  Slicer.prototype.calculateNumSlices = function () {
    // first slice is half a slice height above mesh min and last slice is
    // strictly above mesh, hence +1
    var amax = this.max[this.axis], amin = this.min[this.axis];
    this.numSlices = Math.floor(0.5 + (amax - amin) / this.layerHeight) + 1;
  }

// calculate the lowest boundary of the print, including the raft
  Slicer.prototype.calculateBaseline = function () {
    this.baseline = this.min[this.axis];
    if (this.makeRaft) {
      var raftTopHeight = this.raftTopLayerHeight * this.raftNumTopLayers;
      var raftBaseHeight = this.raftBaseLayerHeight * this.raftNumBaseLayers;
      this.baseline -= raftTopHeight + raftBaseHeight + this.raftGap;
    }
  }

  Slicer.prototype.floorToBaseline = function () {
    if (this.baseline === undefined) this.calculateBaseline();

    var axis = this.axis;
    var baseline = this.baseline;
    var sourceVertices = this.sourceGeo.vertices;
    var faceBounds = this.faceBoundsArray;

    // shift all vertices
    for (var i = 0; i < this.sourceVertexCount; i++) {
      sourceVertices[i][axis] -= baseline;
    }

    this.sourceGeo.verticesNeedUpdate = true;

    // shift all computed face bounds
    for (var i = 0; i < this.sourceFaceCount; i++) {
      var bounds = faceBounds[i];
      bounds.min -= baseline;
      bounds.max -= baseline;
    }

    // shift mesh AABB
    this.min[axis] -= baseline;
    this.max[axis] -= baseline;

    // shift the context calculated for each layer
    this.forEachLayer(function (layer) {
      layer.context.d -= baseline;
    });

    // b/c we just floored, the baseline is 0
    this.baseline = 0;
  }

  Slicer.prototype.gcodeSave = function (params) {
    var filamentDiameter = params.filamentDiameter;
    var filamentCrossSection = filamentDiameter * filamentDiameter * Math.PI / 4;
    var axis = this.axis;
    var coincident = MCG.Math.coincident;

    var exporter = new GcodeExporter();

    exporter.setFilename(params.filename);
    exporter.setExtension(params.extension);
    exporter.setTravelSpeed(params.travelSpeed);
    exporter.setCoordPrecision(params.coordPrecision);
    exporter.setExtruderPrecision(params.extruderPrecision);

    exporter.init();

    exporter.writeHeader();
    exporter.writeNewline();

    exporter.writeHeatExtruder(params.temperature);
    exporter.writeAbsolutePositionionMode();
    exporter.writeNewline();
    exporter.writeComment("PRIME EXTRUDER");
    exporter.writePrimingSequence(params.primeExtrusion);

    var extruderPosition = exporter.e;

    var level0 = this.getMinLevel(), levelk = this.getMaxLevel();

    // write geometry for every layer
    for (var level = level0; level <= levelk; level++) {
      var layer = this.getLayer(level);

      var isRaft = level < 0;
      var isRaftBase = level < (level0 + this.raftNumBaseLayers);

      var layerHeight, lineWidth;
      var wallSpeed, infillSpeed;

      if (isRaft) {
        if (isRaftBase) {
          layerHeight = this.raftBaseLayerHeight;
          lineWidth = this.raftBaseLineWidth;
          wallSpeed = params.wallSpeed;
          infillSpeed = params.raftBasePrintSpeed;
        }
        else {
          layerHeight = this.raftTopLayerHeight;
          lineWidth = this.raftTopLineWidth;
          wallSpeed = params.wallSpeed;
          infillSpeed = params.raftTopPrintSpeed;
        }
      }
      else {
        layerHeight = this.layerHeight;
        lineWidth = this.lineWidth;
        wallSpeed = params.wallSpeed;
        infillSpeed = params.infillSpeed;
      }

      // ratio of cross-sections of printed line and filament; multiply by length
      // of segment to get how much to extrude
      var printCrossSection = layerHeight * lineWidth;
      var extrusionFactor = params.extrusionMultiplier * printCrossSection / filamentCrossSection;

      // duplicate context and shift its recorded position up by half a layer
      // height above the center line b/c that's where the extruder will be
      var context = layer.context.clone();
      context.d += layerHeight / 2;
      // constructor for converting intger-space vectors to physical vectors
      var constr = THREE.Vector3;

      // current position in integer-space coordinates; when encountering a new
      // segment to print and current position is not the same as its start point,
      // travel there first
      var ipos = null;

      exporter.writeNewline();
      exporter.writeComment("LAYER " + level);
      if (isRaft) exporter.writeComment("RAFT");
      exporter.writeNewline();

      var infill = layer.getInfill();
      var infillInner = infill.inner;
      var infillSolid = infill.solid;

      // write inner infill
      writeContour(infillInner, infillSpeed);
      // write solid infill
      writeContour(infillSolid, infillSpeed);

      if (!isRaft || this.raftWriteWalls) {
        var walls = layer.getWalls();
        // write walls
        for (var w = walls.length - 1; w >= 0; w--) {
          writeContour(walls[w], wallSpeed);
        }
      }
    }

    exporter.saveToFile();

    function writeContour(contour, speed) {
      if (!contour) return;

      contour.forEachPointPair(function (p1, p2) {
        var v1 = p1.toVector3(constr, context);
        var v2 = p2.toVector3(constr, context);
        var extrusion = v1.distanceTo(v2) * extrusionFactor;
        extruderPosition += extrusion;

        if (ipos === null || !coincident(ipos, p1)) {
          exporter.writeTravel(v1);
        }

        exporter.writePrint(v2, extruderPosition, speed);

        ipos = p2;
      });
    }
  }

  Slicer.prototype.setMode = function (mode) {
    this.mode = mode;

    this.setLevel(this.currentLevel);
  }

  Slicer.prototype.getMode = function () {
    return this.mode;
  }

  Slicer.prototype.getGeometry = function () {
    return this.geometries;
  }

  Slicer.prototype.getMinLevel = function () {
    return -this.numRaftLayers;
  }

  Slicer.prototype.getMaxLevel = function () {
    return this.numSlices - 1;
  }

  Slicer.prototype.getCurrentLevel = function () {
    return this.currentLevel;
  }

  Slicer.prototype.getLayer = function (level) {
    if (level >= 0) return this.sliceLayers[level];
    else return this.raftLayers[this.numRaftLayers + level];
  }

  Slicer.prototype.getLevelPos = function (level) {
    return this.min[this.axis] + (level + 0.5) * this.layerHeight;
  }

  Slicer.prototype.setLevel = function (level) {
    if (level === undefined) level = this.getCurrentLevel();
    level = clamp(level, this.getMinLevel(), this.getMaxLevel());

    var prevLevel = this.currentLevel;
    this.currentLevel = level;

    var layers = this.sliceLayers;
    var layer = this.getLayer(level);
    var context = layer.context;
    var axis = context.axis;

    var geos = this.geometries;

    // write the current layer if necessary for the mode and display settings
    if (this.mode !== Slicer.Modes.full || this.fullUpToLayer) {
      var currentLayerBaseGeo = geos.currentLayerBase.geo;
      var currentLayerContourGeo = geos.currentLayerContours.geo;
      var currentLayerInfillGeo = geos.currentLayerInfill.geo;

      var baseVertices = currentLayerBaseGeo.vertices;
      baseVertices.length = 0;
      layer.writeBase(baseVertices);

      var contourVertices = currentLayerContourGeo.vertices;
      contourVertices.length = 0;
      // write walls if slice level, or if raft level and writing raft walls
      if (level >= 0 || (level < 0 && this.raftWriteWalls)) {
        layer.writeWalls(contourVertices);
      }

      var infillVertices = currentLayerInfillGeo.vertices;
      infillVertices.length = 0;
      layer.writeInfill(infillVertices);
    }

    if (this.mode === Slicer.Modes.preview) {
      var slicePos = this.getLevelPos(level);
      var faceBoundsArray = this.faceBoundsArray;

      var vertices = this.sourceGeo.vertices;

      // local vars for ease of access
      var vertexCount = this.sourceVertexCount;
      var faceCount = this.sourceFaceCount;

      if (this.previewSliceMesh) {
        var position = geos.slicedMesh.geo.attributes.position;
        var normal = geos.slicedMesh.geo.attributes.normal;

        var idx = 0;

        for (var f = 0, l = this.faceBoundsArray.length; f < l; f++) {
          var bounds = faceBoundsArray[f];
          var face = bounds.face;

          // if the face is entirely below the slicing plane, include it whole
          if (bounds.max < slicePos) {
            var verts = Calculate.faceVertices(face, vertices);

            for (var v = 0; v < 3; v++) {
              position.setX(idx, verts[v].x);
              position.setY(idx, verts[v].y);
              position.setZ(idx, verts[v].z);

              normal.setX(idx, face.normal.x);
              normal.setY(idx, face.normal.y);
              normal.setZ(idx, face.normal.z);

              idx += 1;
            }
          }
          // else, if the face intersects the slicing plane, include one or two
          // faces from slicing the face
          else if (bounds.min < slicePos) {
            this.sliceFace(face, vertices, slicePos, axis, function (n, contour, A, B, C) {
              var verts = [A, B, C];

              for (var v = 0; v < 3; v++) {
                position.setX(idx, verts[v].x);
                position.setY(idx, verts[v].y);
                position.setZ(idx, verts[v].z);

                normal.setX(idx, n.x);
                normal.setY(idx, n.y);
                normal.setZ(idx, n.z);

                idx += 1;
              }
            });
          }
        }

        position.needsUpdate = true;
        normal.needsUpdate = true;

        geos.slicedMesh.geo.setDrawRange(0, idx);
      }
    }
    else if (this.mode === Slicer.Modes.full) {
      var allContoursGeo = geos.allContours.geo;

      var contourVertices = allContoursGeo.vertices;
      contourVertices.length = 0;

      var topLevel = this.fullUpToLayer ? level - 1 : this.getMaxLevel();

      for (var i = this.getMinLevel(); i <= topLevel; i++) {
        var ilayer = this.getLayer(i);

        ilayer.writeWalls(contourVertices);

        if (this.fullShowInfill) ilayer.writeInfill(contourVertices);
      }
    }
  }

  Slicer.prototype.makeGeometry = function () {
    var geos = this.geometries;

    geos.source = {
      geo: this.sourceGeo
    };
    geos.currentLayerContours = {
      geo: new THREE.Geometry()
    };
    geos.currentLayerBase = {
      geo: new THREE.Geometry()
    };
    geos.currentLayerInfill = {
      geo: new THREE.Geometry()
    };
    geos.allContours = {
      geo: new THREE.Geometry()
    };
    geos.slicedMesh = {
      geo: new THREE.Geometry()
    };

    geos.slicedMesh.geo = new THREE.BufferGeometry();

    // factor of 2 because each face may be sliced into two faces, so we need
    // to reserve twice the space
    var position = new Float32Array(this.sourceGeo.faces.length * 9 * 2);
    var normal = new Float32Array(this.sourceGeo.faces.length * 9 * 2);

    var positionAttr = new THREE.BufferAttribute(position, 3);
    var normalAttr = new THREE.BufferAttribute(normal, 3);

    geos.slicedMesh.geo.addAttribute('position', positionAttr);
    geos.slicedMesh.geo.addAttribute('normal', normalAttr);

    /*
    return;

    var vertices = this.sourceGeo.vertices;
    var faces = this.sourceGeo.faces;

    for (var f = 0; f < faces.length; f++) {
      var face = faces[f];

      var vs = [vertices[face.a], vertices[face.b], vertices[face.c]];

      for (var v = 0; v < 3; v++) {
        positionAttr.setX(f*3 + v, vs[v].x);
        positionAttr.setY(f*3 + v, vs[v].y);
        positionAttr.setZ(f*3 + v, vs[v].z);

        normalAttr.setX(f*3 + v, face.normal.x);
        normalAttr.setY(f*3 + v, face.normal.y);
        normalAttr.setZ(f*3 + v, face.normal.z);
      }
    }

    geos.slicedMesh.geo.setDrawRange(0, this.sourceGeo.faces.length * 3);
    */
  }

  Slicer.prototype.makeLayers = function () {
    var numSlices = this.numSlices;
    var layers = new Array(numSlices);

    // arrays of segment sets, each array signifying all segments in one layer
    var segmentSets = this.buildLayerSegmentSets();
    var layerParamsInit = {
      lineWidth: this.lineWidth,
      numWalls: this.numWalls,
      numTopLayers: this.numTopLayers,
      optimizeTopLayers: this.optimizeTopLayers,
      infillType: this.infillType,
      infillDensity: this.infillDensity,
      infillOverlap: this.infillOverlap,
      infillConnectLines: false,
      layers: layers,
      idx: -1
    };

    // make layers containing slices of the mesh
    for (var i = 0; i < segmentSets.length; i++) {
      var params = shallowCopy(layerParamsInit);
      params.idx = i;
      var layer = new Layer(params);
      layer.setSource(segmentSets[i]);

      layers[i] = layer;
    }

    this.sliceLayers = layers;
  }

  Slicer.prototype.makeRaftLayers = function () {
    // if not making a raft or there are no layers on which to base it, return
    if (!this.makeRaft || !this.sliceLayers) {
      this.raftLayers = null;
      return;
    }

    var numRaftLayers = this.numRaftLayers;
    var raftNumTopLayers = this.raftNumTopLayers;
    var raftNumBaseLayers = this.raftNumBaseLayers;
    var raftLayers = new Array(numRaftLayers);
    var raftBaseLayerHeight = this.raftBaseLayerHeight;
    var raftTopLayerHeight = this.raftTopLayerHeight;
    var raftBaseHeight = raftNumBaseLayers * raftBaseLayerHeight;

    // get the lowest slice layer and offset it to use as the base for the raft
    var sourceLayer = this.getLayer(0);
    var sourceOffset = sourceLayer.getBase().foffset(this.raftOffset, this.lineWidth);
    var base = MCG.Boolean.union(sourceOffset).union.toPolygonSet();
    var gap = this.raftGap;
    var baseline = this.baseline;

    var layerParamsInit = {
      lineWidth: this.lineWidth,
      numWalls: this.numWalls,
      numTopLayers: 0,
      infillType: Slicer.InfillTypes.lines,
      infillDensity: 1,
      infillOverlap: this.infillOverlap,
      // connect neighboring lines if not writing walls
      infillConnectLines: !this.raftWriteWalls,
      layers: raftLayers,
      idx: -1
    };

    for (var i = 0; i < numRaftLayers; i++) {
      var isBase = i < raftNumBaseLayers;

      var levelPos = baseline;
      if (isBase) {
        levelPos += (i + 0.5) * raftBaseLayerHeight;
      }
      else {
        levelPos += raftBaseHeight + (i - raftNumBaseLayers + 0.5) * raftTopLayerHeight;
      }

      var context = new MCG.Context(this.axis, levelPos, this.precision);

      // make params object with correct density and idx
      var params = shallowCopy(layerParamsInit);

      if (isBase) params.infillDensity = this.raftBaseDensity;
      else params.infillDensity = this.raftTopDensity;
      params.idx = i;

      var layer = new Layer(params);
      layer.setBase(base);
      layer.setContext(context);

      raftLayers[i] = layer;
    }

    this.raftLayers = raftLayers;
  }


// SLICING THE MESH INTO PATHS

// uses an implementation of "An Optimal Algorithm for 3D Triangle Mesh Slicing"
// http://www.dainf.ct.utfpr.edu.br/~murilo/public/CAD-slicing.pdf

// build arrays of faces crossing each slicing plane
  Slicer.prototype.buildLayerFaceLists = function () {
    var layerHeight = this.layerHeight;
    var faceBoundsArray = this.faceBoundsArray;
    var min = this.min[this.axis];

    var numSlices = this.numSlices;

    // position of first and last layer
    var layer0 = min + layerHeight / 2;
    var layerk = layer0 + layerHeight * (numSlices);

    // init layer lists
    var layerLists = new Array(numSlices);
    for (var i = 0; i < numSlices; i++) layerLists[i] = [];

    // bucket the faces
    for (var i = 0; i < this.sourceFaceCount; i++) {
      var bounds = faceBoundsArray[i];
      var idx;

      /*if (bounds.min < layer0) idx = 0;
      else if (bounds.min > layerk) idx = numSlices;
      else idx = Math.ceil((bounds.min - layer0) / layerHeight);*/

      idx = Math.ceil((bounds.min - layer0) / layerHeight);

      layerLists[idx].push(i);
    }

    return layerLists;
  }

// build segment sets in each slicing plane
  Slicer.prototype.buildLayerSegmentSets = function () {
    var layerLists = this.buildLayerFaceLists();

    // various local vars
    var numSlices = layerLists.length;
    var faceBoundsArray = this.faceBoundsArray;
    var axis = this.axis;
    var min = this.min[axis];
    var layerHeight = this.layerHeight;
    var vertices = this.sourceGeo.vertices;
    var faces = this.sourceGeo.faces;

    var segmentSets = new Array(numSlices);

    // running set of active face indices as we sweep up along the layers
    var sweepSet = new Set();

    for (var i = 0; i < numSlices; i++) {
      // height of layer from mesh min
      var slicePos = this.getLevelPos(i);

      // reaching a new layer, insert whatever new active face indices for that layer
      if (layerLists[i].length > 0) sweepSet = new Set([...sweepSet,...layerLists[i]]);

      var context = new MCG.Context(axis, slicePos, this.precision);

      // accumulate segments for this layer
      var segmentSet = new MCG.SegmentSet(context);

      // for each index in the sweep list, see if it intersects the slicing plane:
      //  if it's below the slicing plane, eliminate it
      //  else, store its intersection with the slicing plane
      for (var idx of sweepSet) {
        var bounds = faceBoundsArray[idx];

        if (bounds.max < slicePos) sweepSet.delete(idx);
        else {
          this.sliceFace(bounds.face, vertices, slicePos, axis, function (normal, contour, A, B) {
            if (!contour) return;

            var segment = new MCG.Segment(context);
            segment.fromVector3Pair(A, B, normal);
            segmentSet.add(segment);
          });
        }
      }

      segmentSets[i] = segmentSet;
    }

    return segmentSets;
  }

// slice a face at the given level and then call the callback
// callback arguments:
//  normal: face normal
//  contour: true if first two points border the slice plane
//  P, Q, R: three CCW-wound points forming a triangle
  Slicer.prototype.sliceFace = function (face, vertices, level, axis, callback) {
    // in the following, A is the bottom vert, B is the middle vert, and XY
    // are the points where the triangle intersects the X-Y segment

    var normal = face.normal;

    // get verts sorted on axis; check if this flipped winding order (default is CCW)
    var vertsSorted = faceGetVertsSorted(face, vertices, axis);
    var [A, B, C] = vertsSorted.verts;
    var ccw = vertsSorted.ccw;

    // if middle vert is greater than slice level, slice into 1 triangle A-AB-AC
    if (B[axis] > level) {
      // calculate intersection of A-B and A-C
      var AB = segmentPlaneIntersection(axis, level, A, B);
      var AC = segmentPlaneIntersection(axis, level, A, C);

      if (ccw) callback(normal, true, AB, AC, A);
      else callback(normal, true, AC, AB, A);
    }
    // else, slice into two triangles: A-B-AC and B-BC-AC
    else {
      // calculate intersection of A-C and B-C
      var AC = segmentPlaneIntersection(axis, level, A, C);
      var BC = segmentPlaneIntersection(axis, level, B, C);

      if (ccw) {
        callback(normal, false, A, B, AC);
        callback(normal, true, BC, AC, B);
      }
      else {
        callback(normal, false, B, A, AC);
        callback(normal, true, AC, BC, B);
      }
    }

    // intersection between line segment and plane normal to axis
    function segmentPlaneIntersection(axis, level, va, vb) {
      if (axis === undefined) axis = 'z';

      // if equal, just return va
      if (va[axis] === vb[axis]) return va;

      // calculate linear interpolation factor; note that, as checked above, the
      // denominator will be positive
      var t = (level - va[axis]) / (vb[axis] - va[axis]);
      // difference vector
      var d = vb.clone().sub(va);
      // interpolate
      return va.clone().addScaledVector(d, t);
    }
  }

}

Slicer.DefaultParams = {
  // base params
  axis: "z",
  layerHeight: 0.1,
  lineWidth: 0.1,
  precision: 5,
  mode: 'preview',

  numWalls: 2,
  numTopLayers: 3,
  optimizeTopLayers: true,
  infillType: 0,
  infillDensity: 0.1,
  infillOverlap: 0.5,

  makeRaft: true,
  raftNumTopLayers: 3,
  raftTopLayerHeight: 0.05,
  raftTopLineWidth: 0.05,
  raftTopDensity: 1.0,
  raftNumBaseLayers: 1,
  raftBaseLayerHeight: 0.1,
  raftBaseLineWidth: 0.1,
  raftBaseDensity: 0.5,
  raftOffset: 1.0,
  raftGap: 0.05,
  raftWriteWalls: false,

  // display params; these determine how much to compute
  previewSliceMesh: false,
  fullUpToLayer: true,
  fullShowInfill: false
};


// contains a single slice of the mesh
function Layer(params) {
  // store parameters

  this.params = params;
  this.context = null;

  // source geometry - base and everything else is derived from this
  this.source = null;

  // base contour, decimated and unified
  this.base = null;

  // internal contours for printing
  this.walls = null;

  // main contour containing the infill
  this.infillContour = null;

  // if infill is not solid, some regions may be filled with that infill, but
  // some might need solid infill b/c they're exposed to air above or below:
  // inner contour can be filled with the specified infill type; solid infill
  // is filled with solid infill
  this.disjointInfillContours = null;

  // set of segments containing the mesh infill
  this.infill = null;


}

function initLayer(){
// readiness checks for various components
Layer.prototype.baseReady = function() { return this.base !== null; }
Layer.prototype.wallsReady = function() { return this.walls !== null; }
Layer.prototype.infillContourReady = function() { return this.infillContour !== null; }
Layer.prototype.disjointInfillContoursReady = function() { return this.disjointInfillContours !== null;}
Layer.prototype.infillReady = function() { return this.infill !== null; }

// unready components and the components derived from them
Layer.prototype.unreadyInfill = function() {
  this.infill = null;
}
Layer.prototype.unreadyDisjointInfillContours = function() {
  this.disjointInfillContours = null;
  this.unreadyInfill();
}
Layer.prototype.unreadyInfillContour = function() {
  this.infillContour = null;
  this.unreadyDisjointInfillContours();
}
Layer.prototype.unreadyWalls = function() {
  this.walls = null;
  this.unreadyInfillContour();
}
Layer.prototype.unreadyBase = function() {
  this.base = null;
  this.unreadyWalls();
}

// getters for geometry

Layer.prototype.getSource = function() {
  return this.source;
}

Layer.prototype.getBase = function() {
  this.computeBase();
  return this.base;
}

Layer.prototype.getWalls = function() {
  this.computeWalls();
  return this.walls;
}

Layer.prototype.getInfillContour = function() {
  this.computeInfillContour();
  return this.infillContour;
}

Layer.prototype.getDisjointInfillContours = function() {
  this.computeDisjointInfillContours();
  return this.disjointInfillContours;
}

Layer.prototype.getInfill = function() {
  this.computeInfill();
  return this.infill;
}

// setters for geometry

Layer.prototype.setSource = function(source) {
  this.source = source;
  this.context = source.context;
  return this;
}

Layer.prototype.setBase = function(base) {
  this.base = base;
  this.context = base.context;
  return this;
}

Layer.prototype.setInfillContour = function(infillContour) {
  this.infillContour = infillContour;
  this.context = base.context;
  return this;
}

Layer.prototype.setContext = function(context) {
  this.context = context;
  return this;
}

Layer.prototype.computeBase = function() {
  if (this.baseReady()) return;

  var lineWidth = this.params.lineWidth;

  var sourceDecimated = this.getSource().toPolygonSet().fdecimate(lineWidth);
  var base = MCG.Boolean.union(sourceDecimated).union.toPolygonSet();

  this.base = base;
}

Layer.prototype.computeWalls = function() {
  if (this.wallsReady()) return;

  var lineWidth = this.params.lineWidth;
  var lineWidthsq = lineWidth * lineWidth;
  var numWalls = this.params.numWalls;

  var walls = [];
  var contour = this.getBase();

  for (var w = 0; w < numWalls; w++) {
    // inset the first contour by half line width, all others by full width,
    // from the preceding contour
    var dist = (w === 0 ? -0.5 : -1) * lineWidth;

    var offset = contour.foffset(dist, lineWidth);
    var union = MCG.Boolean.union(offset).union.toPolygonSet();//.filter(areaFilterFn);
    walls.push(union);

    contour = union;
  }

  this.walls = walls;

  function areaFilterFn(poly) { return poly.areaGreaterThanTolerance(lineWidthsq); }
}

Layer.prototype.computeInfillContour = function() {
  if (this.infillContourReady()) return;

  var lineWidth = this.params.lineWidth;
  var numWalls = this.params.numWalls;
  var overlapFactor = 1.0 - this.params.infillOverlap;

  var source, dist;

  if (this.wallsReady()) {
    source = this.walls[this.walls.length-1];
    dist = lineWidth * overlapFactor;
  }
  else {
    source = this.getBase();
    dist = lineWidth * (numWalls + overlapFactor - 0.5);
  }
  console.log(source, this.params, this.wallsReady(),this.walls);

  this.infillContour = MCG.Boolean.union(source.foffset(-dist, lineWidth)).union;
}

Layer.prototype.computeDisjointInfillContours = function() {
  if (this.disjointInfillContoursReady()) return;

  var layers = this.params.layers;
  var idx = this.params.idx;
  var idxk = layers.length-1;
  var numTopLayers = this.params.numTopLayers;
  var context = this.context;
  var contour = this.getInfillContour();

  // if number of top layers is 0, don't fill any part of any layer with solid
  // infill - just use inner infill for everything
  if (numTopLayers === 0) {
    this.disjointInfillContours = {
      inner: contour,
      solid: new MCG.SegmentSet(context)
    };
  }
  // else, if the layer is within numTopLayers of the top or bottom, fill the
  // whole layer with solid infill
  else if ((idx < numTopLayers) || (idx > idxk - numTopLayers)) {
    this.disjointInfillContours = {
      inner: new MCG.SegmentSet(context),
      solid: contour
    };
  }
  // else, it has at least numTopLayers layers above and below, calculate infill
  // from those
  else {
    var neighborContours = new MCG.SegmentSet(context);
    var numLayers = 0;

    // if optimizing top layer computation (and there are more than 2 top
    // layers), only use the adjacent layers and the farthest layers
    if (this.params.optimizeTopLayers && numTopLayers > 2) {
      neighborContours.merge(layers[idx + 1].getInfillContour());
      neighborContours.merge(layers[idx - 1].getInfillContour());
      neighborContours.merge(layers[idx + numTopLayers].getInfillContour());
      neighborContours.merge(layers[idx - numTopLayers].getInfillContour());

      numLayers = 4;
    }
    else {
      for (var i = 1; i <= numTopLayers; i++) {
        neighborContours.merge(layers[idx + i].getInfillContour());
        neighborContours.merge(layers[idx - i].getInfillContour());
      }

      numLayers = numTopLayers * 2;
    }

    var fullDifference = MCG.Boolean.fullDifference(contour, neighborContours, {
      minDepthB: numLayers
    });

    this.disjointInfillContours = {
      inner: fullDifference.intersection.toPolygonSet().filter(sliverFilterFn),
      solid: fullDifference.AminusB.toPolygonSet().filter(sliverFilterFn)
    };
  }

  function sliverFilterFn(poly) { return !poly.isSliver(); }
}

Layer.prototype.computeInfill = function() {
  if (this.infillReady()) return;

  var lineWidth = this.params.lineWidth;
  var type = this.params.infillType;
  var density = this.params.infillDensity;
  var connectLines = this.params.infillConnectLines;

  // if grid infill and density is too high, use solid infill instead
  if (type === Slicer.InfillTypes.grid && density >= 1.0) {
    type = Slicer.InfillTypes.solid;
  }

  var iLineWidth = MCG.Math.ftoi(lineWidth, this.context);
  var iLineWidthsq = iLineWidth*iLineWidth;
  var infillInner = null, infillSolid = null;

  // if solid infill, just fill the entire contour
  if (type === Slicer.InfillTypes.solid) {
    var infillContour = this.getInfillContour();

    infillSolid = MCG.Infill.generate(infillContour, MCG.Infill.Types.linear, {
      angle: Math.PI / 4,
      spacing: iLineWidth,
      parity: this.params.idx%2,
      connectLines: connectLines
    });
  }
  // if other infill, need to determine where to fill with that and where to
  // fill with solid infill
  else {
    var disjointInfillContours = this.getDisjointInfillContours();

    var innerContour = disjointInfillContours.inner;
    var solidContour = disjointInfillContours.solid;

    if (type === Slicer.InfillTypes.lines) {
      infillInner = MCG.Infill.generate(innerContour, MCG.Infill.Types.linear, {
        angle: Math.PI / 4,
        spacing: iLineWidth / density,
        parity: this.params.idx%2,
        connectLines: connectLines
      });
    }
    else if (type === Slicer.InfillTypes.grid) {
      infillInner = MCG.Infill.generate(innerContour, MCG.Infill.Types.grid, {
        angle: Math.PI / 4,
        spacing: iLineWidth / density,
        connectLines: connectLines
      });
    }

    infillSolid = MCG.Infill.generate(solidContour, MCG.Infill.Types.linear, {
      angle: Math.PI / 4,
      spacing: iLineWidth,
      parity: this.params.idx%2,
      connectLines: connectLines
    });
  }

  if (infillInner !== null) infillInner.filter(lengthFilterFn);
  if (infillSolid !== null) infillSolid.filter(lengthFilterFn);

  this.infill = {
    inner: infillInner,
    solid: infillSolid
  };

  function lengthFilterFn(segment) { return segment.lengthSq() >= iLineWidthsq / 4; }
}

Layer.prototype.writeBase = function(vertices) {
  var context = this.context;
  var base = this.getBase();
  var count = 0;

  if (base) {
    base.forEachPointPair(function(p1, p2) {
      vertices.push(p1.toVector3(THREE.Vector3, context));
      vertices.push(p2.toVector3(THREE.Vector3, context));
      count += 2;
    });
  }

  return count;
}

Layer.prototype.writeWalls = function(vertices) {
  var context = this.context;
  var walls = this.getWalls();
  var count = 0;

  if (walls) {
    for (var w = 0; w < walls.length; w++) {
      walls[w].forEachPointPair(function(p1, p2) {
        vertices.push(p1.toVector3(THREE.Vector3, context));
        vertices.push(p2.toVector3(THREE.Vector3, context));
        count += 2;
      });
    }
  }

  return count;
}

Layer.prototype.writeInfill = function(vertices) {
  var context = this.context;
  var infill = this.getInfill();
  var infillInner = infill.inner;
  var infillSolid = infill.solid;
  var count = 0;

  if (infillInner) {
    infillInner.forEachPointPair(function(p1, p2) {
      vertices.push(p1.toVector3(THREE.Vector3, context));
      vertices.push(p2.toVector3(THREE.Vector3, context));
      count += 2;
    });
  }

  if (infillSolid) {
    infillSolid.forEachPointPair(function(p1, p2) {
      vertices.push(p1.toVector3(THREE.Vector3, context));
      vertices.push(p2.toVector3(THREE.Vector3, context));
      count += 2;
    });
  }

  return count;
}
}


function initMCG(){
  Object.assign(MCG.Boolean, (function() {

    return {
      union: function(a, b, params) {
        var op = MCG.Sweep.Operations.union(params);

        return MCG.Sweep.sweep(op, a, b);
      },

      intersection: function(a, b, params) {
        var op = MCG.Sweep.Operations.intersection(params);
        var context = a.context;

        if (a.count() === 0 || b.count() === 0) return op.initStore(context).result;

        return MCG.Sweep.sweep(op, a, b);
      },

      intersectionOpen: function(a, b, params) {
        var op = MCG.Sweep.Operations.intersectionOpen(params);
        var context = a.context;

        if (a.count() === 0 || b.count() === 0) return op.initStore(context).result;

        return MCG.Sweep.sweep(op, a, b);
      },

      difference: function(a, b, params) {
        var op = MCG.Sweep.Operations.difference(params);
        var context = a.context;

        if (a.count() === 0) return op.initStore(context).result;
        if (b.count() === 0) return a;

        return MCG.Sweep.sweep(op, a, b);
      },

      fullDifference: function(a, b, params) {
        var op = MCG.Sweep.Operations.fullDifference(params);
        var context = a.context;

        if (a.count() === 0) {
          var result = op.initStore(context).result;
          result.BminusA = b;
          return result;
        }

        if (b.count() === 0) {
          var result = op.initStore(context).result;
          result.AminusB = a;
          return result;
        }

        return MCG.Sweep.sweep(op, a, b);
      }
    };

  })());

  MCG.AdjacencyMap = (function() {

    function AdjacencyMap(context) {
      this.context = context;

      this.type = MCG.Types.abstractAdjacencyMap;
    }

    return AdjacencyMap;

  })();



  MCG.DirectedAdjacencyMap = (function() {

    function DirectedAdjacencyMap(context) {
      MCG.AdjacencyMap.call(this, context);

      this.map = {};

      this.type = MCG.Types.directedAdjacencyMap;
    }

    DirectedAdjacencyMap.prototype.addSegment = function(s) {
      var m = this.map;

      var p1 = s.p1;
      var p2 = s.p2;
      var hash1 = p1.hash();
      var hash2 = p2.hash();

      if (!m.hasOwnProperty(hash1)) {
        m[hash1] = new MCG.AdjacencyMapNode(p1, this.context);
      }
      if (!m.hasOwnProperty(hash2)) {
        m[hash2] = new MCG.AdjacencyMapNode(p2, this.context);
      }

      var node1 = m[hash1];
      var node2 = m[hash2];

      node1.addNode(node2);
    }

    DirectedAdjacencyMap.NodeSelectors = {
      noPredecessors: function(node) { return node.predcount === 0 && node.count > 0; },
      oneNeighbor: function(node) { return node.count === 1; },
      neighbors: function(node) { return node.count > 0; },
      noNeighbors: function(node) { return node.count === 0; }
    }

    DirectedAdjacencyMap.prototype.getKeyWithNoPredecessors = function() {
      return this.getKey(DirectedAdjacencyMap.NodeSelectors.noPredecessors);
    }

    DirectedAdjacencyMap.prototype.getKeyWithOneNeighbor = function() {
      return this.getKey(DirectedAdjacencyMap.NodeSelectors.oneNeighbor);
    }

    // get a key that has some neighbors; prioritize nodes with one neighbor
    DirectedAdjacencyMap.prototype.getKeyWithNeighbors = function() {
      var res = this.getKey(DirectedAdjacencyMap.NodeSelectors.oneNeighbor);
      if (res) return res;

      return this.getKey(DirectedAdjacencyMap.NodeSelectors.neighbors);
    }

    DirectedAdjacencyMap.prototype.getKeyWithNoNeighbors = function() {
      return this.getKey(DirectedAdjacencyMap.NodeSelectors.noNeighbors);
    }

    // get the key to a node that satisfies selector sel
    DirectedAdjacencyMap.prototype.getKey = function(sel) {
      var m = this.map;
      if (sel === undefined) sel = DirectedAdjacencyMap.NodeSelectors.oneNeighbor;

      for (var key in m) {
        if (sel(m[key])) return key;
      }

      return null;
    }

    // return a loop of points
    // if allowOpen (true by default), get the open vertex loops first and count
    // them as closed, then the closed loops
    // NB: this mutates the adjacency map
    DirectedAdjacencyMap.prototype.getLoop = function(allowOpen) {
      var m = this.map;
      var _this = this;
      if (allowOpen === undefined) allowOpen = true;

      var startkey;

      // get a key from the map
      while ((startkey = getNewKey()) !== null) {
        var start = m[startkey];
        var current = start;
        var prev = null;

        var loop = [];

        // iterate until we circle back to the start
        do {
          loop.push(current.pt);

          var next = current.nextNode(prev);
          if (next === null) break;

          prev = current;
          current = next;
        } while (current !== start);

        // if complete loop, return that
        if (current === start || allowOpen) return loop;
      }

      // failed to find a loop
      return null;

      function getNewKey() {
        var key = null;

        // if allowing open polygons, find the start of an open vertex chain
        if (allowOpen) key = _this.getKeyWithNoPredecessors();

        // didn't find a key at the start of an open vertex chain; now just find
        // a key with some neighbors
        if (key === null) {
          key = _this.getKeyWithNeighbors();
          allowOpen = false;
        }

        return key;
      }
    }

    // return as many loops as the adjacency map has
    // NB: this mutates the adjacency map
    DirectedAdjacencyMap.prototype.getLoops = function() {
      var m = this.map;
      var loops = [];

      var loop = null;

      while ((loop = this.getLoop()) !== null) {
        loops.push(loop);
      }

      return loops;
    }

    return DirectedAdjacencyMap;
  })();

  MCG.AdjacencyMapNode = (function() {

    // one node signifies one point; a neighbor is another point
    // if count == 0, the node has no neighbor and is either isolated or at the end
    // of a (directed) chain of edges
    // if count == 1, the node points to one neighbor and a traversal can go to
    // that neighbor
    // if count > 1, the node has multiple outgoing directed paths; in that case,
    // neighbor information is recorded in the neighbors array
    function AdjacencyMapNode(pt, context) {
      this.pt = pt;
      this.count = 0;
      this.predcount = 0;

      // neighbor; is set if count === 1
      this.neighbor = null;
      // array of neighbor nodes; is set if count > 1
      this.neighbors = null;
    }

    // if no neighbors, set neighbor to other
    // if 1+ neighbors already exist, push to neighbors array (init if necessary)
    AdjacencyMapNode.prototype.addNode = function(other) {
      if (this.count === 0) this.neighbor = other;
      else {
        if (this.count === 1) {
          this.neighbors = [];
          this.neighbors.push(this.neighbor);

          this.neighbor = null;
        }

        this.neighbors.push(other);
      }

      this.count++;
      other.predcount++;
    }

    AdjacencyMapNode.prototype.removeNode = function(node) {
      var n = null;

      // only one neighbor; get it and null out the current neighbor
      if (this.count === 1) {
        if (this.neighbor === node) {
          n = this.neighbor;
          this.neighbor = null;
          this.count--;
        }
      }
      // multiple neighbors
      else if (this.count > 1) {
        // find neighbor
        var idx = this.neighbors.indexOf(node);

        // if found neighbor, get it and remove it from neighbors array
        if (idx > -1) {
          n = this.neighbors[idx];
          this.neighbors.splice(idx, 1);
          this.count--;

          // if 1 neighbor left, move it to .neighbor and null out neighbors
          if (this.count === 1) {
            this.neighbor = this.neighbors[0];
            this.neighbors = null;
          }
        }
      }

      if (n !== null) n.predcount--;

      return n;
    }

    // get the neighbor node:
    //  if there is one neighbor, return that
    //  if there are multiple neighbors, take the rightmost possible turn
    AdjacencyMapNode.prototype.nextNode = function(prev) {
      if (this.count < 1) {
        return null;
      }
      else {
        var p = null;

        if (this.count === 1) p = this.neighbor;
        else p = this.getRightmostNode(prev);

        var result = p !== null ? this.removeNode(p) : null;

        return result;
      }
    }

    AdjacencyMapNode.prototype.getRightmostNode = function(prev) {
      // traversal might have started at a node with two neighbors without getting
      // there from a previous node; in that case, just pick one of the neighbors
      if (prev === null) return this.neighbors[0];

      var neighbors = this.neighbors;
      var pt = this.pt;
      var prevpt = prev.pt;

      var inDir = prevpt.vectorTo(pt);

      var PI = Math.PI;

      var anglemax = -PI;
      var anglemaxidx = -1;

      var left = MCG.Math.left;

      for (var ni = 0; ni < neighbors.length; ni++) {
        var npt = neighbors[ni].pt;

        var d = pt.vectorTo(npt);
        var angle = inDir.angleTo(d);

        // correct for negative angles
        if (left(prevpt, pt, npt)) angle = -angle;

        if (angle > PI) angle = -PI;

        if (angle >= anglemax) {
          anglemax = angle;
          anglemaxidx = ni;
        }
      }

      var p = anglemaxidx > -1 ? neighbors[anglemaxidx] : null;

      return p;
    }

    return AdjacencyMapNode;

  })();
  MCG.Context = (function() {

    function Context(axis, d, precision) {
      if (axis === undefined) axis = 'z';
      if (d === undefined) d = 0;
      if (precision === undefined) precision = 5;

      this.axis = axis;
      this.ah = cycleAxis(axis);
      this.av = cycleAxis(this.ah);
      this.up = makeAxisUnitVector(axis);
      this.d = d;
      this.precision = precision;

      this.epsilon = Math.pow(10, -this.precision);
      this.p = Math.pow(10, this.precision);

      this.type = MCG.Types.context;
    }

    Object.assign(Context.prototype, {

      constructor: Context,

      clone: function() {
        return new this.constructor(this.axis, this.d, this.precision);
      }

    });

    return Context;

  })();
  Object.assign(MCG.Generate, (function() {

    return {

      infillLinear: function(min, max, spacing, angle, parity) {
        var context = min.context;
        spacing = spacing || context.p;
        angle = angle || 0;
        parity = parity || 0;

        // constants
        var pi = Math.PI;
        var pi2 = pi * 2;
        var pi_2 = pi / 2;

        // rotate by 90 degrees if non-0 parity
        if (parity !== 0) angle += pi_2;

        // clamp angle to 0-pi range
        angle = (angle + pi2) % pi2;
        if (angle > pi) angle -= pi;

        // direction of infill lines
        var d = new MCG.Vector(context).setUnitVector("v").rotate(-angle);
        var dh = d.h, dv = d.v;

        // vector by which the line is shifted at every iteration
        var shift = MCG.Math.orthogonalRightVector(d, spacing);

        // top left and top right corners
        var topleft = new MCG.Vector(context, min.h, max.v);
        var topright = max;

        // lines have positive slope
        var spos = angle < pi_2;

        // if 0-90 degrees CW from the vertical, start from top-left corner;
        // if 90-180, start from top-right corner
        var p = spos ? new MCG.Vector(context, min.h, max.v) : max.clone();

        // horizontal/vertical values the line will cross first/second
        var h1 = min.h, v1 = spos ? min.v : max.v;
        var h2 = max.h, v2 = spos ? max.v : min.v;

        var lines = new MCG.SegmentSet(context);

        // iteratively shift the point and determine where it intersects the
        // rectangular box
        while (1) {
          p.add(shift);

          // each line is given by p + t * d = x, where x is some point on the
          // line; determine the parameter t for crossing the vertical and
          // horizontal boundaries
          // for entry crossing, take the max t; for exit, take the min t
          var th1 = dh !== 0 ? ((h1 - p.h) / dh) : -Infinity;
          var tv1 = dv !== 0 ? ((v1 - p.v) / dv) : -Infinity;
          var t1 = Math.max(th1, tv1);

          var th2 = dh !== 0 ? ((h2 - p.h) / dh) : Infinity;
          var tv2 = dv !== 0 ? ((v2 - p.v) / dv) : Infinity;
          var t2 = Math.min(th2, tv2);

          // line is outside the box, so subsequent lines will be outside too
          if (t1 >= t2) break;

          // calculate intersection points
          var p1 = p.clone().addScaledVector(d, t1);
          var p2 = p.clone().addScaledVector(d, t2);

          lines.addPointPair(p1, p2);
        }

        return lines;
      },

      infillHex: function(min, max, spacing, linewidth, parity) {
        var context = min.context;
        spacing = spacing || context.p;
        linewidth = linewidth || 0;
        parity = parity || 0;

        var sqrt3 = Math.sqrt(3);

        var dh = spacing * sqrt3 / 2;
        var dv = spacing / 2;

        var vertical = new MCG.Vector(context, 0, spacing);

        var lines = new MCG.SegmentSet(context);

        // if parity 0, build the hex pattern in vertical columns
        if (parity === 0) {
          // column index
          var cidx = 0;

          var right = new MCG.Vector(context, dh, dv);
          var left = new MCG.Vector(context, -dh, dv);

          // start point for each column
          var start = min.clone();
          var p = start;

          while (p.h < max.h) {
            // true if column index is even
            var even = cidx % 2 === 0;

            while (p.v < max.v) {
              var p0 = p.clone();
              var p1 = p0.clone().add(vertical);
              var p2 = p1.clone().add(even ? right : left);
              var p3 = p2.clone().add(vertical);
              var p4 = p3.clone().add(even ? left : right);

              lines.addPointPair(p0, p1);
              lines.addPointPair(p1, p2);
              lines.addPointPair(p2, p3);
              lines.addPointPair(p3, p4);

              p = p4;
            }

            start.h += even ? dh * 2 + linewidth : linewidth;
            p = start;

            cidx++;
          }
        }
        // if parity !== 0, go across in a zigzag pattern
        else {
          // horizontal and vertical displacements for zigzag segments
          var dhz = dh + linewidth;
          var dvz = dhz / sqrt3;

          // vertical shift in start point for even and odd iterations
          var vshifteven = dv*2 + spacing + (dvz - dv);
          var vshiftodd = (spacing*2 + dv*2) - vshifteven;

          var up = new MCG.Vector(context, dhz, dvz);
          var down = new MCG.Vector(context, dhz, -dvz);

          // row index
          var ridx = 0;

          // start point for each row
          var start = min.clone().add(vertical);
          start.h -= linewidth / 2;
          start.v -= (dvz - dv) / 2;
          var p = start;

          while (p.v < max.v) {
            // true if row index is even
            var even = ridx % 2 === 0;

            while (p.h < max.h) {
              var p0 = p.clone();
              var p1 = p.clone().add(even ? up : down);
              var p2 = p1.clone().add(even ? down : up);

              lines.addPointPair(p0, p1);
              lines.addPointPair(p1, p2);

              p = p2;
            }

            start.v += even ? vshifteven : vshiftodd;
            p = start;

            ridx++;
          }
        }

        return lines;
      }

    };

  })());
  MCG.GeometrySet = (function() {

    function GeometrySet(context) {
      this.context = context;

      this.elements = [];

      this.min = null;
      this.max = null;

      this.initBounds();

      this.type = MCG.Types.abstractGeometrySet;
    }

    Object.assign(GeometrySet.prototype, {

      add: function(e) {
        if (e.valid()) {
          this.elements.push(e);

          e.updateBoundsFromThis(this.min, this.max);
        }

        return this;
      },

      initBounds: function() {
        var context = this.context;

        this.min = new MCG.Vector(context).setScalar(Infinity);
        this.max = new MCG.Vector(context).setScalar(-Infinity);
      },

      count: function() {
        return this.elements.length;
      },

      forEach: function(f) {
        var elements = this.elements;
        var ct = this.elements.length;

        for (var i = 0; i < ct; i++) {
          f(elements[i]);
        }
      },

      filter: function(valid) {
        var result = [];

        this.forEach(function(element) {
          if (valid(element)) result.push(element);
        });

        this.elements = result;

        return this;
      },

      rotate: function(angle) {
        this.initBounds();
        var _this = this;

        this.forEach(function(element) {
          element.rotate(angle);
          element.updateBoundsFromThis(_this.min, _this.max);
        });

        return this;
      },

      clone: function(recursive) {
        var clone = new this.constructor(this.context);
        var elements = clone.elements;

        this.forEach(function(element) {
          elements.push(recursive ? element.clone(recursive) : element);
        });

        return clone;
      },

      merge: function(other) {
        var elements = this.elements;

        other.forEach(function(element) {
          elements.push(element);
        });

        return this;
      },

      setContext: function(context) {
        this.context = context;

        return this;
      }

    });

    return GeometrySet;

  })();
  Object.assign(MCG.Infill, (function() {

    var Types = {
      none: 0,
      linear: 1,
      grid: 2,
      triangle: 4,
      hex: 8
    };

    function generate(contour, type, params) {
      params = params || {};

      if (type === Types.linear) {
        return generateLinear(contour, params.angle, params.spacing, params.parity, params.connectLines);
      }
      if (type === Types.grid) {
        return generateGrid(contour, params.angle, params.spacing, params.connectLines);
      }
      if (type === Types.triangle) {
        return generateTriangle(contour, params.spacing);
      }
      /*if (type === Types.hex) {
        return generateHex(contour, params.spacing, params.linewidth, params.parity);
      }*/

      return null;
    }

    function generateLinear(contour, angle, spacing, parity, connectLines) {
      var context = contour.context;
      angle = angle || 0;
      spacing = spacing || context.p;
      parity = parity || 0;
      connectLines = connectLines || false;

      // constants
      var pi = Math.PI;
      var pi2 = pi * 2;
      var pi_2 = pi / 2;

      // rotate by 90 degrees if nonzero parity
      if (parity !== 0) angle += pi_2;

      var contourRotated = contour.clone(true).rotate(angle);

      var op = MCG.Sweep.Operations.linearInfill({
        spacing: spacing,
        connectLines: connectLines
      });

      var infillRotated = MCG.Sweep.sweep(op, contourRotated).infill;

      return infillRotated.rotate(-angle);
    }

    function generateGrid(contour, angle, spacing, connectLines) {
      context = contour.context;
      angle = angle || 0;
      spacing = spacing || context.p;
      connectLines = connectLines || false;

      // constants
      var pi = Math.PI;
      var pi2 = pi * 2;
      var pi_2 = pi / 2;

      // make the sweep operation
      var op = MCG.Sweep.Operations.linearInfill({
        spacing: spacing,
        connectLines: connectLines,
        handleIntersections: false
      });

      // clone and rotate the contour by the initial angle
      var contourRotated = contour.clone(true).rotate(angle);
      // construct the infill in one direction
      var infillRotated0 = MCG.Sweep.sweep(op, contourRotated).infill;

      // rotate by pi/2 further
      contourRotated.rotate(pi_2);
      // construct the infill in the orthogonal direction
      var infillRotated1 = MCG.Sweep.sweep(op, contourRotated).infill;

      // unrotate second direction, merge with first direction, unrotate both
      return infillRotated1.rotate(-pi_2).merge(infillRotated0).rotate(-angle);
    }

    return {
      Types: Types,
      generate: generate
    }

  })());
  Object.assign(MCG.Math, (function() {

    // float to integer
    function ftoi(f, context) {
      if (context === undefined) context = new MCG.Context();

      return Math.round(f * context.p);
    }

    // integer to float
    function itof(i, context) {
      if (context === undefined) context = new MCG.Context();

      return i / context.p;
    }

    // true if two points are coincident
    function coincident(a, b) {
      return a.h === b.h && a.v === b.v;
    }

    // area of a-b-c triangle in integer space
    function area(a, b, c) {
      var cross = (c.h-b.h) * (a.v-b.v) - (c.v-b.v) * (a.h-b.h);
      return cross / 2;
    }

    // area of a-b-c triangle using normalized a-b and a-c edges
    function narea(a, b, c) {
      var bc = b.vectorTo(c).normalize();
      var ba = b.vectorTo(a).normalize();

      return bc.cross(ba) / 2;
    }

    // area of a-b-c triangle in floating-point space
    function farea(a, b, c) {
      var ash = a.sh(), asv = a.sv();
      var bsh = b.sh(), bsv = b.sv();
      var csh = c.sh(), csv = c.sv();

      var cross = (bsh-ash) * (csv-asv) - (bsv-asv) * (csh-ash);
      return cross / 2;
    }

    // distance squared from point p to line subtended by a-b segment
    function distanceToLineSq(a, b, p) {
      var ab = b.vectorTo(a);
      var ap = p.vectorTo(a);

      var dot = ab.dot(ap);

      if (dot === 0) return ap.lengthSq();

      var ablensq = ab.lengthSq();
      var proj = ab.multiplyScalar(dot / ablensq);

      return proj.distanceToSq(ap);
    }

    function distanceToLine(a, b, p) {
      return Math.sqrt(distanceToLineSq(a, b, p));
    }

    // returns 0 if c collinear with a-b, 1 if c left of a-b, else -1
    function leftCompare(a, b, c) {
      if (distanceToLineSq(a, b, c) <= 2) return 0;
      else return Math.sign(area(a, b, c));
    }

    function leftCompareStrict(a, b, c) {
      return Math.sign(area(a, b, c));
    }

    // signifies special types of intersection between a0-a1 and b0-b1 segments
    var IntersectionFlags = (function() {
      var a0 = 2, a1 = 4;
      var b0 = 8, b1 = 16;
      var a0b0 = a0 | b0;
      var a1b1 = a1 | b1;
      var a0b1 = a0 | b1;
      var a1b0 = a1 | b0;
      var a01 = a0 | a1;
      var b01 = b0 | b1;

      return {
        none: 0,                // no intersection
        intermediate: 1,        // intersection excludes endpoints
        a0: a0,                 // a0 is on b0-b1
        a1: a1,                 // a1 is on b0-b1
        b0: b0,                 // b0 is on a0-a1
        b1: b1,                 // b1 is on a0-a1
        a: a01,                 // a0 and a1 are on b0-b1
        b: b01,                 // b0 and b1 are on a0-a1
        a0b0: a0b0,            // intersection point is start of both segments
        a1b1: a1b1,              // intersection point is end of both segments
        a0b1: a0b1,             // intersection point is a start and b end
        a1b0: a1b0,             // intersection point is a end and b start
        collinear: a0b0 | a1b1  // a and b are collinear
      };
    })();

    // create a normalized vector that is orthogonal to and right of vector d
    function orthogonalRightVector(d, len) {
      var h = d.h, v = d.v;

      // opposite inverse slope makes an orthogonal vector
      var r = d.clone().set(v, -h);

      if (len !== undefined) return r.setLength(len);
      else return r.normalize();
    }

    return {

      ftoi: ftoi,
      itof: itof,

      coincident: coincident,

      area: area,
      farea: farea,
      narea: narea,

      distanceToLineSq: distanceToLineSq,
      distanceToLine: distanceToLine,

      // leftness predicates - these account for the fuzziness introduced by
      // vertices' snapping to the integer grid

      leftCompare: leftCompare,

      collinear: function(a, b, c) {
        // consecutive vertices a, b, c are collinear if b is on a-c segment
        return leftCompare(a, c, b) === 0;
      },

      left: function(a, b, c) {
        return leftCompare(a, b, c) > 0;
      },

      leftOn: function(a, b, c) {
        return leftCompare(a, b, c) >= 0;
      },

      // strict predicates - exact comparisons of area

      leftCompareStrict: leftCompareStrict,

      collinearStrict: function(a, b, c) {
        return leftCompareStrict(a, b, c) === 0;
      },

      leftStrict: function(a, b, c) {
        return leftCompareStrict(a, b, c) > 0;
      },

      leftOnStrict: function(a, b, c) {
        return leftCompareStrict(a, b, c) >= 0;
      },

      IntersectionFlags: IntersectionFlags,

      // intersection predicate: return true if a-b segment intersects c-d
      // segment; returns
      intersect: function(a, b, c, d) {
        var flags = IntersectionFlags;

        // leftness checks for the endpoint of one segment against the other segment
        var labc = leftCompare(a, b, c), labd = leftCompare(a, b, d);
        var lcda = leftCompare(c, d, a), lcdb = leftCompare(c, d, b);

        var result = flags.none;

        // a-b segment is between endpoints of c-d segment
        var abBtwn = labc !== labd || labc === 0;
        // c-d segment is between endpoints of a-b segment
        var cdBtwn = lcda !== lcdb || lcda === 0;

        // check if one endpoint lies on the other segment

        // c lies on a-b and between a-b
        if (labc === 0 && cdBtwn) result |= flags.b0;
        if (labd === 0 && cdBtwn) result |= flags.b1;
        if (lcda === 0 && abBtwn) result |= flags.a0;
        if (lcdb === 0 && abBtwn) result |= flags.a1;

        // if one segment registers as collinear with the other, say both segments
        // are collinear
        //if (result & flags.a0 && result & flags.a1) return flags.collinear;
        //if (result & flags.b0 && result & flags.b1) return flags.collinear;
        //if (result & flags.a0 && result & flags.b1) return flags.collinear;
        //if (result & flags.a1 && result & flags.b0) return flags.collinear;

        // possible intersection on intermediate points
        if (result === flags.none) {
          if (abBtwn && cdBtwn) {
            result = flags.intermediate;
          }
        }

        return result;
      },

      // calculate intersection point of a0-a1 segment and b0-b1 segment
      intersection: function(a0, a1, b0, b1) {
        // denominator
        var d = a0.h * (b1.v - b0.v) + a1.h * (b0.v - b1.v) +
          b1.h * (a1.v - a0.v) + b0.h * (a0.v - a1.v);
        // if denominator is 0, segments are parallel
        if (d === 0) return null;

        // numerator
        var n;

        // calculate pa
        n = a0.h * (b1.v - b0.v) + b0.h * (a0.v - b1.v) + b1.h * (b0.v - a0.v);
        var pa = n / d;

        var ixn = a0.clone().addScaledVector(a0.vectorTo(a1), pa);

        // if intersection is outside segment a's bounds, it's invalid
        if (!inRange(ixn.h, Math.min(a0.h, a1.h), Math.max(a0.h, a1.h))) return null;
        if (!inRange(ixn.v, Math.min(a0.v, a1.v), Math.max(a0.v, a1.v))) return null;

        return ixn;
      },

      orthogonalRightVector: orthogonalRightVector,

      // the bisector of a-b and b-c segments, looking right of both segments
      bisector: function(a, b, c) {
        var abr = orthogonalRightVector(a.vectorTo(b));
        var bcr = orthogonalRightVector(b.vectorTo(c));

        return abr.add(bcr).normalize();
      },

      cycleAxis: function(a) {
        if (a === "h") return "v";
        else if (a === "v") return "h";
        else if (a === "x") return "y";
        else if (a === "y") return "z";
        else return "x";
      }

    };

  })());
  MCG.Polygon = (function() {

    function Polygon(context, sourcePoints, params) {
      this.context = context;

      // closed by default
      this.closed = !(params && params.open);

      this.points = [];
      this.bisectors = null;
      this.angles = null;

      this.area = 0;

      this.min = null;
      this.max = null;

      this.initBounds();

      // construct the polygon

      if (!sourcePoints) return this;
      if (this.closed && sourcePoints.length < 3) return this;

      // build the points array, eliminating collinear vertices
      var points = this.points;
      var collinear = MCG.Math.collinear;

      var ns = sourcePoints.length;
      for (var si = 0; si < ns; si++) {
        var spt = sourcePoints[si];
        var ct = points.length;

        // if last three points are collinear, replace last point with new point
        if (ct > 1 && collinear(points[ct-2], points[ct-1], spt)) {
          points[ct-1] = spt;
        }
        // else, just add the new point
        else {
          points.push(spt);
        }
      }

      if (!this.valid()) return this;

      if (this.closed) {
        // eliminate points 0 and/or 1 if they are collinear with their neighbors
        var ct = this.count();
        if (collinear(points[ct-2], points[ct-1], points[0])) points.splice(--ct, 1);
        if (collinear(points[ct-1], points[0], points[1])) points.splice(0, 1);

        this.calculateArea();
      }

      if (!this.valid()) return this;

      this.calculateBounds();

      return this;
    }

    Object.assign(Polygon.prototype, {

      count: function() {
        return this.points.length;
      },

      // for each point
      forEach: function(f) {
        var points = this.points;
        var ct = points.length;
        var bisectors = this.bisectors;

        for (var i = 0; i < ct; i++) {
          var b = bisectors !== null ? bisectors[i] : undefined;

          f(points[i], b);
        }
      },

      // for each sequence of two points
      forEachPointPair: function(f) {
        var points = this.points;
        var ct = points.length;
        var ct1 = ct - 1;

        for (var i = 0; i < ct; i++) {
          var p1 = points[i];
          var p2 = points[(i < ct1) ? i+1 : (i+1+ct)%ct];

          f(p1, p2);
        }
      },

      // for each sequence of three points
      forEachSegmentPair: function(f) {
        var points = this.points;
        var ct = points.length;
        var ct1 = ct - 1;
        var ct2 = ct - 2;

        for (var i = 0; i < ct; i++) {
          var p1 = points[i];
          var p2 = points[(i < ct1) ? i+1 : (i+1+ct)%ct];
          var p3 = points[(i < ct2) ? i+2 : (i+2+ct)%ct];

          f(p1, p2, p3);
        }
      },

      initBounds: function() {
        var context = this.context;

        this.min = new MCG.Vector(context).setScalar(Infinity);
        this.max = new MCG.Vector(context).setScalar(-Infinity);
      },

      initArea: function() {
        this.area = 0;
      },

      updateBounds: function(pt) {
        this.min.min(pt);
        this.max.max(pt);
      },

      updateBoundsFromThis: function(min, max) {
        min.min(this.min);
        max.max(this.max);
      },

      calculateBounds: function() {
        var context = this.context;

        this.initBounds();

        var _this = this;

        this.forEach(function(p) {
          _this.updateBounds(p);
        });
      },

      calculateArea: function() {
        this.area = 0;

        if (!this.closed) return;

        var area = MCG.Math.area;
        var points = this.points;
        var ct = this.count();

        for (var i = 1; i < ct - 1; i++) {
          this.area += area(points[0], points[i], points[i+1]);
        }
      },

      perimeter: function() {
        var result = 0;

        this.forEachPointPair(function(p1, p2) {
          result += p1.distanceTo(p2);
        });

        return result;
      },

      isSliver: function(tol) {
        tol = tol || this.context.p / 100;

        return Math.abs(this.area) / this.perimeter() < tol;
      },

      fAreaGreaterThanTolerance: function(ftol) {
        var tol = MCG.Math.ftoi(ftol, this.context);

        return this.areaGreaterThanTolerance(tol);
      },

      areaGreaterThanTolerance: function(tol) {
        return Math.abs(this.area) > tol;
      },

      size: function() {
        return this.min.vectorTo(this.max);
      },

      valid: function() {
        if (this.closed) return this.count() >= 3;
        else return this.count() > 1;
      },

      invalidate: function() {
        this.points = [];
        this.initArea();
        this.initBounds();

        return this;
      },

      createNew: function() {
        return new this.constructor(this.context, undefined, this.closed);
      },

      clone: function(recursive) {
        var clone = this.createNew();

        Object.assign(clone, this);

        if (recursive) {
          // make a new array
          clone.points = [];

          // clone the points
          var ct = this.count();
          for (var i = 0; i < ct; i++) {
            clone.points[i] = this.points[i].clone();
          }
        }

        return clone;
      },

      // points is an array of vectors
      // mk is an optional array of bools indicating valid points
      fromPoints: function(points, mk) {
        if (mk) {
          var rpoints = [];

          for (var i = 0; i < points.length; i++) {
            if (mk[i]) rpoints.push(points[i]);
          }

          this.points = rpoints;
        }
        else {
          this.points = points;
        }

        this.calculateArea();
        this.calculateBounds();

        return this;
      },

      rotate: function(angle) {
        this.forEach(function(point) {
          point.rotate(angle);
        });

        this.calculateBounds();

        return this;
      },

      // compute bisectors and angles between each edge pair and its bisector
      computeBisectors: function() {
        // return if bisectors already calculated or if polygon is open
        if (this.bisectors !== null || !this.closed) return;

        this.bisectors = [];
        this.angles = [];

        var bisectors = this.bisectors;
        var angles = this.angles;
        var points = this.points;

        var ct = this.count();

        for (var i = 0; i < ct; i++) {
          var p1 = points[(i-1+ct)%ct];
          var p2 = points[i];
          var p3 = points[(i+1+ct)%ct];

          var b = MCG.Math.bisector(p1, p2, p3);

          bisectors.push(b);
          angles.push(p2.vectorTo(p3).angleTo(b));
        }
      },

      // offset, but the arguments are given in floating-point space
      foffset: function(fdist, ftol) {
        var context = this.context;
        var dist = MCG.Math.ftoi(fdist, context);
        var tol = ftol !== undefined ? MCG.Math.ftoi(ftol, context): 0;

        return this.offset(dist, tol);
      },

      // offset every point in the polygon by a given distance (positive for
      // outward, negative for inward, given in integer-space units)
      offset: function(dist, tol) {
        if (dist === 0) return this;

        var result = this.createNew();

        if (!this.valid()) return result;

        var size = this.size();
        var area = this.area;
        var minsize = Math.min(size.h, size.v);
        var fdist = MCG.Math.itof(dist, this.context);
        var tol = tol || 0;
        var tolsq = tol * tol;

        // invalid offset if:
        // normal poly and inward offset is too large, or
        // hole and outward offset is too large
        if (this.area > 0 && dist < -minsize / 2) return result;
        if (this.area < 0 && dist > minsize / 2) return result;

        this.computeBisectors();

        var bisectors = this.bisectors;
        var angles = this.angles;
        var points = this.points;
        var rpoints = [];
        var ct = points.length;

        var pi = Math.PI;
        var pi_2 = pi / 2;
        var capThreshold = pi * 5 / 6;
        var orthogonalRightVector = MCG.Math.orthogonalRightVector;
        var coincident = MCG.Math.coincident;

        for (var i = 0; i < ct; i++) {
          var b = bisectors[i];
          var pti = points[i];

          // angle between the offset vector and the neighboring segments (because
          // the angles array stores the angle relative to the outward-facing
          // bisector, which may be antiparallel to the offset vector)
          var a = fdist > 0 ? angles[i] : (pi - angles[i]);

          // should occur rarely - ignore this point if the angle is 0 because
          // dividing by sin(a) gives infinity
          if (a === 0) continue;

          // scale for bisector
          var d = fdist / Math.sin(a);
          // displace by this much
          var displacement = b.clone().multiplyScalar(d);
          // displaced point
          var ptnew = pti.clone().add(displacement);

          // if angle is too sharp, cap the resulting spike
          if (a > capThreshold) {
            // half-angle between displacement and vector orthogonal to segment
            var ha = (a - pi_2) / 2;

            // half-length of the cap for the spike
            var hl = fdist * Math.tan(ha);

            // orthogonal vector from the end of the displacement vector
            var ov = orthogonalRightVector(pti.vectorTo(ptnew));

            // midpoint of the cap
            var mc = pti.clone().addScaledVector(b, fdist);

            // endpoints of the cap segment
            var p0 = mc.clone().addScaledVector(ov, -hl);
            var p1 = mc.clone().addScaledVector(ov, hl);

            var fpt = fdist > 0 ? p0 : p1;
            var spt = fdist > 0 ? p1 : p0;

            rpoints.push(fpt);
            rpoints.push(spt);
          }
          else {
            rpoints.push(ptnew);
          }
        }

        // determine valid points

        var rlen = rpoints.length;
        var rlen1 = rlen - 1;
        var ri = 0;
        var mk = new Array(rlen);
        var lcs = MCG.Math.leftCompareStrict;

        // if displacement is larger than min polygon size, point is invalid;
        // else, if previous point is to the right of bisector or next point is
        // to its left, point is invalid
        for (var i = 0; i < points.length; i++) {
          var a = fdist > 0 ? angles[i] : (pi - angles[i]);

          if (a === 0) continue;

          if (a > capThreshold) {
            mk[ri++] = true;
            mk[ri++] = true;
          }
          else {
            var rpprev = rpoints[ri === 0 ? rlen1 : ri - 1];
            var rpnext = rpoints[ri === rlen1 ? 0 : ri + 1];
            var rp = rpoints[ri];

            var p = points[i];

            //if (rpprev.distanceToSq(rp) < tolsq / 4) mk[ri] = false;

            // validity check that's true if both neighboring offset vertices are
            // on the correct side of the current bisector
            mk[ri] = lcs(p, rp, rpprev) === -1 && lcs(p, rp, rpnext) === 1;
            // reverse if inward offset
            if (dist < 0) mk[ri] = !mk[ri];

            ri++;
          }
        }

        result.fromPoints(rpoints, mk);

        // if result area is too small, invalidate it
        if (Math.abs(result.area) < tolsq) return result.invalidate();

        return result;
      },

      fdecimate: function(ftol) {
        var tol = MCG.Math.ftoi(ftol, this.context);

        return this.decimate(tol);
      },

      // reduce vertex count
      // source: http://geomalgorithms.com/a16-_decimate-1.html
      // NB: this mutates the polygon
      decimate: function(tol) {
        if (tol <= 0) return this;

        // source points
        var spts = this.points;

        // first, decimate by vertex reduction
        var vrpts = decimateVR(spts, tol);

        this.fromPoints(vrpts);

        if (Math.abs(this.area) < tol * tol / 4) this.invalidate();

        return this;

        function decimateVR(pts, tol) {
          var ct = pts.length;
          var tolsq = tol * tol;

          // index of the reference point
          var refidx = 0;

          // result points
          var rpts = [];
          rpts.push(pts[0]);

          for (var si = 1; si < ct; si++) {
            var spt = pts[si];

            // if distance is < tolerance, ignore the point
            if (pts[refidx].distanceToSq(spt) < tolsq) continue;

            // else, include it and set it as the new reference point
            rpts.push(spt);
            refidx = si;
          }

          return rpts;
        }

        function decimateCollinear(pts, tol) {
          var ct = pts.length;
          var ct1 = ct - 1;
          var tolsq = tol * tol;

          // result points
          var rpoints = [];

          var narea = MCG.Math.narea;

          for (var si = 0; si < ct; si++) {
            var pt0 = si === 0 ? pts[ct1] : pts[si-1];
            var pt1 = pts[si];
            var pt2 = si === ct1 ? pts[0] : pts[si+1];

            if (narea(pt0, pt1, pt2) < tolsq) rpoints.push(pt1);
          }

          return rpoints;
        }

        function decimateDP(pts, tol) {
          var ct = pts.length;

          // marker array
          var mk = new Array(ct);
          mk[0] = mk[ct-1] = true;

          // build the mk array
          decimateDPRecursive(pts, mk, tol, 0, ct-1);

          // result points
          var rpts = [];

          // if a point is marked, include it in the result
          for (var i = 0; i < ct; i++) {
            if (mk[i]) rpts.push(pts[i]);
          }

          return rpts;
        }

        // recursive Douglas-Peucker procedure
        function decimateDPRecursive(pts, mk, tol, i, j) {
          if (i >= j-1) return;

          var tolsq = tol * tol;
          var maxdistsq = 0;
          var idx = -1;

          var distanceToLineSq = MCG.Math.distanceToLineSq;
          var pti = pts[i], ptj = pts[j];

          for (var k = i+1; k < j; k++) {
            var distsq = distanceToLineSq(pti, ptj, pts[k]);
            if (distsq > maxdistsq) {
              maxdistsq = distsq;
              idx = k;
            }
          }

          if (distsq > tolsq) {
            mk[idx] = true;

            decimateDPRecursive(pts, mk, tol, i, idx);
            decimateDPRecursive(pts, mk, tol, idx, j);
          }
        }
      }

    });

    return Polygon;

  })();
  MCG.PolygonSet = (function() {

    function PolygonSet(context) {
      MCG.GeometrySet.call(this, context);

      this.type = MCG.Types.polygonSet;
    }

    PolygonSet.prototype = Object.create(MCG.GeometrySet.prototype)

    Object.assign(PolygonSet.prototype, {

      constructor: PolygonSet,

      forEachPoint: function(f) {
        this.forEach(function(polygon) {
          if (polygon.valid()) polygon.forEach(f);
        });
      },

      forEachPointPair: function(f) {
        this.forEach(function(polygon) {
          if (polygon.valid()) polygon.forEachPointPair(f);
        });
      },

      forEachSegmentPair: function(f) {
        this.forEach(function(polygon) {
          if (polygon.valid()) polygon.forEachSegmentPair(f);
        });
      },

      computeBisectors: function() {
        this.forEach(function(polygon) {
          if (polygon.valid()) polygon.computeBisectors();
        });
      },

      foffset: function(fdist, ftol) {
        var polygonSet = new this.constructor(this.context);

        this.forEach(function(polygon) {
          var offset = polygon.foffset(fdist, ftol);

          if (offset.valid()) polygonSet.add(offset);
        });

        return polygonSet;
      },

      offset: function(dist, tol) {
        var polygonSet = new this.constructor(this.context);

        this.forEach(function(polygon) {
          var offset = polygon.offset(dist, tol);

          if (offset.valid()) polygonSet.add(offset);
        });

        return polygonSet;
      },

      fdecimate: function(ftol) {
        this.forEach(function(polygon) {
          polygon.fdecimate(ftol);
        });

        // remove invalid polygons
        this.filter(function(polygon) {
          return polygon.valid();
        });

        return this;
      },

      decimate: function(tol) {
        this.forEach(function(polygon) {
          polygon.decimate(tol);
        });

        // remove invalid polygons
        this.filter(function(polygon) {
          return polygon.valid();
        });

        return this;
      },

      pointCount: function() {
        var count = 0;

        this.forEach(function(polygon) {
          count += polygon.count();
        });

        return count;
      }

    });

    return PolygonSet;

  })();
  MCG.Segment = (function() {

    function Segment(context, p1, p2) {
      this.context = context;

      this.p1 = p1;
      this.p2 = p2;

      this.type = MCG.Types.segment;
    }

    Object.assign(Segment.prototype, {

      fromVector3Pair: function(v1, v2, normal) {
        var context = this.context;

        var p1 = new MCG.Vector(context).fromVector3(v1);
        var p2 = new MCG.Vector(context).fromVector3(v2);

        if (MCG.Math.coincident(p1, p2)) return this;

        // if normal not given, assign points in given order
        if (normal === undefined) {
          this.p1 = p1;
          this.p2 = p2;
        }
        // if normal given, use it to assign points s.t. polygon is on the left
        // when traversing from v1 to v2
        else {
          // determine which way the winding order points
          var cross = context.up.clone().cross(normal);
          var dot = cross.dot(v2.clone().sub(v1));

          this.p1 = dot > 0 ? p1 : p2;
          this.p2 = dot > 0 ? p2 : p1;
        }

        return this;
      },

      valid: function() {
        if (!(this.p1 && this.p2)) return false;

        return !MCG.Math.coincident(this.p1, this.p2);
      },

      clone: function(recursive) {
        var p1 = recursive ? this.p1.clone() : this.p1;
        var p2 = recursive ? this.p2.clone() : this.p2;

        return new this.constructor(this.context, p1, p2);
      },

      rotate: function(angle) {
        this.p1.rotate(angle);
        this.p2.rotate(angle);

        return this;
      },

      updateBoundsFromThis: function(min, max) {
        min.min(this.p1);
        max.max(this.p1);
        min.min(this.p2);
        max.max(this.p2);
      },

      lengthSq: function() {
        return this.p1.distanceToSq(this.p2);
      },

      length: function() {
        return this.pq.distanceTo(this.p2);
      }

    });

    return Segment;

  })();
  MCG.SegmentSet = (function() {

    function SegmentSet(context) {
      MCG.GeometrySet.call(this, context);

      this.type = MCG.Types.segmentSet;
    }

    SegmentSet.prototype = Object.create(MCG.GeometrySet.prototype);

    Object.assign(SegmentSet.prototype, {

      constructor: SegmentSet,

      addPointPair: function(p1, p2) {
        this.add(new MCG.Segment(this.context, p1, p2));
      },

      forEachPointPair: function(f) {
        var segments = this.elements;
        var ct = this.count();

        for (var i = 0; i < ct; i++) {
          var s = segments[i];
          f(s.p1, s.p2);
        }
      },

      makeAdjacencyMap: function() {
        var adjacencyMap = new MCG.DirectedAdjacencyMap(this.context);

        var segments = this.elements;
        var ns = segments.length;

        for (var si = 0; si < ns; si++) {
          adjacencyMap.addSegment(segments[si]);
        }

        return adjacencyMap;
      },

      toPolygonSet: function() {
        var context = this.context;

        var pset = new MCG.PolygonSet(context);

        var adjacencyMap = this.makeAdjacencyMap();

        var loops = adjacencyMap.getLoops();
        for (var li = 0; li < loops.length; li++) {
          var polygon = new MCG.Polygon(context, loops[li]);
          if (polygon.valid()) pset.add(polygon);
        }

        return pset;
      },

      pointCount: function() {
        return this.count() * 2;
      }

    });

    return SegmentSet;

  })();
  Object.assign(MCG.Sweep, (function() {

    var printEvents = false;
    var drawEvents = false;

    function sweep(operation, srcA, srcB) {
      if (!srcA) return null;

      var context = srcA.context;

      var axis = context.axis;
      var ah = context.ah;
      var av = context.av;
      var p = context.p;

      // the farthest event that has occurred (with respect to the scanline) -
      // used to catch events that occur in the past
      var front = null;

      var efactory = new MCG.Sweep.SweepEventFactory(context);

      var store = operation.initStore(context, srcA, srcB);
      var handleIntersections = store.handleIntersections;
      var dbg = store.dbg;

      // priority queue storing events from left to right
      var events = new PriorityQueue({
        comparator: function(a, b) { return a.sweepcompare(b); }
      });

      // structure storing the sweep line status
      var status = new RBTree(
        function(a, b) { return a.linecompare(b); }
      );

      // add events for srcA
      srcA.forEachPointPair(addPointPairA);

      // if available, also add for srcB
      if (srcB !== undefined) srcB.forEachPointPair(addPointPairB);

      // process events in order

      var ct = 0;

      while (events.length > 0) {
        var ev = dequeue();

        updateFront(ev);

        if (ev.hvcompare(front) < 0) break;

        if (ev.isLeft) {
          if (!ev.contributing) continue;

          ev.setT(ct++);

          var ins = insert(ev);

          var [up, dn] = eventGetAdjacent(ev);

          // if the up event has the same starting point, it's possible that it
          // was initially below the current event in slope but then became above
          // in slope due to an intersection, so now the current is is below when
          // it should've been above according to the initial placement in the
          // queue; requeue both and continue
          if (up && ev.hvcompare(up) === 0) {
            requeue(up);
            requeue(ev);

            continue;
          }

          ev.setDepthFromBelow(dn);

          if (handleIntersections) {
            handleEventIntersection(ev, dn);
            handleEventIntersection(up, ev);
          }
        }
        else {
          var tev = ev.twin;

          if (!tev.contributing) continue;

          handleRightEvent(ev);

          var up = null, dn = null;

          // removing an event causes its two adjacent events to become adjacent
          // to each other, so they may intersect
          [up, dn] = eventGetAdjacent(tev);

          remove(tev);

          // handle possible intersection
          if (handleIntersections) handleEventIntersection(up, dn);
        }
      }

      return store.result;


      // create an event pair for a p1-p2 segment
      function createPointPair(p1, p2, wA, wB) {
        if (MCG.Math.coincident(p1, p2)) return null;

        // determine direction: if dir, p1 is left and p2 is right; reverse if !dir
        var vertical = p1.h === p2.h;
        var dir = vertical ? (p1.v < p2.v) : (p1.h < p2.h);

        // make events
        var el = efactory.createLeft(dir ? p1 : p2);
        var er = efactory.createRight(dir ? p2 : p1);

        // weight is +1 if vertical line going up through p1 -> p2 edge transitions
        // from outside polygon to inside, else -1)
        el.weightA = dir ? wA : -wA;
        el.weightB = dir ? wB : -wB;

        // link events to each other
        el.twin = er;
        er.twin = el;

        return el;
      }

      // create and queue an event pair for a p1-p2 segment
      function addPointPair(p1, p2, wA, wB) {
        var el = createPointPair(p1, p2, wA, wB);

        if (el === null) return null;

        var er = el.twin;

        queue(el);
        queue(er);

        return el;
      }

      // functions for adding source A and B
      function addPointPairA(p1, p2) {
        return addPointPair(p1, p2, 1, 0);
      }
      function addPointPairB(p1, p2) {
        return addPointPair(p1, p2, 0, 1);
      }

      // if an event pair's left-right events are in the incorrect order (this can
      // potentially occur when splitting events), recreate the event pair in the
      // correct order
      function handleSwappedEventPair(ev) {
        var tev = ev.twin;

        if (ev.hvcompare(tev) < 0) return ev;

        eventInvalidate(ev);

        var el = createPointPair(tev.p, ev.p);
        if (el === null) return null;

        // assign weight and depth
        el.setWeightFrom(ev, true);
        el.depthBelowA = ev.depthBelowA + ev.weightA;
        el.depthBelowB = ev.depthBelowB + ev.weightB;

        return el;
      }

      function eventGetAdjacent(ev) {
        var it = status.findIter(ev);

        // if event not found for some reason, check if it's actually present;
        // this is an error
        if (!it) {
          var present = false;
          var iter = status.iterator();
          var e;
          while ((e=iter.next()) !== null) {
            if (e==ev) {
              present = true;
              break;
            }
          }
          if (present) {
            //throw "failed to find event in status " + ev.id + " " + ev.twin.id;
            console.log("failed to find event in status", ev.id, ev.twin.id);
            return;
          }
        }

        var prev = null, next = null;
        if (it) {
          prev = it.prev();
          it.next();
          next = it.next();
        }

        return [next, prev];
      }

      function queue(e) {
        if (e === null) return false;
        return events.queue(e);
      }

      function dequeue() {
        return events.dequeue();
      }

      function requeue(e) {
        if (e === null) return null;

        remove(e);
        return queue(e);
      }

      function insert(e) {
        if (!e.contributing) return;

        var ins = status.insert(e);

        return ins;
      }

      function remove(e) {
        var rm = status.remove(e);

        return rm;
      }

      function handleRightEvent(e) {
        var te = e.twin;

        operation.handleEvent(te, status, store);
        eventInvalidate(te);
      }

      function updateFront(ev) {
        if (front === null || ev.hvcompare(front) > 0) front = ev;
      }

      // if two segments are exactly coincident, merge b into a and invalidate b
      function mergeEvents(a, b) {
        a.setDepthFrom(b);
        a.addWeightFrom(b);

        eventInvalidate(b);

        if (a.zeroWeight()) {
          eventInvalidate(a);
          return null;
        }
        else {
          return a;
        }
      }

      // handle a possible intersection between a pair of left events;
      // event a is above event b
      function handleEventIntersection(a, b) {
        if (a === null || b === null) return null;
        if (!a.contributing || !b.contributing) return null;

        var ta = a.twin, tb = b.twin;
        var coincident = MCG.Math.coincident;

        // h-v comparison of start points and end points - 0 if coincident
        var hvcomp = a.hvcompare(b);
        var thvcomp = ta.hvcompare(tb);

        // if two segments are exactly coincident, merge b into a and invalidate b
        if (hvcomp === 0 && thvcomp === 0) {
          remove(a);
          remove(b);

          a = mergeEvents(a, b);
          if (a !== null) {
            insert(a);
            return a.p;
          }
          else return null;
        }

        var flags = MCG.Math.IntersectionFlags;
        var intersection = a.intersects(b);

        if (intersection === flags.none) return null;

        var pa = a.p, pb = b.p;
        var pta = ta.p, ptb = tb.p;

        // if events are not horizontal and have no vertical overlap, return
        if (!a.horizontal() && !b.horizontal()) {
          if (Math.max(pa.v, pta.v) < Math.min(pb.v, ptb.v)) return null;
          if (Math.max(pb.v, ptb.v) < Math.min(pa.v, pta.v)) return null;
        }

        // point of intersection
        var pi = null;

        // if intersection is somewhere along both segments, calculate it
        if (intersection === flags.intermediate) {
          pi = a.intersection(b);
        }
        // else, if starting points aren't coincident and intersection includes
        // one or both of them, set intersection point to one of them
        else if ((hvcomp !== 0) && (intersection & flags.a0b0)) {
          var ia0 = intersection & flags.a0;
          var ib0 = intersection & flags.b0;

          // if potential intersection on either start point, pick the later one
          if (ia0 && ib0) pi = hvcomp > 0 ? pa : pb;
          else if (ia0) pi = pa;
          else if (ib0) pi = pb;
        }
        // else, if ending points aren't coincident and intersection includes
        // one or both of them, set intersection point to one of them
        else if ((thvcomp !== 0) && (intersection & flags.a1b1)) {
          var ia1 = intersection & flags.a1;
          var ib1 = intersection & flags.b1;

          // if potential intersection on either end point, pick the earlier one
          if (ia1 && ib1) pi = thvcomp > 0 ? ptb : pta;
          else if (ia1) pi = pta;
          else if (ib1) pi = ptb;
        }

        // return if no intersection
        if (pi === null) return null;

        // coincidence of intersection point with endpoints
        var ca = coincident(pi, pa), cta = coincident(pi, pta);
        var cb = coincident(pi, pb), ctb = coincident(pi, ptb);

        // if intersection point is earlier than the front, need to shift it so
        // that it's at least in the present
        var fphvcomp = front.hvcomparept(pi);
        if (fphvcomp > 0) {
          var h = Math.max(pi.h, front.p.h) + 1;
          var t = b.vertical() ? a : b;

          pi = t.interpolate(h);

          // update coincidence flags
          ca = coincident(pi, pa), cta = coincident(pi, pta);
          cb = coincident(pi, pb), ctb = coincident(pi, ptb);
        }

        // if intersection point is established, split one or both segments
        if (pi !== null) {

          // remove both events - due to numeric imprecision, their place in the
          // status structure may change after splitting
          var rma = remove(a);
          var rmb = remove(b);

          // new events formed by a split
          var ita = null, itb = null;

          // if intersection point is not on either endpoint of a, split a
          if (!(ca || cta)) {
            ita = eventSplit(a, pi);

            a = handleSwappedEventPair(a);
            ita = handleSwappedEventPair(ita);

            queue(ita);
            queue(ita.twin);
          }

          // likewise for b
          if (!(cb || ctb)) {
            itb = eventSplit(b, pi);

            b = handleSwappedEventPair(b);
            itb = handleSwappedEventPair(itb);

            queue(itb);
            queue(itb.twin);
          }

          // a and b may have become coincident; if so, merge them and return
          if (a.segmentsCoincident(b)) {
            a = mergeEvents(a, b);

            if (a !== null) {
              insert(a);
              return a.p;
            }
            else return null;
          }

          ta = a.twin;
          tb = b.twin;

          var ia = false, ib = false;

          // if a's twin is before or at the front, a is entirely in the past, so
          // handle its right event immediately
          if (front.hvcompare(ta) >= 0) handleRightEvent(ta);
          // else, if a split b, it may not have the correct depth, so requeue it
          else if (ca) queue(a);
          // else, just insert it back
          else if (rma) ia = insert(a);

          // likewise for b
          if (front.hvcompare(tb) >= 0) handleRightEvent(tb);
          else if (cb) queue(b);
          else if (rmb) ib = insert(b);

          // error correction: it's possible that initially adjacent events are
          // reinserted into the status structure but now they're not neighbors
          // (b appears below some events sharing the same start point, and/or
          // a appears above some events sharing the same start point)
          if (ia && ib) {
            var iter = status.findIter(b);
            var next = iter.next();

            if (next !== a) {
              // prev initialized to event below b, curr initialized to b
              iter.prev();
              var prev = iter.prev();
              var curr = iter.next();

              // iterate upward from b, updating depths for all events that cross
              // the scanline at the same point b/c those used to be above b
              while (curr && curr.vlinecompare(b) === 0 && curr !== a) {
                curr.setDepthFromBelow(prev);

                prev = curr;
                curr = iter.next();
              }
            }
          }
        }

        return pi;
      }

      function eventInvalidate(e) {
        e.setNoncontributing();
      }

      // given the left endpoint e of an event pair, split it at vertex pi
      // returns newly created left event
      function eventSplit(e, pi) {
        var te = e.twin;

        // right and left events at intersection point
        var ei = efactory.clone(te, pi);
        var ite = efactory.clone(e, pi);

        e.twin = ei;
        te.twin = ite;

        queue(ei);

        return ite;
      }

      // left event is valid if
      // 1. it is contributing,
      // 2. one side has depth 0 and the other has positive depth
      // (right events are not guaranteed to carry the correct depth information)
      function eventValid(e) {
        if (!e.isLeft) e = e.twin;

        if (!e.contributing) return false;

        var pos = e.getPosition();

        return pos & MCG.Sweep.EventPositionFlags.boundaryAB;
      }

      function statusString() {
        var iter = status.iterator();
        var r = "[ ";
        var e;

        while ((e = iter.next())!==null) {
          r += e.id + " ";
        }
        r += "]";

        return r;
      }

      function statusPrintShort(force) {
        if (!printEvents && !force) return;

        console.log(statusString());
      }

      function statusPrint(vmin, vmax, force) {
        if (!printEvents && !force) return;

        if (vmin === undefined) vmin = -Infinity;
        if (vmax === undefined) vmax = Infinity;

        var iter = status.iterator(), e, p = null;
        while ((e = iter.prev()) !== null) {
          if (e.p.v < vmin || e.p.v > vmax) continue;
          if (p) eventPairComparisonPrint(p, e, force);
          eventPrint(e, ">N", force);
          p = e;
        }
      }

      function eventPairComparisonPrint(ep, ee, force) {
        if (!printEvents && !force) return;

        var lc = MCG.Math.leftCompare;
        if (printEvents && ep && ee) {
          var ef = ep.p.h < ee.p.h ? ep : ee;
          var es = ep.p.h < ee.p.h ? ee : ep;
          console.log(
            ep.linecompare(ee), ee.linecompare(ep),
            ep.vlinecompare(ee), ee.vlinecompare(ep),
            ep.scompare(ee), ee.scompare(ep),
            lc(ep.p, ep.twin.p, ee.twin.p), lc(ee.p, ee.twin.p, ep.twin.p),
            lc(ep.p, ep.twin.p, ee.p), lc(ee.p, ee.twin.p, ep.p),
            ee.intersects(ep),
            ef.interpolate(es.p.h).h, ef.interpolate(es.p.h).v
          );
        }
      }

      function statusDraw(ev, factor, d, force) {
        if (!drawEvents && !force) return;

        var iter = status.iterator(), e;
        var vmin = Infinity, vmax = -Infinity;
        var ctx = Object.assign({}, context);
        ctx.d += d;
        while ((e = iter.next()) !== null) {
          var ep = e.p, etp = e.twin.p;
          vmin = Math.min(vmin, ep.v, etp.v);
          vmax = Math.max(vmax, ep.v, etp.v);
          var epc = ep.clone().multiplyScalar(factor);
          var etpc = etp.clone().multiplyScalar(factor);
          debug.line(epc.toVector3(THREE.Vector3, ctx), etpc.toVector3(THREE.Vector3, ctx), 1, false, 0, ctx.axis);
        }

        var top = ev.p.clone().setV(vmax).multiplyScalar(factor);
        var bot = ev.p.clone().setV(vmin).multiplyScalar(factor);
        debug.line(top.toVector3(THREE.Vector3, ctx), bot.toVector3(THREE.Vector3, ctx), 1, false, 0, ctx.axis);
      }

      function statusValid() {
        var iter = status.iterator(), e, p = null;
        while ((e = iter.prev()) !== null) {
          if (p) {
            var cpe = p.linecompare(e);
            var cep = e.linecompare(p);
            if (cpe === cep || cpe === 0 || cep === 0) return false;
          }
          p = e;
        }

        return true;
      }

      function eventPrint(e, pref, force) {
        if (!force && !printEvents) return;

        if (e===null) console.log(pref, "null");
        else if (e===undefined) console.log(pref, "undefined");
        else console.log(e.toString(pref));
      }

      function eventDraw(e, offset, color, force) {
        if (!e || (!force && !drawEvents)) return;

        offset = offset || 0;
        color = color || eventColor(e);
        debug.oneline(e.p.toVector3(THREE.Vector3, context), e.twin.p.toVector3(THREE.Vector3, context), offset, axis, color);
      }

      function eventColor(e) {
        if (!e.isLeft) {
          if (eventValid(e.twin)) return 0x66ff66;
          else return 0xff0000;
        }
        else if (e.contributing) return 0xff6666;
        else return 0x6666ff;
      }
    }

    return {
      sweep: sweep
    };

  })());
  Object.assign(MCG.Sweep, (function() {

    // signifies an event's position (inside a polygon or at a boundary or neither)
    var EventPositionFlags = {
      none: 0,
      // inside polygon A
      insideA: 1,
      // inside polygon B
      insideB: 2,
      // on the border of A (crosses from non-positive to positive or vice versa)
      boundaryA: 4,
      // on the border of B
      boundaryB: 8,
      // transition from inside A to inside B (or vice versa)
      fromAtoB: 16
    };



    function SweepEvent(p, id) {
      // MCG.Vector at which this event is located
      this.p = p;

      // store parent for testing collinearity and slopes - this prevents drift
      // from multiple split points snapping to the integer grid
      this.parent = this;

      // used as a last-resort ordering criterion for events; the factory
      // guarantees that event ids are unique
      this.id = id !== undefined ? id : -1;

      this.isLeft = false;
      this.twin = null;
    }

    Object.assign(SweepEvent.prototype, {

      clone: function(p, id) {
        var e = new this.constructor(p);

        // copy properties and set point
        Object.assign(e, this);
        e.p = p;
        e.id = id !== undefined ? id : -1;
        e.t = -1;

        return e;
      },

      isParent: function() {
        return this === this.parent;
      },

      vertical: function() {
        return this.p.h === this.twin.p.h;
      },

      horizontal: function() {
        return this.p.v === this.twin.p.v;
      },

      // determine which of two events comes first in a left-right sweep
      sweepcompare: function(other) {
        var a = this, b = other;

        // in case events are the same
        if (a.id === b.id) return 0;

        // primary sorting on horizontal coordinate (x if up axis is z)
        // secondary sorting on vertical coordinate (y if up axis is z)
        var hvcomp = a.hvcompare(b);
        if (hvcomp !== 0) return hvcomp;

        // tertiary sorting on left/right (right goes first so that, given two
        //   segments sharing an endpoint but with no vertical overlap, the first
        //   segment leaves the sweep status structure before the next goes in)
        var lrcomp = a.lrcompare(b);
        if (lrcomp !== 0) return lrcomp;

        // quaternary sorting on slope (increasing)
        var scomp = a.scompare(b);
        if (scomp !== 0) return scomp;

        // comparison based on parent extents
        var pcomp = a.pcompare(b);
        if (pcomp !== 0) return pcomp;

        return Math.sign(a.id - b.id);
      },

      // comparison for two left events along a vertical line passing through both
      // at the earliest point where they have vertical overlap (i.e., horizontal
      // coordinate of the later event)
      linecompare: function(other) {
        var a = this, b = other;

        // in case events are the same
        if (a.id === b.id) return 0;

        // primary sorting on vertical coordinate at the start of the later event
        // (y if up axis is z)
        var vcomp = a.vlinecompare(b);
        if (vcomp !== 0) return vcomp;

        // secondary sorting on slope
        var scomp = a.scompare(b);
        if (scomp !== 0) return scomp;

        // tertiary sorting on time
        var tcomp = a.tcompare(b);
        if (tcomp !== 0) return tcomp;

        // comparison based on parent extents
        var pcomp = a.pcompare(b);
        if (pcomp !== 0) return pcomp;

        return Math.sign(a.id - b.id);
      },

      // return horizontal comparison
      hcompare: function(other) {
        return this.p.hcompare(other.p);
      },

      // return vertical comparison
      vcompare: function(other) {
        return this.p.vcompare(other.p);
      },

      // return horizontal comparison if unequal; else, return vertical comparison
      hvcompare: function(other) {
        var a = this, b = other;
        var pa = a.p, pb = b.p;

        var hcomp = pa.hcompare(pb);
        if (hcomp !== 0) return hcomp;

        return pa.vcompare(pb);
      },

      hvcomparept: function(pt) {
        var pa = this.p;

        var hcomp = pa.hcompare(pt);
        if (hcomp !== 0) return hcomp;

        return pa.vcompare(pt);
      },

      // return left-right comparison for two events (right goes first)
      lrcompare: function(other) {
        if (!this.isLeft && other.isLeft) return -1;
        else if (this.isLeft && !other.isLeft) return 1;
        else return 0;
      },

      // returns slope comparison for two events that share at least one point:
      //   a's slope is greater if a's twin is to b-b.twin's left (above b);
      //   a's slope is less if a's twin is to b-b.twin's right (below b);
      //   equal slopes if collinear
      scompare: function(other) {
        var a = this.isLeft ? this : this.twin;
        var b = other.isLeft ? other : other.twin;

        // basic checks if one or both are vertical
        var va = a.vertical(), vb = b.vertical();

        if (va && vb) return 0;
        else if (!va && vb) return -1;
        else if (va && !vb) return 1;

        var pa = a.p, pta = a.twin.p;
        var pb = b.p, ptb = b.twin.p;

        // if start points coincident, use strict left comparison
        if (MCG.Math.coincident(pa, pb)) {
          var ls = MCG.Math.leftCompareStrict(pb, ptb, pta);
          return ls < 0 ? -1 : ls > 0 ? 1 : 0
        }

        var lc = MCG.Math.leftCompare;

        var lta = lc(pb, ptb, pta);
        var ltb = lc(pa, pta, ptb);

        if (lta === -1 || ltb === 1) return -1;
        if (lta === 1 || ltb === -1) return 1;

        var la = lc(pb, ptb, pa);
        var lb = lc(pa, pta, pb);

        if (la === 1 || lb === -1) return -1;
        if (la === -1 || lb === 1) return 1;

        return 0;
      },

      tcompare: function(other) {
        return Math.sign(this.t - other.t);
      },

      // returns comparison between two left/two right events based on their
      // parent extents
      pcompare: function(other) {
        var a = this, b = other;

        // parent comparison function
        var pcompare = a.vertical() || b.vertical() ? "vcompare" : "hcompare";

        var pcomp = a.parent.p[pcompare](b.parent.p);
        if (pcomp !== 0) return pcomp;

        var ptcomp = a.twin.parent.p[pcompare](b.twin.parent.p);
        if (ptcomp !== 0) return ptcomp;

        return 0;
      },

      toString: function(pref) {
        var src = this.isLeft ? this : this.twin;
        var pst = (src.weightA!==0?"A":"-") + (src.weightB!==0?"B":"-");
        pref = (pref || pst);

        var d = 4;
        var diff = src.p.vectorTo(src.twin.p);
        var slope = src.vertical() ? Infinity : diff.v/diff.h;
        var cslope = slope===Infinity
          ? "v"
          : (slope===0
            ? 0
            : (Math.sign(slope)==1
              ? "+"+(slope>0.5 ? "^" : ">")
              : "-"+(slope<-0.5 ? "v" : ">")));
        var t = (this.isLeft ? this.t : "")+" ";

        var data =
          [t, this.isLeft ? "L " : "R ", this.id, this.twin.id,
            '(', this.p.h,
            this.p.v, ')',
            '(', this.twin.p.h,
            this.twin.p.v, ')',
            cslope, slope.toFixed(6),
            this.p.vectorTo(this.twin.p).length().toFixed(0),
            "w", src.weightA, src.weightB,
            "d", src.depthBelowA, src.depthBelowA+src.weightA, src.depthBelowB, src.depthBelowB+src.weightB,
            src.contributing ? "t" : "f"];
        var p =
          [5, 1, 5, 5,
            2, d+3,
            d+3, 1,
            2, d+3,
            d+3, 1,
            2, 10,
            9,
            2, 2, 2,
            2, 4, 4, 4, 4,
            1]
        var r = "";
        for (var d=0; d<data.length; d++) r += lpad(data[d], p[d]);

        return pref + " " + r;

        function lpad(s, n) {
          n++;
          var ss = ""+s;
          var l = ss.length;
          return " ".repeat(Math.max(n-l, 0)) + ss;
        }
      }

    });


    function RightSweepEvent(p, id) {
      SweepEvent.call(this, p, id);
    }

    RightSweepEvent.prototype = Object.create(SweepEvent.prototype);
    Object.assign(RightSweepEvent.prototype, {
      constructor: RightSweepEvent
    });


    function LeftSweepEvent(p, id) {
      SweepEvent.call(this, p, id);

      this.isLeft = true;

      this.depthBelowA = 0;
      this.weightA = 0;
      this.depthBelowB = 0;
      this.weightB = 0;

      this.contributing = true;

      // time at which the event occurs; used as a tiebreaker to position more
      // recent events above past events
      this.t = -1;
    }

    LeftSweepEvent.prototype = Object.create(SweepEvent.prototype);

    Object.assign(LeftSweepEvent.prototype, {

      constructor: LeftSweepEvent,

      setT: function(t) {
        this.t = t;

        return this;
      },

      setDepthFromBelow: function(below) {
        this.depthBelowA = below !== null ? below.depthBelowA + below.weightA : 0;
        this.depthBelowB = below !== null ? below.depthBelowB + below.weightB : 0;
      },

      setDepthFrom: function(other) {
        this.depthBelowA = other.depthBelowA;
        this.depthBelowB = other.depthBelowB;
      },

      setWeightFrom: function(other, negate) {
        this.weightA = negate ? -other.weightA : other.weightA;
        this.weightB = negate ? -other.weightB : other.weightB;
      },

      addWeightFrom: function(other) {
        this.weightA += other.weightA;
        this.weightB += other.weightB;
      },

      zeroWeight: function() {
        return this.weightA === 0 && this.weightB === 0;
      },

      // get a status code that indicates the event's position (inside or at the
      // boundary of one of the polygons)
      getPosition: function(minDepthA, minDepthB) {
        var flags = EventPositionFlags;
        var mdA = minDepthA || 1;
        var mdB = minDepthB || 1;

        if (!this.contributing) return flags.none;

        var wA = this.weightA, wB = this.weightB;

        // depths above and below for A
        var dbA = this.depthBelowA;
        var daA = dbA + wA;

        // depths above and below for B
        var dbB = this.depthBelowB;
        var daB = dbB + wB;

        var result = flags.none;

        var boundaryA = (daA < mdA && dbA >= mdA) || (daA >= mdA && dbA < mdA);
        var boundaryB = (daB < mdB && dbB >= mdB) || (daB >= mdB && dbB < mdB);
        var signChange = Math.sign(wA) === -Math.sign(wB);

        if (dbA >= mdA && daA >= mdA) result |= flags.insideA;
        if (dbB >= mdB && daB >= mdB) result |= flags.insideB;
        if (boundaryA) result |= flags.boundaryA;
        if (boundaryB) result |= flags.boundaryB;
        if (boundaryA && boundaryB && signChange) result |= flags.fromAtoB;

        return result;
      },

      addSegmentToSet: function(s, invert, weight) {
        var w = weight === undefined ? this.weightA + this.weightB : weight;

        var pf = w < 0 ? this.twin.p : this.p;
        var ps = w < 0 ? this.p : this.twin.p;

        if (invert) s.addPointPair(ps, pf);
        else s.addPointPair(pf, ps);
      },

      // return vertical axis comparison for two left events at the later event's
      // horizontal coordinate
      vlinecompare: function(other) {
        var a = this, b = other;
        var pa = a.p, pb = b.p;
        var pta = a.twin.p, ptb = b.twin.p;
        var pah = pa.h, pbh = pb.h;
        var ptah = pta.h, ptbh = ptb.h;
        var pav = pa.v, pbv = pb.v;

        // if events horizontally coincident, just test the vertical coordinate
        if (pah === pbh) return pa.vcompare(pb);

        // if the end of one is horizontally coincident with the other's start,
        // test that directly
        if (pah === ptbh) return pa.vcompare(ptb);
        if (ptah === pbh) return pta.vcompare(pb);

        var ptav = a.twin.p.v, ptbv = b.twin.p.v;

        // if no vertical overlap, decide by which is higher/lower
        if (Math.max(pav, ptav) < Math.min(pbv, ptbv)) return -1;
        if (Math.max(pbv, ptbv) < Math.min(pav, ptav)) return 1;

        // first and second events by horizontal coordinate
        var f = pah < pbh ? a : b;
        var s = pah < pbh ? b : a;
        var ps = s.p;

        if (0) {
          var lc = MCG.Math.leftCompare(f.p, f.twin.p, s.p);
          if (pah < pbh) lc *= -1;
          return lc;
        }

        var v = f.interpolate(ps.h).v;

        var result = Math.sign(ps.v - v);

        // flip result if necessary
        if (pah < pbh) result *= -1;

        return result;
      },

      // interpolate a (non-vertical) left event's segment to a given horizontal
      // coordinate
      interpolate: function(h) {
        var context = this.p.context;
        var pa = this.p, pat = this.twin.p;

        var v = pa.v + (pat.v - pa.v) * (h - pa.h) / (pat.h - pa.h);

        return new MCG.Vector(context, h, v);
      },

      hcontains: function(h) {
        return this.p.h <= h && h <= this.twin.p.h;
      },

      vcontains: function(v) {
        return this.p.v <= v && v <= this.twin.p.v;
      },

      contains: function(p) {
        return this.hcontains(p.h) || this.vcontains(p.v);
      },

      collinear: function(other) {
        var a = this, b = other;

        var pa = a.p, pat = a.twin.p;
        var pb = b.p, pbt = b.twin.p;

        // verify that the event pairs actually overlap
        if (a.horizontal() && b.horizontal()) {
          if (Math.max(pa.h, pat.h) <= Math.min(pb.h, pbt.h)) return false;
          if (Math.max(pb.h, pbt.h) <= Math.min(pa.h, pat.h)) return false;
        }
        else {
          if (Math.max(pa.v, pat.v) <= Math.min(pb.v, pbt.v)) return false;
          if (Math.max(pb.v, pbt.v) <= Math.min(pa.v, pat.v)) return false;
        }

        if (a.vertical() && b.vertical()) return true;

        var collinear = MCG.Math.collinear;

        return collinear(pa, pat, pb) && collinear(pa, pat, pbt);
      },

      endpointsCoincident: function(other) {
        if (MCG.Math.coincident(this.p, other.p)) return true;
        if (MCG.Math.coincident(this.twin.p, other.twin.p)) return true;

        return false;
      },

      segmentsCoincident: function(other) {
        var coincident = MCG.Math.coincident;

        return coincident(this.p, other.p) && coincident(this.twin.p, other.twin.p);
      },

      // returns MCG.Math.IntersectionFlags
      intersects: function(other) {
        var a = this, b = other;

        var pa = a.p, pta = a.twin.p;
        var pb = b.p, ptb = b.twin.p;

        return MCG.Math.intersect(pa, pta, pb, ptb);
      },

      intersection: function(other) {
        var a = this, b = other;

        if (a.endpointsCoincident(b)) return null;

        var pa = a.p, pta = a.twin.p;
        var pb = b.p, ptb = b.twin.p;

        return MCG.Math.intersection(pa, pta, pb, ptb);
      },

      setNoncontributing: function() {
        this.contributing = false;
      }

    });



    function SweepEventFactory() {
      this.id = 0;
    }

    Object.assign(SweepEventFactory.prototype, {
      createLeft: function(p) {
        return new LeftSweepEvent(p, this.id++);
      },

      createRight: function(p) {
        return new RightSweepEvent(p, this.id++);
      },

      clone: function(e, p) {
        return e.clone(p, this.id++);
      },

      count: function() {
        return this.id;
      }

    });



    return {
      EventPositionFlags: EventPositionFlags,
      LeftSweepEvent: LeftSweepEvent,
      RightSweepEvent: RightSweepEvent,
      SweepEventFactory: SweepEventFactory
    }
  })());
  Object.assign(MCG.Sweep, (function() {

    // adds a new segment set to the result object with the given name
    function resultAddSet(result, context, name) {
      result[name] = new MCG.SegmentSet(context);

      return result;
    }

    // assign values to store, from sweep params if provided
    function assignParams(store, params) {
      params = params || {};

      setProp("minDepthA", 1);
      setProp("minDepthB", 1);
      setProp("handleIntersections", true);
      setProp("dbg", false);

      function setProp(name, def) {
        store[name] = params.hasOwnProperty(name) ? params[name] : def;
      }
    }

    // makes an object containing the init function and event handler
    function makeOperation(initStore, handleEvent) {
      var op = function(params) {
        return {
          initStore: function(context, srcA, srcB) {
            return initStore(context, srcA, srcB, params);
          },
          handleEvent: handleEvent
        };
      };

      return op;
    }



    // operation store initialization functions

    function unionInit(context, srcA, srcB, params) {
      var store = { result: resultAddSet({}, context, "union") };

      assignParams(store, params);

      return store;
    }

    function intersectionInit(context, srcA, srcB, params) {
      var store = { result: resultAddSet({}, context, "intersection") };

      assignParams(store, params);

      return store;
    }

    function intersectionOpenInit(context, srcA, srcB, params) {
      var store = { result: resultAddSet({}, context, "intersectionOpen") };

      assignParams(store, params);

      return store;
    }

    function differenceInit(context, srcA, srcB, params) {
      var store = { result: resultAddSet({}, context, "difference") };

      assignParams(store, params);

      return store;
    }

    function fullDifferenceInit(context, srcA, srcB, params) {
      var result = {};

      resultAddSet(result, context, "AminusB");
      resultAddSet(result, context, "BminusA");
      resultAddSet(result, context, "intersection");

      var store =  { result: result };

      assignParams(store, params);

      return store;
    }

    function linearInfillInit(context, srcA, srcB, params) {
      // calculate the leftmost line that crosses the contour s.t. all lines are
      // vertical, all lines have the given spacing, and one line passes through 0
      var spacing = params.spacing;
      var hline = Math.ceil(srcA.min.h / spacing) * spacing;
      var connectLines = params.connectLines;

      var store = {
        spacing: spacing,
        hline: hline,
        lineidx: 0,
        connectLines: connectLines || false,
        prevLineEnd: null,
        result: resultAddSet({}, context, "infill")
      };

      assignParams(store, params);

      return store;
    }



    // event handler functions

    function unionHandle(event, status, store) {
      var flags = MCG.Sweep.EventPositionFlags;
      var pos = event.getPosition(store.minDepthA, store.minDepthB);
      var result = store.result;

      var inside = pos & flags.insideA || pos & flags.insideB;
      var boundaryA = pos & flags.boundaryA, boundaryB = pos & flags.boundaryB;
      var fromAtoB = pos & flags.fromAtoB;

      if (!inside && (boundaryA || boundaryB) && !fromAtoB) {
        event.addSegmentToSet(result.union);
      }
    }

    function intersectionHandle(event, status, store) {
      var flags = MCG.Sweep.EventPositionFlags;
      var pos = event.getPosition(store.minDepthA, store.minDepthB);
      var result = store.result;

      var inside = pos & flags.insideA || pos & flags.insideB;
      var boundaryA = pos & flags.boundaryA, boundaryB = pos & flags.boundaryB;
      var boundaryAB = boundaryA && boundaryB;
      var fromAtoB = pos & flags.fromAtoB;

      if (boundaryAB && !fromAtoB) {
        event.addSegmentToSet(result.intersection);
      }
      else if (inside && (boundaryA || boundaryB)) {
        event.addSegmentToSet(result.intersection);
      }
    }

    function intersectionOpenHandle(event, status, store) {
      var flags = MCG.Sweep.EventPositionFlags;
      var pos = event.getPosition(store.minDepthA, store.minDepthB);
      var result = store.result;

      var insideA = pos & flags.insideA;
      var isB = event.weightB !== 0;

      if (insideA && isB) {
        event.addSegmentToSet(result.intersectionOpen);
      }
    }

    function differenceHandle(event, status, store) {
      var flags = MCG.Sweep.EventPositionFlags;
      var pos = event.getPosition(store.minDepthA, store.minDepthB);
      var result = store.result;

      var inside = pos & flags.insideA || pos & flags.insideB;
      var boundaryA = pos & flags.boundaryA, boundaryB = pos & flags.boundaryB;
      var boundaryAB = boundaryA && boundaryB;
      var fromAtoB = pos & flags.fromAtoB;

      if (boundaryAB) {
        if (fromAtoB) {
          event.addSegmentToSet(result.difference, false, event.weightA);
        }
      }
      else if (!inside && boundaryA) {
        event.addSegmentToSet(result.difference);
      }
      else if (inside && boundaryB) {
        event.addSegmentToSet(result.difference, true);
      }
    }

    function fullDifferenceHandle(event, status, store, params) {
      var flags = MCG.Sweep.EventPositionFlags;
      var pos = event.getPosition(store.minDepthA, store.minDepthB);
      var result = store.result;

      var inside = pos & flags.insideA || pos & flags.insideB;
      var boundaryA = pos & flags.boundaryA, boundaryB = pos & flags.boundaryB;
      var boundaryAB = boundaryA && boundaryB;
      var fromAtoB = pos & flags.fromAtoB;

      if (boundaryAB) {
        if (fromAtoB) {
          event.addSegmentToSet(result.AminusB, false, event.weightA);
          event.addSegmentToSet(result.BminusA, false, event.weightB);
        }
        else {
          event.addSegmentToSet(result.intersection);
        }
      }
      else {
        if (!inside && boundaryA) {
          event.addSegmentToSet(result.AminusB);
        }
        if (inside && boundaryB) {
          event.addSegmentToSet(result.AminusB, true);
          event.addSegmentToSet(result.intersection);
        }
        if (!inside && boundaryB) {
          event.addSegmentToSet(result.BminusA);
        }
        if (inside && boundaryA) {
          event.addSegmentToSet(result.BminusA, true);
          event.addSegmentToSet(result.intersection);
        }
      }
    }

    function linearInfillHandle(event, status, store) {
      var result = store.result;
      var connectLines = store.connectLines;
      var prevLineEnd = store.prevLineEnd;
      var spacing = store.spacing;
      var hline = store.hline;
      var lineidx = store.lineidx;
      var h = event.p.h, ht = event.twin.p.h;

      // if segment is vertical, return
      if (h === ht) return;
      // if line position is already past the segment, return
      if (hline >= ht) return;

      // move the line position up, drawing lines as we go, until it clears the
      // segment completely
      while (hline <= ht) {
        // go through events in status, find pairs that enclose the interior of the
        // contour, draw a segment between them
        var iter = status.iterator();
        var prev = null, curr;
        // alternate the direction in which infill lines are drawn
        var dir = lineidx%2 === 0 ? "next" : "prev";

        // last vertex of the line drawn at the current h position
        var currLineEnd = null;

        while ((curr = iter[dir]()) !== null) {
          if (!curr.hcontains(hline)) continue;

          if (prev !== null) {
            // only write segment if segments border a 0-to-positive-to-0 winding
            // number transition
            var writeSegment;
            if (lineidx%2 === 0) {
              writeSegment = curr.depthBelowA > 0 && (prev.depthBelowA + prev.weightA) > 0;
            }
            else {
              writeSegment = (curr.depthBelowA + curr.weightA) > 0 && prev.depthBelowA > 0;
            }

            if (writeSegment) {
              // add point pair as usual
              var p1 = prev.interpolate(hline);
              var p2 = curr.interpolate(hline);

              // if
              // 1. we should connect the last vertex of the previous line to the
              // first vertex of this one, and
              // 2. this is the first segment of this line, and
              // 3. there is a known endpoint for the previous line,
              // then connect last known endpoint to the first current
              if (connectLines && currLineEnd === null && prevLineEnd !== null) {
                // additional check: if connection would be too long (angle with
                // previous line less than 45 degrees), don't connect
                var distsq = prevLineEnd.distanceToSq(p1);
                if (distsq <= spacing * spacing * 2) {
                  result.infill.addPointPair(prevLineEnd.clone(), p1.clone());
                }
              }

              result.infill.addPointPair(p1, p2);

              // store p2 as the current end of the line
              currLineEnd = p2;
            }

            prev = null;
          }
          else prev = curr;
        }

        prevLineEnd = currLineEnd;

        hline += spacing;
        lineidx++;
      }

      store.hline = hline;
      store.lineidx = lineidx;
      store.prevLineEnd = prevLineEnd;
    }



    var Operations = {
      union: makeOperation(unionInit, unionHandle),
      intersection: makeOperation(intersectionInit, intersectionHandle),
      intersectionOpen: makeOperation(intersectionOpenInit, intersectionOpenHandle),
      difference: makeOperation(differenceInit, differenceHandle),
      fullDifference: makeOperation(fullDifferenceInit, fullDifferenceHandle),
      linearInfill: makeOperation(linearInfillInit, linearInfillHandle)
    };



    return {
      Operations: Operations
    };

  })());
  MCG.Vector = (function() {

    function Vector(context, h, v) {
      this.context = context || new MCG.Context();

      this.h = Math.round(h || 0);
      this.v = Math.round(v || 0);

      this.type = MCG.Types.vector;
    }

    Object.assign(Vector.prototype, {

      fromVector3: function(v3) {
        var context = this.context;
        var ftoi = MCG.Math.ftoi;

        this.h = ftoi(v3[context.ah], context);
        this.v = ftoi(v3[context.av], context);

        return this;
      },

      // arguments:
      //  constr: 3-vector constructor; assumed to follow THREE.Vector3 API
      //  context: context to use, if different from this.context
      toVector3: function(constr, context) {
        context = context || this.context;

        var itof = MCG.Math.itof;

        var res = new constr();
        res[context.axis] = context.d;
        res[context.ah] = itof(this.h, context);
        res[context.av] = itof(this.v, context);

        return res;
      },

      set: function(h, v) {
        this.h = Math.round(h);
        this.v = Math.round(v);

        return this;
      },

      setH: function(h) {
        this.h = Math.round(h);

        return this;
      },

      setV: function(v) {
        this.v = Math.round(v);

        return this;
      },

      setUnitVector: function(axis) {
        var p = this.context.p;

        if (axis === "h") this.set(p, 0);
        else this.set(0, p);

        return this;
      },

      setScalar: function(s) {
        this.h = s;
        this.v = s;

        return this;
      },

      negate: function() {
        this.h *= -1;
        this.v *= -1;

        return this;
      },

      copy: function(other) {
        this.h = other.h;
        this.v = other.v;
        this.context = other.context;

        return this;
      },

      clone: function() {
        return new this.constructor().copy(this);
      },

      hash: function() {
        return this.h + "_" + this.v;
      },

      sh: function() {
        return MCG.Math.itof(this.h, this.context);
      },

      sv: function() {
        return MCG.Math.itof(this.v, this.context);
      },

      add: function(other) {
        this.h += other.h;
        this.v += other.v;

        return this;
      },

      sub: function(other) {
        this.h -= other.h;
        this.v -= other.v;

        return this;
      },

      multiply: function(other) {
        this.h = this.h * other.h;
        this.v = this.v * other.v;

        return this;
      },

      divide: function(other) {
        this.h = Math.round(this.h / other.h);
        this.v = Math.round(this.v / other.v);

        return this;
      },

      multiplyScalar: function(s) {
        this.h = Math.round(this.h * s);
        this.v = Math.round(this.v * s);

        return this;
      },

      divideScalar: function(s) {
        return this.multiplyScalar(1 / s);
      },

      addScaledVector: function(other, s) {
        return this.set(this.h + other.h * s,
          this.v + other.v * s);
      },

      lengthSq: function() {
        return this.h * this.h + this.v * this.v;
      },

      length: function() {
        return Math.sqrt(this.lengthSq());
      },

      setLength: function(l) {
        var tl = this.length();
        if (tl === l) return this;

        return this.multiplyScalar(l / tl);
      },

      // normalize the vector to length this.context.p (1 in its original
      // floating-point space)
      normalize: function() {
        if (this.h === 0 && this.v === 0) return this;

        var length = this.context.p;
        return this.setLength(length);
      },

      distanceToSq: function(other) {
        var dh = this.h - other.h, dv = this.v - other.v;
        return dh * dh + dv * dv;
      },

      distanceTo: function(other) {
        return Math.sqrt(this.distanceToSq(other));
      },

      dot: function(other) {
        return this.h * other.h + this.v * other.v;
      },

      // component of the cross product normal to the plane
      cross: function(other) {
        return this.h * other.v - this.v * other.h;
      },

      angleTo: function(other) {
        var normalization = Math.sqrt(this.lengthSq() * other.lengthSq());

        return acos(this.dot(other) / normalization);
      },

      vectorTo: function(other) {
        return other.clone().sub(this);
      },

      max: function(other) {
        this.h = Math.max(this.h, other.h);
        this.v = Math.max(this.v, other.v);

        return this;
      },

      min: function(other) {
        this.h = Math.min(this.h, other.h);
        this.v = Math.min(this.v, other.v);

        return this;
      },

      hcompare: function(other) {
        return Math.sign(this.h - other.h);
      },

      vcompare: function(other) {
        return Math.sign(this.v - other.v);
      },

      // rotates CCW
      rotate: function(angle) {
        var h = this.h;
        var v = this.v;
        var c = Math.cos(angle);
        var s = Math.sin(angle);

        this.setH(c * h - s * v);
        this.setV(s * h + c * v);

        return this;
      }

    });

    return Vector;

  })();

}

var epsilonDefault = 1e-5;
var axisDefault = 'z';

function splitFilename(fullName) {
  var idx = fullName.lastIndexOf('.');
  if (idx==-1) {
    return {
      name: fullName,
      extension: ""
    };
  }
  else {
    return {
      name: fullName.substr(0, idx),
      extension: fullName.substr(idx+1).toLowerCase()
    };
  }
}


// swapping
function swap(arr, i, j) {
  var tmp = arr[i];
  arr[i] = arr[j];
  arr[j] = tmp;
}


// Vector3 stuff

// for turning "x" etc. into a normalized Vector3 along axis
var axisToVector3 = function(axis){
  var v = new THREE.Vector3();
  v[axis] = 1;
  return v;
}

// turn 0/1/2 component into 'x'/'y'/'z' label
var vector3ComponentToAxis = function(component) {
  if (component==0) return 'x';
  else if (component==1) return 'y';
  else return 'z';
}

// cycle axis label to the next axis
function cycleAxis(axis) {
  if (axis=='x') return 'y';
  else if (axis=='y') return 'z';
  else return 'x';
}

// special vectors
function getZeroVector() { return new THREE.Vector3(0,0,0); }
function getOneVector() { return new THREE.Vector3(1,1,1); }

// generate unit vector along given axis
function makeAxisUnitVector(axis) {
  if (axis === undefined) axis = axisDefault;

  var v = new THREE.Vector3();
  v[axis] = 1;

  return v;
}



function eulerRadToDeg (euler) {
  var eulerDeg = euler.clone();
  var factor = 180 / Math.PI;

  eulerDeg.x *= factor;
  eulerDeg.y *= factor;
  eulerDeg.z *= factor;

  return eulerDeg;
}
function eulerDegToRad (euler) {
  var eulerRad = euler.clone();
  var factor = Math.PI / 180;

  eulerRad.x *= factor;
  eulerRad.y *= factor;
  eulerRad.z *= factor;

  return eulerRad;
}
function eulerRadNormalize(euler) {
  var max = Math.PI * 2;

  euler.x = ((euler.x % max) + max) % max;
  euler.y = ((euler.y % max) + max) % max;
  euler.z = ((euler.z % max) + max) % max;

  return euler;
}
function eulerDegNormalize(euler) {
  var max = 360;

  euler.x = ((euler.x % max) + max) % max;
  euler.y = ((euler.y % max) + max) % max;
  euler.z = ((euler.z % max) + max) % max;

  return euler;
}

// element max/min
function vector3MaxElement(v) {
  return Math.max(v.x, v.y, v.z);
}
function vector3MinElement(v) {
  return Math.min(v.x, v.y, v.z);
}
// return 'x', 'y', or 'z' depending on which element is greater/lesser
function vector3ArgMax(v) {
  return v.x>v.y ? (v.x>v.z ? 'x' : 'z') : (v.y>v.z ? 'y' : 'z');
}
function vector3ArgMin(v) {
  return v.x<v.y ? (v.x<v.z ? 'x' : 'z') : (v.y<v.z ? 'y' : 'z');
}
function clamp(x, minVal, maxVal) {
  if (x < minVal) x = minVal;
  else if (x > maxVal) x = maxVal;
  return x;
}
function inRange(x, minVal, maxVal) {
  return (minVal===undefined || x >= minVal) && (maxVal===undefined || x <= maxVal);
}
function vector3Abs(v) {
  var result = new THREE.Vector3();
  result.x = Math.abs(v.x);
  result.y = Math.abs(v.y);
  result.z = Math.abs(v.z);
  return result;
}
function vector3AxisMin(v1, v2, axis) {
  if (v1[axis] < v2[axis]) return v1;
  else return v2;
}
function vector3AxisMax(v1, v2, axis) {
  if (v1[axis] > v2[axis]) return v1;
  else return v2;
}


// object type bool checks and other utilities

function isArray(item) {
  return (Object.prototype.toString.call(item) === '[object Array]');
}

function isString(item) {
  return (typeof item === 'string' || item instanceof String);
}

function isNumber(item) {
  return (typeof item === 'number');
}

function isFunction(item) {
  return (typeof item === 'function');
}

function isInfinite(n) {
  return n==Infinity || n==-Infinity;
}

// check if object has properties
function objectIsEmpty(obj) {
  var isEmpty = true;
  for (var key in obj) {
    isEmpty = false;
    break;
  }
  return isEmpty;
}

function shallowCopy(obj) {
  return Object.assign({}, obj);
}

// push b's terms onto a without using concat
function arrayAppend(target, source) {
  var sourceLength = source.length;

  for (var i = 0; i < sourceLength; i++) target.push(source[i]);
}

function cloneVector3Array(arr) {
  var result = [];

  for (var i = 0; i < arr.length; i++) result.push(arr[i].clone());

  return result;
}

// THREE.Face3- and THREE.Vector3-related functions
// get THREE.Face3 vertices
function faceGetVerts(face, vertices) {
  return [
    vertices[face.a],
    vertices[face.b],
    vertices[face.c]
  ];
}
function faceGetMaxAxis(face, vertices, axis) {
  var [a, b, c] = faceGetVerts(face, vertices);
  return Math.max(a[axis], Math.max(b[axis], c[axis]));
}
function faceGetMinAxis(face, vertices, axis) {
  var [a, b, c] = faceGetVerts(face, vertices);
  return Math.min(a[axis], Math.min(b[axis], c[axis]));
}
function faceGetBounds(face, vertices) {
  var min = new THREE.Vector3().setScalar(Infinity);
  var max = new THREE.Vector3().setScalar(-Infinity);
  var verts = faceGetVerts(face, vertices);

  for (var v=0; v<3; v++) {
    min.min(verts[v]);
    max.max(verts[v]);
  }

  return {
    min: min,
    max: max
  };
}
function faceGetBoundsAxis(face, vertices, axis) {
  if (axis === undefined) axis = axisDefault;

  var verts = faceGetVerts(face, vertices);
  return {
    max: Math.max(verts[0][axis], Math.max(verts[1][axis], verts[2][axis])),
    min: Math.min(verts[0][axis], Math.min(verts[1][axis], verts[2][axis]))
  };
}
// get THREE.Face3 vertices and sort them in ascending order on axis
function faceGetVertsSorted(face, vertices, axis) {
  if (axis === undefined) axis = axisDefault;

  var verts = faceGetVerts(face, vertices);
  var ccw = true;
  var a = verts[0][axis];
  var b = verts[1][axis];
  var c = verts[2][axis];

  if (c > a) {
    if (b > c) {
      swap (verts, 1, 2);
      ccw = false;
    }
    else if (a > b) {
      swap (verts, 0, 1);
      ccw = false;
    }
  }
  else {
    if (b > a) {
      swap (verts, 0, 2);
      swap (verts, 1, 2);
    }
    else if (c > b) {
      swap (verts, 0, 2);
      swap (verts, 0, 1);
    }
    else {
      swap (verts, 0, 2);
      ccw = false;
    }
  }

  return {
    verts: verts,
    ccw: ccw
  };
}
function faceGetCenter(face, vertices) {
  var verts = faceGetVerts(face, vertices);
  return verts[0].clone().add(verts[1]).add(verts[2]).divideScalar(3);
}
function faceGetArea(face, vertices) {
  var [a, b, c] = faceGetVerts(face, vertices);
  return b.clone().sub(a).cross(c.clone().sub(a)).length()/2;
}
// compute THREE.Face3 normal
function faceComputeNormal(face, vertices) {
  var [a, b, c] = faceGetVerts(face, vertices);
  face.normal.copy(vertsComputeNormal(a, b, c));
}
function vertsComputeNormal(a, b, c) {
  var ba = a.clone().sub(b);
  var bc = c.clone().sub(b);

  return bc.cross(ba).normalize();
}
// Get THREE.Face3 subscript ('a', 'b', or 'c') for a given 0-2 index.
function faceGetSubscript(idx) {
  return (idx==0) ? 'a' : ((idx==1) ? 'b' : 'c');
}
function numHash(n, p) {
  return Math.round(n*p);
}
function vertexHash(v, p) {
  return numHash(v.x, p)+'_'+numHash(v.y, p)+'_'+numHash(v.z, p);
}

// Remove all meshes with a particular name from a scene.
function removeMeshByName(scene, name) {
  if (!scene) return;

  for (var i=scene.children.length-1; i>=0; i--) {
    var child = scene.children[i];
    if (child.name == name) {
      scene.remove(child);
    }
  }
}



// for calculating triangle area and efficient cross-products

// u cross v = (uy vz - uz vy, uz vx - ux vz, ux vy - uy vx)
// u = b - a; v = c - a; u cross v = 2 * area
// (b-a) cross (c-a) = 2 * area = (
//  (by-ay)(cz-az) - (bz-az)(cy-ay),
//  (bz-az)(cx-ax) - (bx-ax)(cz-az),
//  (bx-ax)(cy-ay) - (by-ay)(cx-ax),
// )
// calculate triangle area
function triangleArea(a, b, c, axis) {
  if (axis === undefined) axis = axisDefault;

  return cornerCrossProduct(a, b, c, axis)/2;
}
// calculates cross product of b-a and c-a
function cornerCrossProduct(a, b, c, axis) {
  if (axis === undefined) axis = axisDefault;

  if (axis == "x") return (b.y-a.y)*(c.z-a.z) - (b.z-a.z)*(c.y-a.y);
  if (axis == "y") return (b.z-a.z)*(c.x-a.x) - (b.x-a.x)*(c.z-a.z);
  if (axis == "z") return (b.x-a.x)*(c.y-a.y) - (b.y-a.y)*(c.x-a.x);
  return 0;
}
// cross product component of two vectors
function crossProductComponent(v, w, axis) {
  if (axis === undefined) axis = axisDefault;

  if (axis == "x") return v.y*w.z - v.z*w.y;
  if (axis == "y") return v.z*w.x - v.x*w.z;
  if (axis == "z") return v.x*w.y - v.y*w.x;
  return 0;
}

// intersection stuff

// intersection between line segment and plane normal to axis
function segmentPlaneIntersection(axis, level, va, vb) {
  if (axis === undefined) axis = axisDefault;

  // if equal, just return va
  if (va[axis] === vb[axis]) return va;

  // calculate linear interpolation factor; note that, as checked above, the
  // denominator will be positive
  var t = (level - va[axis]) / (vb[axis] - va[axis]);
  // difference vector
  var d = vb.clone().sub(va);
  // interpolate
  return va.clone().addScaledVector(d, t);
}

// s1, s2: endpoints of segment
// pt: point emitting ray
// axis: axis orthogonal to plane; therefore:
//  ah: horizontal axis along which ray emits
//  av: orthogonal to ah
// returns: intersection along a1 axis
function raySegmentIntersectionOnHAxis(s1, s2, pt, axis) {
  if (axis === undefined) axis = axisDefault;

  var ah = cycleAxis(axis);
  var av = cycleAxis(ah);
  return s1[ah] + (s2[ah] - s1[ah]) * (pt[av] - s1[av]) / (s2[av] - s1[av]);
}

// flags signifying which bounds to check for intersection testing
var BoundCheckFlags = (function() {
  var s0 = 1, s1 = 2;
  var t0 = 4, t1 = 8;

  return {
    none: 0,                // line-line
    s0: s0,                 // ray-line
    s1: s1,
    t0: t0,                 // line-ray
    t1: t1,
    s0t0: s0 | t0,          // ray-ray
    s1t1: s1 | t1,
    s01: s0 | s1,           // segment-line
    t01: t0 | t1,           // line-segment
    s01t0: s0 | s1 | t0,    // segment-ray
    s0t01: s0 | t0 | t1,    // ray-segment
    all: s0 | s1 | t0 | t1  // segment-segment
  }
})();

// returns the intersection (or null) of ray s with line t (both given as rays)
// basically this math:
// https://stackoverflow.com/a/2931703/7416552
// s, t: ray origins
// sd, td: ray directions (not normalized)
// checks: flags signifying which bounds to check
// point of intersection is s + sd * u = t + td * v
function intersection(s, sd, t, td, checks, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  var p = calculateIntersectionParams(s, t, sd, td, checks, axis, epsilon);

  if (!p) return null;

  return s.clone().addScaledVector(sd, p.u);
}
// returns intersection point (or null) of s-se segment and t-te segment
// E stands for 'endpoints', as opposed to origin+direction
// params:
//  s, se: first and last point of s segment
//  t, te: first and last point of t segment
function intersectionE(s, se, t, te, checks, axis, epsilon) {
  var sd = se.clone().sub(s);
  var td = te.clone().sub(t);

  return intersection(s, sd, t, td, checks, axis, epsilon);
}

// shorthand functions for frequently used intersection types
function lineLineIntersection(s, sd, t, td, axis, epsilon) {
  return intersection(s, sd, t, td, BoundCheckFlags.none, axis, epsilon);
}
function rayLineIntersection(s, sd, t, td, axis, epsilon) {
  return intersection(s, sd, t, td, BoundCheckFlags.s0, axis, epsilon);
}
function raySegmentIntersection(s, sd, t, td, axis, epsilon) {
  return intersection(s, sd, t, td, BoundCheckFlags.s0t01, axis, epsilon);
}
function segmentSegmentIntersection(s, sd, t, td, axis, epsilon) {
  return intersection(s, sd, t, td, BoundCheckFlags.all, axis, epsilon);
}
function segmentSegmentIntersectionE(s, se, t, te, axis, epsilon) {
  return intersectionE(s, se, t, te, BoundCheckFlags.all, axis, epsilon);
}

// calculate both intersection params for two rays instead of inlining the
// same code multiple times; only return the first param (u, corresponding to s
// ray) if the second doesn't need checking
function calculateIntersectionParams(s, t, sd, td, checks, axis, epsilon) {
  var ah = cycleAxis(axis);
  var av = cycleAxis(ah);

  var det = sd[ah]*td[av] - sd[av]*td[ah];
  // lines are exactly parallel, so no intersection
  if (equal(det, 0, epsilon)) return null;

  var dh = t[ah] - s[ah];
  var dv = t[av] - s[av];

  // calculate and check u (s param)
  var u = (td[av]*dh - td[ah]*dv) / det;

  var u0 = checks & BoundCheckFlags.s0;
  var u1 = checks & BoundCheckFlags.s1;

  if (u0 && less(u, 0, epsilon)) return null;
  if (u1 && greater(u, 1, epsilon)) return null;

  // if don't need to check v, just return u
  if (checks & BoundCheckFlags.v01 === 0) return {
    u: u
  };

  // calculate and check v (t param)
  var v = (sd[av]*dh - sd[ah]*dv) / det;

  var v0 = checks & BoundCheckFlags.t0;
  var v1 = checks & BoundCheckFlags.t1;

  if (v0 && less(v, 0, epsilon)) return null;
  if (v1 && greater(v, 1, epsilon)) return null;

  // if all successful, return params
  return {
    u: u,
    v: v
  };
}

/*/
/ validate intersection params within the [0, 1] range
// arguments:
//  p: object containing u and v params
//  checks: some combination of BoundCheckFlags enum values
function validateIntersectionParams(p, checks, epsilon) {
  if (p === null) return false;

  var u = p.u;
  var v = p.v;

  var ulb = checks & BoundCheckFlags.Sstart;
  var uub = checks & BoundCheckFlags.Send;
  var vlb = checks & BoundCheckFlags.Tstart;
  var vub = checks & BoundCheckFlags.Tend;

  if (ulb && less(u, 0, epsilon)) return false;
  if (uub && greater(u, 1, epsilon)) return false;
  if (vlb && less(v, 0, epsilon)) return false;
  if (vub && greater(v, 1, epsilon)) return false;

  return true;
}
*/

// bool check if segment ab intersects segment cd
function segmentIntersectsSegment(checks, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  return ((left(a, b, c, axis, epsilon) ^ left(a, b, d, axis, epsilon)) &&
    (left(c, d, a, axis, epsilon) ^ left(c, d, b, axis, epsilon)));
}

// find the highest point of intersection between two cones; the cones have
// origins p and q, both open downward on axis, and both have walls forming
// the given angle (in radians) with the axis
// if one cone's origin is inside the other cone, return null
// principle:
// points P and P cast rays at the given angle with the vertical to the closest
// intersection I; first move Q along the I-Q line such that it's level with P
// on axis, find the midpoint of the P-Q line, then move that point down by
// (1/2)|P-Q|/tan(angle)
function coneConeIntersection(p, q, angle, axis) {
  if (p === q) return null;

  var up = new THREE.Vector3();
  up[axis] = 1;

  var cos = Math.cos(angle);
  var d = q.clone().sub(p).normalize();

  var dot = -d.dot(up);
  // if p's cone contains q or vice versa, no intersection
  if (dot>cos || dot<cos-1) return null;

  // horizontal (orthogonal to axis), normalized vector from p to q
  d[axis] = 0;
  d.normalize();

  var tan = Math.tan(angle);

  // lift or lower q to be level with p on axis
  var diff = q[axis] - p[axis];
  var qnew = q.clone();
  qnew.addScaledVector(up, -diff);
  qnew.addScaledVector(d, -diff * tan);

  // get the midpoint, lower it as described above, that's the intersection
  var midpoint = p.clone().add(qnew).divideScalar(2);
  var len = midpoint.distanceTo(p);
  midpoint[axis] -= len/tan;

  return midpoint;
}

// returns v's distance to the line through a and b
function distanceToLine(v, a, b) {
  // unit vector from a to b (unit vector along line)
  var abhat = b.clone().sub(a).normalize();

  // vector from a to v
  var av = v.clone().sub(a);
  // projection of a-v vector onto line
  var projection = abhat.multiplyScalar(av.dot(abhat));

  // subtract projection from a-v vector to get orthogonal vector
  return av.sub(projection).length();
}

// find the point on the a-b line that's closest to v
function projectToLine(v, a, b, axis) {
  if (axis === undefined) axis = axisDefault;

  var ah = cycleAxis(axis);
  var av = cycleAxis(ah);

  // unit vector from a to b (unit vector along line)
  var abhat = b.clone().sub(a).normalize();

  // vector from a to v
  var av = v.clone().sub(a);
  // projection of a-v vector onto line
  var projection = abhat.multiplyScalar(av.dot(abhat));

  return a.clone()
}

// given a point p, and a plane containing point d with normal n, project p to
// the plane along axis
// as the plane is the set of points r s.t. (r-d) dot n = 0, r dot n = d dot n;
// if axis is z, rz = (d dot n - rx*nx - ry*ny) / nz
// if nz == 0, then we can't project, so just return p
function projectToPlaneOnAxis(p, d, n, axis) {
  if (axis === undefined) axis = axisDefault;

  var ah = cycleAxis(axis);
  var av = cycleAxis(ah);

  // return p if can't project
  if (n[axis] === 0) return p;

  // get the .axis component
  var rz = (d.dot(n) - p[ah]*n[ah] - p[av]*n[av]) / n[axis];

  // set the component
  var pp = p.clone();
  pp[axis] = rz;

  return pp;
}

// takes v and projects out the n component; n is assumed normalized
function projectOut(v, n) {
  var projection = n.clone().multiplyScalar(v.dot(n));
  return v.clone().sub(projection);
}

// returns an orthogonal vector to v
// default is vector with 0 z-component, but, if v only has a z-component,
// return vector along x
function orthogonalVector(v) {
  if (v.x === 0 && v.y === 0) return new THREE.Vector3(1, 0, 0);
  else return new THREE.Vector3(v.y, -v.x, 0).normalize();
}

// true if c is strictly left of a-b segment
function left(a, b, c, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  var area = triangleArea(a, b, c, axis);

  return greater(area, 0, epsilon);
}

// true if c is left of or on a-b segment
function leftOn(a, b, c, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  var area = triangleArea(a, b, c, axis);

  return !less(area, 0, epsilon);
}

function pointInsideTriangle(p, a, b, c, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  return left(a, b, p, axis, epsilon) &&
    left(b, c, p, axis, epsilon) &&
    left(c, a, p, axis, epsilon);
}

// approximate coincidence testing for vectors
function coincident(a, b, epsilon) {
  if (epsilon === undefined) epsilon = epsilonDefault;

  return equal(a.x - b.x, 0, epsilon) &&
    equal(a.y - b.y, 0, epsilon) &&
    equal(a.z - b.z, 0, epsilon);
}

// approximate collinearity testing for three vectors
function collinear(a, b, c, axis, epsilon) {
  if (axis === undefined) axis = axisDefault;
  if (epsilon === undefined) epsilon = epsilonDefault;

  var area = triangleArea(a, b, c, axis);

  return Math.abs(area) < epsilon;
}

// approximate equality for real numbers
function equal(i, j, epsilon) {
  if (epsilon === undefined) epsilon = epsilonDefault;

  var test = false;
  if (test) {
    if (j===0) return Math.abs(i) < epsilon;
    else return Math.abs(i/j - 1) < epsilon;
  }
  else {
    return equalSimple(i, j, epsilon);
  }
}

function equalSimple(i, j, epsilon) {
  if (i === Infinity || j === Infinity) return i === j;

  return Math.abs(i - j) < epsilon;
}

// approximate less-than testing for real numbers
function less(i, j, epsilon) {
  if (epsilon === undefined) epsilon = epsilonDefault;
  return i < j && !equal(i, j, epsilon);
}

// approximate greater-than testing for real numbers
function greater(i, j, epsilon) {
  if (epsilon === undefined) epsilon = epsilonDefault;
  return i > j && !equal(i, j, epsilon);
}

// standard sorting-type comparator; useful when building more complicated
// comparators because calling less() and greater() together results in two
// equal() checks
function compare(i, j, epsilon) {
  if (epsilon === undefined) epsilon = epsilonDefault;

  if (equal(i, j, epsilon)) return 0;
  else if (i < j) return -1;
  else return 1;
}


// clamps a to [-1, 1] range and returns its acos
function acos(a) {
  return Math.acos(clamp(a, -1, 1));
}
// clamps a to [-1, 1] range and returns its asin
function asin(a) {
  return Math.asin(clamp(a, -1, 1));
}



// for vertex hash maps

// gets the index of a vertex in a hash map, adding it to the map and vertex
// array if necessary
// inputs:
//  map: hash map ({hash:idx} object)
//  v: vertex whose index to get, adding it to the map and array as necessary
//  vertices: array of vertices whose indices are stored in the hash map
//  p: precision factor
function vertexMapIdx(map, v, vertices, p) {
  var hash = vertexHash(v, p);
  var idx = -1;
  if (map[hash]===undefined) {
    idx = vertices.length;
    map[hash] = idx;
    vertices.push(v);
  }
  else {
    idx = map[hash];
  }
  return idx;
}

// make a hash map of a whole array of vertices at once
function vertexArrayToMap(map, vertices, p) {
  for (var v=0; v<vertices.length; v++) {
    map[vertexHash(vertices[v], p)] = v;
  }
}


// non-blocking iterator
// params:
//  f: function to repeat
//  n: number of times to repeat the function
//  batchSize (optional): repeat the function this many times at each iteration
//  onDone (optional): call this when done iterating
//  onProgress (optional): call this at every iteration step
//  onStop (optional): call this when the stop() function is called
// usage:
//  make a new instance with at least the first two params, call start()
function functionIterator(f, n, batchSize, onDone, onProgress, onStop) {
  this.f = f;
  this.n = n;
  this.i = 0;
  this.batchSize = (batchSize===undefined || batchSize<1) ? 1 : batchSize;
  this.onStop = onStop;
  this.onProgress = onProgress;
  this.onDone = onDone;
  this.timer = 0;

  // begin iterating and repeat until done
  this.start = function() {
    this.i = 0;

    this.timer = setTimeout(this.iterate.bind(this), 16);
  };

  // main unit of iteration: repeatedly run f, stopping after batchSize
  // repetitions (or fewer, if we've hit n)
  this.iterate = function() {
    var i;
    var limit = this.i+this.batchSize;
    var n = this.n;
    for (i=this.i; i<limit && i<n; i++) {
      this.f(i);
    }

    this.i = i;

    if (this.onProgress) this.onProgress(i);

    if (i>=n) {
      clearTimeout(this.timer);
      if (this.onDone) this.onDone();
      return;
    }

    this.timer = setTimeout(this.iterate.bind(this), 0);
  };

  // manually terminate the iteration
  this.stop = function() {
    clearTimeout(this.timer);

    if (this.onStop) this.onStop(this.i);
  };

  // return true if there are more iterations to run
  this.running = function() {
    return this.i<this.n;
  }
}



// timer
var Timer = function() {
  this.startTime = 0;
  this.endTime = 0;
  this.running = false;
}
Timer.prototype.start = function() {
  this.startTime = new Date();
  this.running = true;
}
Timer.prototype.stop = function() {
  this.endTime = new Date();
  this.running = false;
  return this.endTime - this.startTime;
}
Timer.prototype.elapsed = function() {
  if (this.running) return new Date() - this.startTime;
  else return this.endTime - this.startTime;
}


// chart of ring inner diameters in mm
// (source: https://en.wikipedia.org/wiki/Ring_size)
var ringSizes = {
  "    0": 11.63,
  " 0.25": 11.84,
  "  0.5": 12.04,
  " 0.75": 12.24,
  "    1": 12.45,
  " 1.25": 12.65,
  "  1.5": 12.85,
  " 1.75": 13.06,
  "    2": 13.26,
  " 2.25": 13.46,
  "  2.5": 13.67,
  " 2.75": 13.87,
  "    3": 14.07,
  " 3.25": 14.27,
  "  3.5": 14.48,
  " 3.75": 14.68,
  "    4": 14.88,
  " 4.25": 15.09,
  "  4.5": 15.29,
  " 4.75": 15.49,
  "    5": 15.7,
  " 5.25": 15.9,
  "  5.5": 16.1,
  " 5.75": 16.31,
  "    6": 16.51,
  " 6.25": 16.71,
  "  6.5": 16.92,
  " 6.75": 17.12,
  "    7": 17.32,
  " 7.25": 17.53,
  "  7.5": 17.73,
  " 7.75": 17.93,
  "    8": 18.14,
  " 8.25": 18.34,
  "  8.5": 18.54,
  " 8.75": 18.75,
  "    9": 18.95,
  " 9.25": 19.15,
  "  9.5": 19.35,
  " 9.75": 19.56,
  "   10": 19.76,
  "10.25": 19.96,
  " 10.5": 20.17,
  "10.75": 20.37,
  "   11": 20.57,
  "11.25": 20.78,
  " 11.5": 20.98,
  "11.75": 21.18,
  "   12": 21.39,
  "12.25": 21.59,
  " 12.5": 21.79,
  "12.75": 22,
  "   13": 22.2,
  "13.25": 22.4,
  " 13.5": 22.61,
  "13.75": 22.81,
  "   14": 23.01,
  "14.25": 23.22,
  " 14.5": 23.42,
  "14.75": 23.62,
  "   15": 23.83,
  "15.25": 24.03,
  " 15.5": 24.23,
  "15.75": 24.43,
  "   16": 24.64
}


// memory usage - from zensh on github: https://gist.github.com/zensh/4975495
function memorySizeOf(obj) {
  var bytes = 0;

  function sizeOf(obj) {
    if(obj !== null && obj !== undefined) {
      switch(typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          var objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if(objClass === 'Object' || objClass === 'Array') {
            for(var key in obj) {
              if(!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
      }
    }
    return bytes;
  };

  function formatByteSize(bytes) {
    if(bytes < 1024) return bytes + " bytes";
    else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
    else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
    else return(bytes / 1073741824).toFixed(3) + " GiB";
  };

  return formatByteSize(sizeOf(obj));
};

export default Slicer
