import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as THREE from 'three';
import {LivePartService} from '../../services/live-part.service';
import {round} from '../../iiLib';

@Component({
  selector: 'app-tree-editor',
  templateUrl: './tree-editor.component.html',
  styleUrls: ['./tree-editor.component.css']
})

export class TreeEditorComponent implements OnInit {
  @Input() data;
  @Input() pObj;
  selected;
  exportData;
  varCheck = true;
  resolution = 16;
  functionOutput = false;
  clipboardFile = true;
  downloadFile = false;
  fileName = '';
  functionName = 'main';

  @Input() exportLanguage = false;
  @Input() selectedSubPart;

  @Output() selectedPart = new EventEmitter();

  constructor(public livePart: LivePartService) { }
  ngOnInit() {
    if (this.selectedSubPart) {
      this.pObj.forEach((p)=> {
        if (p.uid === this.selectedSubPart) {
          this.selected = p;
        }
      });
    }
  }
  setSelected(item){
    this.selected = item;
    this.selectedPart.emit(item);
  }
  async exportCurv(csgData) {
    console.log(this.pObj, this.data);
    let t = new TranslateCurv(this.pObj);
    t.clipboardFile = this.clipboardFile;
    t.downloadFile = this.downloadFile;
    t.fileName = this.fileName;
    t.functionName = this.functionName;
    t.functionOutput = this.functionOutput;
    t.export(csgData);
  }
  getVar(obj,varSubSetName,property){
    if(obj.variableSet.varSubSetTree.hasOwnProperty(varSubSetName)){
      if(obj.variableSet.varSubSetTree[varSubSetName].hasOwnProperty(property)){
        if(obj.variableSet.varSubSetTree[varSubSetName][property].exp != ''){
          return obj.variableSet.varSubSetTree[varSubSetName][property].exp;
        }else{
          let v = obj.variableSet.varSubSetTree[varSubSetName][property].get();
          if(v === false || v === '0' || v === undefined){
            return 0;
          }else{
            return v;
          }
        }
      }
    }
  }
  async exportCodeObj(csgData){
   let data = await this.exportV2(csgData);
   this.livePart.WS.addNewCodePart(data);
  }

  offset(x,y,z,sx,sy,sz){
    let rx, ry, rz;
    if (!x) {
      rx = `(${sx})/2`;
    } else {
      rx = 0;
    }
    if (!y) {
      ry = `(${sy})/2`;
    } else {
      ry = 0;
    }
    if (!z) {
      rz = `(${sz})/2`;
    } else {
      rz = 0;
    }
    return {x:rx, y:ry, z:rz};
  }
  offsetV1(x,y,z,sx,sy,sz){
    let rx, ry, rz;
    if(x){
      rx = `(${sx})/2`;
    } else {
      rx = 0
    }
    if(y){
      ry = `(${sy})/2`;
    } else {
      ry = 0
    }
    if(z){
      rz = `(${sz})/2`;
    } else {
      rz = 0
    }
    return {x:rx, y:ry, z:rz};
  }
  async exportCodeObjV1(csgData) {
    let data = await this.exportV1(csgData);
    this.livePart.WS.addNewCodePartV1(data);
  }
  async exportV1(csgData) {
    const t = new TranslateJsCadV1(this.pObj);
    t.clipboardFile = this.clipboardFile;
    t.downloadFile = this.downloadFile;
    t.fileName = this.fileName;
    t.functionName = this.functionName;
    t.functionOutput = this.functionOutput;
    t.resolution = this.resolution;
    return t.export(csgData);
  }
  async exportV2(csgData) {
    const t = new TranslateJsCadV2(this.pObj);
    t.clipboardFile = this.clipboardFile;
    t.downloadFile = this.downloadFile;
    t.fileName = this.fileName;
    t.functionName = this.functionName;
    t.functionOutput = this.functionOutput;
    t.resolution = this.resolution;
    return t.export(csgData);
  }
  async exportCodeObjII(csgData) {
    let data = await this.exportIIcode(csgData);
    this.livePart.WS.addNewCodePartV1(data);
  }
  async exportIIcode(csgData) {
    const t = new TranslateInventCSGcode(this.pObj);
    t.clipboardFile = this.clipboardFile;
    t.downloadFile = this.downloadFile;
    t.fileName = this.fileName;
    t.functionName = this.functionName;
    t.functionOutput = this.functionOutput;
    t.resolution = this.resolution;
    return t.export(csgData);
  }
  async exportScadCode(csgData) {
    const t = new TranslateScad(this.pObj);
    t.clipboardFile = this.clipboardFile;
    t.downloadFile = this.downloadFile;
    t.fileName = this.fileName;
    t.functionName = this.functionName;
    t.functionOutput = this.functionOutput;
    t.resolution = this.resolution;
    return t.export(csgData);
  }
}

class TranslateCode {
  pObj;
  exportData;
  userVar;
  functionOutput = false;
  clipboardFile = true;
  functionName = 'translateCad';
  fileName = 'translateCad';
  downloadFile = false;
  fileNameExt = '.txt';
  resolution = 16;
  constructor(pObj) {
    this.pObj = pObj;
  }
  buildSolidParam(d) {
    const p = {
      x: this.getVar(d,'size','x'),
      y: this.getVar(d,'size','y'),
      z: this.getVar(d,'size','z'),
      shellOpt: {
        xt: this.getVar(d,'shellSide','x+'),
        yt: this.getVar(d,'shellSide','y+'),
        zt: this.getVar(d,'shellSide','z+'),
        xb: this.getVar(d,'shellSide','x-'),
        yb: this.getVar(d,'shellSide','y-'),
        zb: this.getVar(d,'shellSide','z-'),
        in: this.getVar(d,'shell','inner'),
        en: this.getVar(d,'shell','en'),
        shell: this.getVar(d,'shell','shell'),
        wall: this.getVar(d,'shell','wall'),
      },
      shell: {
        en: this.getVar(d,'shell','en'),
        inner: this.getVar(d,'shell','inner'),
        shell: this.getVar(d,'shell','shell'),
        wall: this.getVar(d,'shell','wall'),
      },
      obj: {enable: this.getVar(d, 'obj', 'enable')},
      p: {
        x: this.getVar(d, 'p', 'x'),
        y: this.getVar(d, 'p', 'y'),
        z: this.getVar(d, 'p', 'z')
      },
      r: {
        x: this.getVar(d, 'r', 'x'),
        y: this.getVar(d, 'r', 'y'),
        z: this.getVar(d, 'r', 'z')
      },
      s: {
        x: this.getVar(d, 's', 'x'),
        y: this.getVar(d, 's', 'y'),
        z: this.getVar(d, 's', 'z')
      },
      round: {
        x: this.getVar(d, 'round', 'x'),
        y: this.getVar(d, 'round', 'y'),
        z: this.getVar(d, 'round', 'z')
      },
      offset: {
        x: this.getVar(d, 'offset', 'x'),
        y: this.getVar(d, 'offset', 'y'),
        z: this.getVar(d, 'offset', 'z')
      },
      linear: {
        height: this.getVar(d, 'linear', 'height'),
      },
      opt: {
        nX: this.getVar(d, 'opt', 'nX'),
        nY: this.getVar(d, 'opt', 'nY'),
        nZ: this.getVar(d, 'opt', 'nZ')
      },
      gearOpt: {
        pressureAngle: this.getVar(d, 'gear', 'pressureAngle'),
        pitch: this.getVar(d, 'gear', 'pitch'),
        profileShift: this.getVar(d, 'gear', 'profileShift'),
        clearance: this.getVar(d, 'gear', 'clearance'),
        backlash: this.getVar(d, 'gear', 'backlash'),
        height: this.getVar(d, 'gear', 'height'),
        holeD1: this.getVar(d, 'gear', 'holeD1'),
        rackLength: this.getVar(d, 'gear', 'rackLength'),
        rackWidth: this.getVar(d, 'gear', 'rackWidth'),
        res: this.getVar(d, 'gear', 'res'),
        show: this.getVar(d, 'gear', 'show'),
        stepsPerToothAngle: this.getVar(d, 'gear', 'stepsPerToothAngle'),
        t1: this.getVar(d, 'gear', 't1'),
        t2: this.getVar(d, 'gear', 't2'),
        twist: this.getVar(d, 'gear', 'twist'),
        b_h: this.getVar(d, 'gear', 'b_h'),
        b_d: this.getVar(d, 'gear', 'b_d'),
        b_g: this.getVar(d, 'gear', 'b_g'),
        t_g: this.getVar(d, 'gear', 't_g'),
        shaft_d: this.getVar(d, 'gear', 'shaft_d'),
        bolt_d: this.getVar(d, 'gear', 'bolt_d'),
        nut_d: this.getVar(d, 'gear', 'nut_d'),
        nut_h: this.getVar(d, 'gear', 'nut_h'),
        nut_offset: this.getVar(d, 'gear', 'nut_offset'),
        nut_slot: this.getVar(d, 'gear', 'nut_slot'),
        toothCount: this.getVar(d, 'gear', 'toothCount'),
        t_h: this.getVar(d, 'gear', 't_h'),
        add_t_w: this.getVar(d, 'gear', 'add_t_w'),
        add_t_d: this.getVar(d, 'gear', 'add_t_d')
      }
    };
    return p;
  }
  getVar(obj,varSubSetName,property) {
    if(obj.variableSet.varSubSetTree.hasOwnProperty(varSubSetName)){
      if(obj.variableSet.varSubSetTree[varSubSetName].hasOwnProperty(property)){
        if(obj.variableSet.varSubSetTree[varSubSetName][property].exp != ''){
          return obj.variableSet.varSubSetTree[varSubSetName][property].exp;
        }else{
          let v = obj.variableSet.varSubSetTree[varSubSetName][property].get();
          if(v === false || v === '0' || v === undefined){
            return 0;
          }else{
            return round(v,3);
          }
        }
      }
    }
  }
  offset(x,y,z,sx,sy,sz){
    let rx, ry, rz;
    if (!x) {
      rx = `(${sx})/2`;
    } else {
      rx = 0;
    }
    if (!y) {
      ry = `(${sy})/2`;
    } else {
      ry = 0;
    }
    if (!z) {
      rz = `(${sz})/2`;
    } else {
      rz = 0;
    }
    return {x:rx, y:ry, z:rz};
  }
  addTabs(priorityCount, priority) {
    let str = '';
    for (let r=0; r < (priorityCount-priority)+1; r++) {
      str += '\t';
    }
    return str;
  }
  getTransform(csgData) {
    const G = new THREE.Group();
    const m = new THREE.Matrix4();
    m.fromArray(csgData.G.matrix.elements);
    G.position.set(0, 0, 0);
    G.rotation.set(0, 0, 0);
    G.scale.set(1, 1, 1);
    G.updateMatrix();
    G.applyMatrix4(m);
    G.updateMatrix();
    const position = G.position;
    const rotation = G.rotation;
    const scale = G.scale;
    return {position, rotation, scale};
  }
  finalizeExport() {
    if (this.downloadFile) {
      saveTextAsFile(this.exportData, this.fileName + this.fileNameExt);
    }
    if (this.clipboardFile) {
      copyTextToClipboard(this.exportData);
    }
  }
}
class TranslateCurv extends TranslateCode {
  fileNameExt = '.curv';
  constructor(pObj) {// array of objects
    super(pObj);
    // (d.csgType == 'repeatr'){//curv variable !!!!! BROKEN post op rotation!!!!, use union wrapper instead
  }
  async export(csgData) {
    this.exportData = '';
    this.userVar = csgData.variableSet.variableDB.userVar;

    // function Output
    if (this.functionOutput) {
      this.exportData += `let \r ${this.functionName}{`;
      this.userVar.forEach( (v,i,arr) => {
        if (v.type === 'checkbox' || v.exp === true || v.exp === false) {
          this.exportData += `${v.name}`;
        } else {
          this.exportData += `${v.name}`;
        }
        if (i < arr.length - 1 ) {
          this.exportData +=`,`;
        }
      });
      this.exportData +=`} = \r`;

      if (csgData.csgOp) {
        //process Op
        this.exportData += this.exportOp(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObj(csgData);
      }

      this.exportData +=`\r in \r ${this.functionName}{`;
      this.userVar.forEach( (v,i,arr) => {
        this.exportData +=`${v.name} : ${Number(v.exp)}`;
        if (i < arr.length - 1) {
          this.exportData += `,`;
        }

      });
      this.exportData +=`} \r`;

    } else {
      this.exportData += 'parametric';
      this.userVar.forEach( (v,i,arr) => {
        if(v.type == 'checkbox' || v.exp === true || v.exp === false){
          this.exportData +=`\r ${v.name} :: int_slider[0,1] = ${Number(v.exp)};`;
        }else{
          this.exportData +=`\r ${v.name} :: slider[0,100] = ${v.exp};`;
        }

      });
      this.exportData +='\r';
      this.exportData +='in\r\r';
      this.exportData +='let\r\r';
      this.exportData +=`${this.makeLib()}\r`;
      this.exportData +='body = ';
      console.log('Exporting curv',csgData);
      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOp(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObj(csgData);
      }
      this.exportData +=';\nin\r\r';
      this.exportData +='body';


    }
    this.finalizeExport();
  }
  exportObj(csgData) {
    let d = csgData;
    console.log(csgData);
    let str = '';
    let valid = true;

    //get parameters
    const p = this.buildSolidParam(csgData);

    if (d.csgType === 'cylinder') {
      let body = `cylinder{d:${d.x},h:${d.z}}`
      let addZ = 0;
      let bodyInside = '';
      if(!p.shellOpt.zt && !p.shellOpt.zb){
        addZ = p.shellOpt.wall*4;
      }
      if (p.shellOpt.in) {
        body = `cylinder{d:${p.x},h:${p.z}}`;
        bodyInside = `cylinder{d:(${p.x})-${p.shellOpt.wall}*2,h:${p.z}+${addZ}} >> move[0,0,${(p.shellOpt.zb-p.shellOpt.zt)*p.shellOpt.wall}]`;
      } else {
        body = `cylinder{d:${p.x}+${p.shellOpt.wall}*2,h:${p.z}+${p.shellOpt.wall}*2}}`;
        bodyInside = `cylinder{d:${p.x},h:${p.z}+${addZ+d.shellOpt.wall}*2}} >> move[0,0,(${p.shellOpt.zb}-${p.shellOpt.zt})*${p.shellOpt.wall}]`;
      }
      if (p.y) {
        body += ` >> local_taper_xy {range:[-${p.z}/2,${p.z}/2], scale:[[1, 1], [1+${p.y}/${p.x}, 1+${p.y}/${p.x}]]}`;
        bodyInside += ` >> local_taper_xy {range:[$-{p.z/2},${p.z}/2], scale:[[1, 1], [1+${p.y}/${p.x}, 1+${p.y}/${p.x}]]}`;
      }
      if (p.shellOpt.en && p.shellOpt.in) {
        body = `difference(${body}, ${bodyInside} )`;
      }
      if (p.shellOpt.en && !p.shellOpt.in) {
        body = `difference(${body}, ${bodyInside})`;
      }
      str += body;

      if (!p.offset.x || !p.offset.y || !p.offset.z) {
        const offset_x = `(1-${p.offset.x})*(${p.x})/2`;
        const offset_y = `(1-${p.offset.y})*(${p.x})/2`;
        const offset_z = `(1-${p.offset.z})*(${p.z})/2`;
        str += ` >> move[${offset_x},${offset_y},${offset_z}]`;
      }

    } else
    if (d.csgType === 'box') {
      if (d.shellOpt.en) {
        let wallXin = d.x - d.shellOpt.wall * ( Number(d.shellOpt.xt) + Number(d.shellOpt.xb) ) + d.shellOpt.wall * flipB(d.shellOpt.in);
        let wallYin = d.y - d.shellOpt.wall * ( Number(d.shellOpt.yt) + Number(d.shellOpt.yb) ) + d.shellOpt.wall * flipB(d.shellOpt.in);
        let wallZin = d.z - d.shellOpt.wall * ( Number(d.shellOpt.zt) + Number(d.shellOpt.zb) ) + d.shellOpt.wall * flipB(d.shellOpt.in);
        const X = d.x + d.shellOpt.wall * flipB(d.shellOpt.in);
        const Y = d.y + d.shellOpt.wall * flipB(d.shellOpt.in);
        const Z = d.z + d.shellOpt.wall * flipB(d.shellOpt.in);
        if (!d.shellOpt.xt && !d.shellOpt.xb) {
          wallXin += 10;
        }
        if (!d.shellOpt.yt && !d.shellOpt.yb) {
          wallYin += 10;
        }
        if (!d.shellOpt.zt && !d.shellOpt.zb) {
          wallYin += 10;
        }
        let base, inside;
        if (d.roundX) {
          base = `box[${X-d.roundX*2},${Y-d.roundX*2},${Z-d.roundX*2}] >> offset (${d.roundX})`;
          inside = `box[${wallXin-d.roundX*2},${wallYin-d.roundX*2},${wallZin-d.roundX*2}] >> offset (${d.roundX})`;
        } else {
          base = `box[${X},${Y},${Z}]`;
          inside = `box[${wallXin-d.roundX*2},${wallYin-d.roundX*2},${wallZin-d.roundX*2}]`;
        }
        if (!d.shellOpt.xt || !d.shellOpt.yt || !d.shellOpt.zt || !d.shellOpt.xb || !d.shellOpt.yb || !d.shellOpt.zb) {
          inside += `>> move[${d.shellOpt.wall / -1 * ( Number(d.shellOpt.xt) -  Number(d.shellOpt.xb))},${d.shellOpt.wall / -1 * ( Number(d.shellOpt.yt) -  Number(d.shellOpt.yb))},${d.shellOpt.wall / -1 * ( Number(d.shellOpt.zt) -  Number(d.shellOpt.zb))}]`;
        }
        str += `difference[${base}, ${inside}]`;
      } else {
        if (p.round.x) {
          str += `box[(${p.x})-(${p.round.x})*2,(${d.y})-(${d.roundX})*2,(${d.z})-(${d.roundX*2})] >> offset (${d.roundX})`;
        } else {
          str += `box[${p.x},${p.y},${p.z}]`;
        }
      }

      if (!p.offset.x || !p.offset.y || !p.offset.z) {
        let offset_x = `(1-${p.offset.x})*(${p.x})/2`;
        let offset_y = `(1-${p.offset.y})*(${p.y})/2`;
        let offset_z = `(1-${p.offset.z})*(${p.z})/2`;
        str += ` >> move[${offset_x},${offset_y},${offset_z}]`;
      }
    } else
    if (d.csgType === 'slot'){
      if(d.roundX){
        str += `box[${d.x-d.roundX*2},${d.y-d.roundX*2},${d.z-d.roundX*2}] >> offset (${d.roundX})`;
      }else{
        str += `box[${d.x},${d.y},${d.z}]`;
      }
      if(d.shellOpt.en && d.shellOpt.in){
        str += ` >> offset (${d.shellOpt.wall/-4}) >> shell (${d.shellOpt.wall/2})`;
      }
      if(d.shellOpt.en && !d.shellOpt.in){
        str += ` >> shell (${d.shellOpt.wall/2})`;
      }

      if(!d.centerX || !d.centerY || !d.centerZ){
        let offset_x = (1-Number(d.centerX))*Number(d.x)/2;
        let offset_y = (1-Number(d.centerY))*Number(d.y)/2;
        let offset_z = (1-Number(d.centerZ))*Number(d.z)/2;
        str += ` >> move[${offset_x},${offset_y},${offset_z}]`;
      }
    } else
    if (d.csgType === 'sphere'){
      str += `sphere(${p.x})`;

      if(d.shellOpt.en && d.shellOpt.in){
        str += ` >> offset ((${p.shellOpt.wall})/-2) >> shell (${d.shellOpt.wall})`;
      }
      if(d.shellOpt.en && !d.shellOpt.in){
        str += ` >> shell (${p.shellOpt.wall})`;
      }
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        let offset_x = `(1-${p.offset.x})*(${p.x})/2`;
        let offset_y = `(1-${p.offset.y})*(${p.x})/2`;
        let offset_z = `(1-${p.offset.z})*(${p.x})/2`;
        str += ` >> move[${offset_x},${offset_y},${offset_z}]`;
      }
    } else
    if (d.csgType === 'toroid'){
      str += `torus{major:${p.x},minor:${p.y}}`;
    } else {
      valid = false;
    }
    //if(d.shellOpt.en && d.shellOpt.in){
    //   str += ` >> offset (${d.shellOpt.wall/-2}) >> shell (${d.shellOpt.wall})`;
    // }
    //if(d.shellOpt.en && !d.shellOpt.in){
    //   str += ` >> shell (${d.shellOpt.wall})`;
    // }

    if (valid) {
      return str;
    } else {
      return '';
    }
  }
  exportOp(csgData) {
    let d = csgData;
    let opSTR = '';
    let priorityCount = 0;
    console.log(this.pObj);
    this.pObj.forEach( (c,i,arr) => {
      if (c.priority > priorityCount) {
        priorityCount = c.priority;
      }
    });
    let p = this.buildSolidParam(d);

    if (d.csgType == 'union' ||
      d.csgType == 'difference' ||
      d.csgType == 'intersection' ||
      d.csgType == 'repeatxyz' ||
      d.csgType == 'repeatr' ||
      d.csgType == 'extrude2d'){
      let str = '';
      let postP = false;

      // Assemble all dependencies in comma sepperated str
      csgData.children.forEach( (c, i, arr) => {
        if (c.type == 'csg') {
          str += '\r';

          if (c.enable == false) {
            str += '//';
          }
          str += this.addTabs(priorityCount,d.priority);

          c.actions.forEach((act, i, arr) => {
            postP = true;
            if (act.action == 'cut') {
              str += `difference[`;
            }
          });

          if (c.curvOpt?.pre) {
            str += c.curvOpt.pre;
          }
//create solid
          if (c.csgOp) {
            str += this.exportOp(c);
          } else {
            //create Obj
            str += this.exportObj(c);
          }
//post process
          c.actions.forEach((act,i,arr) => {
            if (act.action == 'cut') {
              str += `, half_space {at: [${act.origin.x},${act.origin.y},${act.origin.z}], normal: [${act.opt.x*-1},${act.opt.y*-1},${act.opt.z*-1}]}]`
            }
          });

          str = this.postProcessObj(c,str) + '\r';

          if (c.curvOpt?.post) {
            str += c.curvOpt.post;
          }
          if (c.enable == false) {
            str += '//';
          }
          if (i < arr.length-1){
            str += this.addTabs(priorityCount,d.priority);
            str += ',';
          }

        }
      });
      opSTR = '';

      let nameAdd;
      if (d.nickName) {
        nameAdd = `\t\t//${d.nickName}`;
      } else {
        nameAdd = ``;
      }

      if (d.csgType == 'union') {
        opSTR += 'union[' + nameAdd + str + '\r';
        opSTR += this.addTabs(priorityCount, d.priority);
      } else if (d.csgType == 'difference') {
        opSTR += 'difference[' + nameAdd + str + '\r';
        opSTR += this.addTabs(priorityCount, d.priority);
      } else if (d.csgType == 'intersection') {
        opSTR += 'intersection[' + nameAdd + str + '\r';
        opSTR += this.addTabs(priorityCount, d.priority);
      } else if (d.csgType == 'repeatxyz') {
        opSTR += 'union[' + str + '\r';
        opSTR += this.addTabs(priorityCount, d.priority);
      } else if (d.csgType == 'repeatr'){//curv variable !!!!! BROKEN post op rotation!!!!
        opSTR += 'union[for (i in 0..('+Number(d.nX)+'-1)) union['+ str;
      } else if (d.csgType == 'extrude2d') {//curv variable!!!!!
        if (csgData.extrusionType == 'linear') {
          let op = 'extrude';
          if (csgData.curvPancake) {
            op = 'pancake';
          }
          opSTR += `union[${op} ${p.linear.height} << polygon [`;
        } else if (csgData.extrusionType  === 'perimeter_extrude') {
          opSTR += `union[perimeter_extrude (polygon [`;
        } else {
            opSTR += `union[revolve  << polygon [`;
          }
      }
      csgData.children[1]?.PT?.forEach((c,i,arr) => {
          opSTR += `[${round(c.x,2)},${round(c.y,2)}]`;
          if (i<arr.length-1) {
            opSTR += ',';
          }
      });

      if (d.csgType === 'repeatxyz' ||
        d.csgType === 'repeatr') {
        opSTR += this.addTabs(priorityCount, d.priority);
      }
      opSTR += `]`;
      if (d.csgType == 'extrude2d') {
          if (csgData.extrusionType === 'linear') {
            opSTR += `] >> move[0,0,(${p.linear.height})/2]`;
          } else if (csgData.extrusionType  === 'perimeter_extrude' ) {
            let pe_cross = csgData.perimeter_extrude_crossection;
            if ( csgData.perimeter_extrude_crossection === '' ) {
              pe_cross = `circle((${p.linear.height})*2)`;
            }
            opSTR += `) (${pe_cross}) ]`;
          } else {
            opSTR += `] >> rotate{angle:(-90*deg),axis:'X_axis'}`;
          }
      }
      if (d.csgType === 'union' ||
        d.csgType === 'difference' ||
        d.csgType === 'intersection'||
        d.csgType === 'repeatxyz' ||
        d.csgType === 'repeatr') {
        if (d.csgType === 'repeatxyz') {
          opSTR += ` >> repeat_finite [${p.x},${p.y},${p.z}] [${p.opt.nX},${p.opt.nY},${p.opt.nZ}] `;

          if (d.csgType === 'repeatxyz' && (!p.offset.x || !p.offset.y || !p.offset.z)) {
            let offset_x = `(1-(${p.offset.x}))*(${p.x})*((${p.opt.nX})-1)/-2`;
            let offset_y = `(1-(${p.offset.y}))*(${p.y})*((${p.opt.nY})-1)/-2`;
            let offset_z = `(1-(${p.offset.z}))*(${p.z})*((${p.opt.nZ})-1)/-2`;
            opSTR += ` >> move[${offset_x},${offset_y},${offset_z}]`;
          }

        }
        if (d.csgType === 'repeatr') {
          if (p.x) {
            opSTR += ` >> move[0,${p.x},0]`;
          }
          opSTR += ` >> rotate{angle:i*(${p.y})*deg,axis:'Z_axis'}]`;
        }

      }
      return opSTR;
    }
  }
  postProcessObj(csgData, str) {
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);
    //action
    d.actions.forEach((act, i, arr) => {
        if (act.action === 'mirror') {
          str += ` >> reflect [${act.opt.x},${act.opt.y},${act.opt.z}]`;
        }
      }
    );
    //Scale
    if (p.s.x != 1 || p.s.x != 1 || p.s.x != 1) {
      str += ` >> stretch[${p.s.x},${p.s.y},${p.s.z}]`;
    }
    //Rotation
    if (rotation.z) {
      str += ` >> rotate{angle:(${p.r.z})*deg,axis:'Z_axis'}`;
    }
    if (rotation.y) {
      str += ` >> rotate{angle:(${p.r.y})*deg,axis:'Y_axis'}`;
    }
    if (rotation.x) {
      str += ` >> rotate{angle:(${p.r.x})*deg,axis:'X_axis'}`;
    }
    //position
    if (position.x != 0 || position.y != 0 || position.z != 0) {
      str += ` >> move[${p.p.x},${p.p.y},${p.p.z}]`;
    }
    return str;
  }

  makeLib() {
    let lib = `
    patternGyroid = gyroid >> shell .3 >> lipschitz 1.5;
    patternShape =  extrude (1) << repeat_xy [2,2] << shell .2 << circle 2;
    schwartz_p = make_shape {
        dist p = -(cos(p.[X]) + cos(p.[Y]) + cos(p.[Z]));
        is_3d = true;
    };
    patternSchwartz = shell (.2) schwartz_p >> lipschitz 2;
    patternCubes = make_shape {
      dist[x,y,z,_] = sin x * sin y * sin z;
      is_3d = true;
    };
    engrave depth pattern target =
        difference [target,
            intersection [
                target >> shell depth,
                pattern]
        ];
    emboss i pat shape = morph i [shape, intersection[shape, pat]];
    `;
    return lib;
  }
}
class TranslateJsCadV1 extends TranslateCode {
  fileNameExt = '.txt';
  constructor(pObj) {//array of objects
    super(pObj);
  }
  async export(csgData) {
    this.exportData = '';
    let userVar = csgData.WS.variableDB.userVar;
    if(this.functionOutput){
      this.exportData += `function ${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${v.name}`;
        if(i<arr.length-1){
          this.exportData +=`,`;
        }
      });
      this.exportData += '){ return ';
      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOpV1(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV1(csgData);
      }

      this.exportData += ';}\r';

      this.exportData += `\rres = ${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${Number(v.exp)}`;
        if(i<arr.length-1){
          this.exportData += `,`;
        }
      });
      this.exportData += `); \r`;

    } else {
      this.exportData += '';
      userVar.forEach( (v,i,arr) => {
        if(v.type == 'checkbox' || v.exp === true || v.exp === false){
          this.exportData +=`\r let ${v.name} = ${Number(v.exp)};`;
        }else{
          this.exportData +=`\r let ${v.name} = ${Number(v.exp)};`;
        }

      });
      this.exportData +='\n' +
        '\r';
      this.exportData +='\r\rres= ';

      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOpV1(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV1(csgData);
      }

      this.exportData += `\n`;
      this.finalizeExport();
    }
    return this.exportData;
  }

  exportObjV1(csgData) {
    const self = this;
    let d = csgData;
    let str = '';
    let valid = true;
    const p = this.buildSolidParam(csgData);

    if (d.csgType === 'cylinder'){

      let body = `cylinder({ center:[${p.offset.x}, ${p.offset.y}, ${p.offset.z}], fn:${self.resolution}, d:${p.x}, h:${p.z} })`;
      if(p.y){
        body = `cylinder({ center:[${p.offset.x}, ${p.offset.y}, ${p.offset.z}], fn:${self.resolution}, d1:${p.x}, d2:((${p.x})+(${p.y})), h:${p.z} })`;
      }
      str += body;

    }
    else if (d.csgType === 'box'){

      if(p.round.x){
        str += `cube({ center:[${p.offset.x}, ${p.offset.y}, ${p.offset.z}], fn: ${self.resolution}, size:[${p.x},${p.y},${p.z}], radius:[${p.round.x},${p.round.y},${p.round.z}] })`;
      }else{
        str += `cube({ center:[${p.offset.x}, ${p.offset.y}, ${p.offset.z}], size:[${p.x},${p.y},${p.z}] })`;
      }

    }
    else if (d.csgType === 'sphere'){

      str += `sphere({ center:[${p.offset.x}, ${p.offset.y}, ${p.offset.z}],  fn: ${self.resolution}, r:(${p.x})/2})`;

    }
    else if (d.csgType === 'toroid'){
      str += `torus({ fni:${self.resolution}, fno:${self.resolution}, ro:(${p.x})/2, ri:(${p.y})/2 })`;
    }
    else if (d.csgType === 'rackgear'){
      str += `rackgear(${p.x},${p.y},${p.z},{
      "backlash": ${p.gearOpt.backlash},
      "clearance": ${p.gearOpt.clearance},
      "height": ${p.gearOpt.height},
      "holeD1": 3,
      "holeD2": 3,
      "pitch": ${p.gearOpt.pitch},
      "pressureAngle": 20,
      "profileShift": ${p.gearOpt.profileShift},
      "rackLength": 22,
      "rackWidth": 10,
      "res": 3,
      "show": "A",
      "stepsPerToothAngle": 3,
      "t1": 30,
      "t2": 30,
      "twist": 0
    })`;
    }
    else if (d.csgType === 'pulleybelt'){
      str += `belt(${d.gearOpt.pType},${p.gearOpt.toothCount},${p.gearOpt.t_h},${p.gearOpt.add_t_w},${p.gearOpt.add_t_d},{
      "b_h": ${p.gearOpt.b_h},      "b_d": ${p.gearOpt.b_d},
      "shaft_d": ${p.gearOpt.shaft_d},
      "bolt_d": ${p.gearOpt.bolt_d},
      "pitch": 3.14,
      "pressureAngle": 20,
      "profileShift": 0,
      "rackLength": 22,
      "rackWidth": 10,
      "res": 3,
      "show": "A",
      "stepsPerToothAngle": 3,
      "t1": 30,
      "t2": 30,
      "twist": 0
    })`;
    }
    else if (d.csgType === 'pulley'){
      str += `pulley(${d.gearOpt.pType},${p.gearOpt.toothCount},${p.gearOpt.t_h},${p.gearOpt.add_t_w},${p.gearOpt.add_t_d},{
      "t_g": ${p.gearOpt.t_g},
      "b_g": ${p.gearOpt.b_g},
      "b_h": ${p.gearOpt.b_h},
      "b_d": ${p.gearOpt.b_d},
      "shaft_d": ${p.gearOpt.shaft_d},
      "bolt_d": ${p.gearOpt.bolt_d},
      "nut_d": ${p.gearOpt.nut_d},
      "nut_h": ${p.gearOpt.nut_h},
      "nut_offset": ${p.gearOpt.nut_offset},
      "nut_slot": ${p.gearOpt.nut_slot},
      "pitch": 3.14,
      "pressureAngle": 20,
      "profileShift": 0,
      "rackLength": 22,
      "rackWidth": 10,
      "res": 3,
      "show": "A",
      "stepsPerToothAngle": 3,
      "t1": 30,
      "t2": 30,
      "twist": 0
    })`;
    }
    else if (d.csgType === 'bevelgear'){
      str += `bevelgear(${d.gearOpt.OffsetA},${d.gearOpt.OffsetB},${d.gearOpt.t1},${d.gearOpt.t2},{
      "backlash": 0.3,
      "clearance": 0.3,
      "height": 5,
      "holeD1": 3,
      "holeD2": 3,
      "pitch": 3.14,
      "pressureAngle": 20,
      "profileShift": 0,
      "rackLength": 22,
      "rackWidth": 10,
      "res": 3,
      "show": "A",
      "stepsPerToothAngle": 3,
      "t1": 30,
      "t2": 30,
      "twist": 0
    })`;
    }
    else if (d.csgType === 'gear'){
      str += `gear(${p.x},${p.y},${p.z},{
      "backlash": ${p.gearOpt.backlash},
      "clearance": ${p.gearOpt.clearance},
      "height": ${p.gearOpt.height},
      "holeD1": 3,
      "holeD2": 3,
      "pitch": ${p.gearOpt.pitch},
      "pressureAngle": 20,
      "profileShift": ${p.gearOpt.profileShift},
      "rackLength": 22,
      "rackWidth": 10,
      "res": 3,
      "show": "A",
      "stepsPerToothAngle": 3,
      "t1": 30,
      "t2": 30,
      "twist": 0
    })`;
    }
    else {
      valid = false;
    }

    if (valid) {
      return str;
    } else {
      return '';
    }

  }
  exportOpV1(csgData) {
    let d = csgData;
    let opSTR = '';
    let priorityCount = 0;
    this.pObj.forEach( (c, i, arr) => {
      if (c.priority > priorityCount) {
        priorityCount = c.priority;
      }
    });

    const p = this.buildSolidParam(csgData);
    if (d.csgType == 'union' ||
      d.csgType == 'difference' ||
      d.csgType == 'intersection'||
      d.csgType == 'repeatxyz' || d.csgType == 'repeatr' || d.csgType == 'extrude2d'){
      let str = '';
      let postP = false;

      //Assemble all dependencies in comma sepperated str
      csgData.children.forEach( (c,i,arr) => {
        if(c.type == 'csg'){
          str += '\r';

          if(c.enable == false) {
            str += '//';
          }

          str += this.addTabs(priorityCount,d.priority);

          if(c.curvOpt?.pre){
            //str += c.curvOpt.pre;
          }
          //pre-process
          str = this.preProcessObjV1(c,str)+'';

//create solid
          if(c.csgOp){
            str += this.exportOpV1(c);
          } else {
            //create Obj
            str += this.exportObjV1(c);
          }

          //post process
          str = this.postProcessObjV1(c,str)+'\r';

          if(c.curvOpt?.post){
            //str += c.curvOpt.post;
          }
          if(c.enable == false) {
            str += '//';
          }
          if(i < arr.length-1){
            for(let r=0; r < (priorityCount-d.priority)+1; r++){
              str += '\t';
            }
            str += ',';
          }

        }
      });
      opSTR = '';

      let nameAdd;
      if(d.nickName){
        nameAdd = `\t\t//${d.nickName}`;
      }else{
        nameAdd = ``;
      }
      if(d.csgType == 'repeatxyz')
      {

      }
      if(d.csgType == 'union'){
        opSTR += 'union('+ nameAdd + str +'\r';
      }else if(d.csgType == 'difference'){
        opSTR += 'difference('+ nameAdd + str +'\r';
      }else if(d.csgType == 'intersection'){
        opSTR += 'intersection('+ nameAdd + str +'\r';
      }else if(d.csgType == 'repeatxyz'){//BROKEN!!!!!
        //start translate
        console.log(p.offset);

        if (!p.offset.x || !p.offset.y || !p.offset.z) {
          let offset_x = `(1-(${p.offset.x}))*(${p.x})*((${p.opt.nX})-1)/-2`;
          let offset_y = `(1-(${p.offset.y}))*(${p.y})*((${p.opt.nY})-1)/-2`;
          let offset_z = `(1-(${p.offset.z}))*(${p.z})*((${p.opt.nZ})-1)/-2`;
          opSTR += `translate([${offset_x},${offset_y},${offset_z}],`;
        }
        opSTR += `union(loopXYZ(${p.opt.nX},${p.opt.nY},${p.opt.nZ},${p.x},${p.y},${p.z},union(`+ str +'\r';
      }else if(d.csgType == 'repeatr'){//BROKEN!!!!!
        opSTR += `union(loopR(${p.opt.nX},${p.x},${p.y},union(`+ str +'\r';
      }else if(d.csgType == 'extrude2d'){
        if(csgData.extrusionType == 'linear'){
          opSTR += `linear_extrude({height: ${p.linear.height}}, CAG.fromPoints([`;
        } else {
          opSTR += `rotate_extrude({fn: ${this.resolution}, CAG.fromPoints([`;
        }

        csgData.children[1].PT.forEach((c,i,arr)=>{
          opSTR += `[${round(c.x,2)},${round(c.y,2)}]`;
          if(i<arr.length-1){
            opSTR += ',';
          }
        })
        opSTR += `]))\r`;

      }

      if(d.csgType == 'union' ||
        d.csgType == 'difference' ||
        d.csgType == 'intersection'||
        d.csgType == 'repeatxyz' ||
        d.csgType == 'repeatr') {
        for (let r = 0; r < (priorityCount - d.priority); r++) {
          opSTR += '\t';
        }
        opSTR += ')';//end OP
        if(d.csgType == 'repeatxyz' && (!p.offset.x || !p.offset.y || !p.offset.z)){
          opSTR += `)`;
        }
        if(d.csgType == 'repeatxyz'){
          opSTR += `))`;
        }
        if(d.csgType == 'repeatr'){//BROKEN!!!!!
          opSTR += `))`;
        }


      }
      return opSTR;

    }
  }
  preProcessObjV1(csgData, str) {
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    d.actions.forEach((act, i, arr) => {
        if (act.action == 'mirror') {
          str += `mirror({normal: [${act.x},${act.y},${act.z}]}, `;
        }
        if (act.action == 'cut') {
          // str += `CSG.Plane.fromNormalAndPoint([opt.opt.x, opt.opt.y, opt.opt.z], [opt.origin.x, opt.origin.y, opt.origin.z]), `
        }
      });
    // action

    // position start
    if (position.x || position.y || position.z){
      if (csgData.enable == false) {
        str += '//';
      }
      str += `translate([${p.p.x},${p.p.y},${p.p.z}],\r`;
    }

    // Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      if (csgData.enable == false) {
        str += '//';
      }
      str += `rotate([${p.r.x},${p.r.y},${p.r.z}],\r`;

      if (csgData.enable == false) {
        str += '//';
      }
    }

    // Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      if (csgData.enable == false) {
        str += '//';
      }
      str += `scale([${p.s.x},${p.s.y},${p.s.z}],\r`;
    }

    return str;

  }
  postProcessObjV1(csgData, str) {
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    d.actions.forEach((act, i, arr) => {
        if ( act.action === 'mirror' ) {
          str += `)`;
        }
        if ( act.action === 'cut' ) {
          str += `.cutByPlane(CSG.Plane.fromNormalAndPoint([${act.opt.x}, ${act.opt.y}, ${act.opt.z}], [${act.origin.x}, ${act.origin.y}, ${act.origin.z}]))`;
        }
      }
    );
    //action

    //Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      str += `)`;
    }

    //Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      str += `)`;
    }

    //position start
    if (position.x || position.y || position.z){

      str += `)`;
    }


    return str;

  }
}
class TranslateJsCadV2 extends TranslateCode {
  fileNameExt = '.txt';
  constructor(pObj) {//array of objects
    super(pObj);
  }
  async export(csgData) {
    this.exportData = '';
    let userVar = csgData.WS.variableDB.userVar;
    if(this.functionOutput){
      this.exportData += `function ${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${v.name}`;
        if(i<arr.length-1){
          this.exportData +=`,`;
        }
      });
      this.exportData += '){ return ';
      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOpV2(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV2(csgData);
      }

      this.exportData += ';}\r';

      this.exportData += `\rres = ${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${Number(v.exp)}`;
        if(i<arr.length-1){
          this.exportData += `,`;
        }
      });
      this.exportData += `); \r`;

    } else {
      this.exportData += '';
      userVar.forEach( (v,i,arr) => {
        if (v.type == 'checkbox' || v.exp === true || v.exp === false) {
          this.exportData +=`\r let ${v.name} = ${Number(v.exp)};`;
        } else {
          this.exportData +=`\r let ${v.name} = ${Number(v.exp)};`;
        }

      });
      this.exportData +='\n' +
        '\r';
      this.exportData +='\r\rres= ';

      if (csgData.csgOp) {
        //process Op
        this.exportData += this.exportOpV2(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV2(csgData);
      }

      this.exportData += `\n`;
      this.finalizeExport();
    }
    return this.exportData;
  }

  exportObjV2(csgData) {
    const self = this;
    const d = csgData;
    let str = '';
    let valid = true;
    const p = this.buildSolidParam(csgData);

    if (d.csgType == 'cylinder') {

      //start translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        let temp = this.offset(p.offset.x,p.offset.y,p.offset.z,p.x,p.x,p.z);
        str += `translate([${temp.x},${temp.y},${temp.z}],`;
      }

      let body = `cylinder({radius:((${p.x})/2), height:(${p.z}), segments: ${self.resolution}})`;
      if(p.y){
        body = `cylinderElliptic{ startRadius: [(${p.x})/2, (${p.x})/2], endRadius: [((${p.x})+(${p.y}))/2, ((${p.x})+(${p.y}))/2], height:(${p.z}), segments: ${self.resolution}}`;
      }
      str += body;

      //close translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        str += `)`;
      }

    }
    else if (d.csgType == 'box') {

      //start translate
      //start translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        let temp = this.offset(p.offset.x,p.offset.y,p.offset.z,p.x,p.y,p.z);
        str += `translate([${temp.x},${temp.y},${temp.z}],`;
      }

      if(p.round.x){
        str += `roundedCuboid({size:[${p.x},${p.y},${p.z}], roundRadius:${p.round.x}, segments: ${self.resolution} })`;
      }else{
        str += `cuboid({size:[${p.x},${p.y},${p.z}]})`;
      }

      //close translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        str += `)`;
      }
    }
    else if (d.csgType == 'sphere') {

      //start translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        let temp = this.offset(p.offset.x,p.offset.y,p.offset.z,p.x,p.x,p.x);
        str += `translate([${temp.x},${temp.y},${temp.z}],`;
      }

      str += `sphere({radius:(${p.x})/2, segments: ${self.resolution}})`;

      //close translate
      if(!p.offset.x || !p.offset.y || !p.offset.z){
        str += `)`;
      }

    }
    else if (d.csgType == 'toroid') {
      str += `torus({outerRadius:${p.x}, innerRadius:${p.y}, innerSegments:${self.resolution}, outerSegments:${self.resolution} })`;
    }
    else {
      valid = false;
    }
    //if(d.shellOpt.en && d.shellOpt.in){
    //   str += ` >> offset (${d.shellOpt.wall/-2}) >> shell (${d.shellOpt.wall})`;
    // }
    //if(d.shellOpt.en && !d.shellOpt.in){
    //   str += ` >> shell (${d.shellOpt.wall})`;
    // }

    if (valid) {
      return str;
    } else {
      return '';
    }

  }
  exportOpV2(csgData) {
    const d = csgData;
    let opSTR = '';
    let priorityCount = 0;
    this.pObj.forEach( (c, i, arr) => {
      if (c.priority > priorityCount){
        priorityCount = c.priority;
      }
    });
    const p = this.buildSolidParam(csgData);

    if (d.csgType == 'union' ||
      d.csgType == 'difference' ||
      d.csgType == 'intersection' ||
      d.csgType == 'repeatxyz' || d.csgType == 'repeatr'){
      let str = '';
      let postP = false;

      //Assemble all dependencies in comma sepperated str
      csgData.children.forEach( (c,i,arr) => {
        if(c.type == 'csg'){
          str += '\r';

          if(c.enable == false) {
            str += '//';
          }
          for(let r=0; r < (priorityCount-d.priority)+1; r++){
            str += '\t';
          }

          if(c.curvOpt?.pre){
            //str += c.curvOpt.pre;
          }
          //pre-process
          str = this.preProcessObjV2(c,str)+'';

//create solid
          if (c.csgOp) {
            str += this.exportOpV2(c);
          } else {
            //create Obj
            str += this.exportObjV2(c);
          }

          //post process
          str = this.postProcessObjV2(c,str)+'\r';

          if(c.curvOpt?.post){
            //str += c.curvOpt.post;
          }
          if(c.enable == false) {
            str += '//';
          }
          if(i < arr.length-1){
            for(let r=0; r < (priorityCount-d.priority)+1; r++){
              str += '\t';
            }
            str += ',';
          }

        }
      });
      opSTR = '';

      let nameAdd;
      if(d.nickName){
        nameAdd = `\t\t//${d.nickName}`;
      }else{
        nameAdd = ``;
      }
      if(d.csgType == 'union'){
        opSTR += 'union('+ nameAdd + str +'\r';
      }else if(d.csgType == 'difference'){
        opSTR += 'subtract('+ nameAdd + str +'\r';
      }else if(d.csgType == 'intersection'){
        opSTR += 'intersect('+ nameAdd + str +'\r';
      }else if(d.csgType == 'repeatxyz'){//BROKEN!!!!!
        //start translate
        if (!p.offset.x || !p.offset.y || !p.offset.z) {
          let offset_x = `(1-(${p.offset.x}))*(${p.x})*((${p.opt.nX})-1)/-2`;
          let offset_y = `(1-(${p.offset.y}))*(${p.y})*((${p.opt.nY})-1)/-2`;
          let offset_z = `(1-(${p.offset.z}))*(${p.z})*((${p.opt.nZ})-1)/-2`;
          opSTR += `translate([${offset_x},${offset_y},${offset_z}],`;
        }
        opSTR += `union(loopXYZ(${p.opt.nX},${p.opt.nY},${p.opt.nZ},${p.x},${p.y},${p.z},union(`+ str +'\r';
      }else if(d.csgType == 'repeatr'){//BROKEN!!!!!
        opSTR += `union(loopR(${p.opt.nX},${p.x},${p.y},union(`+ str +'\r';
      }

      if(d.csgType == 'union' ||
        d.csgType == 'difference' ||
        d.csgType == 'intersection'||
        d.csgType == 'repeatxyz' ||
        d.csgType == 'repeatr') {
        for (let r = 0; r < (priorityCount - d.priority); r++) {
          opSTR += '\t';
        }
        opSTR += ')';//end OP
        if(d.csgType == 'repeatxyz' && (!p.offset.x || !p.offset.y || !p.offset.z)){
          opSTR += `)`;
        }
        if(d.csgType == 'repeatxyz'){
          opSTR += `))`;
        }
        if(d.csgType == 'repeatr'){//BROKEN!!!!!
          opSTR += `))`;
        }


      }
      return opSTR;

    }
    return opSTR;
  }
  preProcessObjV2(csgData,str){
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    d.actions.forEach((act, i, arr) => {
        if(act.action == 'mirror'){
          //str += `mirror({normal: [${act.x},${act.y},${act.z}]}, `
        }
        // if(act.action == 'cut'){
        //   str += `cutByPlane({normal: [${act.x},${act.y},${act.z}]}, `
        // }
      }
    );
    //action

    //position start
    if (position.x || position.y || position.z) {
      str += `translate([${p.p.x},${p.p.y},${p.p.z}],`;
    }
    //Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      str += `rotate([(${p.r.x})*0.0174533, (${p.r.y})*0.0174533, (${p.r.z})*0.0174533],`;
    }
    //Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      str += `scale([${p.s.x},${p.s.y},${p.s.z}],`;
    }


    return str;

  }
  postProcessObjV2(csgData,str){
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    //Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      str += `)`;
    }

    //Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      str += `)`;
    }

    //position start
    if (position.x || position.y || position.z){

      str += `)`;
    }

    d.actions.forEach((act,i,arr) => {
        if (act.action == 'mirror') {
          //str += `)`
        }
      }
    );
    //action

    return str;

  }
}
class TranslateInventCSGcode extends TranslateCode {
  fileNameExt = '.txt';
  constructor(pObj) {//array of objects
    super(pObj);
  }
  async export(csgData) {
    this.exportData = '\n';
    console.log(this);
    let objData = {};
    let objDatalist = [];
    let objList = this.pObj;
    objList.forEach(element => {
      let el = element.save();
      console.log(el);
      if (el.uid) {
        objData[el.uid] = el;
        objDatalist.push(el);
      }
    });
    objDatalist.sort(function(a, b){
      return a.q-b.q;
    });
    objDatalist.forEach(element => {
      this.exportData += this.processCSG(element);
    });
    this.finalizeExport();
    return this.exportData;
  }
  processCSG(obj) {
    let code = "";
    let o = obj.opt;

    if (obj?.opt?.csgOp) {
      code += this.objOpCSG(obj);
    } else {
      code += this.objCSG(obj);
      //obj
    }
    console.log(code);
    return code;
  }
  postProcessCSG(obj, body){
    let str = '';
    obj.actions.forEach((act,i,arr) => {
        str += `actionOp(\n`;
      }
    );
    str += body;
    obj.actions.forEach((act,i,arr) => {
        str += `,${objToString(act)})\n`;
      }
    );
    return str;
  }
  objCSG(obj) {
    let str = '';
    let body = `\n`;
    console.log(obj);
    const transformMatrix = obj?.t?.e;
    if(obj.gUIDf && obj.opt && obj.opt.csgType){
      if(transformMatrix){
        str = `\nlet _${obj.gUIDf} = transform(${JSON.stringify(transformMatrix)},\n`;
        body = `${obj.opt.csgType}Gen(${objToString(obj.opt)})`;
        str += this.postProcessCSG(obj,body);
        str += `);\n`;

      }else{
        str = `\nlet _${obj.gUIDf} = ${obj.opt.csgType}Gen(${objToString(obj.opt)});\n`;
      }
    }
    return str;
  }
  objOpCSG(obj){
    let str = '';
    const transformMatrix = obj?.t?.e;
    if (obj.uid && obj.opt && obj.opt.csgType) {

      if (transformMatrix) {
        str = `\nlet _${obj.gUIDf} = transform(${JSON.stringify(transformMatrix)},`;
        let body = `\n
        ${obj.opt.csgType}Op([`;
        obj.opt.csgChild.forEach((element,i,arr) => {
          body += '_'+element;
          if( (arr.length)>1 && i>=0 && i<arr.length ) {
            body += ',';
          }
        });
        body +=`], {opt: ${objToString(obj.opt)}})`;

        str += this.postProcessCSG(obj, body);

        str += `);\n`;

      } else {
        str = `\nlet _${obj.gUIDf} = ${obj.opt.csgType}Op([`;
        obj.opt.csgChild.forEach((element,i,arr) => {
          str += '_'+element;
          if ( (arr.length)>1 && i>=0 && i<arr.length ) {
            str += ',';
          }
        });


        str +=`], {opt: ${objToString(obj.opt)}});\n`;
      }

    }
    return str;
  }
}

//Work in progress
class TranslateScad extends TranslateCode {
  fileNameExt = '.txt';
  constructor(pObj) {//array of objects
    super(pObj);
  }
  addInclude() {
    this.exportData +='use <torus.scad> \n';
    this.exportData +='use <ii.scad> \n';
  }
  async export(csgData) {
    this.exportData = '';
    let userVar = csgData.WS.variableDB.userVar;
    if(this.functionOutput){
      this.addInclude();
      this.exportData += `module ${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${v.name}=${Number(v.exp)}`;
        if(i<arr.length-1){
          this.exportData +=`,`;
        }
      });
      this.exportData += '){\r ';
      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOpV1(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV1(csgData);
      }

      this.exportData += ';}\r';

      this.exportData += `\r${this.functionName}(`;
      userVar.forEach( (v,i,arr) => {
        this.exportData +=`${v.name}=${Number(v.exp)}`;
        if(i<arr.length-1){
          this.exportData += `,`;
        }
      });
      this.exportData += `); \r`;
      this.finalizeExport();

    } else {
      this.exportData += '';
      this.addInclude();
      userVar.forEach( (v,i,arr) => {
        if(v.type == 'checkbox' || v.exp === true || v.exp === false){
          this.exportData +=`\r${v.name} = ${Number(v.exp)};`;
        }else{
          this.exportData +=`\r${v.name} = ${Number(v.exp)};`;
        }

      });

      this.exportData +='\r\r';

      if(csgData.csgOp){
        //process Op
        this.exportData += this.exportOpV1(csgData);
      } else {
        //create Obj
        this.exportData += this.exportObjV1(csgData);
      }

      this.exportData += `\n`;
      this.finalizeExport();
    }
    return this.exportData;
  }

  exportObjV1(csgData) {
    const self = this;
    let d = csgData;
    let str = '';
    let valid = true;
    const p = this.buildSolidParam(csgData);


    if (d.csgType === 'cylinder') {
      if (!p.offset.x || !p.offset.y || !p.offset.z){
        str += `translate([(${p.x})/2*(1-${p.offset.x}),(${p.x})/2*(1-${p.offset.y}),(${p.z})/2*(1-${p.offset.z})])`;
      }
      let body = `cylinder(center=true, $fn=${self.resolution}, d=${p.x}, h=${p.z})`;
      if(p.y){
        body = `cylinder(center=true, $fn=${self.resolution}, d=${p.x}, h=${p.z}, d2=(${p.x})+(${p.y}))`;
      }
      str += body;
    }
    else if (d.csgType === 'box') {
      if (!p.offset.x || !p.offset.y || !p.offset.z){
        str += `translate([(${p.x})/2*(1-${p.offset.x}),(${p.y})/2*(1-${p.offset.y}),(${p.z})/2*(1-${p.offset.z})])`;
      }
      if (p.round.x || p.round.y || p.round.z) {
        let plane = '';
        let radius = 0;
        if (p.round.x && p.round.y && p.round.z) {
          plane = 'all';
          radius = p.round.x;
        } else if (p.round.x && p.round.y) {
          plane = 'z';
          radius = p.round.x;
        } else if (p.round.x && p.round.z) {
          plane = 'y';
          radius = p.round.x;
        } else if (p.round.y && p.round.z) {
          plane = 'x';
          radius = p.round.y;
        }
        str += `roundedcube(center=true, size=[${p.x},${p.y},${p.z}], radius=${radius}, apply_to="${plane}")`;
      } else {
        str += `cube(center=true, size=[${p.x},${p.y},${p.z}])`;
      }
    }
    else if (d.csgType === 'sphere') {
      // position start
      if (csgData.enable == false) {
        str += '//';
      }
      if (!p.offset.x || !p.offset.y || !p.offset.z){
        str += `translate([(${p.x})/2*(1-${p.offset.x}),(${p.x})/2*(1-${p.offset.y}),(${p.x})/2*(1-${p.offset.z})])`;
      }
      str += `sphere(center=true, $fn=${self.resolution}, d=${p.x})`;
    }
    else if (d.csgType === 'toroid') {
      str += `torus($fn=${self.resolution}, r2=(${p.x})/2, r1=(${p.y})/2)`;
    }
    else {
      valid = false;
    }

    if (valid) {
      return str;
    } else {
      return '';
    }

  }
  exportOpV1(csgData) {
    let d = csgData;
    let opSTR = '';
    let priorityCount = 0;
    this.pObj.forEach( (c, i, arr) => {
      if (c.priority > priorityCount) {
        priorityCount = c.priority;
      }
    });

    const p = this.buildSolidParam(csgData);
    if (d.csgType == 'union' ||
      d.csgType == 'difference' ||
      d.csgType == 'intersection'||
      d.csgType == 'repeatxyz' || d.csgType == 'repeatr' || d.csgType == 'extrude2d'){
      let str = '';
      let postP = false;

      //Assemble all dependencies in comma sepperated str
      csgData.children.forEach( (c,i,arr) => {
        if(c.type == 'csg'){
          str += '\r';

          if(c.enable == false) {
            str += '//';
          }
          for(let r=0; r < (priorityCount-d.priority)+1; r++){
            str += '\t';
          }

          if(c.curvOpt?.pre){
            //str += c.curvOpt.pre;
          }
          //pre-process
          str = this.preProcessObjV1(c,str)+'';

//create solid
          if(c.csgOp){
            str += this.exportOpV1(c);
          } else {
            //create Obj
            str += this.exportObjV1(c);
          }

          //post process
          str = this.postProcessObjV1(c,str);

          if (c.curvOpt?.post){
            //str += c.curvOpt.post;
          }
          if (c.enable == false) {
            str += '//';
          }

          if (d.csgType == 'union' ||
            d.csgType == 'difference' ||
            d.csgType == 'intersection') {
            str += ';';
          }
          if(i < arr.length-1){
            for(let r=0; r < (priorityCount-d.priority)+1; r++){
              str += '\t';
            }
          }

        }
      });
      opSTR = '';

      let nameAdd;
      if(d.nickName) {
        nameAdd = `\t\t//${d.nickName}`;
      }else{
        nameAdd = ``;
      }
      if(d.csgType == 'repeatxyz')
      {

      }
      if(d.csgType == 'union'){
        opSTR += 'union(){'+ nameAdd + str +'\r';
      }else if(d.csgType == 'difference'){
        opSTR += 'difference(){'+ nameAdd + str +'\r';
      }else if(d.csgType == 'intersection'){
        opSTR += 'intersection(){'+ nameAdd + str +'\r';
      }else if(d.csgType == 'repeatxyz'){
        if (!p.offset.x || !p.offset.y || !p.offset.z) {
          let offset_x = `(1-(${p.offset.x}))*(${p.x})*((${p.opt.nX})-1)/-2`;
          let offset_y = `(1-(${p.offset.y}))*(${p.y})*((${p.opt.nY})-1)/-2`;
          let offset_z = `(1-(${p.offset.z}))*(${p.z})*((${p.opt.nZ})-1)/-2`;
          opSTR += `translate([${offset_x},${offset_y},${offset_z}])`;
        }
        opSTR += `loopXYZ(${p.opt.nX},${p.opt.nY},${p.opt.nZ},${p.x},${p.y},${p.z})`+ str +'\r';
      }else if(d.csgType == 'repeatr'){//BROKEN!!!!!
        opSTR += `loopR(${p.opt.nX},${p.x},${p.y})`+ str +'\r';
      }else if(d.csgType == 'extrude2d'){
        if(csgData.extrusionType == 'linear'){
          opSTR += `linear_extrude({height: ${p.linear.height}}, CAG.fromPoints([`;
        } else {
          opSTR += `rotate_extrude({fn: ${this.resolution}, CAG.fromPoints([`;
        }

        csgData.children[1].PT.forEach((c,i,arr)=>{
          opSTR += `[${round(c.x,2)},${round(c.y,2)}]`;
          if(i<arr.length-1){
            opSTR += ',';
          }
        });
        opSTR += `]))\r`;

      }

      if(d.csgType == 'union' ||
        d.csgType == 'difference' ||
        d.csgType == 'intersection') {
        for (let r = 0; r < (priorityCount - d.priority); r++) {
          opSTR += '\t';
        }
        opSTR += '}';//end OP
      }
      if(d.csgType == 'repeatxyz' ||
        d.csgType == 'repeatr') {
        for (let r = 0; r < (priorityCount - d.priority); r++) {
          opSTR += '\t';
        }
        opSTR += '';//end OP
      }
      return opSTR;

    }
  }
  preProcessObjV1(csgData, str) {
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    // position start
    if (position.x || position.y || position.z){
      if (csgData.enable == false) {
        str += '//';
      }
      str += `translate([${p.p.x},${p.p.y},${p.p.z}])`;
    }

    // Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      if (csgData.enable == false) {
        str += '//';
      }
      str += `rotate([${p.r.x},${p.r.y},${p.r.z}])`;

      if (csgData.enable == false) {
        str += '//';
      }
    }

    // Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      if (csgData.enable == false) {
        str += '//';
      }
      str += `scale([${p.s.x},${p.s.y},${p.s.z}])`;
    }

    d.actions.forEach((act, i, arr) => {
      if (act.action == 'mirror') {
        str += `mirror2(v=[${act.opt.x},${act.opt.y},${act.opt.z}],${Boolean(act.opt.copy)})`;
      }
      if (act.action == 'cut') {
        str += `clipByPlane(n=[${act.opt.x},${act.opt.y},${act.opt.z}], v=[${act.origin.x},${act.origin.y},${act.origin.z}])`;
      }
    });
    // action

    return str;

  }
  postProcessObjV1(csgData, str) {
    const d = csgData;
    const transform = this.getTransform(csgData);
    const position = transform.position; const rotation = transform.rotation; const scale = transform.scale;
    const p = this.buildSolidParam(csgData);

    //d.actions.forEach((act, i, arr) => {
        //if ( act.action === 'mirror' ) {
        //  str += `}`;
        //}
        //if ( act.action === 'cut' ) {
          //str += `.cutByPlane(CSG.Plane.fromNormalAndPoint([${act.opt.x}, ${act.opt.y}, ${act.opt.z}], [${act.origin.x}, ${act.origin.y}, ${act.origin.z}]))`;
        //}
    //  }
    //);
    //action

    //Scale start
    if (scale.x != 1 || scale.y != 1 || scale.z != 1) {
      str += ``;
    }

    //Rotate start
    if (rotation.x || rotation.y || rotation.z) {
      str += ``;
    }

    //position start
    if (position.x || position.y || position.z){

      str += ``;
    }


    return str;

  }
}

function saveTextAsFile(textToWrite, fileNameToSaveAs) {
    let textFileAsBlob = new Blob([textToWrite], {type:'text/plain'});
    let downloadLink = document.createElement("a");
    downloadLink.download = fileNameToSaveAs;
    downloadLink.innerHTML = "Download File";
    if (window.webkitURL != null)
    {
        // Chrome allows the link to be clicked
        // without actually adding it to the DOM.
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
    } else {
        // Firefox requires the link to be added to the DOM
        // before it can be clicked.
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
    }
    downloadLink.click();

}
export class ObjLang {
  name: 'jsCad';

  m;
  position;
  rotation;
  scale;

  csgData;

  data;
  opt;

  constructor(csgData){
  this.csgData = csgData;
  this.init();

  }
  init(){
    this.data = '';//this.csgata;
    this.opt = this.data.opt;
    let G = new THREE.Group();
    const m = new THREE.Matrix4();
    m.fromArray(this.data.t.e);
    G.position.set(0, 0, 0);
    G.rotation.set(0, 0, 0);
    G.scale.set(1, 1, 1);
    G.updateMatrix();
    G.applyMatrix4(m);
    G.updateMatrix();
    this.position = G.position;
    this.rotation = G.rotation;
    this.scale = G.scale;
  }

  setM(str){

        //Scale
        if (this.scale.x != 1 || this.scale.y != 1 || this.scale.z != 1) {
          str += ` >> stretch(${this.scale.x},${this.scale.y},${this.scale.z})`
        }

        //Rotate
        if (this.rotation.x) {
          str += ` >> rotate{angle:${this.rotation.x},axis:'X_axis'}`
        }
        if (this.rotation.y) {
          str += ` >> rotate{angle:${this.rotation.y},axis:'Y_axis'}`
        }
        if (this.rotation.z) {
          str += ` >> rotate{angle:${this.rotation.z},axis:'Z_axis'}`
        }

        //position
        if (this.position.x || this.position.y || this.position.z){
          str += ` >> move(${this.data.t.e[12]},${this.data.t.e[13]},${this.data.t.e[14]})`;
        }

        return str;
  }

  exportObj(csgData){
    let d = this.opt;
    let str = "";


    if(d.csgType == 'cylinder'){
      str +=  `cylinder{d:${d.x},h:${d.z}}`
    }
    if(d.csgType == 'box'){
      str += `box(${d.x},${d.y},${d.z})`;
    }
    if(d.csgType == 'sphere'){
      str += `sphere(${d.x})`;
    }
    if(d.csgType == 'torus'){
      str += `torus{major:${d.x},minor:${d.y}}`;
    }

    str = this.setM(str);

    return str;
  }

  exportOp(csgData){
    let d = csgData.opt;
    if(d.csgType == 'union' || d.csgType == 'difference' || d.csgType == 'intersection'){
      let str = '';
      //Assemble all dependencies in comma sepperated str
      csgData.children.forEach( (c,i,arr) => {
          if(c.opt.csgOp){
            str += '\t'+this.exportOp(c) + '\r';
          } else {
            //create Obj
            str += this.exportObj(c);
          }
        if(i < arr.length-1){
          str += ',\n';
        }
      });


      if(d.csgType == 'union'){
        return 'union[\r'+ str + ']\r';
      }else if(d.csgType == 'difference'){
        return 'difference(\r'+ str + ')\r';
      }else if(d.csgType == 'intersection'){
        return 'intersection(\r'+ str + ')\r';
      } else if(d.csgType == 'repeatxyz'){
        let item = str;
        return 'intersection(\r'+ str + ')\r';
      }

    }
  }

}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
function flipB(i) {
  let bool  = Boolean(i);
  if (bool) {
    return 0;
  } else {
    return 1;
  }
}
function objToString(obj) {
  return JSON.stringify(obj);
}
