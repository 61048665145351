import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContentDocument} from '../../reactive-content';
import {DbService} from '../../services/db.service';
import {RootService, UserService} from '../../services/root.service';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  public index: ContentDocument;
  public current: ContentDocument;
  page$;
  pt = [[22,33],[32,31],[42,13],[11,31],[33,70]];
  userInfo;
  editContent = false;
  constructor(public db: DbService,
              public auth: AuthService,
              public root: RootService) {

  }

  ngOnInit() {
    this.auth.user$.subscribe(u => {
      this.editContent = this.root.checkAdmin(u.uid);
    });
    this.current = {
        edit: true,
        title: 'Loading content...',
        _type: 'page',
        _embedded: {
          headline: {
            text: 'Loading content...',
            _type: 'headline'
          }
        }
      };
    this.getContent();
  }
  getContent() {
    this.page$ = this.db.getPageData('home').subscribe(data => {
      this.current = data;
    });
  }
  updateContent() {
    this.db.updatePageData('home', this.current);
  }

  ngOnDestroy(): void {
    //this.page$.unsubscribe();
  }
}
