import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';

@Component({
  selector: 'rc-image',
  template: `<span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <button *ngIf="root.showBtn" (click)="root.show = !root.show">View Image</button>
    <img [attr.height]="root.height" [attr.src]="root.src" [attr.alt]="alt" class="panel-image" *ngIf="root.show">
    <div *ngIf="root.text" class="note">{{root.text}}</div>
    <div *ngIf="edit()">
      Show/hide:<button (click)="root.showBtn = !root.showBtn">show/hide</button>
      Height:<input [(ngModel)]="root.height">
      Src:<input [(ngModel)]="root.src">
      <app-image-uploader (selectedFile)="root.src = $event.downloadURL" [size]="[400,400]"></app-image-uploader>
      <mat-form-field class="paragraph-text" *ngIf="edit()">
        <textarea matInput placeholder="Text" [(ngModel)]="root.text" [rows]="9"></textarea>
      </mat-form-field>
    </div>
  `,
  styles: [`img { object-fit: cover; }`]
})
export class ImageComponent implements OnInit, ContentOnCreate {


  @Input()
  public src: string;

  @Input()
  public alt: string;

  content;
  root;
  localEdit = false;

  constructor() { }

  ngOnInit() {

  }
  edit(){
    if(this.localEdit && this.content.edit){
      return true;
    } else {
      return false;
    }
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.src = values.src;
    this.alt = values.alt;
  }

}
