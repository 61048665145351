import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

declare const require: any;
import * as THREE from 'three';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxPicaErrorInterface, NgxPicaResizeOptionsInterface, NgxPicaService} from '@digitalascetic/ngx-pica';
import {DbService} from '../../services/paperdb.service';
import {UploadFile} from '../../models/tools.model';
import {AuthService} from '../../services/auth.service';
import {finalize, tap} from 'rxjs/operators';

import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
const fileToArrayBuffer = require('file-to-array-buffer')
const {CSG, CAG} = require('@jscad/csg/src/api/index.js').csg;

import Typeson from 'typeson';
import pako from 'pako';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);

@Component({
  selector: 'app-image-uploader',
  template: `
  <div *ngIf="csg === false" class="upload-toolbar">

  <button (click)="pickUploaded = !pickUploaded" > Image List </button>
    <button (click)="uploadShow = !uploadShow" >+@ Upload files</button>
    <div *ngIf="uploadShow">
      IMG,SVG(raw):<input type="file" [attr.accept]="'image/*'" multiple
                 (change)="handleFilesSVG($event)"><br>
      STL(raw):<input type="file" [attr.accept]="'application/sla'" multiple
                 (change)="handleFilesSTL($event)">
    </div>

<app-image-list *ngIf="pickUploaded" (selectedFile)="selectFile($event)"></app-image-list>

</div>
  <div *ngIf="csg === true" class="upload-toolbar">
    <button (click)="pickUploaded = !pickUploaded" > User Image List </button>
    <div>
      STL:<input type="file" [attr.accept]="'/*'"
                 (change)="handleFilesSTL($event)">
      <app-image-list *ngIf="pickUploaded" (selectedFile)="selectFile($event)"></app-image-list>
    </div>

  </div>
  `
})
export class ImageUploaderComponent implements OnInit {
  public metadata: UploadFile = {};
  @Input() uploadType = 'stl';
  @Input() uploadTypeForce = false;
  @Input() public size: number[];
  @Input() public csg: boolean = false;
  @Output() selectedFile = new EventEmitter<UploadFile>();
  images: File[] = [];
  pickUploaded = false;

  uploadShow = false;

  constructor(private PicaService: NgxPicaService, public DB: DbService, public auth: AuthService) {

  }
  ngOnInit(): void {
  }

  selectFile(file) {
    console.log(file);
    this.selectedFile.emit(file);
  }
  public upload( file: File ) {
    const self = this;
    self.auth.user$.subscribe(u => {
      if (u) {
        self.metadata.uploader = u.uid;
        self.metadata.name = file.name;
        self.metadata.size = file.size;
        self.startUpload( self.metadata, file);
      }
    });
  }
  async startUpload(file: UploadFile, fileData) {
    // console.log(file, fileData );
    const self = this;
    // The storage rootPath
    const path = `files/${Date.now()}_${file.name}`;
    // Reference to storage bucket
    const ref = this.DB.storage.ref(path);
    // The main task
    const uploadTask = this.DB.storage.upload(path, fileData);
    // Progress monitoring
    const percentage = uploadTask.percentageChanges();
    const snapshot = uploadTask.snapshotChanges().pipe(
      // tap(console.log),
      finalize( async () =>  {
        file.downloadURL = await ref.getDownloadURL().toPromise();
        console.log(file);
        if(!self.DB.fileList){
          self.DB.fileList = {img:[]};
        }
        self.DB.fileList.img.push(file);
        self.DB.saveFileList();
        self.selectFile(file);
      }),
    );
    snapshot.subscribe(data => {
      // console.log(data);
    });
  }
  public handleFiles(event: any) {
    const self = this;
    const files: File[] = event.target.files;

    //not working
    this.PicaService.resizeImages(files, self.size[0], self.size[1],
      {
        aspectRatio: {keepAspectRatio: true  }
      } as NgxPicaResizeOptionsInterface
    )
      .subscribe((imageResized: File) => {
        const reader: FileReader = new FileReader();

        reader.addEventListener('load', (e: any) => {
          this.images.push(e.target.result);
        }, false);

        reader.readAsDataURL(imageResized);
        self.upload(imageResized);
      }, (err: NgxPicaErrorInterface) => {
        throw err.err;
      });
  }
  public handleFilesSVG(event: any) {
    const self = this;
    const files: File[] = event.target.files;
    self.upload(files[0]);
  }
  public handleFilesSTL(event: any) {
    const self = this;
    const files: File[] = event.target.files;
    const loader = new STLLoader();

    fileToArrayBuffer(files[0]).then((data) => {
      let g = loader.parse(data);
      let m = new THREE.Mesh(g);

      let geom = new THREE.Geometry().fromBufferGeometry(g);
      //} else {
      // geom = mesh.geometry.clone();
      //}
      let fs = geom.faces;
      let vs = geom.vertices;
      let polys = [];
      let fm = ['a', 'b', 'c'];
      for (let i = 0; i < fs.length; i++) {
        let f = fs[i];
        let vertices = [];
        for (let j = 0; j < 3; j++) {
          vertices.push(new CSG.Vertex(new CSG.Vector3D(vs[f[fm[j]]])));
          // new Vertex(vs[f[fm[j]]],f.vertexNormals[j],geom.faceVertexUvs[0][i][j]));
        }
        polys.push(new CSG.Polygon(vertices));
      }
      let csgFile =  CSG.fromPolygons(polys);
      let cb = csgFile.toCompactBinary();//exportCSGcb(csg3.toCompactBinary());
      let minimize = exportCSGcb(cb);
      console.log(cb,minimize);
      let file = new File([minimize],files[0].name,{
        type: 'text/plain'
      });
      self.upload(file);
    });
  }

}
function exportCSGcb(cb) {
  let str = JSON.stringify(TSON.encapsulate(cb), null, 2);
  let deflate = pako.deflate(str,{ level:9, to: 'string' });
  return deflate;
}
