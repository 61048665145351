import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {ITreeOptions, TreeModel} from 'angular-tree-component';
import {LivePartService} from '../../services/live-part.service';

interface CsgObj {
  name?: string;
  id?: string;
  type?: string;
  csgType?: string;
  children?: CsgObj[];
  opt: any;
}

const TREE_DATA: CsgObj[] = [];


@Component({
  selector: 'app-csg-explorer',
  templateUrl: './csg-explorer.component.html',
  styleUrls: ['./csg-explorer.component.css']
})
export class CsgExplorerComponent implements OnInit, OnChanges {
  treeControl = new NestedTreeControl<CsgObj>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CsgObj>();
  hasChild = (_: number, node: CsgObj) => !!node.children && node.children.length > 0;
  @Input() height;
  @ViewChild('partTree', {static: false}) pTree: TreeModel;
  @Input() part;
  @Input() pObj;
  @Input() partObj;
  @Input() selectedSubPart;

  @Output() selectedPart = new EventEmitter();
  selectPart(e) {
    console.log('selectPart', e);
    console.log(this);
    this.selectedPart.emit(e.id);
  }
  exportLanguage = false;

  constructor(public livePart: LivePartService) {
    this.dataSource.data = TREE_DATA;
  }

  ngOnInit() {
    this.loadData();

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }
  buildTree(o) {
      let obj = {
        name: o.name,
        id: o.uid,
        type: o.type,
        csgType: o.opt?.csgType,
        opt: o.opt,
        children: []
      } as CsgObj;
      o.c?.forEach((child) => {
        for (let i= 0; i<this.partObj.data.length; i++) {
          if (this.partObj.data[i].uid == child) {
            obj.children.push(this.buildTree(this.partObj.data[i]));
          }
        }
      });
      return obj;
  }
  loadData() {
    this.dataSource.data = [];
    this.pObj = [];
    if (this.partObj) {
      for (let i=0; i<this.partObj.data.length; i++) {
        const obj: CsgObj = this.buildTree(this.partObj.data[i]);
        this.dataSource.data.push(obj);
        this.pObj.push(obj);
      }
    } else if (this.part) {
      this.exportLanguage = true;
      for (const property in this.part) {
        this.dataSource.data.push(this.part[property] as CsgObj);
        this.pObj.push(this.part[property]);
      }
    }
  }
}
