import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {RootService} from '../../services/root.service';
import {DbService} from '../../services/db.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(public auth: AuthService, public root: RootService, public db: DbService) {
  }

  ngOnInit() {
  }

}
