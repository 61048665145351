import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of } from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';
import {User} from '../models/auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService {

  user$: Observable<User>;
  user;
  userData;
  loaded = false;


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {  // Get the auth state, then fetch the Firestore user document or return null
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          this.user = user;
          if (user.isAnonymous) {
            this.userData = user;
          } else {
            this.afs.doc<User>(`users/${user.uid}`).valueChanges().subscribe(u => {
              this.loaded = true;
              this.userData = u;
            });
          }
          return of(user);
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }
  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.auth.signInWithPopup(provider);
    return this.updateUserData(credential.user);
  }
  async anonSignin() {
    const credential = await this.afAuth.auth.signInAnonymously();
    console.log(credential);
    return this.updateUserData(credential.user);
  }
  getUserID(){
    if(this.user?.uid){
      return this.user.uid;
    } else {
      return 'anonymous';
    }
  }
  addCategory(opt){
    let user = this.userData;
    if(!user?.category){
      user.category = [];
    }
    user.category.forEach(c => {
      if (c.value == opt.value) {
        return;
      }
    });
    user.category.push(opt);
    this.updateUserData(user);
  }
  removeCategory(opt) {
    let needle = -1;
    this.userData.category.forEach( (c, i, arr) => {
      if (c.value === opt.value) {
        needle = i;
      }
    });
    if (needle > -1) {
      this.userData.category.splice(needle,1);
      this.updateUserData(this.userData);
    }
  }
  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    if(!user.category) {
      user.category = [];
    }
    if(!user.selectedPart) {
      user.selectedPart = '';
    }
    console.log(user);
    let dbUpdate = userRef.set(user, { merge: true }).then(r => {
      console.log(r);
    });
  }
  private updateUserByID(user,data) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    return userRef.set(data, { merge: true });
  }

  getUser(id: string) {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          return this.afs.doc<User>(`users/${id}`).valueChanges().pipe(shareReplay(1));
        } else {
          // Logged out
          return of(null);
        }
      })
    );
  }

  async signOut() {
    await this.afAuth.auth.signOut();
  }
}
