declare const require: any;
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone, OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges, ViewChild
} from '@angular/core';

const {CSG, CAG} = require('@jscad/csg/src/api/index.js').csg;
import * as THREE from 'three';
const threeCSG = require('../../3d/CSGMesh.js').default;
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { Vector3 } from 'three';
import Typeson from 'typeson';
import pako from 'pako';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';

const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);
import {STLExporter} from 'three/examples/jsm/exporters/STLExporter';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';

declare var Slicer: any;
var Slicer = require('../../../assets/lib/slicer.js').default;
const exporterSTL = new STLExporter();
const dxfSerializer = require('@jscad/dxf-serializer');

@Component({
  selector: 'stl-model-viewer',
  template: `
    <canvas #canvas width="100px" height="100px"></canvas>
    <ng-container *ngIf="{expanded: false} as uiPanel" [style.left]="10 + '%'" [style.top]="0 + 'px'" class="panel-body red-outline">
      <button (click)="uiPanel.expanded = !uiPanel.expanded"><mat-icon>square_foot</mat-icon></button>
      <ng-container *ngIf="uiPanel.expanded">
        <button (click)="exportSTL()"><mat-icon>play_for_work</mat-icon>STL</button>
      </ng-container>
    </ng-container>
  `
})
export class StlModelViewerComponent implements OnInit, OnChanges {
  @ViewChild('canvas', {static: false}) canvasRef: ElementRef;
  @Input('hasControls') hasControls: boolean = true;

  type: string = 'csg';
  camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.01, 2);
  cameraTarget: THREE.Vector3 = new THREE.Vector3( 0, 0, 0 );
  light: THREE.Light = new THREE.PointLight( 0xffffff );
  material: THREE.Material = new THREE.MeshPhongMaterial({ color: 0xc4c4c4, shininess: 100, specular: 0x111111 });
  scene: THREE.Scene = new THREE.Scene();
  renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  controls: any | null = null;
  @Input('csgModel') csgModel = '';
  csg = true;
  @Input('axesShow') axesShow = false;
  @Input('height') height = 100;
  @Input('width') width = 100;

  isRendered = false;
  meshSlicer;
  mesh;
  grid;
  axes;
  finalCSG;
  csgMesh;
  uid = 0;

  constructor(private ngZone: NgZone) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(changes);
    if (changes.hasOwnProperty('csg') || changes.hasOwnProperty('csgModel')) {
      let old = this.scene.getObjectByName( "current");
      if (old) {
          if (old) {
            old.parent.remove(old);
          }
          old = undefined;
      }
      if (this.csgMesh) {
        if (this.csgMesh.parent) {
          this.csgMesh.parent.remove(this.csgMesh);
        }
        this.csgMesh = undefined;
      }
      this.createMesh(this.csgModel).then((mesh) => {
        mesh.name = 'current';
        this.scene.add(mesh);
        this.setSizes();
        this.render();
      });
    }
  }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.init();
    });
  }
  ngOnDestroy() {
    const self = this;
    window.removeEventListener('resize', this.onWindowResize, false);
    if (this.controls) {
      this.controls.removeEventListener('change', this.render);
      this.controls.dispose();
    }
    if (this.scene) {
      this.scene.children.forEach((child) => {
        this.scene.remove(child);
      });
    }
    //self.renderer.forceContextLoss();
    //self.renderer.context = null;
    //self.renderer.domElement = null;
    //self.renderer = null;
  }
  private init() {
    this.light = new THREE.HemisphereLight( 0xffffff, 0xbbbbff, 1 );
    this.light.position.set( 0.5, 1, 0.25 );
    this.scene.add( this.light );
    // default camera position
    this.camera.position.set(1, 1, 1);
    // default renderer options
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.camera.add(this.light);
    this.scene.add(this.camera);

    this.grid = new THREE.GridHelper(100, 10);
    this.grid.rotateX(Math.PI / 2);
    this.axes = new THREE.AxesHelper( 1000 );
    if (this.axesShow) {
      this.scene.add(this.axes);
    }

    this.camera.lookAt(0, 0, 0);
    this.camera.up.set(0, 0, 1);

    // use default controls
    if (this.hasControls && !this.controls) {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableZoom = true;
      this.controls.minDistance = 1;
      this.controls.maxDistance = 100;
      this.controls.enabled = true;
      this.controls.addEventListener('change', this.render);
    }

    this.setSizes();
    const self = this;
    window.addEventListener('resize', this.onWindowResize, false);

    this.createMesh(this.csgModel)
      .then((mesh: THREE.Object3D) => this.scene.add(this.mesh))
      .then(() => {
        self.render();
      })
      .then(() => this.ngZone.run(() => {
        this.isRendered = true;
      }));
  }
  createMesh(path: string): Promise<any> {
    const self = this;
    return new Promise((resolve) => {
      if (this.type === 'csg') {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function(event) {
            var blob = xhr.response;
            var reader = new FileReader();
            reader.onload = function() {
              self.finalCSG = reader.result;
              let deflate = pako.inflate(self.finalCSG,{ to: 'string' });
              let cast = castCompactCSG(deflate);
              let csgObj = CSG.fromCompactBinary(cast);
              self.mesh = new THREE.Mesh(new THREE.Geometry, self.material);
              jsCSGtoMesh(self.mesh, csgObj);
              return resolve(self.mesh);
            };
            reader.readAsText(blob);
          };
          xhr.open('GET', self.csgModel);
          xhr.send();
      }
    });
  }
  render = () => {
    this.renderer.render(this.scene, this.camera);
    console.log('rendered');
  }
  setSizes() {
    let width = this.width;
    let height = this.height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }
  onWindowResize = () => {
    this.setSizes();
    this.render();
  }
  // Common CSG functions
  exportSTL() {
    let temp = new THREE.Mesh(this.mesh.geometry.clone());
    temp.scale.set(100,100,100);
    exportSTL(temp, this.uid);
  }
}
function castCompactCSG(OldCsg) {
  const parsed = JSON.parse(OldCsg);
// Revive back again:
  const revived = TSON.revive(parsed);
  return revived;
}
function saveCompactCSG(OldCsg){
  return JSON.stringify(TSON.encapsulate(OldCsg), null, 2);
}
function jsCSGtoMesh(mesh, csg) {
  mesh.geometry.dispose();
  mesh.geometry = threeCSG.toMesh(threeCSG.fromJSCADcsg(csg), new THREE.Matrix4(), true);
  mesh.geometry.computeFaceNormals();
  mesh.geometry.computeVertexNormals();
}


// mesh functions
function removeMesh(mesh) {
  mesh.parent.remove(mesh);
  mesh.geometry.dispose();
  mesh.material.dispose();
}

// Export functions
function exportSTL(mesh, name) {
  const result = exporterSTL.parse(mesh);
  saveString( result, name + '.stl' );
}

function exportCSGcb(cb, name) {
  let str = JSON.stringify(TSON.encapsulate(cb), null, 2);
  let deflate = pako.deflate(str,{ level:9, to: 'string' });
  saveString( deflate, name + '.csg' );
}

function exportCSG(mesh) {
  const result = exporterSTL.parse(mesh, { binary: true });
  let geom;
  if ( mesh.geometry.isBufferGeometry ) {
    geom = new THREE.Geometry().fromBufferGeometry(mesh.geometry);
  } else {
    geom = mesh.geometry.clone();
  }
  let fs = geom.faces;
  let vs = geom.vertices;
  let polys = [];
  let fm = ['a','b','c'];
  for (let i=0; i < fs.length; i++) {
    let f = fs[i];
    let vertices = [];
    for (let j = 0; j < 3; j++) {
      vertices.push(new CSG.Vertex(new CSG.Vector3D(vs[f[fm[j]]])));
      // new Vertex(vs[f[fm[j]]],f.vertexNormals[j],geom.faceVertexUvs[0][i][j]));
    }
    polys.push(new CSG.Polygon(vertices));
  }
  return CSG.fromPolygons(polys);
}
function save(blob, filename ) {
  const link = document.createElement( 'a' );
  link.style.display = 'none';
  document.body.appendChild( link );

  link.href = URL.createObjectURL( blob );
  link.download = filename;
  link.click();

}
function saveString( text, filename ) {

  save( new Blob( [ text ], { type: 'text/plain' } ), filename );

}
function saveGLB( text, filename ) {

  save( new Blob( [ text ], { type: 'application/octet-stream' } ), filename );

}
function saveArrayBuffer( buffer, filename ) {

  save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

}

// utility functions
function deepEqual(x, y) {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
    ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}
function removeFromArr(item, array) {
  if (array) {
    const id = array.indexOf(item);
    if (id > -1) {
      array.splice(id, 1);
      return true;
    } else {
      return false;
    }
  } else {
    console.log('unable to remove', item);
  }
}
function round(num, decimal) {
  return Math.round(num * Math.pow(10, decimal) ) / Math.pow(10, decimal);
}
function timeF(f) {
  const t0 = performance.now();
  f();
  const t1 = performance.now();
  console.log('function took: ' + (t1 - t0) + ' ms.');
}
