import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DbService} from '../../services/paperdb.service';
import {UploadFile, UploadFileList} from '../../models/tools.model';
import {AuthService} from '../../services/auth.service';
import {async} from 'rxjs/internal/scheduler/async';
import {Observable} from 'rxjs';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-image-list',
  template: `
    <div>

  <div>
    extension:<input [(ngModel)]="ext">
    <button (click)="ext = ''">all</button>
    <button (click)="ext = 'stl'">stl</button>
    <button (click)="ext = 'svg'">svg</button>
    <button (click)="ext = 'png'">png</button>
    <button mat-button (click)="edit = !edit">Edit Images</button>
  </div>
  <div class="container">
    <div class="row">
      <ng-container *ngFor="let file of userFiles">
        <span  style="width:100px;" *ngIf="checkExt(file.name) == ext || ext == ''" class="col-3"  (click)="selectFile(file)">
          <span *ngIf="checkExt(file.name) != 'stl'"><img [title]="file.name" *ngIf="checkExt(file.name) != 'stl'" style="width:100px;" [src]="file.downloadURL" alt="image">
            <button *ngIf="edit" (click)="DB.deleteFile(file)">x</button>
          </span>
          <span *ngIf="checkExt(file.name) == 'stl'"> {{file.name}}{{file.size/1000}}kb"
            <button *ngIf="edit" (click)="DB.deleteFile(file)">x</button>
          </span>
        </span>
      </ng-container>
    </div>
  </div>
    </div>
  `
})
export class ImageListComponent implements OnInit {
  userFiles;
  user;
  edit = false;
  ext = 'stl';
  @Output() selectedFile = new EventEmitter<UploadFile>();
  constructor(public DB: DbService, public auth: AuthService) {
    this.init();
  }
  selectFile(file) {
    this.selectedFile.emit(file);
  }
  checkExt(fileName){
    var last = fileName.substr(fileName.length - 3);
    return last;
  }
  init() {
    const self = this;
    self.auth.user$.subscribe(user => {
      if ( user ) {
        self.DB.getLoadedFileListOb().subscribe( filesLoaded => {
          if ( filesLoaded === true ) {
            self.userFiles = [];
            self.DB.fileList.img.forEach(image => {
              if (image.uploader === user.uid || image.public === true) {
                self.userFiles.push(image);
              }
            });
            console.log(self.userFiles.reverse());
          }
        });
      }
    });
  }

  ngOnInit() {
  }

}
