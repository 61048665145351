import { NgModule, ModuleWithProviders, Provider , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';

import {CdkTableModule} from '@angular/cdk/table';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {PortalModule} from '@angular/cdk/portal';
import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkTreeModule} from '@angular/cdk/tree';

import { CONTENT_MAPPINGS } from '../reactive-content';
import { HeadlineComponent } from './headline/headline.component';
import { ImageComponent } from './image/image.component';
import { GridComponent } from './grid/grid.component';
import { PageComponent } from './page/page.component';
import { GridColumnComponent } from './grid/grid-column.component';
import { GridRowComponent } from './grid/grid-row.component';

import { CarouselComponent } from './carousel/carousel.component';
import {TabsComponent} from './tabs/tabs.component';
import {FeatureComponent} from './feature/feature.component';
import {ParagraphComponent} from './paragraph/paragraph.component';
import {SlidesComponent} from './slides/slides.component';
import {View3dComponent} from './view3d/view3d.component';

import { ImageUploaderComponent } from '../widget/image-uploader/image-uploader.component';
import { MarkedPipe } from '../pipe/marked.pipe';
import { ImageListComponent } from '../widget/image-list/image-list.component';
import {CamComponent} from '../widget/cam/cam.component';
import {PartsComponent} from '../pages/parts/parts.component';

export const CONTENT_COMPONENTS = [
  HeadlineComponent,
  ImageComponent,
  GridComponent,
  GridColumnComponent,
  GridRowComponent,
  PageComponent,
  CarouselComponent,
  TabsComponent,
  FeatureComponent,
  ParagraphComponent,
  SlidesComponent,
  View3dComponent
];

export const CONTENT_MAPPINGS_PROVIDER: Provider = [
  {
    provide: CONTENT_MAPPINGS,
    useValue: {
      'headline': HeadlineComponent,
      'grid': GridComponent,
      'column': GridColumnComponent,
      'row': GridRowComponent,
      'image': ImageComponent,
      'page': PageComponent,
      'carusel': CarouselComponent,
      'tabs': TabsComponent,
      'slides': SlidesComponent,
      'feature': FeatureComponent,
      'paragraph': ParagraphComponent,
      'view3d': View3dComponent
    }
  }
];

export class ImageUploaderComponentc extends ImageUploaderComponent {};
export class ImageListComponentc extends ImageListComponent {};
export class CamComponentc extends CamComponent {};
export class PartListComponentc extends PartsComponent {};
export class MarkedPipec extends MarkedPipe {};
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTreeModule,
    DragDropModule,
    A11yModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
  ],
  declarations: [
    ...CONTENT_COMPONENTS,
    PageComponent,
    ImageUploaderComponentc,
    ImageListComponentc,
    CamComponentc,
    MarkedPipec,
    PartListComponentc
  ],
  entryComponents: [
    ...CONTENT_COMPONENTS
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContentModule {

  public static forRoot(): ModuleWithProviders {

    return {
      ngModule: ContentModule,
      providers: [
        CONTENT_MAPPINGS_PROVIDER
      ]
    };
  }

}
