import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
@Injectable({
  providedIn: 'root'
})
export class IconResolverService {
  iconsOp = [
    {name: 'difference', path: 'difference.svg'},
    {name: 'intersection', path: 'intersection.svg'},
    {name: 'union', path: 'union.svg'},
    {name: 'repeatxyz', path: 'repeatxyz.svg'},
    {name: 'repeatr', path: 'repeatr.svg'},
    {name: 'code', path: 'code.svg'},
    {name: 'jsCadV2', path: 'code.svg'}, //Needs update
    {name: 'implicit', path: 'implicit.svg'},
    {name: 'parametric', path: 'parametric.svg'},
    {name: 'extrude2d', path: 'extrude2d.svg'},
    {name: 'shape2d', path: 'shape2D.svg'},
    {name: 'codemaker2d', path: 'codemaker2d.svg'},
    {name: 'expand', path: 'expand.svg'},
    {name: 'fastener', path: 'fastener.svg'},
    {name: 'motor', path: 'motor.svg'}
  ];
  iconsObj = [
    {name: 'point2d', path: 'point.svg'},
    {name: 'box', path: 'box.svg'},
    {name: 'cylinder', path: 'cylinder.svg'},
    {name: 'sphere', path: 'sphere.svg'},
    {name: 'toroid', path: 'toroid.svg'},
    {name: 'text', path: 'text.svg'},
    {name: 'draw2D', path: 'draw2D.svg'},
    {name: 'draw3D', path: 'draw3D.svg'},
    {name: 'object', path: 'object.svg'},
    {name: 'cp', path: 'cp.svg'},
    {name: 'plane', path: 'plane.svg'},
    {name: 'construct', path: 'construct.svg'},
    {name: 'gear', path: 'gear.svg'},
    {name: 'bevelgear', path: 'bevelgear.svg'},
    {name: 'rackgear', path: 'rackgear.svg'},
    {name: 'pulleygear', path: 'pulleygear.svg'},
    {name: 'pulleybelt', path: 'pulleybelt.svg'},
    {name: 'slot', path: 'slot.svg'}
  ];
  iconsTransform = [
    {name: 'rotate', path: 'rotate.svg'},
    {name: 'scale', path: 'scale.svg'},
    {name: 'translate', path: 'translate.svg'}
  ];
  iconsBtn = [
    {name: 'more', path: 'more.svg'},
    {name: 'function', path: 'function.svg'},
    {name: 'var', path: 'var.svg'},
    {name: 'var_list', path: 'var_list.svg'},
    {name: 'varbox', path: 'varbox.svg'},
    {name: 'vari', path: 'vari.svg'},
    {name: 'reload', path: 'reload.svg'},
    {name: 'obj_list', path: 'obj_list.svg'},
    {name: 'obj_one', path: 'obj_one.svg'},
    {name: 'snippet_list', path: 'snippet_list.svg'},
    {name: 'snippet_one', path: 'snippet_one.svg'},
    {name: 'size_l', path: 'size_l.svg'},
    {name: 'size_m', path: 'size_m.svg'},
    {name: 'size_s', path: 'size_s.svg'},
    {name: 'save', path: 'save.svg'},
    {name: 'save_all', path: 'save-all.svg'},
    {name: 'save_edit', path: 'save-edit.svg'},
    {name: 'save_move', path: 'save-move.svg'},
    {name: 'save_settings', path: 'save-settings.svg'},
    {name: 'save', path: 'save.svg'}
  ];
  iconsEditStates = [
    {name: 'select', path: 'select.svg'},
    {name: 'view', path: 'view.svg'},
    {name: 'edit', path: 'edit.svg'},
    {name: 'shadow', path: 'shadow.svg'},
    {name: 'hide', path: 'hide.svg'},
    {name: 'parentShadow', path: 'parentShadow.svg'},
    {name: 'parentHide', path: 'parentHide.svg'},
    {name: 'childShadow', path: 'childShadow.svg'},
    {name: 'childHide', path: 'childHide.svg'}
  ];
  iconsOpt = [
    {name: 'align', path: 'align.svg'},
    {name: 'gear', path: 'gear.svg'},
    {name: 'shape', path: 'shape.svg'},
    {name: 'size', path: 'size.svg'},
    {name: 'spline', path: 'spline.svg'},
    {name: 'round', path: 'round.svg'},
    {name: 'res', path: 'res.svg'},
    {name: 'shell', path: 'shell.svg'},
    {name: 'closedcurve', path: 'closedcurve.svg'},
    {name: 'closedcurvesharp', path: 'closedcurvesharp.svg'},
    {name: 'curveflatten', path: 'curveflatten.svg'},
    {name: 'curvesharp', path: 'curvesharp.svg'},
    {name: 'curvesmooth', path: 'curvesmooth.svg'},
    {name: 'curvesmooth2', path: 'curvesmooth2.svg'}
  ];
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}
  init() {
    this.addIcon('opt_', this.iconsOpt, '../../assets/Icons/Opt/', '0 0 32 32' );
    this.addIcon('', this.iconsOp, '../../assets/Icons/Op/', '0 0 32 32' );
    this.addIcon('s_', this.iconsOp, '../../assets/Icons/Op/', '0 0 45 45' );
    this.addIcon('', this.iconsObj, '../../assets/Icons/Obj/', '0 0 32 32');
    this.addIcon('', this.iconsTransform, '../../assets/Icons/Transform/', '0 0 20 20');
    this.addIcon('', this.iconsBtn, '../../assets/Icons/Btn/', '0 0 32 32');
    this.addIcon('', this.iconsEditStates, '../../assets/Icons/editStates/', '0 0 22 22');
  }
  addIcon(prefix, iconSet, rootPath, viewBox) {
    iconSet.forEach( icon => {
      this.matIconRegistry.addSvgIcon(
        prefix + icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(rootPath + icon.path),
        { viewBox }
      );
    });
  }
}
