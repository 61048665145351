import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'rc-slides',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <mat-tab-group>
      <mat-tab *ngFor="let tab of arr" [label]="tab.name">
        <span *ngIf="content.edit && localEdit">
          Name:<input type="text" [(ngModel)]="tab.name">
          <button *ngIf="content.edit && localEdit" (click)="removeTab(arr.indexOf(tab))">x</button>
          <button *ngIf="content.edit && localEdit" (click)="addTab()">Add Tab</button>
        </span>
        <mat-form-field class="paragraph-text" *ngIf="content.edit && localEdit">
          <textarea matInput placeholder="Text" [(ngModel)]="tab.text">
          </textarea>
        </mat-form-field>
        <div class="paragraph-text" *ngIf="tab.text && !localEdit" [innerHTML]="tab.text | marked"></div>
      </mat-tab>
    </mat-tab-group>
  `
})
export class TabsComponent implements OnInit, ContentOnCreate {

  @Input() public arr: any[];
  content;
  root;
  localEdit = false;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  addTab() {
    this.arr.push( {name: 'New Tab', text: ''} );
  }
  removeTab(index) {
    this.arr.splice(index, 1);
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.arr = values.arr;
  }
  url(input) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(input + '?embedded=true');
  }

}
