import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DbService} from '../../services/paperdb.service';
import {AuthService} from '../../services/auth.service';
import {UploadFile} from '../../models/tools.model';
import {finalize} from 'rxjs/operators';
import * as cv from "@techstark/opencv-js";

import * as tf from '@tensorflow/tfjs';
import * as handPoseDetection from '@tensorflow-models/hand-pose-detection';
import {round, v3} from '../../iiLib';
import {constraints} from '@tensorflow/tfjs';

@Component({
  selector: 'app-cam',
  template: `
    <button (click)="setupDevices('front');">startCam Front</button>
    <button (click)="setupDevices('back');">startCam Back</button>

    <ng-container *ngIf="{canny:false, hough:false, circle:false } as ui">
      <button *ngFor="let uiOpt of ui | keyvalue" (click)="ui[uiOpt.key] = !ui[uiOpt.key]">{{uiOpt.key}}</button>

      <ng-container *ngFor="let optSet of CVopt | keyvalue" >
        <div *ngIf="ui[optSet.key]">
          {{optSet.key}}:
          <span *ngFor="let opt of CVopt[optSet.key] | keyvalue">{{opt.key}}<input min="0" width="2" max="300" type="number" [(ngModel)]="CVopt[optSet.key][opt.key]"></span>
        </div>
      </ng-container>

    </ng-container>
    <button (click)="testTF()">testTF</button>
    <button (click)="openCV();">OpenCV</button>
    <button (click)="openCVlines();">OpenCV[lines]</button>
    <div class="video-container" *ngIf="!error">
      <video [class.show]="!isCaptured" [class.hide]="isCaptured" #video id="video" [width]="WIDTH" [height]="HEIGHT"
             autoplay></video>
      <canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="WIDTH"
              [height]="HEIGHT"></canvas>
    </div>

    <div class="snap-container">
      <div>
        show:
        <span *ngFor="let opt of show | keyvalue">{{opt.key}}:<input  type="checkbox" [(ngModel)]="show[opt.key]" /></span>
      </div>
      <button class="btn btn-primary" (click)="upload()">Upload</button>
      <button class="btn btn-primary" (click)="redraw()">redraw</button>

      <div *ngIf="ws" >
        Circles:{{circles.length}}
        Hands:{{hands.length}}
        type:<input type="text" [(ngModel)]="addObjOpt.type"/>
        height:<input width="2" type="number" [(ngModel)]="addObjOpt.height"/>

        <button (click)="addHandsAsObjects()">addHandsAsObjects()</button>
        <button class="btn btn-primary" *ngIf="isCaptured" (click)="addCirclesAsObjects()">addCirclesAsObjects()</button>
      </div>
      <div *ngIf="error" class="text-danger mt-4">
        {{ error }}
      </div>
    </div>

    <ul class="list-unstyled">
  <span *ngFor="let src of captures; let idx = index" (click)="setPhoto(idx)">
    <img [src]="src" height="50" />
  </span>
    </ul>
  `
})
export class CamComponent implements AfterViewInit {
  hide = false;
  WIDTH = 200;
  HEIGHT = 200;
  @Input() ws;
  constructor(public DB: DbService, public auth: AuthService) {
    setTimeout(() => {
      (window as any).cv = cv;
      console.log(cv.getBuildInformation());
    }, 1000);

  }
  @ViewChild("video", {static: false}) public video: ElementRef;
  @ViewChild("canvas", {static: false}) public canvas: ElementRef;


  captures: string[] = [];
  error: any;
  isCaptured: boolean;
  show = {text:true, scale:true, center:false, date: true};
  CVopt = {
    circle: {
      minDist: 45,
      param1: 75,
      param2: 40,
      minRadius: 0,
      maxRadius: 0,
    },
    canny: {
      threshold1: 50,
      threshold2: 200,
      apertureSize: 3
    },
    hough: {
      rho: 1,
      theta: Math.PI / 180,
      threshold: 100,
      minLineLength: 10,
      maxLineGap: 100
    }
  };
  addObjOpt = {
    type: 'cylinder',
    height: 10,
    taper: 0,
  }
  circles: any[] = [];

  @Input() text = "inventinside";
  @Input() scale = 1;
  @Input() pos: { x: number, y: number } = {x: 0, y: 0};
  maxGridBounds=30;

  //
  drawGrid(){
    let context = this.canvas.nativeElement.getContext("2d");
    context.beginPath();
    context.strokeStyle = "rgba(0,0,0,0.5)";
    context.lineWidth = 0.25;
    for(let i=-this.maxGridBounds/this.scale; i<=this.maxGridBounds/this.scale+1; i +=10/this.scale){
      let center = {x: this.WIDTH/2, y: this.HEIGHT/2};
      let offset = {x: this.pos.x/this.scale, y: this.pos.y/this.scale};
      let x = center.x - offset.x;
      let y = center.y + offset.y;
      context.moveTo(x+i, y-this.maxGridBounds/this.scale);
      context.lineTo(x+i, y+this.maxGridBounds/this.scale);
      context.stroke();
      //add vertical line
      context.moveTo(x-this.maxGridBounds/this.scale, y+i);
      context.lineTo(x+this.maxGridBounds/this.scale, y+i);
      context.stroke();

      //add red labels
      context.font = "10px Arial";
      context.fillStyle = "black";
      context.fillText(round(i*this.scale,1), x+i, 15);
      context.fillText(round(i*this.scale,1), 5, y+i);

    }
  }

  stream;
  @Output() selectedFile = new EventEmitter<File>();
  @Output() canvasClick = new EventEmitter<any>();


  async ngAfterViewInit() {
    await this.setupDevices();
    //get context of canvas element
    let context = this.canvas.nativeElement.getContext("2d");
    this.canvas.nativeElement.addEventListener('click', event =>
    {
      let bound = this.canvas.nativeElement.getBoundingClientRect();

      let x = event.clientX - bound.left - this.canvas.nativeElement.clientLeft;
      let y = event.clientY - bound.top - this.canvas.nativeElement.clientTop;
      this.canvasClick.emit({x: this.scale*(x-this.WIDTH/2), y: this.scale*(-y+this.HEIGHT/2)});

      this.redraw();
      //draw direction vector on canvas from center to point xy
      context.beginPath();
      context.moveTo(this.WIDTH/2, this.HEIGHT/2);
      context.lineTo(x, y);
      context.stroke();


      //draw circle on canvas at point xy
      context.beginPath();
      context.arc(x, y, 5, 0, 2 * Math.PI);
      context.stroke();


      //redraw after some time
      setTimeout(() => {
        this.redraw();
      },1000);

    });
  }

  async setupDevices(camType = '') {
    let constraints = {};
    if(camType == "front"){
      constraints = {
        video: { facingMode: "user" }
      };
    }else if (camType == "back") {
      constraints = {
        video: {
          facingMode: {exact: "environment"}
        }
      };
    }else{
        constraints = {
          video: true
        };
    }

    if (this.stream) {
      this.video.nativeElement.srcObject = this.stream;
      this.video.nativeElement.play();
      this.error = null;
    }else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = "You have no output video device";
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  public capture() {
    let img = this.canvas.nativeElement.toDataURL("image/png");
    this.captures.push(img);
    this.selectedFile.emit(img);
  }
  public redraw() {
    this.drawImageToCanvas(this.video.nativeElement);
    let img = this.canvas.nativeElement.toDataURL("image/png");
    this.selectedFile.emit(img);
    this.isCaptured = true;
  }


  //get coordinates of a click on canvas element
  public getCoordinates(event) {
    let rect = this.canvas.nativeElement.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;
    return {x: x, y: y};
  }

  openCV(){
    this.redraw();
    // circle detection code
    let src = cv.imread(this.canvas.nativeElement);
    let dst = cv.Mat.zeros(src.rows, src.cols, cv.CV_8U);
    let circles = new cv.Mat();
    let color = new cv.Scalar(255, 0, 0);
    cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
// You can try more different parameters
    cv.HoughCircles(src, circles, cv.HOUGH_GRADIENT,
      1, this.CVopt.circle.minDist, this.CVopt.circle.param1, this.CVopt.circle.param2, this.CVopt.circle.minRadius, this.CVopt.circle.maxRadius);
// draw circles
    this.circles = [];
    for (let i = 0; i < circles.cols; ++i) {
      let x = circles.data32F[i * 3];
      let y = circles.data32F[i * 3 + 1];
      let radius = circles.data32F[i * 3 + 2];
      let center = new cv.Point(x, y);
      this.circles.push({x:x*this.scale, y:y*this.scale, radius:radius*this.scale});
      console.log(dst, center, radius, color);
      //draw circle stroke on html canvas with given radius
      let context = this.canvas.nativeElement.getContext("2d");
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      context.stroke();
      //add center and label radius
      context.font = "15px Arial";
      context.fillText(`${round(radius*this.scale,1)}`, x - 10, y + 10);

    }

  }
  openCVlines(){
    this.redraw();
    let src = cv.imread(this.canvas.nativeElement);
    let dst = cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC3);
    let lines = new cv.Mat();
    let color = new cv.Scalar(255, 0, 0);
    cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);
    cv.Canny(src, src, this.CVopt.canny.threshold1, this.CVopt.canny.threshold2 , this.CVopt.canny.apertureSize);
// You can try more different parameters
    cv.HoughLinesP(src, lines, this.CVopt.hough.rho, this.CVopt.hough.theta, this.CVopt.hough.threshold, this.CVopt.hough.minLineLength, this.CVopt.hough.maxLineGap);

// draw lines
    this.lines = [];
    for (let i = 0; i < lines.rows; ++i) {
      let startPoint = {x: lines.data32S[i * 4], y: lines.data32S[i * 4 + 1]};
      let endPoint = {x: lines.data32S[i * 4 + 2], y: lines.data32S[i * 4 + 3]};
      this.lines.push({startPoint: startPoint, endPoint: endPoint});
      let sstartPoint = {x: lines.data32S[i * 4]*this.scale, y: lines.data32S[i * 4 + 1]*this.scale};
      let sendPoint = {x: lines.data32S[i * 4 + 2]*this.scale, y: lines.data32S[i * 4 + 3]*this.scale};
      this.lines.push({startPoint: sstartPoint, endPoint: sendPoint});

      let context = this.canvas.nativeElement.getContext("2d");
      context.beginPath();
      context.moveTo(startPoint.x, startPoint.y);
      context.lineTo(endPoint.x, endPoint.y);
      context.stroke();
      //add length of line
      context.font = "15px Arial";

      let distance = Math.sqrt(Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2));
      context.fillText(`${round(distance*this.scale,1)}`, startPoint.x - 10, startPoint.y + 10);
    }
    //cv.imshow('canvasOutput', dst);
    src.delete(); dst.delete(); lines.delete();

  }
  lines = [];

  async testTF(){
    this.redraw();
      // Create a simple model.
      const model = tf.sequential();
      model.add(tf.layers.dense({units: 1, inputShape: [1]}));

      // Prepare the model for training: Specify the loss and the optimizer.
      model.compile({loss: 'meanSquaredError', optimizer: 'sgd'});

      // Generate some synthetic data for training. (y = 2x - 1)
      const xs = tf.tensor2d([-1, 0, 1, 2, 3, 4], [6, 1]);
      const ys = tf.tensor2d([-3, -1, 1, 3, 5, 7], [6, 1]);

      // Train the model using the data.
      await model.fit(xs, ys, {epochs: 250});

      // Use the model to do inference on a data point the model hasn't seen.
      // Should print approximately 39.
      let ans = model.predict(tf.tensor2d([20], [1, 1])).toString();
      console.log(ans);


      const model2 = handPoseDetection.SupportedModels.MediaPipeHands;
      const detectorConfig = {
        runtime: 'tfjs',
      } as any;
      let detector = await handPoseDetection.createDetector(model2, detectorConfig);
      console.log(detector);
      const estimationConfig = {flipHorizontal: false};
      const hands = await detector.estimateHands(this.canvas.nativeElement, estimationConfig);
      console.log(hands);
      const self = this;
      this.hands = [];
      hands.forEach(hand => {
        let h = [];
        hand.keypoints.forEach((keypoint,i,arr) => {
          let pt = {x: keypoint.x, y: keypoint.y, name: keypoint.name, pt: hand.keypoints3D[i]};
          h.push(pt);
        });
        self.hands.push(h);
      });
      await this.drawHands(this.hands);
  }
  hands = [];
  async drawHands(hands){
    let context = this.canvas.nativeElement.getContext("2d");
    //context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    for (let i = 0; i < hands[0].length; i++) {
      //draw circle stroke on html canvas with given radius
      context.beginPath();
      //set color of circle
      context.strokeStyle = "red";
      context.lineWidth = 20;
      // fill circle
      context.fillStyle = "red";
      context.arc(hands[0][i].x, hands[0][i].y, 2, 0, 2 * Math.PI);
      context.stroke();

    }
  }

  addCirclesAsObjects() {
    //loop though all of the circles and add them as objects to the database
    let sack = this.ws.newCSG('union',this.ws.basePlane);

    for (let i = 0; i < this.circles.length; i++) {
      let circle = this.circles[i];
      let n = this.ws.newCSG(this.addObjOpt.type,sack);
      n.setPos(v3(circle.x-this.scale*(this.WIDTH/2), -circle.y+this.scale*(this.HEIGHT/2), 0));
      if(this.addObjOpt.type === 'cylinder') {
        n.updateConfig(circle.radius*2,0,this.addObjOpt.height);
      } else if (this.addObjOpt.type === 'sphere') {
        n.updateConfig(circle.radius*2);
      } else{
        n.updateConfig(circle.radius*2,circle.radius*2,this.addObjOpt.height);
      }
    }
  }
  addHandsAsObjects() {
    //loop though all of the circles and add them as objects to the database
    let sack = this.ws.newCSG('union',this.ws.basePlane);

    for (let i = 0; i < this.hands[0].length; i++) {
      let handPos = this.hands[0][i]['pt'];
      let n = this.ws.newCSG(this.addObjOpt.type,sack);
      n.setPos(v3(handPos.x*1000, handPos.y*1000, handPos.z*1000));
      if(this.addObjOpt.type === 'cylinder') {
        n.updateConfig(this.addObjOpt.height,0,this.addObjOpt.height);
      } else if (this.addObjOpt.type === 'sphere') {
        n.updateConfig(this.addObjOpt.height);
      } else{
        n.updateConfig(this.addObjOpt.height,this.addObjOpt.height,this.addObjOpt.height);
      }
    }
  }

  removeCurrent() {
    this.isCaptured = false;
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the ArrayBuffer
    var ia = new Uint8Array(ab);

    // set the bytes of the ArrayBuffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
  public upload(name: string="") {
    let uri = this.canvas.nativeElement.toDataURL("image/png");
    let blob = this.dataURItoBlob(uri);
    const self = this;
    self.auth.user$.subscribe(u => {
      if (u) {
        self.startUpload(name, u.uid, 0, blob);
      }
    });
  }
  async startUpload(name,uploader,size, fileData) {
    let file = {
      name: name,
      uploader: uploader,
      size: size,
      downloadURL: null
    }
    const self = this;
    // The storage rootPath
    const path = `files/${Date.now()}_${file.name}`;
    // Reference to storage bucket
    const ref = this.DB.storage.ref(path);
    // The main task
    const uploadTask = this.DB.storage.upload(path, fileData);
    // Progress monitoring
    const percentage = uploadTask.percentageChanges();
    const snapshot = uploadTask.snapshotChanges().pipe(
      // tap(console.log),
      finalize( async () =>  {
        file.downloadURL = await ref.getDownloadURL().toPromise();
        console.log(file);
        if(!self.DB.fileList){
          self.DB.fileList = {img:[]};
        }
        self.DB.fileList.img.push(file);
        self.DB.saveFileList();
      }),
    );
    snapshot.subscribe(data => {
      // console.log(data);
    });
  }
  setPhoto(idx: number) {
    this.isCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    //draw image to canvas
    let ctx = this.canvas.nativeElement.getContext("2d");
    //clear canvas
    ctx.clearRect(0, 0, this.WIDTH, this.HEIGHT);
    ctx.drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  drawImageToCanvas(image: any) {
    let ctx = this.canvas.nativeElement.getContext("2d");
    function drawImageScaled(img, ctx) {
      var canvas = ctx.canvas ;
      var hRatio = canvas.width  / img.videoWidth    ;
      var vRatio =  canvas.height / img.videoHeight  ;
      var ratio  = Math.min ( hRatio, vRatio );
      var centerShift_x = ( canvas.width - img.videoWidth*ratio ) / 2;
      var centerShift_y = ( canvas.height - img.videoHeight*ratio ) / 2;
      ctx.clearRect(0,0,canvas.width, canvas.height);
      ctx.drawImage(img, 0,0, img.videoWidth, img.videoHeight,
        centerShift_x,centerShift_y,img.videoWidth*ratio, img.videoHeight*ratio);
    }
    console.log(image);
    drawImageScaled(image, ctx);
    if(this.show.text) {
      ctx.font = "12px Georgia";
      ctx.fillText(this.text, 5, 17);
    }

    if(this.show.scale) {
      // add scale bar indicating mm to pixel ratio
      var scale = this.scale;
      var scaleBarLength = (this.WIDTH - 100)/2;
      var scaleBarWidth = 2;
      ctx.strokeStyle = "black";
      ctx.lineWidth = scaleBarWidth;
      ctx.beginPath();
      ctx.moveTo(0, this.HEIGHT - scaleBarWidth-17);
      ctx.lineTo(scaleBarLength, this.HEIGHT - scaleBarWidth-17);
      ctx.stroke();
      ctx.fillText(scale*scaleBarLength + " mm", scaleBarLength + 5, this.HEIGHT - scaleBarWidth - 15);
    }

    if(this.show.center) {
      //add crosshairs to center of image
      ctx.strokeStyle = "black";
      let padding = 50;
      ctx.lineWidth = 0.5;
      ctx.beginPath();
      ctx.moveTo(this.WIDTH / 2, padding);
      ctx.lineTo(this.WIDTH / 2, this.HEIGHT - padding);
      ctx.moveTo(padding, this.HEIGHT / 2);
      ctx.lineTo(this.WIDTH - padding, this.HEIGHT / 2);
      ctx.stroke();
      this.drawGrid();
    }
    if(this.show.date){
      //get current date yy/mm/dd time
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      let time = year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
      ctx.font = "10px Georgia";
      ctx.fillText(time, 3, this.HEIGHT - 3);
    }

  }
}
