import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Router} from '@angular/router';
import {finalize, map, shareReplay} from 'rxjs/operators';
import {AngularFireDatabase} from '@angular/fire/database';
import {FileData, Part} from '../models/auth.model';
import { HttpClient } from '@angular/common/http';

import {  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  files;
  loaded = false;
  partObList = [];
  frontPage = {};
  apiUrl = "HTTP://192.168.1.111/";

  pageItemTypes = [
    {name: 'feature', value: 'feature'},
    {name: 'image', value: 'image'},
    {name: 'headline', value: 'headline'},
    {name: 'paragraph', value: 'paragraph'},
    {name: 'tabs', value: 'tabs'},
    {name: 'carusel', value: 'carusel'},
    {name: 'grid', value: 'grid'},
    {name: 'slides', value: 'slides'},
    {name: 'view3d', value: 'view3d'}
  ];

  constructor(public fs: AngularFirestore,
              public fsStorage: AngularFireStorage,
              public  router: Router,
              public storage: AngularFireStorage,
              public db: AngularFireDatabase,
              private httpClient: HttpClient) {
    const self = this;
  }
  //Duet 3d printer services
  sendPostRequest(url, params): Observable<Object> {
    url = url + params;
    return this.httpClient.get(url);
  }
  sendPostRequestFileDyDir(file, url, dir = 'gcodes', name = 'live'): Observable<Object> {
    url = url + "rr_upload?name=0:/"+dir+"/" + name + ".gcode&time=" + encodeURIComponent(timeToStr(new Date()));
    let data = file;
    return this.httpClient.post(url, data);
  }
  sendPostRequestFile(file, url, name = 'live'): Observable<Object> {
    return this.sendPostRequestFileDyDir(file, url,'gcodes', name = 'live');
  }
  sendRequest(type = 'login', data = {}, url = '192.168.1.111', name = null, curaSlicer = null) {
    if (type == 'login') {
      let params = "rr_connect?password=&time=" + encodeURIComponent(timeToStr(new Date()));
      this.sendPostRequest('HTTP://' + url + '/', params).subscribe(res => {
        console.log(res);
        if (curaSlicer) {
          curaSlicer.handleDuet(type, res);
        }
      });
    } else if (type == 'rr_upload') {
      this.sendPostRequestFile(data, 'HTTP://' + url + '/', name).subscribe(res => {
        console.log(res);
        if (curaSlicer) {
          curaSlicer.handleDuet(type, res);
        }
      });
    } else if (type == 'rr_status') {
      let params = "rr_status?type=" + name;
      this.sendPostRequest('HTTP://' + url + '/', params).subscribe(res => {
        console.log(res);
        if (curaSlicer) {
          curaSlicer.handleDuet(type, res);
        }
      });
    } else if (type == 'rr_gcode') {
      let params = "rr_gcode?gcode=" + name;
      return this.sendPostRequest('HTTP://' + url + '/', params).toPromise();
    } else if (type == 'rr_replay') {
      let params = "rr_replay";
      this.sendPostRequest('HTTP://' + url + '/', params).subscribe(res => {
        console.log(res);
        if (curaSlicer) {
          curaSlicer.handleDuet(type, res);
        }
      });
    }
  }

  getPageData(page) {
    if (this.frontPage.hasOwnProperty(page)) {
      return this.frontPage[page];
    } else {
      this.frontPage[page] = this.fs.collection('content').doc(page).valueChanges().pipe(shareReplay(1));
      return this.frontPage[page];
    }
  }
  updatePageData(page, data) {
    return this.fs.doc('content/' + page).set(data);
  }
  createPageItem(type) {
    let fet = {};
    if (type === 'feature') {
      fet = {
        heading: '',
        subHeading: '',
        img: '//placekitten.com/500/400',
        text: '',
        partLink: '',
        _type: 'feature'
      };
    } else if (type === 'image') {
      fet = {
        src: '//placekitten.com/500/10',
        alt: 'First image',
        _type: 'image'
      };
    } else if (type === 'view3d') {
      fet = {
        src: '',
        alt: 'First image',
        _type: 'view3d'
      };
    } else if (type === 'headline') {
      fet = {
        text: 'New Headline',
        _type: 'headline'
      };
    } else if (type === 'paragraph') {
      fet = {
        heading: '',
        subHeading: '',
        text: '',
        _type: 'paragraph'
      };
    } else if (type === 'slides') {
      fet = {
        arr: [
          {
            img: '//placekitten.com/500/10',
            name: 'one',
            text: 'text1'
          }, {
            img: '//placekitten.com/500/10',
            name: 'two',
            text: 'text2'
          }, {
            img: '//placekitten.com/500/10',
            name: 'three',
            text: 'text3'
          }
        ],
        _type: 'slides'
      };
    } else if (type === 'tabs') {
      fet = {
        arr: [
          {
            name: 'N',
            text: ''
          }, {
            name: 'Issues',
            text: ''
          }, {
            name: 'Proposals',
            text: ''
          }
        ],
        _type: 'tabs'
      };
    } else if (type === 'carusel') {
      fet = {
        arr: [
          {
            img: '//placekitten.com/500/10',
            name: 'one',
            text: 'text1'
          }, {
            img: '//placekitten.com/500/10',
            name: 'two',
            text: 'text2'
          }, {
            img: '//placekitten.com/500/10',
            name: 'three',
            text: 'text3'
          }
        ],
        _type: 'carusel'
      };
    } else if (type === 'grid') {
      fet = {
        _embedded: {
          row0: this.createPageItem('row')
        },
        _type: 'grid'
      };
    } else if (type === 'row') {
      fet = {
        _type: 'row',
        _embedded: {
          columns: [this.createPageItem('column')]
        }
      };
    } else if (type === 'column') {
      fet = {
        _type: 'column',
        size: '',
        offset: '',
        _embedded: {}
      };
    } else if (type === 'columnNote') {
      fet = {
        _type: 'column',
        size: '',
        offset: '',
        _embedded: {
          paragraph: this.createPageItem('paragraph'),
          view3d: this.createPageItem('view3d')
        }
      };
    } else if (type === 'columnImg') {
      fet = {
        _type: 'column',
        size: '',
        offset: '',
        _embedded: {
          img: this.createPageItem('image')
        }
      };
    } else if (type === 'column3d') {
      fet = {
        _type: 'column',
        size: '',
        offset: '',
        _embedded: {
          img: this.createPageItem('view3d')
        }
      };
    } else if (type === 'columnModel') {
      fet = {
        _type: 'column',
        size: '',
        offset: '',
        _embedded: {
          3: this.createPageItem('grid'),
          0: this.createPageItem('paragraph'),
          1: this.createPageItem('view3d')
        }
      };
    }

    return fet;
  }


  // FILES - NOT USED.
  getFiles(rootPath: string) {
    return this.fs.collection('files', ref => {
      let query = ref;
      if (rootPath) {
        return query.where('rootPath', '==', rootPath)
      } else {
        return ref
      }
      ;
    }).snapshotChanges().pipe(shareReplay(1));
  }
  updateFile(fileData: FileData) {
    // update file data
    this.fs.doc('files/' + fileData.id).update(fileData);
  }
  deleteFile(file: FileData) {
    const desertRef = this.fsStorage.storage.refFromURL(file.downloadURL);
// Delete the file
    desertRef.delete().then(e => {
      this.fs.doc('files/' + file.id).delete();
      // File deleted successfully
    }).catch(e => {
      console.log(e);
    });
  }
  //Storage
  storageUploadFile(partId, file, data = null) {
    // Create the file metadata
    // The storage rootPath
    const path = `renderObj/${partId}.glb`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    let task = ref.put(file);
    console.log('upload part');

    task.snapshotChanges().pipe(
      finalize(async () => {
        let downloadURL = await ref.getDownloadURL().toPromise();
        console.log('updatePart:' + downloadURL);
        this.fs.doc('parts/' + partId).update({opt: downloadURL});
      })
    )
      .subscribe();
    //
  }
  storageUploadCSG(partId, file, update = true) {
// Create the file metadata
    // The storage rootPath
    const path = `csgData/${partId}.csg`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    let task = ref.put(file);
    console.log('upload CSG');

    task.snapshotChanges().pipe(
      finalize(async () => {
        let downloadURL = await ref.getDownloadURL().toPromise();
        console.log('updatePart:' + downloadURL);
        if (update) {
          this.fs.doc('parts/' + partId).update({csgData: downloadURL});
        }
      })
    ).subscribe();
  }
//ESP
  sendToESP(motor, setting, value) {
    return this.db.object('test/stream/data/' + motor + '/' + setting).set(value);
  }




}



function timeToStr(time) {
  let result = "";
  result += time.getFullYear() + "-";
  result += (time.getMonth() + 1) + "-";
  result += time.getDate() + "T";
  result += time.getHours() + ":";
  result += time.getMinutes() + ":";
  result += time.getSeconds();
  return result;
}
