import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';

@Component({
  selector: 'rc-paragraph',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>

    <img *ngIf="root.src" [attr.src]="root.src" [attr.alt]="'Error loading'" class="panel-image">
    <div *ngIf="edit()">
      src:<input [(ngModel)]="root.src">
      <app-image-uploader (selectedFile)="root.src = $event.downloadURL" [size]="[400,400]"></app-image-uploader>
    </div>

    <div class="paragraph-body">
      <div class="paragraph-header" *ngIf="root.heading && !edit()" [innerHTML]="root.heading | marked">
      </div>
      <div *ngIf="edit()">
        <input class="paragraph-header"  type="text" [(ngModel)]="root.heading">
      </div>

      <div class="paragraph-subheader" *ngIf="root.subHeading && !edit()" [innerHTML]="root.subHeading | marked"></div>
      <div *ngIf="edit()">
        <input class="paragraph-subheader" type="text" [(ngModel)]="root.subHeading">
      </div>

      <div *ngIf="root.text && !edit()" class="paragraph-text">
        <div [innerHTML]="root.text | marked"> </div>
      </div>
      <button  *ngIf="root.pageLink"  (click)="goto(root.pageLink)">Go to Page:{{root.pageLink}}</button>

      <mat-form-field class="paragraph-text" *ngIf="edit()">
        <textarea matInput placeholder="Text" [(ngModel)]="root.text" [rows]="16"></textarea>
      </mat-form-field>

      <div *ngIf="edit()">
        pageLink:<input [(ngModel)]="root.pageLink"><br>
      </div>

    </div>
  `
})
export class ParagraphComponent implements OnInit, ContentOnCreate {

  root;
  content;
  localEdit;

  constructor() { }

  edit(){
    if (this.localEdit && this.content.edit) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit() {
  }
  goto(page){
    let rootURL = window.location.hostname;
    window.open('http://'+rootURL+':'+window.location.port+'/item/'+page,'popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
  }

}
