import { Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { ContentOnCreate, ContentEmbeddable } from '../../reactive-content';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DbService} from '../../services/db.service';


@Component({
  selector: 'rc-grid-column',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <div class="grid-col" [style.width]="root.size" [style.height]="root.offset" [ngClass]="{'edit-border': edit()}">
    <ng-container *ngIf="{edit: false} as opt">
      <ng-container *ngIf="edit()">
        <div class="panel-edit-btn">
        <button  (click)="opt.edit = !opt.edit" >.</button><br>
        </div>


        <div *ngIf="opt.edit" class='optionsWindow'>
          <br>
          <button (click)="addItem('view3d')">3d+</button><br>
          <button (click)="addItem('paragraph')">p+</button><br>
          <button (click)="addItem('image')">i+</button>
          <br>
          <div>Width:<input type='text' [(ngModel)]="root.size"></div>
          <div>Height:<input type='text' [(ngModel)]="root.offset"></div>
          <mat-form-field>
            <mat-label>Add Content</mat-label>
            <mat-select #type>
              <mat-option *ngFor="let type of db.pageItemTypes" [value]="type.value">
                {{type.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="addItem(type.value)">+</button>
        </div>

        <div *ngIf="opt.edit">

          <div cdkDropList  (cdkDropListDropped)="drop($event)">
            <div  *ngFor="let child of childList" cdkDrag>
              {{child.itemKey}} [{{child.type}}]-<button (click)="deleteChild(child.itemKey)">X</button></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container #embed></ng-container>
  </div>` // `<div class="column {{columnSize}} {{columnOffset}}" #embed></div>`
})
export class GridColumnComponent implements ContentOnCreate, ContentEmbeddable {

  @HostBinding('class')
  public cssClasses: string;

  @ViewChild('embed', { read: ViewContainerRef, static: true })
  public embed: ViewContainerRef;

  childList = [];
  content;
  root;
  localEdit = false;
  edit() {
    if (this.localEdit && this.content.edit) {
      return true;
    } else {
      return false;
    }
  }

  addItem(type) {
    this.root._embedded[this.childList.length] = this.db.createPageItem(type);
    this.content.updateHostedContent(this.content.document);
    console.log(this.root);
  }
  deleteChild(child) {
    delete this.root._embedded[child];
    this.content.updateHostedContent(this.content.document);
  }


  constructor(public db: DbService) {}

  contentOnCreate(values: { [key: string]: any; }, content): void {
    //const size: string = values.size ? `col-${values.size}` : '';
    //const offset: string = values.offset ? `offset-${values.offset}` : '';
    this.cssClasses = ['col-sm'].join(' ');
    this.content = content;
    this.root = values;
    this.genChildList();
  }

  contentEmbeddable(): ViewContainerRef {
    return this.embed;
  }

  genChildList() {
    const self = this;
    self.childList = [];
    if (this.root._embedded && Object.keys(this.root._embedded).length > 0) {
      Object.keys(self.root._embedded).sort().forEach((key: string) => {
        self.childList.push({ itemKey: key, type: self.root._embedded[key]._type, obj: self.root._embedded[key] });
      });
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    const self = this;
    //this.rename( this.childList[event.previousIndex].itemKey , 'list' + event.currentIndex );
    moveItemInArray(this.childList, event.previousIndex, event.currentIndex);
    let newSet = {};
    self.childList.forEach((c,i)=>{
      newSet[i] = c.obj;
    });
    this.root._embedded = newSet;
    this.content.updateHostedContent(this.content.document);
  }
}
