import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { DbService } from '../../services/db.service';
import {LivePartService} from '../../services/live-part.service';
import {map} from 'rxjs/operators';
import {RootService, UserService} from '../../services/root.service';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {of} from 'rxjs';

@Component({
  selector: 'app-parts',
  template: `
    <ng-container *ngIf="mode == 'page'|| mode == 'app' || mode == 'tiles'">
      <a class="menu-item" routerLink="/home" routerLinkActive="active">Home</a>
      <a class="menu-item" routerLink="/editor" routerLinkActive="active">Editor</a>
      <a class="menu-item" routerLink="/parts" routerLinkActive="active">Parts</a>
      <a class="menu-item" routerLink="/profile" routerLinkActive="active">Profile</a>

      <div *ngIf="{selected: null, currentPage: 1, total: 0, edit: false, category: ''} as ui">
        <div *ngIf="root.checkAdmin()" class="highlight-row">
          <input [(ngModel)]="filterCat">

          <button (click)="searchCategory()">Search Category</button>
          Admin:
          <button [ngClass]="{'active-btn': filterCat === '' }" (click)="filterCat = ''; getPartListAll();">[-]</button>
        </div>

        <div class="part-opt6" *ngIf="auth.user$ | async as u">
          <ng-container *ngFor="let cat of auth.userData?.category">
            <button  *ngIf="cat.value" [ngClass]="{'active-btn': filterCat === cat.value}"
                     (click)="filterCat = cat.value; searchCategory()">
              <span *ngIf="cat.c">[{{cat.c}}]</span>
              <mat-icon *ngIf="cat.img">{{cat.img}}</mat-icon>
              <span *ngIf="!cat.img">{{cat.name}}</span>
            </button>
          </ng-container>
        </div>

        <ng-container *ngFor="let cat of root.UI.partCategory">
          <ng-container *ngIf="cat.c">
            <button  *ngIf="cat.value" [ngClass]="{'active-btn': filterCat === cat.value}"
                     (click)="filterCat = cat.value; searchCategory()">
              <span *ngIf="cat.c">[{{cat.c}}]</span>
              <mat-icon *ngIf="cat.img">{{cat.img}}</mat-icon>
              <span *ngIf="!cat.img">{{cat.name}}</span>
            </button>
          </ng-container>
        </ng-container>
        <div class="container">

          <ng-container *ngFor="let part of showList">
      <span  [ngClass]="{'highlight-row': selected === part,'red-outline': part.opt.hidden}">

        <div class="child" *ngIf="(part.owner == root.USER.userData.uid || !part.hidden) && {details:false} as opt" (click)="selected = part; partSelected(part)" >

          <ng-container>
            <div class="input-full-width"
                 *ngIf="part.opt && part.opt.partInfo">
              <img height="100px"  *ngIf="part.opt.downloadURL?.img" [src]="part.opt.downloadURL?.img">
              <img height="100px"  *ngIf="!part.opt.downloadURL?.img" [src]="">
              <br>
              <span *ngIf="root.checkAdmin()">[{{part.opt.partInfo.category}}]</span>
              <br>
              <mat-icon *ngIf="part.opt.partInfo.category && root.UI.partCatObj[part.opt.partInfo.category]">{{root.UI.partCatObj[part.opt.partInfo.category].img}}</mat-icon>
              <span>{{part.opt.partInfo.name}}</span>
              <span *ngIf="part.opt.hidden">
              <mat-icon>hide_image</mat-icon>
            </span>
              <span *ngIf="part.opt.public">
              <mat-icon>edit</mat-icon>
            </span>

              <div *ngIf="selected?.id == part.id">
                <!--<button (click)="openPartWindow('same', selected.id)"><mat-icon>open_in_new</mat-icon>Open</button> -->
                <button *ngIf="root.checkAdmin()" (click)="openPartWindow('popup', selected.id)"><mat-icon>open_in_new</mat-icon></button>
                <button (click)="openPartWindow('small', selected.id)"><mat-icon>download</mat-icon></button>
                <!-- button to open the manuDetails -->
                <button *ngIf="root.checkAdmin()" [matMenuTriggerFor]="menuDetails" [matMenuTriggerData]="part">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        </span>
          </ng-container>

        </div>


        <mat-menu #menuDetails="matMenu">
          <ng-template  matMenuContent let-obj="obj">
            <div (click)="$event.stopPropagation();" *ngIf="{varlist:false, tree:false} as ui2">

              <span *ngIf="edit"><input type="text" [(ngModel)]="selected.opt.partInfo.name"></span>
              <span *ngIf="!edit">
                  <h3>{{selected.opt.partInfo.name}}</h3>
                </span>
              <h5>Category:</h5>
              <span *ngIf="!edit">{{selected.opt.partInfo.category}}</span>
              <span *ngIf="edit">
                        <input [(ngModel)]="selected.opt.partInfo.category">
                        <mat-form-field>
                          <mat-label>Category:</mat-label>
                          <mat-select [(value)]="selected.opt.partInfo.category">
                            <mat-option *ngFor="let cat of auth?.userData?.category" [value]="cat.value">{{cat.name}}</mat-option>
                            <mat-option *ngFor="let cat of root.UI.partCategory" [value]="cat.value">{{cat.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                  </span>
              <div class="part-opt6" *ngIf="root.checkAdmin()">
                <button (click)="edit = !edit"><mat-icon>edit</mat-icon>edit</button>
                <button *ngIf="edit" (click)="updatePart(selected)"><mat-icon>save</mat-icon>Update</button>
              </div>
              <h5 *ngIf="edit">Options:</h5>
              <div *ngIf="edit">
                <input type="checkbox" *ngIf="edit" [(ngModel)]="selected.opt.public">Public Edit: {{selected.opt.public}}
                <input type="checkbox" *ngIf="edit" [(ngModel)]="selected.opt.hidden">Hidden: {{selected.opt.hidden}}
              </div>
              <h5>Description:</h5>
              <p *ngIf="!edit">{{selected.opt.partInfo.description}}</p>
              <input *ngIf="edit" type="text" [(ngModel)]="selected.opt.partInfo.description">
              <ng-container *ngIf="ui2.varlist">
              <h5>Technical Details:</h5>
              id:[{{selected.id}}]<br>
              owner id:[{{selected.id}}]<br>
              updated:[{{selected.updated | date:'short'}}]<br>
              created:[{{selected.created | date:'short'}}]<br>
              Nodes:{{selected.data.length}}<br>
                Variables:{{selected.opt.varSet.length}}
              </ng-container>
              <h5>Variables:</h5>
              <button title="Parameter values defined at the time of saving." (click)="ui2.list=!ui2.list">Variables [{{selected.opt.varSet.length}}]</button>
              <ng-container *ngIf="ui2.varlist" >
                <div *ngFor="let v of selected.opt.varSet">
                  <span>{{v.name}}= {{v.exp}}</span>
                </div>
              </ng-container>
              <button title="Parameter values defined at the time of saving." (click)="ui2.tree=!ui2.tree">Tree</button>

              <ng-container *ngIf="ui2.tree" >
                <app-csg-explorer [partObj]="selected" ></app-csg-explorer>
              </ng-container>

              <div class="part-opt6" *ngIf="root.checkAdmin()">
                <button *ngIf="edit" (click)="deletePart(selected.id)"><mat-icon>trash</mat-icon>X</button>
              </div>
            </div>
          </ng-template>
        </mat-menu>

      </div>
    </ng-container>
    <ng-container *ngIf="mode == 'con'">

      <div *ngIf="{selected: null, currentPage: 1, total: 0, edit: false, category: ''} as ui">
        <div *ngIf="root.checkAdmin()" class="highlight-row">
          <input [(ngModel)]="filterCat">

          <button (click)="searchCategory()">Search Category</button>
          Admin:
          <button [ngClass]="{'active-btn': filterCat === '' }" (click)="filterCat = ''; getPartListAll();">[-]</button>
        </div>

        <div class="part-opt6" *ngIf="auth.user$ | async as u">
          <ng-container *ngFor="let cat of auth.userData?.category">
            <button  *ngIf="cat.value" [ngClass]="{'active-btn': filterCat === cat.value}"
                     (click)="filterCat = cat.value; searchCategory()">
              <span *ngIf="cat.c">[{{cat.c}}]</span>
              <mat-icon *ngIf="cat.img">{{cat.img}}</mat-icon>
              <span *ngIf="!cat.img">{{cat.name}}</span>
            </button>
          </ng-container>
        </div>

        <ng-container *ngFor="let cat of root.UI.partCategory">
          <ng-container *ngIf="cat.c">
            <button  *ngIf="cat.value" [ngClass]="{'active-btn': filterCat === cat.value}"
                     (click)="filterCat = cat.value; searchCategory()">
              <span *ngIf="cat.c">[{{cat.c}}]</span>
              <mat-icon *ngIf="cat.img">{{cat.img}}</mat-icon>
              <span *ngIf="!cat.img">{{cat.name}}</span>
            </button>
          </ng-container>
        </ng-container>
        <div class="container">

          <ng-container *ngFor="let part of showList">
      <span  [ngClass]="{'highlight-row': selected === part,'red-outline': part.opt.hidden}">

        <div class="child" *ngIf="(part.owner == root.USER.userData.uid || !part.hidden) && {details:false} as opt" (click)="selected = part">

          <ng-container>
            <div class="input-full-width" *ngIf="part.opt && part.opt.partInfo">
              <img height="100px"  *ngIf="part.opt.downloadURL?.img" [src]="part.opt.downloadURL?.img">
              <img height="100px"  *ngIf="!part.opt.downloadURL?.img" [src]="">
              <br>
              <mat-icon *ngIf="part.opt.partInfo.category && root.UI.partCatObj[part.opt.partInfo.category]">{{root.UI.partCatObj[part.opt.partInfo.category].img}}</mat-icon>
              <span>{{part.opt.partInfo.name}}</span>
              <span *ngIf="part.opt.hidden">
              <mat-icon>hide_image</mat-icon>
            </span>
              <span *ngIf="part.opt.public">
              <mat-icon>edit</mat-icon>
            </span>

              <div *ngIf="selected?.id == part.id">
                <button (click)="partSelected(part)">Select</button>
                <button [matMenuTriggerFor]="menuDetails" [matMenuTriggerData]="{part:part}">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
        </span>
          </ng-container>

        </div>


        <mat-menu #menuDetails="matMenu">
          <ng-template  matMenuContent let-part="part">
            <div (click)="$event.stopPropagation();" *ngIf="{varlist:false, tree:false} as ui2">
              <button (click)="openPartWindow('popup', selected.id)"><mat-icon>open_in_new</mat-icon></button>
              <app-csg-explorer [partObj]="part" [selectedSubPart]="selectedSubPart" (selectedPart)="selectedPart(part,$event)" ></app-csg-explorer>
            </div>
          </ng-template>
        </mat-menu>

      </div>
    </ng-container>

    <!-- page feature -->
    <ng-container *ngIf="mode == 'feature'">
      <div *ngIf="root.checkAdmin()" class="highlight-row">
        <button [ngClass]="{'active-btn': filterCat === '' }" (click)="getPartList();">[p]</button>
        <button [ngClass]="{'active-btn': filterCat === '' }" (click)="getPartListAll();">[-]</button>
      </div>
      <div *ngIf="{selected: null, currentPage: 1, total: 0, edit: false, category: ''} as ui">
        <div class="container">
          <ng-container *ngFor="let part of showList">
            <span  [ngClass]="{'highlight-row': selected === part,'red-outline': part.opt.hidden}">
        <div class="child" *ngIf="(part.owner == root.USER.userData.uid || !part.hidden) && {details:false} as opt"
             (click)="selected = part; partSelected(part)" >
          <ng-container>
            <div class="input-full-width"
                 *ngIf="part.opt && part.opt.partInfo">
              <img height="100px"  *ngIf="part.opt.downloadURL?.img" [src]="part.opt.downloadURL?.img">
              <img height="100px"  *ngIf="!part.opt.downloadURL?.img" [src]="">
              <br>
              <mat-icon *ngIf="part.opt.partInfo.category && root.UI.partCatObj[part.opt.partInfo.category]">{{root.UI.partCatObj[part.opt.partInfo.category].img}}</mat-icon>
            <span>{{part.opt.partInfo.name}}</span>
            <span *ngIf="part.opt.hidden">
              <mat-icon>hide_image</mat-icon>
            </span>
              <span *ngIf="part.opt.public">
                <mat-icon>edit</mat-icon>
              </span>

              <div *ngIf="selected?.id == part.id">
                <!--<button (click)="openPartWindow('same', selected.id)"><mat-icon>open_in_new</mat-icon>Open</button> -->
                <button *ngIf="root.checkAdmin()" (click)="openPartWindow('popup', selected.id)"><mat-icon>open_in_new</mat-icon></button>
                <button (click)="openPartWindow('small', selected.id)"><mat-icon>download</mat-icon></button>
                <!-- button to open the manuDetails -->
                <button *ngIf="root.checkAdmin()" [matMenuTriggerFor]="menuDetails" [matMenuTriggerData]="part">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </span>
          </ng-container>
        </div>
        <mat-menu #menuDetails="matMenu">
          <ng-template  matMenuContent let-obj="obj">
            <div (click)="$event.stopPropagation();" *ngIf="{varlist:false, tree:false} as ui2">

              <span *ngIf="edit"><input type="text" [(ngModel)]="selected.opt.partInfo.name"></span>
              <span *ngIf="!edit">
                  <h3>{{selected.opt.partInfo.name}}</h3>
                </span>
              <h5>Category:</h5>
              <span *ngIf="!edit">{{selected.opt.partInfo.category}}</span>
              <span *ngIf="edit">
                <input [(ngModel)]="selected.opt.partInfo.category">
                <mat-form-field>
                    <mat-label>Category:</mat-label>
                    <mat-select [(value)]="selected.opt.partInfo.category">
                      <mat-option *ngFor="let cat of auth?.userData?.category" [value]="cat.value">{{cat.name}}</mat-option>
                      <mat-option *ngFor="let cat of root.UI.partCategory" [value]="cat.value">{{cat.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
              </span>
              <div class="part-opt6" *ngIf="root.checkAdmin()">
                <button (click)="edit = !edit"><mat-icon>edit</mat-icon>edit</button>
                <button *ngIf="edit" (click)="updatePart(selected)"><mat-icon>save</mat-icon>Update</button>
              </div>
              <h5 *ngIf="edit">Options:</h5>
              <div *ngIf="edit">
                <input type="checkbox" *ngIf="edit" [(ngModel)]="selected.opt.public">Public Edit: {{selected.opt.public}}
                <input type="checkbox" *ngIf="edit" [(ngModel)]="selected.opt.hidden">Hidden: {{selected.opt.hidden}}
              </div>
              <h5>Description:</h5>
              <p *ngIf="!edit">{{selected.opt.partInfo.description}}</p>
              <input *ngIf="edit" type="text" [(ngModel)]="selected.opt.partInfo.description">
              <h5>Technical Details:</h5>
              id:[{{selected.id}}]<br>
              owner id:[{{selected.id}}]<br>
              updated:[{{selected.updated | date:'short'}}]<br>
              created:[{{selected.created | date:'short'}}]<br>
              Nodes:{{selected.data.length}}<br>
              Variables:{{selected.opt.varSet.length}}

              <h5>Variables:</h5>
              <button title="Parameter values defined at the time of saving." (click)="ui2.list=!ui2.list">Variables [{{selected.opt.varSet.length}}]</button>
              <ng-container *ngIf="ui2.varlist" >
                <div *ngFor="let v of selected.opt.varSet">
                  <span>{{v.name}}= {{v.exp}}</span>
                </div>
              </ng-container>
              <button title="Parameter values defined at the time of saving." (click)="ui2.tree=!ui2.tree">Tree</button>

              <ng-container *ngIf="ui2.tree" >
                <app-csg-explorer [partObj]="selected" ></app-csg-explorer>
              </ng-container>

              <div class="part-opt6" *ngIf="root.checkAdmin()">
                <button *ngIf="edit" (click)="deletePart(selected.id)"><mat-icon>trash</mat-icon>X</button>
              </div>
            </div>
          </ng-template>
        </mat-menu>
      </div>
    </ng-container>
  `
})
export class PartsComponent implements OnInit, OnDestroy {
  partList$;

  @Input('mode') mode = 'page';
  @Input('initID') initID = '';
  @Input('filterCat') filterCat = '';
  @Input('selectedSubPart') selectedSubPart = '';
  @Output('urlSelected') urlSelected = new EventEmitter(true);
  @Output('pSelected') pSelected = new EventEmitter(true);
  @Output('subPartSelected') subPartSelected = new EventEmitter(true);
  @Output('categorySelected') categorySelected = new EventEmitter(true);

  showList = [];
  pL = [];
  filterText = '';
  filterOwner = '';
  csgURL = {};
  category = [
    {c:0, name:'All', value:'', img:''},
    {c:0, name:'motor', value:'motor', img:'motor'},
    {c:0, name:'test', value:'test', img:'test'},
    {c:0, name:'robot', value:'robot', img:'motor'},
    {c:0, name:'robotArm', value:'robot_arm', img:'motor'},
    {c:0, name:'electronics', value:'electronics', img:'memory'},
    {c:0, name:'household', value:'household', img:'motor'},
    {c:0, name:'boxes', value:'boxes', img:'motor'},
    {c:0, name:'toys', value:'toys', img:'toys'},
    {c:0, name:'fixtures', value:'fixtures', img:'motor'},
    {c:0, name:'lamps', value:'lamps', img:'motor'},
    {c:0, name:'generative art', value:'generative_art', img:'motor'},
    {c:0, name:'agriculture', value:'agriculture', img:'motor'},
    {c:0, name:'Video Games', value:'video_games', img:'videogame_asset'},
    {c:0, name:'audio/video', value:'av', img:'camera_roll'},
    {c:0, name:'costumes', value:'costume', img:'security'},
    {c:0, name:'art', value:'art', img:'security'},
    {c:0, name:'gearbox', value:'gearbox', img:'security'},
    {c:0, name:'t1', value:'t1', img:'motor'},
    {c:0, name:'t2', value:'t2', img:'toys'},
    {c:0, name:'t3', value:'t3', img:'security'},
  ];
  category2 = [
    {c:0, name:'All', value:'', img:''},
    {c:0, name:'tools', value:'tools', img:'memory'},
    {c:0, name:'t1', value:'t1', img:'motor'},
    {c:0, name:'t2', value:'t2', img:'toys'},
    {c:0, name:'t3', value:'t3', img:'security'},
  ];
  edit = false;
  root: RootService;

  routerSub;
  partSub;
  routerParam = {id:'', category:'',name:''};
  selected;

  async selectedURL(obj) {
    let id = this.getObjThumbnailId(obj);
    this.urlSelected.emit(obj.downloadURL[id]);
    this.pSelected.emit(obj);
    this.subPartSelected.emit(id);
    this.categorySelected.emit(obj.opt.partInfo.category);
  }
  selectedPart(obj, id) {
    this.urlSelected.emit(obj.downloadURL[id]);
    this.subPartSelected.emit(id);
    this.categorySelected.emit(obj.opt.partInfo.category);
  }
  constructor(public db: DbService,
              public livePart: LivePartService,
              root: RootService,
              public auth: AuthService,
              public router: Router,
              private route: ActivatedRoute) {
    const self = this;
    this.root = root;
    self.resetSub(this.livePart.partList$);
  }
  resetSub(sub) {
    let self = this;
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.partSub) {
      this.partSub.unsubscribe();
    }
    this.partSub = sub.subscribe(data => {
      //update the category counts,
      self.root.UI.partCategory.forEach(c => {
        c.c = 0;
      });
      self.root.UI.partCategory.forEach(c => {
        data.forEach( d => {
          if (d.opt?.partInfo?.category == c.value) {
            c.c = c.c+1;
          }
        });
      });
      this.pL = data;

      if (this.mode === 'con') {
        this.pL.forEach(p => {
          if (p.id === this.initID) {
            self.selected = p;
          }
        });
      }

      if (this.mode === 'page') {
        this.routerSub = this.route.params.subscribe(params => {
          this.routerParam.id = params['id']; // (+) converts string 'id' to a number
          this.routerParam.category = params['category']; // (+) converts string 'id' to a number
          this.routerParam.name = params['name']; // (+) converts string 'id' to a number
          this.pL.forEach(p => {
            if (p.id === this.routerParam.id) {
              self.selected = p;
            }
          });
          if (params['category'] && params['category'] != '0') {
            self.filterCat = params['category'];
            self.searchCategory();
          }
          if (self.filterCat != '') {
            self.searchCategory();
          }

        });
      } else {
        self.searchCategory();
      }

    });
  }
  ngOnInit() {
    if (this.filterCat) {
      this.searchCategory();
    }
  }
  ngOnDestroy(): void {
      this.routerSub?.unsubscribe();
  }
  partSelected(part) {
    const self = this;
    if (this.mode == 'app') {
      if (!self.filterCat) {
        this.router.navigate(['/parts/'+part.id+'/0/'+part.opt.partInfo.name]);
      } else {
        this.router.navigate(['/parts/'+part.id+'/'+self.filterCat+'/'+part.opt.partInfo.name]);
      }
    } else if (this.mode == 'page') {
      if (!self.filterCat) {
        this.router.navigate(['/parts/'+part.id+'/0/'+part.opt.partInfo.name]);
      } else {
        this.router.navigate(['/parts/'+part.id+'/'+self.filterCat+'/'+part.opt.partInfo.name]);
      }
    } else if (this.mode == 'con' || this.mode == 'feature') {
      this.selectedURL(part);
    }


    if (!part.opt.partInfo.objectDoc) {
      part.opt.partInfo.objectDoc = {
        "_embedded": {
          "1": {
            "_type": "headline",
            "text": "Title"
          },
        },
        "_type": "page",
        "edit": true,
        "title": "Part Description Document"
      };
    }

  }
  getObjThumbnailId(part) {
    //console.log(part);
    let found = null;
    if (part && part.data) {
      part.data.forEach(p =>{
        if (!p.p && p.type == 'plane') {
          found = p;
        }
      });
    }
    if (found && found.c && found.c[0]) {
      //console.log(found.c[0]);
      return found.c[0];

    }
  }
  updatePart(part){
    this.livePart.updatePartData(part.id, part.data, part.opt, true);
  }
  deletePart(id) {
    this.livePart.deletePart(id);
  }
  searchCategory() {
    const self = this;
    self.showList = [];
    self.pL.forEach(v => {
      if (self.filterCat == ''){
        self.showList.push(v);
      } else if (v.opt?.partInfo?.category == self.filterCat) {
        self.showList.push(v);
      }
    });
  }
  getPartList() {
    this.livePart.getPartList();
    this.resetSub(this.livePart.partList$);
  }
  getPartListOwn() {
    let self = this;
    let partCollection;
    partCollection = this.db.fs.collection<any>('p', ref => ref.where('owner', '==', self.auth.userData.uid));  //
    // @ts-ignore
    this.partList$ = partCollection.snapshotChanges().pipe(
      map((actions:any) => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      })));
    self.resetSub(this.partList$);
  }
  getPartListAll() {
    this.livePart.getPartListAll();
    this.resetSub(this.livePart.partList$);
  }
  openPartWindow(mode, id) {
    return this.root.openPartWindow(mode, id);
  }// Stub
}
