// variables
import * as math from 'mathjs';
import {round, array_move, removeFromArr} from '../iiLib';


export class Variable {
  pinUI = false;
  constructor(public name: string,
              public subSetName: string,
              public exp: any,
              public set: any,
              public get: any,
              public type: any,
              public displayName: string,
              public variableSet: VariableSet){
  }
  getRefName(){
    return this.variableSet.obj.name+'.'+this.subSetName+'.'+this.name;
  }
  getObj(){
    return this.variableSet.obj;
  }
}
export class VariableSet {
  enabled = false;
  variables = [];
  varSubSet = {};
  varSubSetTree = {};
  obj;
  scopeObj;
  variableDB;
  constructor(obj) {
    this.obj = obj;
  }
  setVariableDB(varDB) {
    this.variableDB = varDB;
  }
  findVariable(subSetName,name){
    return this.varSubSetTree[subSetName][name];
  }
  removeVariableById(id){
    this.variables.splice(id,1);
    return this.rebuildScope();
  }
  removeVariable(variable){
    let id = this.variableDB.varDB.indexOf(variable);
    let vid = this.variables.indexOf(variable);
    this.variables.splice(vid,1);
    this.variableDB.varDB.splice(id,1);
    return this.rebuildScope();
  }
  addVariable(name, setF, getF, subSetName, type = 'number', displayName = '', ) {
    const self = this;
    if (this.varSubSetTree[subSetName] && this.varSubSetTree[subSetName][name]) {
      return this.varSubSetTree[subSetName][name];
    }
    const variable = new Variable(name,subSetName,'',setF,getF,type,displayName,this);

    variable.set = function(x, broadcast = true) {
      if (broadcast && self.obj.WS.livePart.liveRTC) {
        self.obj.WS.livePart.liveRTC.sendVarUpdate(variable);
      }
      setF(x);
    };

    this.variables.push(variable);
    if (subSetName) {
      if (!this.varSubSet.hasOwnProperty(subSetName)) {
        this.varSubSet[subSetName] = [];
      }
      if (!this.varSubSetTree.hasOwnProperty(subSetName)) {
        this.varSubSetTree[subSetName] = {};
      }
      this.varSubSetTree[subSetName][name] = variable;
      this.varSubSet[subSetName].push(variable);
    }
    this.rebuildScope();
    return variable;
  }
  removeAll() {
    this.enabled = false;
    if (this.variableDB) {
      removeFromArr(this, this.variableDB.varDB);
    }
    this.variables = [];
    delete this.scopeObj;
    delete this.varSubSet;
  }
  setExp() {
    this.obj.setVariable();
  }
  rebuildScope() {
    this.scopeObj = {child:[],parent:null};
    this.obj.children.forEach(c => {
      this.scopeObj.child.push(
        this.variableDB?.scope[c.name]
      );
    });

    this.variables.forEach(v => {
      if (v.subSetName) {
        if (!this.scopeObj.hasOwnProperty(v.subSetName)) {
          this.scopeObj[v.subSetName] = {};
        }
        this.scopeObj[v.subSetName][v.name] = v.get();
      } else {
        this.scopeObj[v.name] = v.get();
      }
    });
    if (this.obj.parent){
      this.scopeObj.parent = this.variableDB?.scope[this.obj.parent.name];
    }
  }
}
export class VariableDB {
  WS;
  varDB = [];
  userVar = [];
  userVarObj = {};
  activeVar = [];
  scope = {};
  userVarPreset = [];
  userVarPresetSelected = {name: '', userVar: []};
  constructor(WS) {
    this.WS = WS;
  }
  updateUserVarPreset(name) {
    this.userVarPreset.forEach(v => {
      if (v.name === name) {
        v.userVar = JSON.parse(JSON.stringify(this.userVar));
      }
    });
  }
  addUserVarPreset(name) {
    this.userVarPreset.push({name:name, userVar: JSON.parse(JSON.stringify(this.userVar))});
  }
  loadUserVarPreset(name) {
    const self = this;
    this.userVarPreset.forEach(v => {
      if (v.name === name) {
        self.userVar = JSON.parse(JSON.stringify(v.userVar));
        self.userVarObj = {};
        self.userVar.forEach(v => {
          self.userVarObj[v.name] = v;
        });
        self.userVarPresetSelected = v;
        self.buildScope();
      }
    });
  }
  removeUserVarPreset(varPreset) {
    const self = this;
    this.userVarPreset.forEach(v => {
      if (v.name === name) {
        removeFromArr(v, this.userVarPreset);
      }
    });
  }
  addUserVar(name, type='number', data, n = -1) {
    if(!data){
      data = {min:0,max:100};
    }
    this.userVarObj[name] = {
      name, exp: 1, type, data
    };
    // add new item to array at specific position
    if (n >= 0) {
      this.userVar.splice(n, 0, this.userVarObj[name]);
    } else {
      this.userVar.push(this.userVarObj[name]);
    }
    this.buildScope();
  }
  removeUserVar(v) {
    removeFromArr(v, this.userVar);
    this.buildScope();
  }
  changeObjName(newName,oldName){
    const self = this;
    console.log(self.activeVar);
    self.activeVar.forEach(v => {
      if(v.exp){
        v.exp = v.exp.replaceAll(oldName+'.', newName+'.');
      }
    });
  }
  getExp(subSetName,name,obj){
    if(this.scope[name][subSetName].exp){
      return this.scope[name][subSetName].exp;
    } else {
      return this.scope[name][subSetName].get();
    }
  }
  initScopeObj(){
    return {
      r: function(n){
        return math.random(n);
      },
      rInt: function(n){
        return math.floor(math.random(n));
      },
      text: function(n){
        return n;
      },
    };
  }
  buildScope() {
    const self = this;
    // find all variable obj's
    this.varDB = [];
    this.scope = this.initScopeObj();
    this.userVar.forEach(v => {
      const name = v.name;
      self.scope[name] = v.exp;
    });
    for (let key in this.WS.objects) {
      if (this.WS.objects[key].variableSet.enabled === true) {
        this.WS.objects[key].variableSet.setVariableDB(this);
        this.varDB.push(this.WS.objects[key].variableSet);
      }
    }
    this.varDB.forEach(v => {
      let name = v.obj.name;
      if (!v.obj.name || v.obj.name === '') {
        name = v.obj.uid;
      }
      v.rebuildScope();
      self.scope[name] = v.scopeObj;
    });

  }
  evaluate() {
    const self = this;
    self.buildScope();
    let updated = false;
    self.activeVar = [];
    self.varDB.forEach(v => {
      v.variables.forEach(val => {
        let root = val.name.split('.');
        let evalVar;
        let opt = val.variableSet.obj.save().opt;
        let p = {};
        let c = [];
        if (val.variableSet.obj.parent){
          p = self.scope[val.variableSet.obj.parent.name];
        }
        if (val.variableSet.obj.children.length){
          val.variableSet.obj.children.forEach( child => {
            c.push(self.scope[child.name]);
          })

        }
        //console.log({...self.scope, opt, parent:p, child:c});
        //console.log(opt);
        if (val.exp !== '') {
          try {
            evalVar = math.evaluate(val.exp, {...self.scope, opt, parent:p, child:c, _ : val.variableSet.scopeObj});
            evalVar = round(evalVar,2);
          } catch (e) {
            console.log(e);
          }

          if (self.activeVar.indexOf(val) < 0) {
            self.activeVar.push(val);
          }
          if (evalVar !== val.get()) {
            val.set(evalVar);
            self.buildScope();
          }
        } else if (val.exp === '' && (root[0] === 'rot' || root[0] === 'pos') && val.get() !== 0) {
          val.set(0);
          self.buildScope();
          //self.boxTree.initBase(); not sure what this is for.
          updated = true;
        }
      });
    });
    return updated;
  }
}
