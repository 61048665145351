import {DbService} from '../services/db.service';

export class GCodeSender{

  sendFile = false;
  saveFile = true;
  url = "192.168.1.184";
  fileName = 'live';
  status = "";
  duet;
  db

  constructor(db:DbService){
    this.db = db;
  }

  handleDuet(type,res){
    console.log(type,res);
    if(type == 'rr_upload'){
      if(res.err == 0){
        this.status = 'uploaded.';
      }
    }
    if(type == 'rr_status'){
      this.duet = res;
      if(res.err == 0){
        this.status = 'reloaded.';
      }
    }
    if(type == 'rr_reply'){
      console.log(res);
      if(res.err == 0){
        this.status = 'reply.';
      }
    }
  }
  Gcode(code){
    this.db.sendRequest('rr_gcode',{},encodeURIComponent(this.url),code,this);
    this.updateDuet();
  }
  updateDuet(){
    this.db.sendRequest('rr_status',{},this.url,'1',this);
  }
  updateDetailed(){
    this.db.sendRequest('rr_status',{},this.url,'2',this);
  }
  playFile(name){
    this.db.sendRequest('rr_status',{},this.url,this.fileName,this);
  }
  loginDuet(){
    this.db.sendRequest('login',{},this.url,this.fileName,this);
  }
}
