declare const require: any;
import { Component } from '@angular/core';
import {IconResolverService} from './services/icon-resolver.service';
import {RootService} from './services/root.service';
import {DbService} from './services/db.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Invent Inside';
  constructor(public icons: IconResolverService, public root: RootService, public db: DbService) {
    this.icons.init();
  }
  ngOnInit() {
  }
}
