import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {ContentDocument, ContentEmbeddable, ContentOnCreate} from '../../reactive-content';
import {DomSanitizer} from '@angular/platform-browser';
declare var require: any;
const _ = require('lodash');

@Component({
  selector: 'rc-feature',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <div class="paragraph-body" [ngStyle]="{'background-image': 'url('+ root.img +')'}">
      <div class="paragraph-header" *ngIf="root.heading && !edit()" [innerHTML]="root.heading | marked"></div>
      <div *ngIf="edit()">
        <input class="paragraph-header"  type="text" [(ngModel)]="root.heading">
      </div>

      <span *ngIf="edit() && addAttachment">
        img:<input type="text" [(ngModel)]="root.img">
        <app-image-uploader (selectedFile)="root.img = $event.downloadURL" [size]="[200,200]"></app-image-uploader>
        partLink:<input type="text" [(ngModel)]="root.partLink">
        partCategory:<input type="text" [(ngModel)]="root.partCategory">
        partViewHeight:<input type="text" [(ngModel)]="root.height">
      </span>
      <button *ngIf="root.partLink" (click)="openPartWindow('blank', root.partLink)">
        <mat-icon>open_in_new</mat-icon>Open Part
      </button>
      <div class="paragraph-subheader" *ngIf="root.subHeading && !edit()" [innerHTML]="root.subHeading | marked"></div>

      <div *ngIf="edit()">
        <input type="text" [(ngModel)]="root.subHeading">
        <button (click)="addAttachment = !addAttachment" *ngIf="edit()">+@</button>
      </div>

      <div *ngIf="root.text && !edit()" [innerHTML]="root.text | marked" class="paragraph-text"></div>
      <mat-form-field class="paragraph-text" *ngIf="edit()">
        <textarea matInput placeholder="Text" [(ngModel)]="root.text"></textarea>
      </mat-form-field>
      <div *ngIf="root.partCategory">
        <div *ngIf="partView && root.height">
          <iframe  width="100%"  [style.height]="root.height + 'px'"  [src]="partURL"></iframe>
        </div>
        <app-parts [mode]="'feature'" [filterCat]="root.partCategory" (pSelected)="selectedPart($event)"></app-parts>
      </div>
    </div>
  `
})
export class FeatureComponent implements OnInit, ContentOnCreate {
  content;
  root;
  addAttachment = false;
  localEdit = false;
  partView = '';
  partURL;
  constructor(public router: Router, public dom: DomSanitizer) { }

  ngOnInit() {
  }
  selectedPart(e) {
    this.partURL = this.dom.bypassSecurityTrustResourceUrl(
      'https://inventinside.com/editor/'+e.id+'/'+this.root.viewType
    );
    console.log('selectedPart', e);
    this.partView = e.id;
  }

  edit(){
    if(this.localEdit && this.content.edit){
      return true;
    } else {
      return false;
    }
  }

  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    if (!this.root.viewType) {
      this.root.viewType = 'small';
    }
    if (!this.root.partCategory) {
      this.root.partCategory = 'art';
    }
  }
  openPartWindow(mode, id) {
    let rootURL = window.location.hostname;
    if (mode == 'same') {
      this.router.navigate(['/editor', id]);
    } else if (mode == 'popup') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'split') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id+'/split','popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'blank') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'_blank','scrollbars=no,resizable=yes');
    } else if (mode == 'blank') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'_blank','scrollbars=no,resizable=yes');
    }
  }
}
