import {v3} from '.././iiLib';
import * as THREE from 'three';
class CsgPhyisicsManager {
    RAPIER: any;
    world;
    eventQueue;
    WS;
    gravity = {x:0, y:0, z:-9.81};
    pOBJ: any;
    physics_enable = true;
    physics_enable_ui = false;
    phyics_ready = false;
    objSync = [];
    groundColliderDesc = [];
    animation_enable = true;
    animation;
    animationRecord = false;
    timeNow = 0;
    timeToStart = 0;
    timeStart = 0;
    timeEnd = 1000;
    objAnimation = {};
    aF = {times: [], pos: [], vel: [], acc: [], rot: [], rotVel: [], rotAcc: []};
    jointSet;
    pJoint = [];
    contacts = [];
    ground_size = {x:500, y:500, z:100};
    ground_thickness = 10;
    cage_height = 50;
    initOpt;

    sizeFactor = 100;
    resetAnimation() {
      this.timeNow = 0;
      this.timeStart = 0;
      this.objAnimation = {};
      this.contacts = [];
      this.aF = {times: [], pos: [], vel: [], acc: [], rot: [], rotVel: [], rotAcc: []};
      this.animationRecord = false;
    }
    genAnimation() {
      const self = this;
      for (const objUid in self.objAnimation) {
        const pKF = new THREE.VectorKeyframeTrack( objUid + '.position', self.objAnimation[objUid].time, self.objAnimation[objUid].pos );
        const rKF = new THREE.QuaternionKeyframeTrack( objUid + '.quaternion', self.objAnimation[objUid].time, self.objAnimation[objUid].rot );
        const animSet = new THREE.AnimationClip( 'a', -1, [ pKF, rKF ] );
        const editorObj =  self.WS.getObjectByUid(objUid);
        editorObj.Gpa['animations'] = [animSet];
        editorObj.Gpa.name = objUid;
        editorObj.updateAnimationShape();
      }
    }
    startAnimation() {
      const self = this;
      self.animationRecord = true;
      self.timeStart = new Date().getTime();
    }
    constructor(WS) {
        this.WS = WS;
        this.initEngine();
    }
    initEngine() {
        const self = this;
        import('@dimforge/rapier3d').then(RAPIER => {
            // Use the RAPIER module here.
            self.objSync = [];
            self.pJoint = [];
            self.world = new RAPIER.World(self.gravity);
            self.eventQueue = new RAPIER.EventQueue(true);
            self.RAPIER = RAPIER;
            self.initBaseObjects();
        });
    }
    initBaseObjects() {
        const self = this;
        if (self.RAPIER) { // setup physics body

            self.phyics_ready = false;
            // Create the ground
            self.groundColliderDesc.push(self.RAPIER.ColliderDesc.cuboid(self.ground_size.x, self.ground_size.y, self.ground_thickness));
            self.groundColliderDesc[0].setTranslation(0, 0, -self.ground_thickness);
            self.world.createCollider(self.groundColliderDesc[0]);

            self.groundColliderDesc.push(self.RAPIER.ColliderDesc.cuboid(self.ground_size.y, self.ground_thickness, self.cage_height));
            self.groundColliderDesc[1].setTranslation(0, self.ground_size.y / 2, 0);
            self.world.createCollider(self.groundColliderDesc[1]);

            self.groundColliderDesc.push(self.RAPIER.ColliderDesc.cuboid(self.ground_size.x, self.ground_thickness, self.cage_height));
            self.groundColliderDesc[2].setTranslation(0, -self.ground_size.y / 2, 0);
            self.world.createCollider(self.groundColliderDesc[2]);

            self.groundColliderDesc.push(self.RAPIER.ColliderDesc.cuboid( self.ground_thickness, self.ground_size.y, self.cage_height));
            self.groundColliderDesc[3].setTranslation(self.ground_size.x / 2, 0, 0);
            self.world.createCollider(self.groundColliderDesc[3]);

            self.groundColliderDesc.push(self.RAPIER.ColliderDesc.cuboid( self.ground_thickness, self.ground_size.y, self.cage_height));
            self.groundColliderDesc[4].setTranslation(-self.ground_size.x / 2, 0, 0);
            self.world.createCollider(self.groundColliderDesc[4]);

            self.phyics_ready = true;
          }
    }
    save() { // looks unused
      const self = this;
      const saveSet = [];
      self.objSync.forEach(objSet => {
        saveSet.push({ obj: objSet.obj.uid, rb: objSet.rb.handle });
      });

      return {
        objSync: saveSet,
        gravity: self.gravity,
        en_ui: self.physics_enable_ui,
      };
    }

    load(opt) {
      const self = this;
      self.initOpt = opt;
      opt.objSync.forEach(objSet => {
        const obj = self.WS.getObjectByUid(objSet.obj);
        self.addObject(obj, true);
      });
      if (opt.gravity) {
        this.gravity.z = opt.gravity.z;
      }
      if (opt.en_ui) {
        this.physics_enable_ui = opt.en_ui;
      }
      self.physicsUpdate();
    }

    renderStep(deltaTime) {
      const self = this;
      self.world.step(self.eventQueue);
      self.eventQueue.drainContactForceEvents(event => {
        if ( self.animationRecord && (self.timeToStart < self.timeNow) ) {
          const handle1 = event.collider1(); // Handle of the first collider involved in the event.
          const handle2 = event.collider2(); // Handle of the second collider involved in the event.
          const objA = findClosest(handle1, self.objSync);
          const objB = findClosest(handle2, self.objSync);

          if ((handle1 > 0 && handle2 > 0)) {
            const force = event.totalForce();
            if (self.contacts.length > 0) {
              if (Math.abs(findClosestForce(event.totalForceMagnitude(), self.contacts).totalForceMagnitude - event.totalForceMagnitude()) > 5) {
                self.contacts.push({
                  time: self.timeNow / 1000, a: objA.uid , b: objB.uid,
                  totalForce: {x: force.x, y: force.y, z: force.z}, totalForceMagnitude: event.totalForceMagnitude()
                });
              }
            } else {
              self.contacts.push({
                time: self.timeNow / 1000, a: objA.uid , b: objB.uid,
                totalForce: {x: force.x, y: force.y, z: force.z}, totalForceMagnitude: event.totalForceMagnitude()
              });
            }
          }
        }
      });

      if (self.animationRecord) {
            self.timeNow = new Date().getTime() - self.timeStart;
            if (self.timeNow > self.timeEnd) {
              self.animationRecord = false;
              if (self.animation_enable) {
                self.genAnimation();
              }
            }
      }

        // Get and print the rigid-body's position.
      self.objSync.forEach( s => {
        const p = s.rb.translation();
        const p2 = v3(p.x, p.y, p.z);
        const r = s.rb.rotation();
        const r2 = new THREE.Quaternion(r.x, r.y, r.z, r.w);
        const linvel = s.rb.linvel();
        const angvel = s.rb.angvel();
        if (self.animationRecord && (self.timeToStart < self.timeNow)) {
          if (self.objAnimation.hasOwnProperty(s.obj.uid)) {
            self.objAnimation[s.obj.uid]['time'].push(Number(self.timeNow) / 1000);
            self.objAnimation[s.obj.uid]['pos'].push(p2.x * self.sizeFactor);
            self.objAnimation[s.obj.uid]['pos'].push(p2.y * self.sizeFactor);
            self.objAnimation[s.obj.uid]['pos'].push(p2.z * self.sizeFactor);

            self.objAnimation[s.obj.uid]['rot'].push(r2.x);
            self.objAnimation[s.obj.uid]['rot'].push(r2.y);
            self.objAnimation[s.obj.uid]['rot'].push(r2.z);
            self.objAnimation[s.obj.uid]['rot'].push(r2.w);

            self.objAnimation[s.obj.uid]['angvel'].push(angvel.x);
            self.objAnimation[s.obj.uid]['angvel'].push(angvel.y);
            self.objAnimation[s.obj.uid]['angvel'].push(angvel.z);

            self.objAnimation[s.obj.uid]['linvel'].push(angvel.x);
            self.objAnimation[s.obj.uid]['linvel'].push(angvel.y);
            self.objAnimation[s.obj.uid]['linvel'].push(angvel.z);
          } else {
            self.objAnimation[s.obj.uid] = {
              time: [Number(self.timeNow) / 1000],
              pos: [p.x * self.sizeFactor, p.y * self.sizeFactor, p.z * self.sizeFactor],
              rot: [r.x, r.y, r.z, r.w],
              poc: [],
              maxForce: 0,
              angvel: [angvel.x, angvel.y, angvel.z],
              linvel: [linvel.x, linvel.y, linvel.z],
            };
          }
        }
        s.obj.Gp.quaternion.set(r.x, r.y, r.z, r.w);
        s.obj.Gp.position.copy(v3(p.x * self.sizeFactor, p.y * self.sizeFactor, p.z * self.sizeFactor));
      });
    }
    physicsGet() {
      const self = this;
      self.pOBJ = [];
      self.world.bodies.forEach( (body, i, arr) => {
        self.pOBJ.push(body);
      });
    }
    // UI set position function
    physicsSet() {
      const self = this;
      this.objSync.forEach( s => {
        const p = s.rb.translation();
        const r = s.rb.rotation();
        s.obj.G.quaternion.set(r.x, r.y, r.z, r.w);
        s.obj.G.position.copy(v3(p.x * self.sizeFactor, p.y * self.sizeFactor, p.z * self.sizeFactor));
        s.obj.G.visible = true;
      });
    }
    reset() {
      this.physics_enable = false;
      this.physicsGet();
      this.objSync.forEach( s => {
        this.world.removeRigidBody(s.rb);
      });
      this.objSync = [];
      this.world = new this.RAPIER.World(this.gravity);
      this.initBaseObjects();
      if (this.initOpt) {
        this.load(this.initOpt);
      }
      this.physicsUpdate();
    }
    physicsUpdate() {
      const self = this;
      const list = [];
      this.objSync.forEach( s => list.push(s) );

      list.forEach( s => {
          if (s.obj.genUID !== s.genUID) {
            const uid = s.obj.uid;
            this.physicsRemoveObj(this.WS.getObjectByUid(uid));
            const objRb = this.addObject(this.WS.getObjectByUid(uid));
            this.setOpt(this.WS.getObjectByUid(uid));
          } else if (s.obj.genUID && s.rb.handle > -1) {
            const p = s.obj.G.position;
            const q = s.obj.G.quaternion;
            s.rb.resetForces();
            s.rb.resetTorques();
            this.setOpt(s.obj);
            s.rb.setLinvel({ x: 0, y: 0, z: 0 }, true);
            s.rb.setAngvel({ x: 0, y: 0, z: 0 }, true);
            s.rb.setRotation({ x: q.x, y: q.y, z: q.z, w: q.w }, true);
            s.rb.setTranslation({ x: p.x / self.sizeFactor, y: p.y / self.sizeFactor, z: p.z / self.sizeFactor }, true);
          }
      });
      list.forEach( s => {
        if (s.obj.genUID !== s.genUID) {
          const uid = s.obj.uid;
          const obj = this.WS.getObjectByUid(uid);

          // joint initailization
          obj.phyOpt.jointSet.forEach( j => {
            const foundJointObj = self.objSync.find( s => s.obj.uid === j.rootUID);
            let joint;
            if (j.type === 'revolute') {
              const params = self.RAPIER.JointData.revolute(
                {x: j.jointPositionA.x / (self.sizeFactor), y: j.jointPositionA.y / (self.sizeFactor), z: j.jointPositionA.z / (self.sizeFactor)},
                {x: j.jointPositionB.x / (self.sizeFactor), y: j.jointPositionB.y / (self.sizeFactor), z: j.jointPositionB.z / (self.sizeFactor)},
                j.axis
              );
              if (params.limitsEnabled) {
                params.limitsEnabled = j.limitsEnabled;
                params.limits = j.limits;
              }
              if (foundJointObj) {
                joint = self.world.createImpulseJoint(params, obj.physics.rb, foundJointObj.rb);
              }
            }
            if (j.type === 'prismatic') {
              const params = self.RAPIER.JointData.prismatic(
                {x: j.jointPositionA.x / (self.sizeFactor), y: j.jointPositionA.y / (self.sizeFactor), z: j.jointPositionA.z / (self.sizeFactor)},
                {x: j.jointPositionB.x / (self.sizeFactor), y: j.jointPositionB.y / (self.sizeFactor), z: j.jointPositionB.z / (self.sizeFactor)},
                j.axis
              );
              if (params.limitsEnabled) {
                params.limitsEnabled = j.limitsEnabled;
                params.limits = j.limits;
              }
              if (foundJointObj) {
                joint = self.world.createImpulseJoint(params, obj.physics.rb, foundJointObj.rb);
              }
            }
            if (j.type === 'spherical') {
              const params = self.RAPIER.JointData.spherical(
                {x: j.jointPositionA.x / (self.sizeFactor), y: j.jointPositionA.y / (self.sizeFactor), z: j.jointPositionA.z / (self.sizeFactor)},
                {x: j.jointPositionB.x / (self.sizeFactor), y: j.jointPositionB.y / (self.sizeFactor), z: j.jointPositionB.z / (self.sizeFactor)},
              );
              if (foundJointObj) {
                joint = self.world.createImpulseJoint(params, obj.physics.rb, foundJointObj.rb);
              }
            }

          });
        }
      });
      this.physics_enable_ui = true;
      if (this.animation_enable) {
        this.resetAnimation();
        this.startAnimation();
      }
    }
    physicsRemoveObj(obj, update= false) {
        let id = -1;
        this.objSync.forEach( (s, i, arr) => {
          if (s.obj.uid === obj.uid && s.rb) {
            this.physicsRemoveJoint(s.rb);
            this.world.removeRigidBody(s.rb);
            obj.physics = undefined;
            id = i;
          } else {
            if (s.obj.uid === obj.uid) {
              obj.physics = undefined;
            }
          }
        });
        if (id > -1) {
          obj.updatePhysicsShape();
          this.objSync.splice(id, 1);
        }

        if ( update ) {
        } else {
          this.physicsGet();
          obj._update();
        }

    }
    // Add all children to physics
    physicsAddObjChild(obj, add= true) {
        obj.loopChildren( c => {
            this.addObject(c, true);
        });
    }
    // Add clone of object above to editor/physics
    physicsAddObjNew(obj, add= true) {
          const newObj = obj.copyTo(obj.parent, false);
          this.addObject(newObj);
          newObj.setPos(obj.G.position.clone().add(v3(0, 0, obj.x * 1.5)));
          newObj.click();
    }
    // Add MULTIPLES OF clone of object to editor/physics
    addObjNew(obj, dirStep, n) {
      n = [Number(n[0]), Number(n[1]), Number(n[2])];
      for (let i1 = 1; i1 < n[0] + 1; i1++) {
        for (let i2 = 1; i2 < n[1] + 1; i2++) {
          for (let i3 = 1; i3 < n[2] + 1; i3++) {
            const newObj = obj.copyTo(obj.parent, false);
            newObj.setPos(obj.G.position.clone().add(v3(dirStep[0] * i1, dirStep[1] * i2, dirStep[2] * i3)));
            this.addObject(newObj);
          }
        }
      }
      this.physicsGet();
    }
    checkJoints(obj) { // RigidBody - obj
      const a = [];
      const b = [];
      const self = this;
      //stub
    }
    setOpt(obj) { // csg obj
      const dataObj = this.objSync.find( s => s.obj.uid === obj.uid);
      if (dataObj && obj.physics) {
        if (obj.phyOpt) {
          dataObj.rb.setEnabledTranslations(obj.phyOpt.lockR[0], obj.phyOpt.lockR[1], obj.phyOpt.lockR[2], true);
          dataObj.rb.setEnabledTranslations(obj.phyOpt.lockT[0], obj.phyOpt.lockT[1], obj.phyOpt.lockT[2], true);
        }
        obj.phyOpt.initSet?.forEach( action => {
          if (action.type === 'force') {
            dataObj.rb.addForce({x: action.value.x, y: action.value.y, z: action.value.z}, true);
          }
          if (action.type === 'torque') {
            dataObj.rb.addTorque({x: action.value.x, y: action.value.y, z: action.value.z}, true);
          }
        });
      }
    }
    addObject(obj, add= false, saveOpt= {}, mesh= false) { // CSGObject - obj
      const self = this;
      const found = self.objSync.find( s => s.obj.uid === obj.uid);
      if ( found ) {
        this.physicsRemoveObj(obj);
      }
      const p = obj.G.position;
      const q = obj.G.quaternion;
      let rigidBody;
      let rigidBodyDesc;
      let collider;
      let colliderDesc;

      if (obj.phyOpt.type === 'dynamic') {
        rigidBodyDesc = self.RAPIER.RigidBodyDesc.dynamic();
      } else if (obj.phyOpt.type === 'static') {
        rigidBodyDesc = self.RAPIER.RigidBodyDesc.fixed();
      } else if (obj.phyOpt.type === 'position') {
        rigidBodyDesc = self.RAPIER.RigidBodyDesc.kinematicPositionBased();
      } else if (obj.phyOpt.type === 'velocity') {
        rigidBodyDesc = self.RAPIER.RigidBodyDesc.kinematicVelocityBased();
      } else {
        rigidBodyDesc = self.RAPIER.RigidBodyDesc.fixed();
      }
      rigidBody = self.world.createRigidBody(rigidBodyDesc, null, 0);
      if (obj.phyOpt.mesh === 'tri_mesh' || obj.phyOpt.mesh === 'convex_mesh' || obj.phyOpt.mesh === 'convex_hull') {
        const vert = [];
        const ind = [];

        obj.csgOutput.geometry.vertices.forEach( v => {
          vert.push(v.x / self.sizeFactor);
          vert.push(v.y / self.sizeFactor);
          vert.push(v.z / self.sizeFactor);
        });
        obj.csgOutput.geometry.faces.forEach( f => {
          ind.push(f.a);
          ind.push(f.b);
          ind.push(f.c);
        });
        const vertices = new Float32Array(vert);
        const indiceis = new Uint32Array(ind);

        if (obj.phyOpt.mesh === 'tri_mesh') {
          colliderDesc = self.RAPIER.ColliderDesc.trimesh(vertices, indiceis);
        }
        if (obj.phyOpt.mesh === 'convex_mesh') {
          colliderDesc = self.RAPIER.ColliderDesc.convexMesh(vertices, indiceis);
        }
        if (obj.phyOpt.mesh === 'convex_hull') {
          colliderDesc = self.RAPIER.ColliderDesc.convexHull(vertices);
        }

    } else if (obj.phyOpt.mesh === 'solid') {
          if (obj.csgType === 'box') {
            // Create a cuboid collider attached to the dynamic rigidBody.
            colliderDesc = self.RAPIER.ColliderDesc.cuboid(obj.x / (2 * self.sizeFactor), obj.y / (2 * self.sizeFactor), obj.z / (2 * self.sizeFactor));
          }
          if (obj.csgType === 'cylinder') {
            const q2 = (new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, 0)));
            // Create a cuboid collider attached to the dynamic rigidBody.
            colliderDesc = self.RAPIER.ColliderDesc.cylinder(obj.z / (2 * self.sizeFactor), obj.x / (2 * self.sizeFactor));
            colliderDesc.setRotation({ x: q2.x, y: q2.y, z: q2.z, w: q2.w });
          }
          if (obj.csgType === 'toroid') {
            const q2 = (new THREE.Quaternion().setFromEuler(new THREE.Euler(Math.PI / 2, 0, 0)));
            // Create a cuboid collider attached to the dynamic rigidBody.
            colliderDesc = self.RAPIER.ColliderDesc.cylinder(obj.y / (2 * self.sizeFactor), (obj.y + obj.x / 2) / self.sizeFactor);
            colliderDesc.setRotation({ x: q2.x, y: q2.y, z: q2.z, w: q2.w });
          }
          if (obj.csgType === 'sphere') {
            // Create a cuboid collider attached to the dynamic rigidBody.
            colliderDesc = self.RAPIER.ColliderDesc.ball(obj.x / (2 * self.sizeFactor));
          }
          if (obj.csgType === 'union') {
            obj.csgOutput.geometry.computeBoundingBox();
            const bb = obj.csgOutput.geometry.boundingBox;
            const size = bb.getSize(new THREE.Vector3());
            const center = bb.max.clone().add(bb.min).multiplyScalar(-0.5).add(obj.G.position).multiplyScalar(-self.sizeFactor);
            // Create a dynamic rigid-body.
            // Create a cuboid collider attached to the dynamic rigidBody.
            colliderDesc = self.RAPIER.ColliderDesc.cuboid(size.x / (2 * self.sizeFactor), size.y / (2 * self.sizeFactor), size.z / (2 * self.sizeFactor));
            colliderDesc.setTranslation(center.x, center.y, center.z);
          }
      }
      if (colliderDesc.setDensity) {
          colliderDesc.setDensity(obj.phyOpt.density || 1);
      }
      if (colliderDesc.setFriction) {
          colliderDesc.setFriction(obj.phyOpt.friction || 0.5);
      }
      if (colliderDesc.setRestitution) {
          colliderDesc.setRestitution(obj.phyOpt.restitution || 0.5);
      }
      collider = self.world.createCollider(colliderDesc, rigidBody);
      collider.setActiveCollisionTypes(self.RAPIER.ActiveCollisionTypes.DEFAULT);
      collider.setActiveEvents(self.RAPIER.ActiveEvents.COLLISION_EVENTS);
      collider.setActiveEvents(self.RAPIER.ActiveEvents.CONTACT_FORCE_EVENTS);

      obj.physics = {
        rb: rigidBody, obj: obj, collider: collider, handle: rigidBody.handle, genUID: obj.genUID, uid: obj.uid, saveOpt: {}
      };

      rigidBody.setEnabledRotations(obj.phyOpt.lockR[0], obj.phyOpt.lockR[1], obj.phyOpt.lockR[2], true);
      rigidBody.setEnabledTranslations(obj.phyOpt.lockT[0], obj.phyOpt.lockT[1], obj.phyOpt.lockT[2], true);
      rigidBody.enableCcd(true);
      rigidBody.setTranslation( {x: p.x / (self.sizeFactor), y: p.y / (self.sizeFactor), z: p.z / (self.sizeFactor)} );
      rigidBody.setRotation( { x: q.x, y: q.y, z: q.z, w: q.w }, true );



      self.objSync.push(obj.physics);

      obj.updatePhysicsShape();
      obj.I.send('select');
      obj.I.send('view');
      this.physicsGet();
      obj._update();

      return rigidBody;
    }
    // BROKEN
    addPhysicsJoint(type, a, b, posA, posB) {//RigidBody - obj  -- broken
      //stub
    }
    physicsRemoveJoint(joint) {
      this.world.removeImpulseJoint(joint);
      this.pJoint.splice(this.pJoint.indexOf(joint), 1);
      this.physicsGet();
    }
    physicsAddChain(root, link, n, sp) {
        let last = root.physics.rb;
        root.physics.opt.lockR = true;
        root.physics.opt.lockT = true;
        for (let i = 0; i < n; i++) {
          const newObj = link.copyTo(link.parent, false);
          newObj.G.position.copy(root.G.position.clone().sub(v3(0, 0, sp * (i + 1))));
          const rb = this.addObject(newObj);
          this.addPhysicsJoint('spherical', last, rb, {x: 0, y: 0, z: sp / 2}, {x: 0, y: 0, z: -sp / 2});
          last = rb;
        }
        this.physicsGet();
      }
    physicsAddChain2(root, end, link, n, sp) {
        let last = root.rb;
        root.rb.lockTranslations(1);
        for (let i = 0; i < n; i++) {
          const newObj = link.obj.copyTo(link.obj.parent, false);
          newObj.G.position.copy(root.obj.G.position.clone().sub(v3(0, 0, sp * (i + 1))));
          const rb = this.addObject(newObj);
          this.addPhysicsJoint('spherical', last, rb, {x: 0, y: 0, z: sp / 2}, {x: 0, y: 0, z: -sp / 2});
          last = rb;
        }
        this.addPhysicsJoint('spherical', last, end.rb, {x: 0, y: 0, z: sp / 2}, {x: 0, y: 0, z: -sp / 2});
        end.obj.G.position.copy(root.obj.G.position.clone().sub(v3(0, 0, sp * (n + 1) )));
        end.rb.lockTranslations(1);
        this.physicsGet();
    }
    physicsOnSelected(rb) {
        const p = this.WS.selectedObj.G.position;
        const q = this.WS.selectedObj.G.quaternion;
        if (p) {
          this.objSync.push({ rb: rb, obj: this.WS.selectedObj });
          rb.setTranslation({ x: p.x, y: p.y, z: p.z }, true);
          rb.setRotation({ w: q.w, x: q.x, y: q.y, z: q.z }, true);
        }
      }
}
//compare numbers from given number and an array of objects where the number is given as a property named handle, find the closest match between two floats
function findClosest(num, arr) {
  if (num === 0) { return 0; }
  let minDiff = 10000000;
  let ans;
  let diff = 0;
  for ( let i = 0; i < arr.length; i++) {
    const m = arr[i].rb.handle;
    diff = Math.abs(num - m);
    if (diff < minDiff) {
      minDiff = diff;
      ans = arr[i];
    }
  }
  return ans;
}
function findClosestForce(num, arr) {
  let minDiff = 10000000;
  let ans;
  let diff = 0;
  for ( let i = 0; i < arr.length; i++) {
    const m = arr[i].totalForceMagnitude;
    diff = Math.abs(num - m);
    if (diff < minDiff) {
      minDiff = diff;
      ans = arr[i];
    }
  }
  return ans;
}

export { CsgPhyisicsManager };
