import { Component, OnInit, Input } from '@angular/core';
import { ContentOnCreate } from '../../reactive-content';

@Component({
  selector: 'rc-carousel',
  template: `
    <span *ngIf="content.edit" title="Edit cell content" (click)="localEdit = !localEdit">e</span>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let a of arr">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{a.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <img *ngIf="a.img" src="{{a.img}}" alt="first slide" [ngStyle]="{'background-image': 'url('+ a.img +')','height': a.height}">
        <ng-container *ngIf="content.edit && localEdit">
          <button (click)="removeTab(arr.indexOf(a))">x</button>
          <button (click)="addTab()">Add Tab</button>
          Name:<input type="text" [(ngModel)]="a.name">
          <mat-form-field class="paragraph-text">
            <textarea matInput placeholder="Text" [(ngModel)]="a.text">
            </textarea>
          </mat-form-field>
          img:<input type="text" [(ngModel)]="a.img">
          height:<input type="text" [(ngModel)]="a.height">
          <app-image-uploader (selectedFile)="arr[arr.indexOf(a)].img = $event.downloadURL" [size]="[400,400]"></app-image-uploader>
        </ng-container>
       <div class="paragraph-text" *ngIf="a.text && !localEdit" [innerHTML]="a.text | marked"></div>
      </mat-expansion-panel>
    </mat-accordion>`
})
export class CarouselComponent implements OnInit, ContentOnCreate {

  @Input() public arr: any[];

  content;
  localEdit = false;
  constructor() { }

  ngOnInit() {
  }

  addTab() {
    this.arr.push( {name: 'New Tab', text: '', img: '',height:''} );
  }
  removeTab(index) {
    this.arr.splice(index, 1);
  }
  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.arr = values.arr;
  }

}
