import { Inject, Injectable, InjectionToken, Provider } from '@angular/core';

import { CONTENT_BASE_URL } from './content';

@Injectable()
export class ContentSourceService {

  constructor(
    @Inject(CONTENT_BASE_URL) private baseUrl: string
  ) {}
}
