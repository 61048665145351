import {Injectable, OnDestroy} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, take, finalize, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import * as firebase from 'firebase/app';
import {dataURItoBlob, getremoveEmpty, removeEmpty} from '../iiLib';
import {RootService} from './root.service';

@Injectable({
  providedIn: 'root'
})
export class LivePartService implements OnDestroy {
  //nft
  data;
  opt;
  partID = null;
  userID = null;

  OB;//Part observable
  WS;

  snippetOB: Observable<any>;
  partList$;
  newPartId;
  loadingStaus = {data: false, actions: false};
  resetFlag = false;

  constructor(public fs: AngularFirestore,
              public fsStorage: AngularFireStorage,
              public storage: AngularFireStorage,
              private db: AngularFireDatabase) {

    this.snippetOB = this.db.list('codeSnippets').snapshotChanges();
    //add id to payload
    this.snippetOB = this.snippetOB.pipe(map(changes => {
      return changes.map(c => ({id: c.payload.key, ...c.payload.val()}));
    }
    ));
    this.getPartList();
  }

  async updatePart(id, data, opt,csgFiles = {}) {
    const self = this;
    let rootPartData = {};
    let fileName = [];
    let fileData = [];
    let fileBlob = [];
    for (let i=0; i < data.length; i++) {
      if(!rootPartData.hasOwnProperty(data[i].uid)){
        rootPartData[data[i].uid] = {};
      }
      if(csgFiles.hasOwnProperty(data[i].uid)){
        fileName.push(data[i].name+'_3dGoldCsg');
        fileData.push(data[i]);
        fileBlob.push(csgFiles[data[i].uid]);
      }
      rootPartData[data[i].uid] = data[i];
    }
    //console.log(fileName, fileBlob);
    if (!id) {
      id = this.fs.createId();
    }
    console.log(id);
    await this.updatePartData(id,data,opt,true);
    this.storageUploadCSG(csgFiles,id);
  }
  async updatePartData(partId,data,opt,update){
    let r;
    let saveData = {} as any;

    if (!opt.partInfo.objectDoc){
      opt.partInfo.objectDoc = {
        "_embedded": {
          "1": {
            "_type": "headline",
            "text": "Title"
          }
        },
        "_type": "page",
        "edit": true,
        "title": "Part Description Document"
      };
    }

    if(!opt && this.opt){
      opt = this.opt;
      if (!opt.hidden){
        opt.hidden = false;
      }
      if (!opt.public){
        opt.public = false;
      }
      saveData = {
        data,
        opt,
        hidden:opt.hidden,
        public:opt.public
      };
    } else if (opt){
      if (!opt.hidden){
        opt.hidden = false;
      }
      if (!opt.public){
        opt.public = false;
      }
      saveData = {
        data,
        opt,
        hidden:opt.hidden,
        public:opt.public
      };
    } else {
      saveData = {
        data,
        hidden:false,
        public:false
      };
    }

    saveData = {...saveData?.opt, ...saveData?.opt?.partInfo, ...saveData};//Make these searchable in db.
    console.log(saveData);
    window.localStorage.setItem('save', JSON.stringify(saveData));
    if (update && partId) {
      console.log("update:",partId,data,opt);
      r = this.fs.collection('p').doc(partId);
        //if(saveData.opt.owner == this.userID || saveData.opt.public){
          try {
             return await r.set(saveData,{merge:true});
          }  catch (e){
            console.log('failed update',e);
            try {
              return await r.update(saveData);
            }  catch (e){
              console.log('failed update 2',e);
              return 0;
            }
          }
        //}
    } else {
      r = this.fs.collection('p').doc(partId);
      return await r.set(saveData);
    }

  }
  loadLocal(){
    const d = JSON.parse(window.localStorage.getItem('save'));
    console.log(d);
    this.data = d['data'];//JSON.parse(pako.inflate(d['data'], { to: 'string' }));
    this.opt = d['opt'];

    this.WS.resetWS();
    this.WS.loadData(this.data, this.opt);
    this.loadingStaus.data = true;
  }

  getPart(id) {
    const self = this;
    this.resetLivePart();
    this.partID = id;
    const ref = this.fs.collection('p').doc(id);
    const store = ref.valueChanges();
    this.OB = {store};
    store.pipe(take(1)).subscribe(d => {
      if(d && d.hasOwnProperty('data') && d.hasOwnProperty('opt')){
        if(!this.loadingStaus.data){
          this.WS.resetWS();
          this.data = d['data'];
          this.opt = d['opt'];
          this.WS.loadData(this.data, this.opt);
          this.loadingStaus.data = true;
        }
      }
    });
    return this.OB;
  }
  resetLivePart() {
    this.loadingStaus.data = false;
    this.loadingStaus.actions = false;
    this.partID = null;
    this.data = {};
    this.opt = {};
  }
  deletePart(id) {
    const ref = this.fs.collection('p').doc(id);
    ref.delete();
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  getCodeSnippet(id) {
    return this.db.object(`codeSnippets/${id}`).valueChanges();
  }
  deleteCodeSnippet(id) {
    this.db.object(`codeSnippets/${id}`).remove().then(d => {
      console.log('removed snippet');
    });
  }
  createCodeSnippet(data) {
    const pushId = this.db.createPushId();
    const saveData = {
      ...data,
        id: pushId,
    };
    this.updateCodeSnippet(pushId, saveData, true);
  }
  updateCodeSnippet(id, data, update = true) {
    // Create the file metadata
    const r = this.db.object(`codeSnippets/${id}`);
    //Thumbnail Image
    var dataUrl = this.WS.renderer.domElement.toDataURL("image/png");
    let thumbnailImage = new File([dataURItoBlob(dataUrl)], 'img.png', {type: 'image/png'});

    let path = `snippetImg/${id}.png`;
    const ref = this.storage.ref(path);
    let task = ref.put(thumbnailImage);
    task.snapshotChanges().pipe(
      finalize(async () => {
        let downloadURL = await ref.getDownloadURL().toPromise();
        if (update) {
          data = removeEmpty(data);
          data['img'] = downloadURL;
          r.update(data).then();
        } else {
          data = removeEmpty(data);
          data['img'] = downloadURL;
          r.set(data).then();
        }
      })
    ).toPromise().then();
  }
  async uploadToSW(file,fileName){
      let url = 'https://api.shapeways.com/models/v1'; // data for specific
      //console.log('get model by Name');
      let p = await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function(event) {
          console.log(xhr.response);
          resolve(JSON.parse(xhr.response));
        };
        xhr.open('POST', url);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOjQ1MjU2NywiYXVkIjoxODEyLCJleHAiOjE2NTQxOTQ1OTQsInNjb3BlIjoiIn0.A37Mcoh8Xn5aZLHjlgXKjxzZu76n-zeRwOq2OYxhvwV34GQAIbNYXPieEfNgvLVmky-k3I3gljpLRQd2v8w3eA');

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function() {
          var base64data = reader.result;
          console.log(base64data);
          xhr.send(JSON.stringify({
            file: base64data,
            fileName: fileName,
            hasRightsToModel: 1,
            acceptTermsAndConditions: 1,
          }));

        }


      });
      return p;
  }

  //Storage
  storageUploadCSG(csgList,Uid) {

    //Thumbnail Image
    var dataUrl = this.WS.renderer.domElement.toDataURL("image/png");
    csgList['img'] = new File([dataURItoBlob(dataUrl)], 'img.png', {type: 'image/png'});
    let promiseResolved = 0;
    let URL = {};

    for (const id in csgList) {
      let path = ``;
      if(id == 'img'){
        path = `csgOBJ/${Uid}.png`;
      }else{
        path = `csgOBJ/${id}.csg`;
      }

      const blob = csgList[id];
      const ref = this.storage.ref(path);
      let task = ref.put(blob);
      task.snapshotChanges().pipe(
        finalize(async () => {
          let downloadURL = await ref.getDownloadURL().toPromise();
          URL[id]=downloadURL;
          promiseResolved = promiseResolved+1;
          if( promiseResolved == Object.keys(csgList).length ){
            if(Uid){
              let r = this.fs.collection('p').doc(Uid);
              return r.set({opt:{downloadURL:URL}},{merge: true});
            }
          }
        })
      ).subscribe();
    }

  }
  storageUploadFile(partId, file, saveData, update = true) {
    // Create the file metadata
    // The storage rootPath
    const path = `renderObj/${partId}.glb`;
    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    let task = ref.put(file);
    console.log('upload part');

    task.snapshotChanges().pipe(
      finalize(async () => {
        let downloadURL = await ref.getDownloadURL().toPromise();
        console.log('updatePart:' + downloadURL);
        saveData.opt['downloadURL'] = downloadURL;
        const r = this.fs.collection('p').doc(partId);
        if (update) {
          r.update(saveData);
        } else {
          r.set(saveData);
        }
      })
    ).subscribe();
  }


  ngOnDestroy() {
  }
  getPartList() {
    const self = this;
    let partCollection;
    partCollection = this.fs.collection<any>('p', ref => ref.where(
      'hidden', '==', false));  //
    // @ts-ignore
    self.partList$ = partCollection.snapshotChanges().pipe(shareReplay(1)).pipe(
      map((actions:any) => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      })));
    return self.partList$;
  }
  getPartListAll() {
    let self = this;
    let partCollection;
    partCollection = this.fs.collection<any>('p');  //
    // @ts-ignore
    this.partList$ = partCollection.snapshotChanges().pipe(shareReplay(1)).pipe(
      map((actions:any) => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      })));
    return self.partList$;
  }
}


