import { AfterViewInit,  NgZone, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  Machine,
  State,
  actions,
  assign,
  send,
  sendParent,
  interpret,
  spawn
} from 'xstate';
import {AuthService} from '../../services/auth.service';
declare const require: any;
declare var Module: any;
import {RootService} from '../../services/root.service'
import {WorkSpace} from '../3dEngine';
import {LivePartService} from '../../services/live-part.service';
import {ITreeOptions, KEYS, TREE_ACTIONS, TreeModel} from 'angular-tree-component';
import {ContextMenuComponent} from 'ngx-contextmenu';
import { DbService } from 'src/app/services/db.service';
import {array_move} from '../../iiLib';

class AppState {
  I;
  M;
  root;
  r;
  WS;
  public clipboard;
  clipboardCopyPRS;
  varCategory = [
    {name: 'newLineSeperator', value: 'nl', data: {}},
    {name: 'number', value: 'number', data: {}},
    {name: 'text', value: 'text', data: {}},
    {name: 'checkbox', value: 'checkbox', data: {}},

    {name: 'slider', value: 'slider', data: {min:0, max:100, step:1}},
    {name: 's/0-360x45', value: 'slider', data: {min:0, max:360, step:45}},
    {name: 's/0-180x15', value: 'slider', data: {min:0, max:180, step:15}},
    {name: 's/0-90x5', value: 'slider', data: {min:0, max:90, step:5}},
    {name: 's/0-100x10', value: 'slider', data: {min:0, max:100, step:10}},
    {name: 's/0-50x5', value: 'slider', data: {min:0, max:50, step:5}},
    {name: 's/0-25x1', value: 'slider', data: {min:0, max:25, step:1}},
    {name: 's/0-10x1', value: 'slider', data: {min:0, max:10, step:1}},
    {name: 's/0-5x0.5', value: 'slider', data: {min:0, max:5, step:0.5}},
    {name: 's/0-1x0.1', value: 'slider', data: {min:0, max:1, step:0.1}},
    {name: 'toggle/0-5 x1', value: 'toggle', data: {list:[0,1,2,3,4,5]}},
    {name: 't/0-25 x5', value: 'toggle', data: {list:[0,5,10,15,20,25]}},
    {name: 't/-10-10 x2', value: 'toggle', data: {list:[-10,-8,-6,-4,-2,0,2,4,6,8,10]}},
    {name: 't/0-50 x10', value: 'toggle', data: {list:[0,10,20,30,40,50]}},
    {name: 't/0-.5 x0.1', value: 'toggle', data: {list:[0.0,0.1,0.2,0.3,0.4,0.5]}},
    ];
  controls = {
    joystick: false,
  };
  ui = {
    grid: {x:false,y:false,z:true},
    axes: false,
    buildVolume: false,
    size: 100,
    snap:1,
    snapMode:{p:1,s:0.01,r:Math.PI/2},
    sensitivity:0.15,
    offset: {x:-50,y:-50,z:-50},
    divisions: 2,
    divisions2: 10,
    divisions3: 100,
    divisionsOn: {main:true,a:false,b:false},
    xyzviewSize:800,
    cpScale:0.25,
    LightSp:400,
    LightH:300,
    s: {
      view:{
        text1:1,
        text1EXP:0.5,
        text2:0.5,
        text2EXP:0.5,
        scale:0.5,
        scaleEXP:0.5,
        handle:0.5,
        handleSelected:0.5,
        handleOP:1,
      },
      edit:{
        text1:1,
        text1EXP:1.6,
        text2:1.6,
        text2EXP:1,
        scale:1.6,
        scaleEXP:1,
        handle:0.3,
        handleSelected:0.3,
        handleOP:0.9,
      },
      select:{
        text1:1.2,
        text1EXP:1.2,
        text2:1.2,
        text2EXP:1.2,
        scale:2,
        scaleEXP:1,
        handle:1,
        handleSelected:1,
        handleOP:1,
      }
    },
    plane: {
      zoom:{
        x: 200,
        y: 200,
        z: 200,
        selected:1.8,
        selectedGrid:50,
        selectedGridDiv:10,
        selectedLightSp:200,
        selectedLightH:100,
        panels:1.8,
        near: 0.1,
        far: 1000
      }
    }

  };
  panelPos = {
    live: {x:300,y:300}
  };
  setPanelPos(e){
    this.panelPos = e;
  }
  status = '';
  statusTime;
  statusElapsed;
  statusTotal;
  UIpanel;
  OBJpanel;
  public contextMenuActions = [
    {
      icon: `file_copy`,
      html: (item) => `Copy`,
      click: (item) => {
        this.clipboard = item;
        console.log('copy:',item);

      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg',
    },
    {
      icon: `filter_frames`,
      html: (item) => `Paste`,
      click: (item) => {
        console.log('paste:',item,this.clipboard);
        if(item.csgOp === true){
          this.clipboard.changeParent(item);
        } else if (item.parent) {
          this.clipboard.changeParent(item.parent);
        }
        this.clipboard = null;
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && this.clipboard,
    },
    {
      icon: `clear`,
      html: (item) => `Delete`,
      click: (item) => {
        item.remove(true);
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg',
    },
    {
      divider: true,
      visible: true,
    },
    {
      icon: `blur_linear`,
      html: (item) => `Clone Matrix`,
      click: (item) => {
        let fromObj = this.clipboard;
        let toObj = item;
        toObj.copyMatrix(fromObj);
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && this.clipboard,
    },
    {
      icon: `control_camera`,
      html: (item) => `Clone Position`,
      click: (item) => {
        let newP = item.parent;
        let list = [];
        let fromObj = this.clipboard;
        let toObj = item;
        toObj.copyMatrix(fromObj, ['p']);

      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && this.clipboard,
    },
    {
      icon: `360`,
      html: (item) => `Clone Rotation`,
      click: (item) => {
        let newP = item.parent;
        let list = [];
        let fromObj = this.clipboard;
        let toObj = item;
        toObj.copyMatrix(fromObj, ['r']);

      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && this.clipboard,
    },
    {
      icon: `photo_size_select_small`,
      html: (item) => `Clone Scale`,
      click: (item) => {
        let newP = item.parent;
        let list = [];
        let fromObj = this.clipboard;
        let toObj = item;
        toObj.copyMatrix(fromObj, ['s']);

      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && this.clipboard,
    },
    {
      divider: true,
      visible: true,
    },
    {
      icon: `gps_fixed`,
      html: (item) => `Center Op`,
      click: (item) => {
        let newP = item.parent;
        let list = [];
        item.children.forEach(c => {
          list.push(c);
        });
        let first = list[0];
        let childVarList = [];
        let myVarList = [];
        console.log(item);
        console.log(list[0].variableSet.varSubSetTree,item.variableSet.varSubSetTree);
        for(let a of ['p','r','s']){
          for(let b of ['x','y','z']){
            if(list[0].variableSet.varSubSetTree[a][b] && item.variableSet.varSubSetTree[a][b]){
              console.log(
                item.variableSet.varSubSetTree[a][b].exp, list[0].variableSet.varSubSetTree[a][b].exp
              );
              console.log(
                list[0].variableSet.varSubSetTree[a][b].get()
              );
              item.variableSet.varSubSetTree[a][b].exp = list[0].variableSet.varSubSetTree[a][b].exp;
              item.variableSet.varSubSetTree[a][b].set(list[0].variableSet.varSubSetTree[a][b].get());
              if (a === 's'){
                list[0].variableSet.varSubSetTree[a][b].set(1);
              } else {
                list[0].variableSet.varSubSetTree[a][b].set(0);
              }
            }
          }
        }
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && item.getObj().csgOp === true,
    },
    {
      icon: `format_indent_decrease`,
      html: (item) => `Clear Operation`,
      click: (item) => {
        item.clearOperation();
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg' && item.getObj().csgOp === true && item.getObj().parent,
    },
    {
      divider: true,
      visible: true,
    },
    {
      icon: `play_for_work`,
      html: (item) => `Export STL`,
      click: (item) => {
        item.exportSTL();
      },
      enabled: (item) => true,
      passive: (item) => false,
      visible: (item) => item.getObj().type === 'csg',
    },
    {
      divider: true,
      visible: true,
    },
  ];
  treeOpt: ITreeOptions = {
    displayField: 'name',
    isExpandedField: 'expanded',
    idField: 'id',
    hasChildrenField: 'children',
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          const obj = node.data.getObj();
        },
        click: (tree, node, $event) => {
          const obj = node.data.getObj();
          //console.log('click', tree, node, $event);
          //console.log('obj', obj);
        },
        contextMenu: (tree, node, $event) => {
          $event.preventDefault();
          const obj = node.data.getObj();
          //console.log('menu', tree, node, $event);
          //console.log('obj', obj);
        },
        drop: (tree, node, $event: any, { from, to }) => {
          //console.log('drop', tree, node, $event);
          const toObj = to.parent.data.getObj();
          const fromObj = from.data.getObj();

          //from
          if( fromObj.type === 'csg'){
            if(toObj.csgOp === true || toObj.type === 'plane'){
              if ($event.ctrlKey) {
                fromObj.copyTo(toObj, true);
              } else {
                fromObj.changeParent(toObj, true);
              }
            }
          }
          //console.log('from', fromObj);
          //console.log('to', toObj);

          // use from to get the dragged node.
          // use to.parent and to.index to get the drop location
          // use TREE_ACTIONS.MOVE_NODE to invoke the original action
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          node.expandAll();
        }
      }
    },
    nodeHeight: 20,
    allowDrag: (node) => {
      //console.log('Allow Drag:', node);
      if (node.data.type === 'csg' || node.data.type === 'shape2d') {
        return true;
      } else {
        return false;
      }
    },
    allowDrop: (element, { parent, index }) => {
      //console.log('Allow Drop:', element, { parent, index });
      //console.log(element, parent, index);
      return true;
    },
    useCheckbox: false,
    allowDragoverStyling: true,
    levelPadding: 10,
    useVirtualScroll: false,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
    scrollContainer: document.documentElement // HTML
  };
  constructor(root,ws,rservice) {
    this.root = root;
    this.r = rservice;
    this.WS = ws;
    //this.setUI();
  }
  init() {
    const self = this;
    this.M = Machine({
      // Machine identifier
      id: 'mode',

      // Initial state
      initial: 'view',

      // Local context for entire machine
      context: {
        UI: {
          modeLabel: 'View',
          modePanel: true,
          selectPanel: false,
          editPanel: false,
        },
        WS: {
          grid: true,
          axis: true,
          pointState: 'view'
        }
      },

      // State definitions
      states: {
        view: {
          entry: 'setView',
          on: {
            select: {
              target: 'select'
            },
            edit: {
              target: 'edit'
            },
            inspect: {
              target: 'inspect'
            }
          }
          /* ... */
        },
        select: {
          entry: 'setSelect',
          on: {
            view: {
              target: 'view'
            },
            edit: {
              target: 'edit'
            },
            inspect: {
              target: 'inspect'
            }
          }
          /* ... */
        },
        edit: {
          entry: 'setEdit',
          on: {
            view: {
              target: 'view'
            },
            select: {
              target: 'select'
            },
            inspect: {
              target: 'inspect'
            }
          }
          /* ... */
        },
        inspect: {
          entry: 'setInspect',
          on: {
            view: {
              target: 'view'
            },
            select: {
              target: 'select'
            },
            edit: {
              target: 'edit'
            }
          }
          /* ... */
        }
      }
    }, {
      actions: {
        // action implementation
        setView: (context, event) => {
          context.UI.modeLabel = 'View';
          context.UI.selectPanel = false;
          context.UI.editPanel = false;
          context.WS.grid = true;
          context.WS.axis = true;
          context.WS.pointState = 'view';
          //console.log(context, event);
          self.WS.setMode(event.type, context);
        },
        setSelect: (context, event) => {
          context.UI.modeLabel = 'Select';
          context.UI.selectPanel = true;
          context.UI.editPanel = false;
          context.WS.grid = true;
          context.WS.axis = true;
          context.WS.pointState = 'view';
          //console.log(context, event);
          self.WS.setMode(event.type, context);
        },
        setEdit: (context, event) => {
          context.UI.modeLabel = 'Edit';
          context.UI.selectPanel = false;
          context.UI.editPanel = true;
          context.WS.grid = true;
          context.WS.axis = true;
          context.WS.pointState = 'view';
          //console.log(context, event);
          self.WS.setMode(event.type, context);
        },
        setInspect: (context, event) => {
          context.UI.modeLabel = 'Inspect';
          context.UI.selectPanel = false;
          context.UI.editPanel = false;
          context.WS.grid = false;
          context.WS.axis = false;
          context.WS.pointState = 'hide';
          //console.log(context, event);
          self.WS.setMode(event.type, context);
        }
      },
      activities: {
        /* ... */
      },
      guards: {
        /* ... */
      },
      services: {
        /* ... */
      }
    });
    this.I = interpret(this.M);
    this.I.start();

    this.setUI();
    this.UIpanel = new snapPanel({ x: -260, y: 0 },{ x: 0, y: 0 });
    this.OBJpanel = new snapPanel({ x: 230, y: 0 },{ x: 0, y: 0 });
    //this.UIpanel.snapLeft();

  }
  setMode(mode) {
    this.I.send(mode);
  }
  setUI() {
    if (this.WS.uiMode.widgets) {
      this.WS?.grid?.setVisible(this.ui);
    } else {
      this.WS?.grid?.setVisible(false);
    }
  }
  setStatus(str, reset = false, measure = 'na') {
    if (reset) {
      this.statusTime = performance.now();
      this.status = str;
    } else {
      this.statusElapsed = Math.round( performance.now() - this.statusTime);
      this.statusTime = performance.now();
    }
    this.status = this.statusElapsed + 'ms ' + str;
    if (measure === 'start') {
      this.statusTotal = 0;
    } else if (measure === 'show') {
      this.statusTotal += this.statusElapsed ;
      this.status = `[${this.statusTotal}]+ ${this.status}`;
    }
  }
}

class snapPanel {
  draggable = true;
  useHandle = false;
  zIndex;
  zIndexMoving;
  preventDefaultEvent = false;
  trackPosition = true;
  position = { x: 0, y: 0 };
  posOpen = {x:50, y:50};
  posClosed = {x:10, y:10};
  open = true;
  positionA = { x: 300, y: 0 };
  positionB = { x: 0, y: 0 };

  snapArea = {max:200,min:0};
  constructor(positionA,positionB){
    this.positionA = positionA;
    this.positionB = positionB;
    this.init();
  }
  toggle(){
    const x  = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    const y = window.innerHeight|| document.documentElement.clientHeight||
      document.body.clientHeight;
    if(this.open){
      this.position = this.positionA;
    } else {
      this.position = this.positionB;
    }
    this.open = !this.open;
  }
  snapLeft(){
    const x  = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    const y = window.innerHeight|| document.documentElement.clientHeight||
      document.body.clientHeight;
    this.position = { x: 0, y: 0 };
  }
  init(){
    this.toggle();
  }
  movingOffset(e){
    //console.log(e,this);
  }
  onStart(e){
    //console.log(e,this);
  }
  onStop(e){
    //console.log(e,this);
  }
  onMoveEnd(e){
    console.log(e,this.position);
  }
}


@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls: ['./engine.component.css']
})
export class EngineComponent implements OnInit, AfterViewInit, OnChanges   {
  @ViewChild('partTree', {static: false}) pTree: TreeModel;
  @ViewChild('canvas', {static: false}) canvasRef: ElementRef;
  @ViewChild(ContextMenuComponent, {static: false}) public treeContextMenu: ContextMenuComponent;
  @Input('view') view = 'editor';
  @Input('uid') uid = '';
  code = 'hi';

  public WS: WorkSpace;
  public AppState;

  public partID = null;
  user;
  OB;
  mono;

  loaded = false;
  interactive = false;

  @HostListener('window:resize', ['$event'])
  resize(event) {
    this.WS.onResize(event);
  }

  constructor(public auth: AuthService,
    public livePart: LivePartService,
    public db: DbService,
    public root: RootService,
    private route: ActivatedRoute,
              private router: Router,
              private ngZone: NgZone) {
    auth.user$.subscribe(u => {
      if (u) {
        this.user = u;
        this.livePart.userID = this.user.uid;
        let id = this.route.snapshot.paramMap.get('id');
                let view = this.route.snapshot.paramMap.get('view');
                if(view){
                  this.view = view;
                }
        if(id) {
          this.loadLivePart(id);
        }
      }
    });
  }
  createNewPart(name, category, description) {
    let partInfo = this.root.PART.updatePartInfo(
      {
      name,
      category,
      description
      });
    let newPartInfo = this.WS.updateData(null, false, partInfo);

  }
  cloneObj(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  updatePart(partInfo) {
    if(partInfo){
      partInfo = this.root.PART.updatePartInfo(partInfo);
    }
    const saveData = this.WS.updateData(this.partID, true, partInfo);
    console.log(saveData);
  }
  deletePart(id) {
    this.livePart.deletePart(id);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('uid') && this.user && this.loaded) {
      this.livePart.resetLivePart();
      this.WS.resetWS();
      this.loadLivePart(this.uid);
    }
  }
  insertLivePart(id) {
    if (this.WS) {
      const newObj = this.WS.newCSG('construct', this.WS.basePlane, null);
      newObj.constructID = id;
      newObj.loadDB();
    }
  }
  getUserInfo(id) {
    return this.auth.getUser(id);
  }
  loadLivePart(id) {
    if (this.user) {
      this.partID = id;
      this.OB = this.livePart.getPart(id);
    } else {
      console.log('Please Log In');
    }
  }
  resetLivePart() {
    this.livePart.resetLivePart();
    this.WS.resetWS();
  }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    let view = this.route.snapshot.paramMap.get('view');
    if(view){
      this.view = view;
    }
  }
  ngAfterViewInit() {
    const self = this;
      this.WS = new WorkSpace(this.canvasRef.nativeElement, this.livePart);
      this.AppState = new AppState(this, this.WS, this.root);
      self.AppState.init();
      self.WS.AppState = self.AppState;
      self.WS.dbService = self.db;
      self.WS.init();
      this.ngZone.runOutsideAngular(() => {
        self.WS.startRenderingLoop();
        self.WS.onResize(new Event('yy'));
      });
      setTimeout( function() {
        self.loaded = true;
      }, 0);
  }

  ////utility functions
  copyToClip(text) {
    return copyTextToClipboard(text);
  }
  resetVector(v, opt){
    if(opt == 'p'){
      v.variableSet.varSubSet.p[0].set(0);
      v.variableSet.varSubSet.p[1].set(0);
      v.variableSet.varSubSet.p[2].set(0);
    }
    if(opt == 'r') {
      v.variableSet.varSubSet.r[0].set(0);
      v.variableSet.varSubSet.r[1].set(0);
      v.variableSet.varSubSet.r[2].set(0);
    }
    if(opt == 's') {
      v.variableSet.varSubSet.s[0].set(1);
      v.variableSet.varSubSet.s[1].set(1);
      v.variableSet.varSubSet.s[2].set(1);
    }
  }
  setVariable(v, num){
    v.set(Number(v.val)+Number(num));
  }


  shiftArrVar(arr,variable,direction){
    let id = arr.indexOf(variable);
    array_move(arr,id,id+direction);
  }
}


function save(blob, filename ) {
  const link = document.createElement( 'a' );
  link.style.display = 'none';
  link.href = URL.createObjectURL( blob );
  link.download = filename;
  document.body.appendChild( link );
  link.click();
}
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
function saveString( text, filename ) {
  save( new Blob( [ text ], { type: 'text/plain' } ), filename );
}
function saveArrayBuffer( buffer, filename ) { //anf GLB

  save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

}
