import {Router} from '@angular/router';

declare const require: any;
import {Injectable, OnDestroy} from '@angular/core';
import {AuthService} from './auth.service';
import {LivePartService} from './live-part.service';
import * as II from '../iiLib';


import Typeson from 'typeson';
import date from 'typeson-registry/types/date';
import error from 'typeson-registry/types/error';
import regexp from 'typeson-registry/types/regexp';
import typedArrays from 'typeson-registry/types/typed-arrays';
import pako from 'pako';
const TSON = new Typeson().register([
  date,
  error,
  regexp,
  typedArrays
]);

@Injectable({
  providedIn: 'root'
})
export class RootService implements OnDestroy {
  USER: UserService;
  UI: UiService;
  PART: PartService;
  AdminUID = ['ki0rtEqQFsfgRHJgk6uuw0nvPf03',''];
  _user$;

  constructor(public authService: AuthService,
              public livePartService: LivePartService,
              public router: Router) {
    this.UI = new UiService(this);
    this.PART = new PartService(this);
    this._user$ = this.authService.user$.subscribe(user => {
      if (user) {
        this.USER = new UserService(this, user);
      } else {
        authService.anonSignin();
        console.log('no User data [login], try to anon login');
      }
    });
  }
  checkAdmin( id=null ) {
    if (!id) {
      id = this.USER?.userData?.uid;
    }
    for (let i = 0; i < this.AdminUID.length; i++) {
      if ( this.AdminUID[i] === id ) {
        return true;
      }
    }
    return false;
  }
  openPartWindow(mode, id) {
    let rootURL = window.location.hostname;
    if ( mode == 'same' ) {
      this.router.navigate(['/editor', id]);
    } else if (mode == 'popup') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'split') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id+'/split','popup','width=600,height=600,scrollbars=no,resizable=yes,location=no\'');
    } else if (mode == 'blank') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id,'_blank','scrollbars=no,resizable=yes');
    } else if (mode == 'small') {
      window.open('http://'+rootURL+':'+window.location.port+'/editor/'+id+'/small','_blank','scrollbars=no,resizable=yes');
    }
  }
  ngOnDestroy(): void {
    if(this._user$){
      this._user$.unsubscribe();
    }
  }
}
export class UserService {
  userParts;
  userData = {name:'local', uid:'0'};
  constructor(public root, userData = null){
    if(userData){
      this.userData = userData;
    }
  }
  init(){

  }
  getUserID(){
    if(this.userData?.uid){
      return this.userData.uid;
    } else {
      return 'anonymous';
    }

  }
}
export class PartService {
  userParts;
  default_partInfo = { //default new part.
    name: '',
    category: '',
    description: "",
    owner: ""
  };
  constructor(public root) {
    this.default_partInfo = { //default new part.
      name: 'newPart_'+ II.ID(),
      category: 'new',
      description: "",
      owner: "",
    };
  }
  updatePartInfo(partInfo) {
    let newP;
    let time = {
      updated: Date.now(),
      updatedID: this.root.USER.getUserID()
    } as any;
    if (!partInfo.hasOwnProperty('created')) {
      time = {
        updated: Date.now(),
        updatedID: this.root.USER.getUserID(),
        created: Date.now(),
        createdID: this.root.USER.getUserID()
      };
    }

    if (partInfo == null) {//if nothing is passed, lets serve default.
      let time = {
        created: Date.now(),
        createdID: this.root.USER.getUserID()
      };
      newP = {...this.default_partInfo, ...time};
    } else {//or merge if anything is passed.
      newP = {...this.default_partInfo, ...partInfo, ...time};
    }
    return newP;
  }
  getObjects(objects) {
    let dataSet = [];
    for (let key in objects) {
      if (objects[key].type !== 'cp') {
        dataSet.push(objects[key].save(false,true));
      }
    }
    return dataSet;
  }
  getVariableDB(variableDB) {
    let varSet = [];
    variableDB.userVar.forEach( v => {
      varSet.push(v);
    });
    return varSet;
  }
  saveCompactCSG(OldCsg) {
    return JSON.stringify(TSON.encapsulate(OldCsg), null, 2);
  }
  getRoots(objects) {
    let csgFiles = {};
    for ( const id in objects) { //if its CSG and we have Compact Binary from worker.
      if (objects[id].type === 'csg' && objects[id].csgCB) {
        //we compress the data and store it as string.
        csgFiles[id] = new Blob([pako.deflate(saveCompactCSG(objects[id].csgCB), {to:'string', level:9})], {
          type: 'text/plain'
        });
      }
    }
    return csgFiles;
  }

}
export class UiService {
  pageItemTypes = [
    {name: 'feature', value: 'feature'},
    {name: 'image', value: 'image'},
    {name: 'headline', value: 'headline'},
    {name: 'paragraph', value: 'paragraph'},
    {name: 'tabs', value: 'tabs'},
    {name: 'carusel', value: 'carusel'},
    {name: 'grid', value: 'grid'},
    {name: 'slides', value: 'slides'},
    {name: 'view3d', value: 'view3d'}
  ];
  partCategory = [
    {c:0, name:'All', value:'', img:''},

    //{c:0, name:'_fastener', value:'_fastener', img:'apps'},
    //{c:0, name:'_bearing', value:'_bearing', img:'apps'},
    //{c:0, name:'_motor', value:'_motor', img:'apps'},
    //{c:0, name:'_electronics', value:'_electronics', img:'apps'},

    //{c:0, name:'test', value:'test', img:'waves'},
    //{c:0, name:'hw', value:'hw', img:'extension'},
    //{c:0, name:'cards', value:'cards', img:'style'},
    //{c:0, name:'boxes', value:'boxes', img:'inbox'},
    //automotiveb/n. /////
    //{c:0, name:'bike', ;value:'bike', img:'restorant'},
    //toys
    {c:0, name:'toys', value:'toys', img:'toys'},
    //{c:0, name:'pets', value:'pets', img:'pets'},
    //{c:0, name:'sports', value:'sports', img:'rowing'},
    //{c:0, name:'Video Games', value:'video_games', img:'videogame_asset'},
    {c:0, name:'costumes', value:'costume', img:'android'},

    //robotics
    {c:0, name:'electronics', value:'electronics', img:'memory'},
    {c:0, name:'gearbox', value:'gearbox', img:'security'},
    //{c:0, name:'robot', value:'robot', img:'adb'},
    //{c:0, name:'robotArm', value:'robot_arm', img:'precision_manufacturing'},

    //constructor
    //{c:0, name:'r1', value:'r1', img:'adb'},

    //{c:0, name:'c_electronics', value:'c_electronics', img:'memory'},
    //{c:0, name:'c_transmission', value:'c_transmission', img:'settings_application'},
    //{c:0, name:'c_framework', value:'c_framework', img:'widgets'},
    //{c:0, name:'c_connector', value:'c_fasteners', img:'settings_input_component'},

    //tools
    {c:0, name:'tool', value:'tool', img:'square_foot'},
    //{c:0, name:'fixtures', value:'fixtures', img:'wb_iridescent'},
    //{c:0, name:'connectors', value:'connectors', img:'power'},
    //{c:0, name:'agriculture', value:'agriculture', img:'eco'},

    //household
    {c:0, name:'household', value:'household', img:'store_mall_directory'},
    {c:0, name:'furniture', value:'furniture', img:'event_seat'},
    //{c:0, name:'lamps', value:'lamps', img:'wb_incandescent'},
    //{c:0, name:'clock', value:'clock', img:'timer'},
    //{c:0, name:'utencils', value:'utencils', img:'local_dining'},
    //{c:0, name:'audio/video', value:'av', img:'camera_roll'},
    //{c:0, name:'books', value:'books', img: 'import_contacts'},
    //{c:0, name:'child care', value:'child_care', img: 'child_care'},

    {c:0, name:'constructor', value:'constructor', img: 'done'},
    //art
    {c:0, name:'art', value:'art', img:'security'},
    //{c:0, name:'generative art', value:'generative_art', img:'gesture'},
    //{c:0, name:'vase', value:'test', img:'code'},
    //education
    //{c:0, name:'edu', value:'edu', img:'cast_for_education'},
    {c:0, name:'tutorial', value:'tutorial', img:'school'},
    {c:0, name:'FBD', value:'FBD', img:'cast_for_education'},
    {c:0, name:'FBD2', value:'FBD2', img:'cast_for_education'},
    {c:0, name:'FBD3', value:'FBD3', img:'cast_for_education'},
  ];
  partCatObj = {};

  snippetCategory = [
    {c:0, name:'jsCadUI', value:'jscadUI'},
    {c:0, name:'jsCad', value:'jscad'},
    {c:0, name:'iUI', value:'iUI'},
    {c:0, name:'fUI', value:'fUI'},
    {c:0, name:'pUI', value:'pUI'},
    {c:0, name:'i', value:'i'},
    {c:0, name:'f', value:'f'},
    {c:0, name:'p', value:'p'},
    {c:0, name:'sphere', value:'sphere'},
    {c:0, name:'cylinder', value:'cylinder'},
    {c:0, name:'math', value:'math'},
    {c:0, name:'ui', value:'ui'},
    {c:0, name:'slot', value:'slot'},
    {c:0, name:'op', value:'op'},
    {c:0, name:'physics', value:'physics'}
  ];
  snippetUIcode = ['jscadUI', 'math', 'slot', 'op', 'physics'];

  constructor(public root) {
    this.partCategory.forEach(p => {
      this.partCatObj[p.value] = p;
    });
  }
}

function saveCompactCSG(OldCsg) {
  return JSON.stringify(TSON.encapsulate(OldCsg), null, 2);
}
