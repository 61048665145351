import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ContentOnCreate, ContentEmbeddable, ContentDocument} from '../../reactive-content';

import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DbService} from '../../services/db.service';
@Component({
  selector: 'rc-page',
  template: `
    <div *ngIf="content && content.editShow">
      <button class="center" (click)="content.edit = !content.edit">Edit</button></div>
    <div [ngClass]="{'edit-border': content.edit}">
      <ng-container *ngIf="{edit: false} as opt">

        <ng-container *ngIf="content.edit">
          <span class="panel-edit-btn" >
            <button (click)="opt.edit = !opt.edit" >
                <mat-icon>edit</mat-icon>P
            </button>
          <button (click)="addItem('feature'); updateDocument();">+f</button>
          <button (click)="addItem('image'); updateDocument();">+i</button>
          <button (click)="addItem('paragraph'); updateDocument();">+p</button>
          </span>

          <span *ngIf="opt.edit">
            Page Title:<input type="text" [(ngModel)]="root.title">
              <mat-form-field>

                <mat-label>Add Content</mat-label>
                <mat-select #type>
                  <mat-option *ngFor="let type of db.pageItemTypes" [value]="type.value">
                    {{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button (click)="addItem(type.value)">+</button>
            </span>

          <div *ngIf="opt.edit">
            <div cdkDropList (cdkDropListDropped)="drop($event)">
              <div class="box" *ngFor="let child of childList; index as i" cdkDrag>
                {{child.itemKey}} [{{child.type}}]-{{child.obj.root | json}}<button (click)="deleteChild(child.itemKey)">X</button></div>
            </div>
            <div>
              <button (click)="updateDocument()">Update Doc</button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container #embed></ng-container>
    </div>
  `,
  styles: [`

  `]
})
export class PageComponent implements ContentOnCreate, ContentEmbeddable {

  @ViewChild('embed', { read: ViewContainerRef, static: true })
  public embed: ViewContainerRef;

  childList = [];
  content;
  root;
  error;
  addItem(type) {
    let name = this.childList.length+1;
    if (this.root._embedded.hasOwnProperty(name)) {
      name = this.childList.length+2;
    }
    this.root._embedded[name] = this.db.createPageItem(type);
    this.genChildList();
  }
  deleteChild(child) {
    if (this.root._embedded.hasOwnProperty(child)) {
      delete this.root._embedded[child];
      this.genChildList();
    } else {
      this.error = child + ' does not exist. Failed to be removed.';
    }
  }

  constructor(
    private title: Title,
    public db: DbService
  ) { }

  contentOnCreate(values: { [key: string]: any; }, content): void {
    this.content = content;
    this.root = values;
    this.title.setTitle(this.root.title);
    this.genChildList();
  }

  contentEmbeddable(): ViewContainerRef {
    return this.embed;
  }
  genChildList() {
    const self = this;
    self.childList = [];
    if (this.root._embedded && Object.keys(this.root._embedded).length > 0) {
        Object.keys(self.root._embedded).sort().forEach((key: string) => {
          self.childList.push({ itemKey: key, type: self.root._embedded[key]._type, obj: self.root._embedded[key] });
        });
      }
    }
  drop(event: CdkDragDrop<any[]>) {
    const self = this;
    moveItemInArray(this.childList, event.previousIndex, event.currentIndex);
    let newSet = {};
    self.childList.forEach((c, i) => {
      newSet[i] = c.obj;
    });
    this.root._embedded = newSet;
  }
  updateDocument() {
    this.content.updateHostedContent(this.content.document);
  }

}
