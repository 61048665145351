import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {PartsComponent} from './pages/parts/parts.component';
import {EditorComponent} from './pages/editor/editor.component';
import {UserProfileComponent} from './pages/user-profile/user-profile.component';
import {ItemComponent} from './pages/item/item.component';
import {PartComponent} from './pages/part/part.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'item/:name', component: ItemComponent},
  { path: 'parts', component: PartsComponent },
  { path: 'parts/:id', component: PartsComponent },
  { path: 'part/:id', component: PartComponent },
  { path: 'parts/:id/:category', component: PartsComponent },
  { path: 'parts/:id/:category/:name', component: PartsComponent },
  { path: 'editor', component: EditorComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'editor/:id', component: EditorComponent },
  { path: 'editor/:id/:view', component: EditorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
